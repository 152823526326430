export const hostSelectCustomStyles = {
    // Estilos para o container principal do select
    control: (base) => ({
      ...base,
      minHeight: '30px',  // Reduz a altura mínima
      height: '30px',     // Define uma altura fixa menor
      color: "var(--text-color)",
      border: '1px solid var(--host-r24-border-color)',
      borderRadius: '0.375rem',
      cursor: 'pointer',
      boxShadow: 'none',
      transition: 'all 0.2s ease',
      '& .select__placeholder': {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
      },
      '&:hover': {
        borderColor: '#3b82f6'
      }
    }),

    // Estilos para o menu dropdown
    menu: (base, state) => ({
        ...base,
        color: "var(--text-color)",
        animation: state.selectProps.menuIsOpen
          ? 'slideDown 0.3s ease-in-out'
          : 'slideUp 0.3s ease-in-out',
        borderRadius: '0.375rem',
        boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1)',
        // Animação de entrada do menu dropdown
        '@keyframes slideDown': {
          from: {
            opacity: 0,
            transform: 'translateY(-10px)'
          },
          to: {
            opacity: 1,
            transform: 'translateY(0)'
          }
        },
        // Animação de saída do menu dropdown
        '@keyframes slideUp': {
          from: {
            opacity: 1,
            transform: 'translateY(0)'
          },
          to: {
            opacity: 0,
            transform: 'translateY(-10px)'
          }
        }
      }),

    // Estilos para cada opção no dropdown
    option: (base, state) => ({
      ...base,
      // Cores de fundo baseadas no estado (selecionado, hover, padrão)
      backgroundColor: state.isSelected
        ? '#DDEBF6'
        : state.isFocused
          ? '#f3f4f6'
          : 'white',
      color: "var(--text-color)",
      fontSize: '12px',
      fontFamily: 'Poppins, sans-serif',
      padding: '0.5rem',
      transition: 'all 0.2s ease',
      cursor: 'pointer',
      borderRadius: '0.375rem',
      fontWeight: state.isSelected ? '600' : '400', // Negrito para item selecionado
      // Efeito hover
      '&:hover': {
        backgroundColor: state.isSelected ? '' : 'var(--host-r24-hover-color)'
      }
    }),

    // Estilos para a lista que contém as opções
    menuList: (base) => ({
      ...base,
      padding: '4px',
    }),

    // Estilos para o valor selecionado que aparece no select
    singleValue: (base) => ({
      ...base,
      color: "var(--text-color)",
      fontSize: '12px',
      fontFamily: 'Poppins, sans-serif',
    }),

    // Estilos para o indicador de dropdown (seta)
    dropdownIndicator: (base, state) => ({
      ...base,
      color: "var(--host-r24-border-color-3)", // Cor da seta
      transition: 'transform 0.2s ease',
      transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null,
      margin: '0px 6px',
      padding: '0px',
      display: 'flex',
      alignItems: 'center' // Alinha verticalmente // Rotação da seta quando aberto
    }),

    // Estilos para o container de indicadores (inclui a seta de dropdown)
    indicatorsContainer: (base) => ({
      ...base,
      color: "var(--host-r24-text-color)"

    }),

    indicatorSeparator: () => ({
      display: "none"

    }),

    // Estilos para o container do valor
    valueContainer: (base) => ({
      ...base,

    }),

    // Estilos para quando o select está com foco
    input: (base) => ({
      ...base,
      color: "var(--host-r24-text-color)",
    }),

    // Estilos para o placeholder
    placeholder: (base) => ({
      ...base,
      color: "var(--host-r24-text-color-secondary)",
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      fontSize: '12px',
    }),
  };

  export const hostSelectCustomStylesModal = {
    // Estilos para o container principal do select
    control: (base) => ({
      ...base,
      minHeight: '32px',
      height: '32px',
      color: "var(--text-color)",
      border: '1px solid var(--host-r24-border-color)',
      borderRadius: '0.375rem',
      cursor: 'pointer',
      boxShadow: 'none',
      transition: 'all 0.2s ease',
      display: 'flex',
      alignItems: 'center',
      '&:hover': {
        borderColor: '#3b82f6'
      },
      padding: '0',
    }),

    // Estilos para o menu dropdown
    menu: (base, state) => ({
      ...base,
      color: "var(--text-color)",
      animation: state.selectProps.menuIsOpen
        ? 'slideDown 0.3s ease-in-out'
        : 'slideUp 0.3s ease-in-out',
      borderRadius: '0.375rem',
      boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1)',
      '@keyframes slideDown': {
        from: {
          opacity: 0,
          transform: 'translateY(-10px)'
        },
        to: {
          opacity: 1,
          transform: 'translateY(0)'
        }
      },
      '@keyframes slideUp': {
        from: {
          opacity: 1,
          transform: 'translateY(0)'
        },
        to: {
          opacity: 0,
          transform: 'translateY(-10px)'
        }
      }
    }),

    // Estilos para cada opção no dropdown
    option: (base, state) => ({
      ...base,
      backgroundColor: state.isSelected
        ? '#DDEBF6'
        : state.isFocused
          ? '#f3f4f6'
          : 'white',
      color: "var(--text-color)",
      fontSize: '12px',
      fontFamily: 'Poppins, sans-serif',
      padding: '0.5rem',
      transition: 'all 0.2s ease',
      cursor: 'pointer',
      borderRadius: '0.375rem',
      fontWeight: state.isSelected ? '600' : '400',
      '&:hover': {
        backgroundColor: state.isSelected ? '' : 'var(--host-r24-hover-color)'
      }
    }),

    // Estilos para a lista que contém as opções
    menuList: (base) => ({
      ...base,
      padding: '4px',
    }),

    // Estilos para o valor selecionado que aparece no select
    singleValue: (base) => ({
      ...base,
      color: "var(--text-color)",
      fontSize: '12px',
      fontFamily: 'Poppins, sans-serif',
      margin: '0',
      display: 'flex',
      alignItems: 'center',
      height: '100%',
      paddingLeft: '0',
      position: 'absolute', // Modificado para posicionamento absoluto
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: 'calc(100% - 8px)', // Garante que não ultrapasse o container
    }),

    // Estilos para o indicador de dropdown (seta)
    dropdownIndicator: (base, state) => ({
      ...base,
      color: "var(--host-r24-border-color-3)",
      transition: 'transform 0.2s ease',
      transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null,
      margin: '0px 6px',
      padding: '0px',
      display: 'flex',
      alignItems: 'center',
      height: '100%',
    }),

    // Estilos para o container de indicadores (inclui a seta de dropdown)
    indicatorsContainer: (base) => ({
      ...base,
      color: "var(--host-r24-text-color)",
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      padding: '0',
    }),

    indicatorSeparator: () => ({
      display: "none"
    }),

    // Modificado para resolver o problema do cursor
    valueContainer: (base) => ({
      ...base,
      paddingLeft: '8px',
      paddingRight: '8px',
      paddingTop: '0',
      paddingBottom: '0',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      margin: '0',
      position: 'relative',
      flexWrap: 'nowrap',
      overflow: 'hidden',
      gridGap: '0',
    }),

    // Modificado para posicionar corretamente o cursor
    inputContainer: (base) => ({
      ...base,
      padding: '0',
      margin: '0',
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      width: '100%', // Garante que ocupa toda a largura disponível
    }),

    // Estilos para quando o select está com foco - com cursor no início
    input: (base, state) => ({
      ...base,
      color: "var(--host-r24-text-color)",
      margin: '0',
      padding: '0',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      width: '100%', // Importante para ocupar todo o espaço
      border: 'none !important',
      boxShadow: 'none !important',
      outline: 'none !important',
      caretColor: 'var(--host-r24-text-color)',
      position: 'relative',
      opacity: state.hasValue ? 1 : 1, // Garante que seja sempre visível
      '& input': {
        border: 'none !important',
        outline: 'none !important',
        boxShadow: 'none !important',
        padding: '0 !important',
        margin: '0 !important',
        textAlign: 'left !important',
        textIndent: '0 !important',
        width: '100% !important', // Ocupa toda a largura
        position: 'relative !important',
        cursor: 'text !important',
        caretColor: 'var(--host-r24-text-color) !important',
        left: '0 !important',
      }
    }),

    // Placeholder com posicionamento absoluto
    placeholder: (base, { hasValue, isFocused }) => ({
      ...base,
      color: "var(--host-r24-text-color-secondary)",
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      fontSize: '12px',
      margin: '0',
      padding: '0',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      opacity: (isFocused || hasValue) ? 0 : 1,
      transition: 'opacity 150ms ease-in-out',
    }),
  };