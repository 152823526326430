/**
 * Mapeamento básico entre códigos de campo e componentes do formulário
 */
export const FIELD_COMPONENT_MAPPING = {
    "NAME2": "FirstName",  // FirstName
    "NAME1": "LastName",   // LastName
    "BIRTHDATE": "BirthDate", // BirthDate
    "VATNUMBER": "Nif", // Nif
    "NATIONALITY": "Nationality", // Nationality
    "SEX": "Gender", // Gender
    "PASSTYPE": "DocumentType", // DocumentType
    "DOCNUMBER": "DocumentNumber", // DocumentNumber
    "DocExpiryDate": "DocumentExpireDate", // DocumentExpireDate
    "ISSUEAUTHOR": "DocumentAuthor", // DocumentAuthor
    "EMAIL1": "Email", // Email
    "TELEPHONE1": "Phone", // Phone
    "MOBILEPHONE1": "Mobile", // Mobile
    "TITLESHORT_ID": "ShortTitle", // TitleShort_Id
    "TITLELONG_ID": "LongTitle", // TitleLong_Id
    "TITLE_ID": "Title", // Title_Id
    "ADDRESS1": "Address1", // Address1
    "ZIPCODE": "ZipCode", // ZipCode
    "TOWN": "Town", // Town
    "COUNTRY_ID": "Country", // Country_Id
    "ADDRESS2": "Address2", // Address2
    "ADDRESS3": "Address3", // Address3
    "ADDRESSSTATE": "AddressState", // AddressState
    "REGION_ID": "Region", // Region_Id
    "VEHICLENUMBER": "VehicleNumber", // VehicleNumber
    "COMPANYVATNUMBER": "CompanyVatNumber", // CompanyVatNumber
    "COMPANYDESIGNATION": "CompanyDesignation", // CompanyDesignation
    "COMPANYADDRESS": "CompanyAddress", // CompanyAddress
    "COMPANYZIPCODE": "CompanyZipCode", // CompanyZipCode
    "COMPANYTOWN": "CompanyTown", // CompanyTown
    "COMPANYCOUNTRY_ID": "CompanyCountry", // CompanyCountry_Id
    "INDIVIDUALVATNUMBER": "IndividualVatNumber", // IndividualVatNumber
    "INDIVIDUALNAME1": "IndividualLastName", // IndividualLastName
    "INDIVIDUALNAME2": "IndividualFirstName", // IndividualFirstName
    "INDIVIDUALADDRESS": "IndividualAddress", // IndividualAddress
    "INDIVIDUALZIPCODE": "IndividualZipCode", // IndividualZipCode
    "INDIVIDUALTOWN": "IndividualTown", // IndividualTown
    "INDIVIDUALCOUNTRY_ID": "IndividualCountry", // IndividualCountry_Id
  };

  /**
   * Verifica se um campo deve ser visível para um determinado tipo de hóspede
   * @param {Object} field - O objeto de campo com as regras
   * @param {Object} guest - Os dados do hóspede
   * @returns {Object} - Objeto com informações de visibilidade e obrigatoriedade
   */
  export const CheckFieldVisibility = (field, guest, state) => {

    if (!field || !guest || !guest.data) {
      return { isVisible: false, isMandatory: false };
    }

    const hotelCountryId =state?.hotelInitialData?.CountryList?.find((country)=>country.IsoCode === state?.currentHotel?.hotelCountry)?.Id ;

    let isVisible = false;
    let isMandatory = false;

    if (field.Rules.length > 0) {

      field.Rules.forEach(rule => {

        if(rule.AppliesTo === "main_guest" && guest.data.IsMainGuest === true) {
          if(rule.HotelCountryId === hotelCountryId && rule.GuestCountryId === guest.data.CountryId) {
            isVisible = rule.IsVisible;
            isMandatory = rule.IsMandatory;
          } else {
            isVisible = field.ActiveOnMainGuest === true;
            isMandatory = field.MandatoryOnMainGuest === true;
          }
        }
        if(rule.AppliesTo === "adults" && guest.data.IsMainGuest === false && guest.data.IsChildren === false) {
          if(rule.HotelCountryId === hotelCountryId && rule.GuestCountryId === guest.data.CountryId) {
            isVisible = rule.IsVisible;
            isMandatory = rule.IsMandatory;
          } else {
            isVisible = field.ActiveOnAdult === true;
            isMandatory = field.MandatoryOnAdult === true;
          }
        }
        if(rule.AppliesTo === "children" && guest.data.IsChildren === true) {
          if(rule.HotelCountryId === hotelCountryId && rule.GuestCountryId === guest.data.CountryId) {
            isVisible = rule.IsVisible;
            isMandatory = rule.IsMandatory;
          } else {
            isVisible = field.ActiveOnChildren === true;
            isMandatory = field.MandatoryOnChildren === true;
          }
        }
      });

    } else {

    if (guest.data.IsMainGuest === true) {
      isVisible = field.ActiveOnMainGuest === true;
      isMandatory = field.MandatoryOnMainGuest === true;
    } else if (guest.data.IsChildren === true) {
      isVisible = field.ActiveOnChildren === true;
      isMandatory = field.MandatoryOnChildren === true;
    } else {
      isVisible = field.ActiveOnAdult === true;
      isMandatory = field.MandatoryOnAdult === true;
      }
    }

    return { isVisible, isMandatory };
  };

