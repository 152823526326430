import { useContext, useEffect, useRef, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import { getDefaultPreviewInitialData, getReservationByDetailId } from "../../services";
import { toast } from "react-toastify";
import { MainSection } from "./components/MainSection";
import { AppContext } from "../../context/AppContext";
import { AnimatePresence, motion } from "framer-motion";
import { SliderSection } from "./components/SliderSection";
import { NotificationsBanner } from "./components/NotificationsBanner";
import { Modal } from "./components/Modal";
import { previewCurrentHotel, previewHomeReservationsData, previewMenuEN, previewMenuPT, previewReservation } from "./Utils/previewModeData";

/**
* @module HotelHome
* @description Core module for rendering individual hotel reservation details page.
* Manages reservation data, animations, and modal interactions.
*/

/**
* @namespace HotelHome
* @memberof module:HotelHome
* @description Main component responsible for displaying hotel reservation menu.
* Handles reservation data fetching, fetching menu elements, section animations and others.
*
* @component
* @returns {JSX.Element} HotelHome page component
*
* @example
* <HotelHome />
*
* @author Tiago Ferreira <tiago.ferreira@hhs.pt>
* @since 1.0.0
* @version 1.0.0
*/
export const HotelHome = () => {
  const { platformUid, detailId } = useParams();
  const [state, dispatch] = useContext(AppContext);
  const [reservation, setReservation] = useState(state.reservation);
  const [cookie, setCookie] = useCookies(["platformUid", "currentHotel", "GUid", "reservation", "detailId"]); // eslint-disable-line
  const [isSubmenuActive, setIsSubmenuActive] = useState(false);
  const [activeSection, setActiveSection] = useState(null);
  const [isAnimating, setIsAnimating] = useState(false); // Novo estado para controlar a animação
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [notificationToShow, setNotificationToShow] = useState(null);
  const [userLanguage, setUserLanguage] = useState(localStorage.getItem("userLanguage"));

  //////////// previewmode ///////////////
const { pathname } = useLocation();
const isPreviewMode = pathname.includes("/previewmode/");
const {defaultThemeId} = useParams()
const [isHovered, setIsHovered] = useState();
const [currentRef, setCurrentRef] = useState(null);
const [inspectMode, setInspectMode] = useState(JSON.parse(localStorage.getItem("inspectMode"))==="true" ? true : false);
const HTPageRef = useRef(null);
const HTCardRef = useRef(null);

const handleDoubleClick = (e, elementRef) => {
  e.stopPropagation();

  // Verifica se o inspectMode está ativo antes de enviar a mensagem
  if (isPreviewMode && inspectMode && elementRef.current) {
    const elementId = elementRef.current.id;

    window.parent.postMessage({
      action: "doubleClick",
      elementId: elementId,
      debugInfo: 'Double click on element to expand category/section'
    }, "*");
  }
};

/**
 * @function useEffectFetchDefaultPreviewInitialData
* @memberof module:Checkout.Checkout
 * @description Effect that loads the initial preview data for the default theme.
 * Fetches and sets the active theme when the component is in preview mode.
 *
 * @effect Updates the active theme in the global application state
 * @dependencies [defaultThemeId] - Executes when the default theme ID changes
 *
 * @example
 * useEffect(() => {
 *   if (isPreviewMode) {
 *     fetchDefaultPreviewInitialData()
 *   }
 * }, [defaultThemeId]);
 *
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @version 1.0.0
 */
useEffect(() => {
const fetchDefaultPreviewInitialData = async () => {
  try {
    const InitialData = await getDefaultPreviewInitialData(defaultThemeId);
    dispatch({ type: "SET_ACTIVE_THEME", payload: InitialData.activeTheme });
  } catch (error) {
    console.error("Error loading initialData-AllRoutes:", error);
  }
}

if (isPreviewMode) {
  fetchDefaultPreviewInitialData()
}
}, [defaultThemeId]); // eslint-disable-line

/**
* @function handleMouseEnter
* @memberof module:Checkout.Checkout
* @description Manages mouse enter events on elements during preview mode.
* Sends messages to the parent component with information about the focused element.
*
* @param {Event} e - Mouse event
* @param {React.RefObject} elementRef - React element reference
*
* @example
* handleMouseEnter(event, myRef)
*
* @author Tiago Ferreira <tiago.ferreira@hhs.pt>
* @since 1.0.0
* @version 1.0.0
*/
const handleMouseEnter = (e, elementRef) => {
e.stopPropagation();

if (isPreviewMode && elementRef.current) {
  const current = elementRef.current;
  window.parent.postMessage({
    action: "hover",
    elementId: current.id,
    elementClass: current.className || '',
    elementTag: current.tagName || '',
    debugInfo: 'Hover on specific hoverable element'
  }, "*");
}
};

/**
* @function handleMouseLeave
* @memberof module:Checkout.Checkout
* @description Handles mouse leave events for elements in preview mode.
* Sends a message to the parent window with element details when hover is removed.
*
* @param {Event} e - Mouse event object
* @param {React.RefObject} elementRef - Reference to the DOM element
*
* @example
* handleMouseLeave(event, myElementRef)
*
* @author Tiago Ferreira <tiago.ferreira@hhs.pt>
* @since 1.0.0
* @version 1.0.0
*/
const handleMouseLeave = (e, elementRef) => {

if (isPreviewMode && elementRef.current) {
  const current = elementRef.current;
  window.parent.postMessage({
    action: "hoverOut",
    elementId: current.id,
    elementClass: current.className || '',
    elementTag: current.tagName || '',
    debugInfo: 'HoverOut from specific hoverable element'
  }, "*");
}
};

/**
* @function handleElementEnter
* @memberof module:Checkout.Checkout
* @description Manages element hover state entry in preview mode.
* Handles state transitions between different hovered elements and triggers
* appropriate mouse enter events.
*
* @param {Event} e - Mouse event object
* @param {React.RefObject} ref - Reference to the target DOM element
* @param {string} id - Unique identifier for the element
*
* @example
* handleElementEnter(event, elementRef, "element-id")
*
* @author Tiago Ferreira <tiago.ferreira@hhs.pt>
* @since 1.0.0
* @version 1.0.0
*/
const handleElementEnter = (e, ref, id) => {
e.preventDefault();
e.stopPropagation();

// Se houver um hover ativo em outro elemento
if (isHovered && isHovered !== id && currentRef) {
    handleMouseLeave(e, currentRef);
}

// Atualiza o estado para o novo elemento
if(inspectMode){
setCurrentRef(ref);
handleMouseEnter(e, ref);
setIsHovered(id);
}
};

/**
* @function handleElementLeave
* @memberof module:Checkout.Checkout
* @description Manages element hover state exit in preview mode.
* Cleans up hover states and references when leaving an element.
*
* @param {Event} e - Mouse event object
* @param {React.RefObject} ref - Reference to the target DOM element
* @param {string} id - Unique identifier for the element
*
* @example
* handleElementLeave(event, elementRef, "element-id")
*
* @author Tiago Ferreira <tiago.ferreira@hhs.pt>
* @since 1.0.0
* @version 1.0.0
*/
const handleElementLeave = (e, ref, id) => {
e.preventDefault();
e.stopPropagation();

// Apenas limpa o estado se for o elemento atual com hover
if (isHovered === id) {
    handleMouseLeave(e, ref);
    setIsHovered(null);
    setCurrentRef(null);
}
};

/**
* @function useEffectMessageHandler
* @memberof module:Checkout.Checkout
* @description Effect that sets up a message event listener for inspect mode toggling.
* Manages the inspect mode state and persists it to localStorage.
*
* @effect Sets up and cleans up message event listener
* @dependencies []
*
* @example
* useEffect(() => {
*   const handleMessage = (event) => { ... };
*   window.addEventListener("message", handleMessage);
*   return () => window.removeEventListener("message", handleMessage);
* }, []);
*
* @author Tiago Ferreira <tiago.ferreira@hhs.pt>
* @since 1.0.0
* @version 1.0.0
*/
useEffect(() => {
const handleMessage = (event) => {
// Log para debug
// console.log("Mensagem recebida no iframe:", event.data);

if (event.data.action === "toggleInspectMode") {
  const newMode = event.data.value;
  setInspectMode(newMode);
  localStorage.setItem("inspectMode", newMode.toString());
}
};

window.addEventListener("message", handleMessage);
return () => window.removeEventListener("message", handleMessage);
}, []);

/**
* @function useEffectThemeUpdate
* @memberof module:Checkout.Checkout
* @description Effect that registers a global theme update function.
* Allows dynamic theme style updates through a globally accessible function.
* Used in preview mode to listen for theme changes and apply them to the global state.
* The global function is exposed to allow parent windows to trigger theme updates.
*
* @effect Sets up and cleans up global theme update function
* @dependencies [dispatch]
*
* @example
* useEffect(() => {
*   window.__THEME_UPDATE__ = (themeStyleCode, value) => { ... };
*   return () => { delete window.__THEME_UPDATE__; };
* }, [dispatch]);
*
* @author Tiago Ferreira <tiago.ferreira@hhs.pt>
* @since 1.0.0
* @version 1.0.0
*/
useEffect(() => {
// Registrar a função de atualização globalmente
window.__THEME_UPDATE__ = (themeStyleCode, value) => {
  // console.log('Theme update function called:', { themeStyleCode, value });
  dispatch({
    type: 'UPDATE_ACTIVE_THEME_STYLE',
    payload: { themeStyleCode, value }
  });
};
return () => {
  delete window.__THEME_UPDATE__;
};
}, [dispatch]);

//////////////////////////////////////////////////////////////////////////

   /**
  * @function handleExitAnimation
  * @memberof module:HotelHome.HotelHome
  * @description Manages the exit animation for sections.
  * Controls animation states and timing for smooth transitions.
  *
  * @example
  * handleExitAnimation()
  *
  * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
  * @since 1.0.0
  * @version 1.0.0
  */
  const handleExitAnimation = () => {
    setIsAnimating(true);
    setActiveSection(null); // Garante que ambas as seções estão saindo juntas
    setTimeout(() => {
      setIsAnimating(false); // Permite novas animações após a saída
    }, 300); // Ajuste a duração conforme necessário
  };

   /**
  * @function fetchReservationByDetailId
  * @memberof module:HotelHome.HotelHome
  * @description Fetches reservation details using detail ID.
  * Updates reservation state and dispatches to global context.
  *
  * @async
  * @returns {Promise<void>}
  *
  * @example
  * await fetchReservationByDetailId()
  *
  * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
  * @since 1.0.0
  * @version 1.0.0
  */
  const fetchReservationByDetailId = async () => {
    if (state.currentHotel?.subscriptionKey && detailId) {
      try {
        const results = await getReservationByDetailId(detailId, state.currentHotel, cookie.sUid);
        setReservation(results[0]);
        dispatch({ type: "SET_USER_RESERVATION", payload: results[0] });
      } catch (error) {
        console.log(error);
        toast.error("Unable to get reservations!");
      }
    }
  };

   /**
  * @function useEffectDetailId
  * @memberof module:HotelHome.HotelHome
  * @description Effect that updates detail ID cookie.
  *
  * @effect Sets detailId cookie
  * @dependencies [detailId]
  *
  * @example
  * useEffect(() => {
  *   setCookie("detailId", detailId, { path: "/" });
  * }, [detailId]);
  *
  * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
  * @since 1.0.0
  * @version 1.0.0
  */
   useEffect(() => {
    setCookie("detailId", detailId, { path: "/" });
  }, [detailId]); // eslint-disable-line

   /**
  * @function useEffectFetchReservation
  * @memberof module:HotelHome.HotelHome
  * @description Effect that triggers reservation fetching.
  * Fetches data when hotel subscription key and detail ID are available.
  *
  * @effect Fetches reservation data
  * @dependencies [detailId, state.currentHotel?.subscriptionKey]
  *
  * @example
  * useEffect(() => {
  *   if (state.currentHotel?.subscriptionKey && detailId) {
  *     fetchReservationByDetailId();
  *   }
  * }, [detailId, state.currentHotel?.subscriptionKey]);
  *
  * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
  * @since 1.0.0
  * @version 1.0.0
  */
  useEffect(() => {
    if(isPreviewMode){
      dispatch({ type: "SET_USER_RESERVATION", payload: previewHomeReservationsData[0].reservations[1] });
      setReservation(previewHomeReservationsData[0].reservations[1])
      return;
    }

    if (state.currentHotel?.subscriptionKey && detailId) {
      fetchReservationByDetailId();
    }
  }, [detailId,state.currentHotel?.subscriptionKey]); // eslint-disable-line

  useEffect(() => {
    if (isPreviewMode) {
        const menuData = userLanguage === "pt-PT" ? previewMenuPT : previewMenuEN
        dispatch({ type:"SET_HOTEL_MENU", payload: menuData });
        dispatch({ type:"SET_USER_RESERVATION", payload: previewReservation });
        dispatch({ type:"SET_CURRENT_HOTEL", payload: previewCurrentHotel });
        return
    }
  }, [state.currentHotel?.subscriptionKey, userLanguage, platformUid, state.userLanguage]);

  return (
    <AnimatePresence mode="wait">
      {state.currentHotel && state.reservation && (
        <motion.div id="HT-Page" ref={HTPageRef} onDoubleClick={(e) => handleDoubleClick(e, HTPageRef)} onMouseOver={(e) => handleElementEnter(e, HTPageRef, "HT-Page")} onMouseLeave={(e) => handleElementLeave(e, HTPageRef, "HT-Page")} key="main-content" className={` ${isPreviewMode && isHovered === "HT-Page" ? "diagonal-stripes" : ""} dark:bg-gxp_dark_2 py-2 w-full HT-bg_color ${isPreviewMode ? "h-screen flex flex-col flex-1" : "h-full"} `} initial={{ opacity: 0, x: -100 }} animate={{ opacity: 1, x: 0 }} exit={{ opacity: 0, x: 100 }} transition={{ duration: 0.3 }} >
          <main className=" max-w-4xl w-full h-full items-center mx-auto ">
            <NotificationsBanner setNotificationToShow={setNotificationToShow} setIsModalOpen={setIsModalOpen} />
            <motion.div id="HT-Card" ref={HTCardRef} onDoubleClick={(e) => handleDoubleClick(e, HTCardRef)} onMouseOver={(e) => handleElementEnter(e, HTCardRef, "HT-Card")} onMouseLeave={(e) => handleElementLeave(e, HTCardRef, "HT-Card")} key="sections-container" className={`${isPreviewMode && isHovered === "HT-Card" ? "diagonal-stripes" : ""} w-full items-center`} initial={{ opacity: 0, x: -100 }} animate={{ opacity: 1, x: 0 }} exit={{ opacity: 0, x: 100 }} transition={{ duration: 0.3 }} >
              {!isAnimating && (activeSection === "MAIN" || activeSection === null) && (
                <MainSection isPreviewMode={isPreviewMode} detailId={detailId} onMenuStateChange={setIsSubmenuActive} posSection="MAIN" setActiveSection={setActiveSection} setIsExiting={handleExitAnimation} />
              )}
               {!isAnimating && (activeSection === "SLIDER" || activeSection === null) && (
                <SliderSection isPreviewMode={isPreviewMode} posSection="SLIDER" className={``} />
              )}
              {!isAnimating && (activeSection === "MAIN-2" || activeSection === null) && (
                <MainSection isPreviewMode={isPreviewMode} detailId={detailId} onMenuStateChange={setIsSubmenuActive} posSection="MAIN-2" setActiveSection={setActiveSection} setIsExiting={handleExitAnimation} />
              )}
            </motion.div>
            <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} initialText={notificationToShow?.Description}  />
          </main>
        </motion.div>
      )}
    </AnimatePresence>
  );
};