import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../context/AppContext";
import { previewHotelInitialData, previewProfile } from "../Utils/previewModeData";
import { useLocation } from "react-router-dom";

export const HeadersPageCK = ({ title, room }) => {
  const [t] = useTranslation("global");
  const [cookie] = useCookies(["guest"]);
  const [imageUrl, setImageUrl] = useState("");
  const [logoUrl, setLogoUrl] = useState("");
  const [state] = useContext(AppContext);
  const [militaryMode, setMilitaryMode]= useState(false)
  const [post, setPost] = useState("")
  const [guest, setGuest] = useState()
  const [titles, setTitles]= useState({})
  const [titlesLoading, setTitlesLoading] = useState(true);
  const { pathname } = useLocation();
  const isPreviewMode = pathname.includes("/previewmode/");
  const [initialData, setInitialData] = useState(isPreviewMode ? previewHotelInitialData : state.hotelInitialData);
  const [roomNumber, setRoomNumber] = useState(isPreviewMode? "744" : room);

  useEffect(() => {
    if (state.activeTheme) {
      const imageUrl = state.activeTheme?.find(
        (theme) => theme.themeStyleCode === "CK-Header-image"
      )?.value;
      setImageUrl(imageUrl);
    }

    if (state.activeTheme) {
      const logoUrl = state.activeTheme?.find(
        (theme) => theme.themeStyleCode === "CK-Header-logo_image"
      )?.value;
      setLogoUrl(logoUrl);
    }
  }, [state.activeTheme]);

  useEffect(()=>{
    const guest = state.userDetails
    if(militaryMode && titles && guest){
      const newTitle = titles?.result?.title.find((element) => element.ID === guest.data.Title_Id)
      setPost(newTitle)
    }
  },[state.userDetails, titles, militaryMode])

  useEffect(() => {
    if(isPreviewMode){
      setTitles(previewHotelInitialData.Titles)
      setInitialData(previewHotelInitialData);
      setMilitaryMode(previewHotelInitialData?.Parameters?.MilitaryMode);
      return
    }
    if(state.hotelInitialData){
      setTitles(state.hotelInitialData.Titles)
      setInitialData(state.hotelInitialData);
      setMilitaryMode(state.hotelInitialData?.Parameters?.MilitaryMode);
    }
  }, [state.hotelInitialData]); // eslint-disable-line

  useEffect(() => {
    if(isPreviewMode){
      setGuest(previewProfile)
      return
    }
    if (state.userDetails) {
      setGuest(state.userDetails)
    }
  }, [state.userDetails]);

  return (
    <section>
      <div className="sm:hidden">
        <div
          className="flex-col text-white flex justify-between bg-cover h-40"
          style={{ backgroundImage: `url(${imageUrl})` }}
        >
          <div className="flex justify-center items-center h-full w-ful">
          { logoUrl!=="" &&
            <img src={logoUrl} className={`w-1/4`} alt="logo" />
          }
          </div>
          <div className="flex ml-6">
            <h1
              className={`dark:text-white CK-Header-title_color CK-Header-title_size CK-Header-title_font_family CK-Header-title_variant mb-2 sm:mb-4`}
            >
              {" "}
              {title}{" "}
            </h1>
          </div>
        </div>
        <div className="dark:bg-gxp_dark_1 dark:border-white dark:text-white CK-Header-text_color CK-Header-text_size CK-Header-text_font_family CK-Header-text_variant CK-Header-bar_bg_color CK-Header-bar_border_color CK-Header-bar_border_width CK-Header-bar_border_rounded CK-Header-bar_border_position w-full py-4 items-center flex justify-between">
          {militaryMode && post ? (
            <h1 className="ml-6">{`${post.Description} ${guest?.LastName}`}</h1>
          ) : (
            <h1 className="ml-6">
              {" "}
              {t("Checkout.welcome")} {guest?.FirstName} {guest?.LastName}{" "}
            </h1>
          )}
          <span className="mr-6">
            {" "}
            {roomNumber === "" || roomNumber === undefined || roomNumber === null
              ? ""
              : `${t("Checkout.room")} ${roomNumber}`}{" "}
          </span>
        </div>
      </div>

      <div className="max-sm:hidden lg:hidden">
        <div
          className="flex-col text-white flex justify-between bg-cover h-52"
          style={{ backgroundImage: `url(${imageUrl})` }}
        >
          <div className="flex justify-center items-center h-full w-ful">
          { logoUrl!=="" &&
            <img src={logoUrl} className={`h-1/2`} alt="logo" />
          }
          </div>
          <div className="flex ml-6">
            <h1
              className={`dark:text-white CK-Header-title_color CK-Header-title_size CK-Header-title_font_family CK-Header-title_variant mb-2 sm:mb-4`}
            >
              {" "}
              {title}{" "}
            </h1>
          </div>
        </div>
        <div className="dark:bg-gxp_dark_1 dark:border-white dark:text-white CK-Header-text_color CK-Header-text_size CK-Header-text_font_family CK-Header-text_variant CK-Header-bar_bg_color CK-Header-bar_border_color CK-Header-bar_border_width CK-Header-bar_border_rounded CK-Header-bar_border_position w-full py-4 items-center flex justify-between">
          {militaryMode && post ? (
            <h1 className="ml-6">{`${post.Description} ${guest?.LastName}`}</h1>
          ) : (
            <h1 className="ml-6">
              {" "}
              {t("Checkout.welcome")} {guest?.FirstName} {guest?.LastName}{" "}
            </h1>
          )}
          <span className="mr-6">
            {" "}
            {roomNumber === "" || roomNumber === undefined || roomNumber === null
              ? ""
              : `${t("Checkout.room")} ${roomNumber}`}
          </span>
        </div>
      </div>

      <div className="max-lg:hidden">
        <div
          className="flex-col text-white flex justify-between bg-cover h-52"
          style={{ backgroundImage: `url(${imageUrl})` }}
        >
          <div className="flex justify-center items-center h-full w-ful">
          { logoUrl!=="" &&
            <img src={logoUrl} className={`h-2/3`} alt="logo" />
          }
          </div>
          <div className="flex ml-6">
            <h1
              className={`dark:text-white CK-Header-title_color CK-Header-title_size font-bold CK-Header-title_font_family CK-Header-title_variant mb-2 sm:mb-4`}
            >
              {" "}
              {title}{" "}
            </h1>
          </div>
        </div>
        <div className="dark:bg-gxp_dark_1 dark:border-white dark:text-white CK-Header-text_color CK-Header-text_size CK-Header-text_font_family CK-Header-text_variant CK-Header-bar_bg_color CK-Header-bar_border_color CK-Header-bar_border_width CK-Header-bar_border_rounded CK-Header-bar_border_position w-full py-4 items-center flex justify-between">
          {militaryMode && post ? (
            <h1 className="ml-6">{`${post.Description} ${guest?.LastName}`}</h1>
          ) : (
            <h1 className="ml-6">
              {" "}
              {t("Checkout.welcome")} {guest?.FirstName} {guest?.LastName}{" "}
            </h1>
          )}
          <span className="mr-6">
            {" "}
            {roomNumber === "" || roomNumber === undefined || roomNumber === null
              ? ""
              : `${t("Checkout.room")} ${roomNumber}`}{" "}
          </span>
        </div>
      </div>
    </section>
  );
};
