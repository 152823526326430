import { useContext, useEffect, useRef, useState } from "react";
import { getDefaultPreviewInitialData, getEMenuDetails } from "../../services";
import { useLocation, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";
import { toast } from "react-toastify";
import { organizeMenuData } from "./Components/utils/OrganizeMenuData";
import { MenuLayout } from "./MenuLayout";
import { MenuDetail } from "./MenuDetail";
import { MenuHome } from "./MenuHome";
import { findItemById } from "./Components/utils/findItemById";
import { Loader } from "../../components/Layout/components/Loader/Loader";
import { searchInItems } from "./Components/utils/searchInItems";
import { MenuItem } from "./MenuItem";
import { AppContext } from "../../context/AppContext";
import { Modal } from "./Components/Modals/Modal";
import { previewEMenuData } from "./Utilities/previewModeData";

/**
 * @module EMenu
 * @description Electronic menu system module that handles menu display, happy hour calculations,
 * and dynamic content organization. Provides functionality for viewing menu items, categories,
 * and handling special pricing scenarios.
 */

/**
 * @namespace EMenu
 * @memberof module:EMenu
 * @description Main component for the electronic menu system. Manages menu data,
 * happy hour calculations, and view states.
 *
 * @component
 * @returns {JSX.Element} Complete electronic menu interface
 *
 * @example
 * // Usage in router
 * <Route path="/menu/:key/:itemId?" element={<EMenu />} />
 *
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @since 1.0.0
 * @version 1.0.0
 */
export const EMenu = () => {
  const [serverTime, setServerTime] = useState();
  const { key, itemId } = useParams();
  const [t] = useTranslation("global");
  const [cookie, setCookie] = useCookies([
    "currentHotel",
    "cultureCode",
    "platformUid",
    "detailId",
    "sUid",
  ]); // eslint-disable-line
  const [isLoading, setIsLoading] = useState(true);
  const [service, setService] = useState([]);
  const [organized, setOrganized] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [happyHour, setHappyHour] = useState();
  const [userLanguage, setUserLanguage] = useState(
    localStorage.getItem("userLanguage")
  );
  const [state, dispatch] = useContext(AppContext);
  const [isModalOpen, setIsModalOpen] = useState(true);

  //////////// previewmode  ///////////////
  const { pathname } = useLocation();
  const isPreviewMode = pathname.includes("/previewmode/");
  const { defaultThemeId } = useParams();
  const [isHovered, setIsHovered] = useState();
  const [currentRef, setCurrentRef] = useState(null);
  const [inspectMode, setInspectMode] = useState(
    JSON.parse(localStorage.getItem("inspectMode")) === "true" ? true : false
  );
  const EMPageRef = useRef(null);
  const EMSearchbarRef = useRef(null);
  const EMEMenuCardRef = useRef(null);
  const EMCardRef = useRef(null);

  const handleDoubleClick = (e, elementRef) => {
    e.stopPropagation();

    // Verifica se o inspectMode está ativo antes de enviar a mensagem
    if (isPreviewMode && inspectMode && elementRef.current) {
      const elementId = elementRef.current.id;

      console.log("elementId", elementRef.current.id);

      window.parent.postMessage({
        action: "doubleClick",
        elementId: elementId,
        debugInfo: 'Double click on element to expand category/section'
      }, "*");
    }
  };

  /**
   * @function useEffectFetchDefaultPreviewInitialData
   * @memberof module:Home.Home
   * @description Effect that loads the initial preview data for the default theme.
   * Fetches and sets the active theme when the component is in preview mode.
   *
   * @effect Updates the active theme in the global application state
   * @dependencies [defaultThemeId] - Executes when the default theme ID changes
   *
   * @example
   * useEffect(() => {
   *   if (isPreviewMode) {
   *     fetchDefaultPreviewInitialData()
   *   }
   * }, [defaultThemeId]);
   *
   * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @version 1.0.0
   */
  useEffect(() => {
    const fetchDefaultPreviewInitialData = async () => {
      try {
        const InitialData = await getDefaultPreviewInitialData(defaultThemeId);
        dispatch({
          type: "SET_ACTIVE_THEME",
          payload: InitialData.activeTheme,
        });
      } catch (error) {
        console.error("Error loading initialData-AllRoutes:", error);
      }
    };

    if (isPreviewMode) {
      fetchDefaultPreviewInitialData();
    }
  }, [defaultThemeId]); // eslint-disable-line

  /**
   * @function handleMouseEnter
   * @memberof module:Home.Home
   * @description Manages mouse enter events on elements during preview mode.
   * Sends messages to the parent component with information about the focused element.
   *
   * @param {Event} e - Mouse event
   * @param {React.RefObject} elementRef - React element reference
   *
   * @example
   * handleMouseEnter(event, myRef)
   *
   * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @since 1.0.0
   * @version 1.0.0
   */
  const handleMouseEnter = (e, elementRef) => {
    e.stopPropagation();

    if (isPreviewMode && elementRef.current) {
      const current = elementRef.current;
      window.parent.postMessage(
        {
          action: "hover",
          elementId: current.id,
          elementClass: current.className || "",
          elementTag: current.tagName || "",
          debugInfo: "Hover on specific hoverable element",
        },
        "*"
      );
    }
  };

  /**
   * @function handleMouseLeave
   * @memberof module:Home.Home
   * @description Handles mouse leave events for elements in preview mode.
   * Sends a message to the parent window with element details when hover is removed.
   *
   * @param {Event} e - Mouse event object
   * @param {React.RefObject} elementRef - Reference to the DOM element
   *
   * @example
   * handleMouseLeave(event, myElementRef)
   *
   * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @since 1.0.0
   * @version 1.0.0
   */
  const handleMouseLeave = (e, elementRef) => {
    if (isPreviewMode && elementRef.current) {
      const current = elementRef.current;
      window.parent.postMessage(
        {
          action: "hoverOut",
          elementId: current.id,
          elementClass: current.className || "",
          elementTag: current.tagName || "",
          debugInfo: "HoverOut from specific hoverable element",
        },
        "*"
      );
    }
  };

  /**
   * @function handleElementEnter
   * @memberof module:Home.Home
   * @description Manages element hover state entry in preview mode.
   * Handles state transitions between different hovered elements and triggers
   * appropriate mouse enter events.
   *
   * @param {Event} e - Mouse event object
   * @param {React.RefObject} ref - Reference to the target DOM element
   * @param {string} id - Unique identifier for the element
   *
   * @example
   * handleElementEnter(event, elementRef, "element-id")
   *
   * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @since 1.0.0
   * @version 1.0.0
   */
  const handleElementEnter = (e, ref, id) => {
    e.preventDefault();
    e.stopPropagation();

    // Se houver um hover ativo em outro elemento
    if (isHovered && isHovered !== id && currentRef) {
      handleMouseLeave(e, currentRef);
    }

    // Atualiza o estado para o novo elemento
    if (inspectMode) {
      setCurrentRef(ref);
      handleMouseEnter(e, ref);
      setIsHovered(id);
    }
  };

  /**
   * @function handleElementLeave
   * @memberof module:Home.Home
   * @description Manages element hover state exit in preview mode.
   * Cleans up hover states and references when leaving an element.
   *
   * @param {Event} e - Mouse event object
   * @param {React.RefObject} ref - Reference to the target DOM element
   * @param {string} id - Unique identifier for the element
   *
   * @example
   * handleElementLeave(event, elementRef, "element-id")
   *
   * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @since 1.0.0
   * @version 1.0.0
   */
  const handleElementLeave = (e, ref, id) => {
    e.preventDefault();
    e.stopPropagation();

    // Apenas limpa o estado se for o elemento atual com hover
    if (isHovered === id) {
      handleMouseLeave(e, ref);
      setIsHovered(null);
      setCurrentRef(null);
    }
  };

  /**
   * @function useEffectMessageHandler
   * @memberof module:Home.Home
   * @description Effect that sets up a message event listener for inspect mode toggling.
   * Manages the inspect mode state and persists it to localStorage.
   *
   * @effect Sets up and cleans up message event listener
   * @dependencies []
   *
   * @example
   * useEffect(() => {
   *   const handleMessage = (event) => { ... };
   *   window.addEventListener("message", handleMessage);
   *   return () => window.removeEventListener("message", handleMessage);
   * }, []);
   *
   * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @since 1.0.0
   * @version 1.0.0
   */
  useEffect(() => {
    const handleMessage = (event) => {
      // Log para debug
      // console.log("Mensagem recebida no iframe:", event.data);

      if (event.data.action === "toggleInspectMode") {
        const newMode = event.data.value;
        setInspectMode(newMode);
        localStorage.setItem("inspectMode", newMode.toString());
      }
    };

    window.addEventListener("message", handleMessage);
    return () => window.removeEventListener("message", handleMessage);
  }, []);

  /**
   * @function useEffectThemeUpdate
   * @memberof module:Home.Home
   * @description Effect that registers a global theme update function.
   * Allows dynamic theme style updates through a globally accessible function.
   * Used in preview mode to listen for theme changes and apply them to the global state.
   * The global function is exposed to allow parent windows to trigger theme updates.
   *
   * @effect Sets up and cleans up global theme update function
   * @dependencies [dispatch]
   *
   * @example
   * useEffect(() => {
   *   window.__THEME_UPDATE__ = (themeStyleCode, value) => { ... };
   *   return () => { delete window.__THEME_UPDATE__; };
   * }, [dispatch]);
   *
   * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @since 1.0.0
   * @version 1.0.0
   */
  useEffect(() => {
    // Registrar a função de atualização globalmente
    window.__THEME_UPDATE__ = (themeStyleCode, value) => {

      dispatch({
        type: "UPDATE_ACTIVE_THEME_STYLE",
        payload: { themeStyleCode, value },
      });
    };
    return () => {
      delete window.__THEME_UPDATE__;
    };
  }, [dispatch]);

  //////////////////////////////////////////////////////////////////////////

  /**
   * @function updateServerTime
   * @memberof module:EMenu.EMenu
   * @description Updates the server time by incrementing one minute.
   * Used for synchronizing menu display with server time.
   *
   * @param {string} initialTime - ISO string of initial server time
   * @returns {void}
   *
   * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @since 1.0.0
   * @version 1.0.0
   */
  const updateServerTime = (initialTime) => {
    const currentServerTime = new Date(initialTime);
    currentServerTime.setMinutes(currentServerTime.getMinutes() + 1);
    setServerTime(currentServerTime.toISOString());
  };

  /**
   * @function loadEmenuDetails
   * @memberof module:EMenu.EMenu
   * @description Loads menu details from the server including happy hour information.
   * Handles error states and loading states.
   *
   * @async
   * @returns {Promise<void>}
   *
   * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @since 1.0.0
   * @version 1.0.0
   */
  const loadEmenuDetails = async () => {
    if (key && state.currentHotel && userLanguage) {
      try {
        setIsLoading(true);
        const details = await getEMenuDetails(
          key,
          userLanguage,
          state.currentHotel
        );
        setServerTime(details.ServerTime);
        setService(details);
        if (details.HappyHours.length > 0) {
          setHappyHour(details.HappyHours);
        }
      } catch (error) {
        console.error("Error loading EMenu Headers:", error);
        toast.error(t("OnlineCheckIn.errorhost"));
      } finally {
        setIsLoading(false);
      }
    }
  };

  /**
   * @function useEffectLanguage
   * @memberof module:EMenu.EMenu
   * @description Effect that synchronizes the user language state with localStorage.
   * Updates language state when localStorage language changes.
   *
   * @effect Updates userLanguage state
   * @dependencies [localStorage.getItem("userLanguage")]
   *
   * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @since 1.0.0
   * @version 1.0.0
   */
  useEffect(() => {
    setUserLanguage(localStorage.getItem("userLanguage"));
  }, [localStorage.getItem("userLanguage")]);

  /**
   * @function useEffectLoadMenu
   * @memberof module:EMenu.EMenu
   * @description Effect that triggers the loading of menu details when key dependencies change.
   * Initiates menu data fetching when hotel, language, or menu key updates.
   * This effect is crucial for maintaining menu data synchronization with the current
   * context (hotel, language) and menu selection.
   *
   * @effect Triggers loadEmenuDetails to fetch updated menu data
   * @dependencies [key, state.currentHotel, userLanguage]
   *
   * @example
   * // Automatically triggers when language changes
   * useEffect(() => {
   *   loadEmenuDetails();
   * }, [key, state.currentHotel, userLanguage]);
   *
   * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @since 1.0.0
   * @version 1.0.0
   */
  useEffect(() => {
    if (isPreviewMode) {
      setServerTime(previewEMenuData.ServerTime);
      setService(previewEMenuData);
      if (previewEMenuData.HappyHours.length > 0) {
        setHappyHour(previewEMenuData.HappyHours);
      }
      setIsLoading(false);
      return;
    }

    loadEmenuDetails();
  }, [key, state.currentHotel, userLanguage]);

  /**
   * @function useEffectOrganizeMenu
   * @memberof module:EMenu.EMenu
   * @description Effect that organizes menu data when service details are available.
   * Processes menu details, allergens, and messages into organized structure.
   *
   * @effect Updates organized menu data state
   * @dependencies [service, cookie.platformUid, cookie.detailId, cookie.sUid, key]
   *
   * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @since 1.0.0
   * @version 1.0.0
   */
  useEffect(() => {
    if (service?.MenuDetails) {
      const routeParams = {
        platformUid: cookie.platformUid,
        detailId: cookie.detailId,
        sUid: cookie.sUid,
        key: key,
      };

      const organizedData = organizeMenuData(
        service.MenuDetails,
        service.Allergens,
        service.Messages,
        routeParams,
        isPreviewMode,
        defaultThemeId
      );
      setOrganized(organizedData);
    }
  }, [service, cookie.platformUid, cookie.detailId, cookie.sUid, key]);

  /**
   * @function useEffectServerTime
   * @memberof module:EMenu.EMenu
   * @description Effect that maintains server time synchronization.
   * Updates server time every minute to keep menu pricing current.
   *
   * @effect Updates server time state periodically
   * @dependencies [serverTime]
   *
   * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @since 1.0.0
   * @version 1.0.0
   */
  useEffect(() => {
    if (!serverTime) return;
    const interval = setInterval(() => {
      updateServerTime(serverTime);
    }, 60000); // Update every minute
    return () => clearInterval(interval);
  }, [serverTime]);

  /**
   * @function processItemAndChildrenDiscount
   * @memberof module:EMenu.EMenu
   * @description Recursively processes items and their children for percentage-based happy hour discounts.
   *
   * @param {Object} item - Menu item to process
   * @param {Object} discountItem - Discount configuration
   * @returns {void}
   *
   * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @since 1.0.0
   * @version 1.0.0
   */
  const processItemAndChildrenDiscount = (item, discountItem) => {
    // Processa o item atual
    if (item.ItemId === discountItem.I && item.AllPrices) {
      try {
        const priceEntries = item.AllPrices.split(";");
        const currentPriceEntry = priceEntries.find((p) =>
          p.startsWith(`${item.PriceLevel}:`)
        );

        if (!currentPriceEntry) return;

        const originalPrice = parseFloat(
          currentPriceEntry.split(":")[1] || "0"
        );
        if (isNaN(originalPrice)) return;

        const discountPercent = discountItem.Dv;
        const discountedPrice = originalPrice * (1 - discountPercent / 100);

        // Apenas adiciona a propriedade hh com o preço com desconto
        item.hh = discountedPrice.toFixed(2);
      } catch (error) {
        console.warn(
          "Error processing happy hour price for item:",
          item.Id,
          error
        );
      }
    }

    // Processa recursivamente todos os children
    if (item.Children && Array.isArray(item.Children)) {
      item.Children.forEach((child) =>
        processItemAndChildrenDiscount(child, discountItem)
      );
    }
  };

  /**
   * @function processItemAndChildrenFixedPrice
   * @memberof module:EMenu.EMenu
   * @description Recursively processes items and their children for fixed-price happy hour specials.
   *
   * @param {Object} item - Menu item to process
   * @param {string} happyHourPrice - Fixed price level for happy hour
   * @returns {void}
   *
   * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @since 1.0.0
   * @version 1.0.0
   */
  const processItemAndChildrenFixedPrice = (item, happyHourPrice) => {
    // Processa o item atual
    if (item.AllPrices) {
      try {
        const priceEntries = item.AllPrices.split(";");

        // Encontra o preço do happy hour
        const fixedPriceEntry = priceEntries.find((p) =>
          p.startsWith(`${happyHourPrice}:`)
        );

        // Encontra o preço inicial (baseado no PriceLevel do item)
        const currentPriceEntry = priceEntries.find((p) =>
          p.startsWith(`${item.PriceLevel}:`)
        );

        if (fixedPriceEntry && currentPriceEntry) {
          const fixedPrice = parseFloat(fixedPriceEntry.split(":")[1] || "0");
          const currentPrice = parseFloat(
            currentPriceEntry.split(":")[1] || "0"
          );

          // Só adiciona hh se os preços forem diferentes
          if (
            !isNaN(fixedPrice) &&
            !isNaN(currentPrice) &&
            fixedPrice !== currentPrice
          ) {
            item.hh = fixedPrice.toFixed(2);
          }
        }
      } catch (error) {
        console.warn(
          "Error processing fixed happy hour price for item:",
          item.Id,
          error
        );
      }
    }

    // Processa recursivamente todos os children
    if (item.Children && Array.isArray(item.Children)) {
      item.Children.forEach((child) =>
        processItemAndChildrenFixedPrice(child, happyHourPrice)
      );
    }
  };

  /**
   * @function useEffectHappyHour
   * @memberof module:EMenu.EMenu
   * @description Effect that processes happy hour pricing and discounts.
   * Checks if current time falls within happy hour period and applies appropriate
   * discounts or fixed prices to menu items.
   *
   * @effect Updates menu items with happy hour prices
   * @dependencies [happyHour, organized, serverTime]
   *
   * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @since 1.0.0
   * @version 1.0.0
   */
  useEffect(() => {
    if (!happyHour || !organized || happyHour.length === 0 || !serverTime)
      return;

    const activeHappyHour = happyHour[0];

    // Verifica se a happy hour está ativa
    const currentTime = new Date(serverTime);
    const fromHour = new Date(activeHappyHour.FromHour);
    const toHour = new Date(activeHappyHour.ToHour);
    // Ajusta o dia da semana para começar em Segunda = 0
    // let currentDay = currentTime.getDay() - 1;
    // if (currentDay === -1) currentDay = 6; // Se for Domingo (0-1 = -1), ajusta para 6

    // Ajusta os horários para comparar apenas horas e minutos
    const currentTimeMs =
      currentTime.getHours() * 60 * 60 * 1000 +
      currentTime.getMinutes() * 60 * 1000;
    const fromHourMs =
      fromHour.getHours() * 60 * 60 * 1000 + fromHour.getMinutes() * 60 * 1000;
    const toHourMs =
      toHour.getHours() * 60 * 60 * 1000 + toHour.getMinutes() * 60 * 1000;

    // Verifica se está dentro do horário e dia correto
    const isActive = currentTimeMs >= fromHourMs && currentTimeMs <= toHourMs;

    if (!isActive) return;

    if (activeHappyHour.IsTypeDiscount) {
      // Lógica de desconto percentual
      activeHappyHour.DiscountItems.forEach((discountItem) => {
        organized.forEach((item) =>
          processItemAndChildrenDiscount(item, discountItem)
        );
      });
    } else {
      // Lógica de preço fixo
      organized.forEach((item) =>
        processItemAndChildrenFixedPrice(item, activeHappyHour.Price)
      );
    }
  }, [happyHour, organized, serverTime]);

  // Filtrar conteúdo baseado na pesquisa se necessário
  const filteredData = organized;
  // Procura o item em toda a estrutura de dados
  const currentCategory = itemId ? findItemById(organized, itemId) : null;

  const searchData = searchInItems(organized, searchText);

  if (isLoading) {
    return (
      <div className="flex justify-center h-screen dark:text-white dark:bg-gxp_dark_2">
        <Loader className={"w-20 h-20 mt-20"} />
      </div>
    );
  }

  if (!service) return null;

  return (
    <div id="EM-Page" ref={EMPageRef} onDoubleClick={(e) => handleDoubleClick(e, EMPageRef)} onMouseOver={(e) => handleElementEnter(e, EMPageRef, "EM-Page")} onMouseLeave={(e) => handleElementLeave(e, EMPageRef, "EM-Page")} className={`${ isPreviewMode && isHovered === "EM-Page" ? "diagonal-stripes" : "" } EM-bg_color dark:bg-gxp_dark_2 h-full`} >
      <MenuLayout
        inspectMode={inspectMode}
        isPreviewMode={isPreviewMode}
        isHovered={isHovered}
        handleElementEnter={handleElementEnter}
        handleElementLeave={handleElementLeave}
        handleDoubleClick={handleDoubleClick}
        EMSearchbarRef={EMSearchbarRef}
        searchText={searchText}
        onSearchChange={setSearchText}
        className={"EM-bg_color dark:bg-gxp_dark_2 max-w-4xl"}
        service={service}
      >
        {searchData.length > 0 ? (
          <div className="space-y-3 p-2 items-center justify-center max-w-4xl mx-auto">
            {searchData?.map((item) => (
              <MenuItem
                isPreviewMode={isPreviewMode}
                isHovered={isHovered}
                handleElementEnter={handleElementEnter}
                handleElementLeave={handleElementLeave}
                handleDoubleClick={handleDoubleClick}
                EMCardRef={EMCardRef}
                key={item.Id}
                item={item}
                service={service}
              />
            ))}
          </div>
        ) : itemId ? (
          <MenuDetail
            isPreviewMode={isPreviewMode}
            isHovered={isHovered}
            handleElementEnter={handleElementEnter}
            handleElementLeave={handleElementLeave}
            handleDoubleClick={handleDoubleClick}
            EMEMenuCardRef={EMEMenuCardRef}
            EMCardRef={EMCardRef}
            category={currentCategory}
            serverTime={serverTime}
            service={service}
          />
        ) : (
          <MenuHome
            isPreviewMode={isPreviewMode}
            isHovered={isHovered}
            handleElementEnter={handleElementEnter}
            handleElementLeave={handleElementLeave}
            EMEMenuCardRef={EMEMenuCardRef}
            categories={filteredData} />
        )}
      </MenuLayout>
      {service.HasInitialText && (
        <Modal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          initialText={service.InitialText}
        />
      )}
    </div>
  );
};
