import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { getPriceByLevel } from "./Components/utils/getPriceByLevel";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import { NotebookPen } from "lucide-react";
import { WellnessFormModal } from "./Components/Modals/WellnessFormModal";

/**
 * @namespace MenuItem
 * @memberof module:EMenu
 * @description Component for rendering and managing individual menu items.
 * Handles item display, pricing, allergens, and wellness booking functionality.
 *
 * @component
 * @param {Object} props Component properties
 * @param {Object} props.item Current menu item data
 * @param {Object} props.service Service configuration for menu functionality
 *
 * @returns {JSX.Element} Menu item component with appropriate styling and features
 *
 * @example
 * <MenuItem
 *   item={{
 *     Description: "Pizza Margherita",
 *     AllPrices: "1:10.99;2:12.99",
 *     PriceLevel: "1"
 *   }}
 *   service={{
 *     AllowWellnessReservation: false
 *   }}
 * />
 *
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @since 1.0.0
 * @version 1.0.0
 */
export const MenuItem = ({handleDoubleClick, item, service, isPreviewMode, isHovered, handleElementEnter, handleElementLeave, EMCardRef, }) => {
  const [stableItem, setStableItem] = useState(null);
  const [isStable, setIsStable] = useState(false);
  const [t] = useTranslation("global");
  const MotionLink = motion.create(Link);
  const [isModalOpen, setIsModalOpen] = useState(false);

  /**
   * @function PriceDisplay
   * @memberof module:EMenu.MenuItem
   * @description Component for displaying price information with promotion.
   * Shows original and discounted prices with promotional styling.
   *
   * @param {Object} props Component properties
   * @param {string} props.originalPrice Original item price
   * @param {string} props.discountPrice Discounted price (e.g., happy hour)
   * @returns {JSX.Element} Price display with promotion
   *
   * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @since 1.0.0
   * @version 1.0.0
   */
  const PriceDisplay = ({ originalPrice, discountPrice }) => (
    <div className="flex flex-col items-end">
      <div className="flex items-center gap-2">
        <div className="EM-Card-promotion_bg_color text-white EM-Card-title_size EM-Card-title_variant EM-Card-title_font_family px-2 py-0.5 rounded-full animate-pulse">
          {`${t("MenuItem.promotion")}`}
        </div>
        <div className="EM-Card-promotion_text_color EM-Card-title_size EM-Card-title_variant EM-Card-title_font_family whitespace-nowrap">
          {discountPrice}€
        </div>
      </div>
      <div className="EM-Card-title_variant EM-Card-title_font_family EM-Card-title_color dark:text-gray-400 line-through">
        {originalPrice}€
      </div>
    </div>
  );

  /**
  /**
   * @function useEffectStabilize
   * @memberof module:EMenu.MenuItem
   * @description Effect that manages item data stability to prevent flickering.
   * Introduces a small delay before updating the stable item state.
   *
   * @effect Updates stableItem state
   * @dependencies [item, item.hh, item.AllPrices]
   *
   * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @since 1.0.0
   * @version 1.0.0
   */
  useEffect(() => {
    if (!item) return;
    const timer = setTimeout(() => {
      setStableItem(item);
      setIsStable(true);
    }, 100);

    return () => clearTimeout(timer);
  }, [item, item.hh, item.AllPrices]);


  /**
   * @function useEffectModal
   * @memberof module:EMenu.MenuItem
   * @description Effect that manages scroll behavior when modal is open.
   * Prevents background scrolling when wellness form is visible.
   *
   * @effect Controls document body overflow
   * @dependencies [isModalOpen]
   *
   * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @since 1.0.0
   * @version 1.0.0
   */
  useEffect(() => {
    if (isModalOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }

    // Cleanup ao desmontar
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [isModalOpen]);

  if (!isStable || !stableItem) {
    return (
      <div className="animate-pulse bg-gray-200 dark:bg-gray-700 h-24 rounded-lg"></div>
    );
  }

  if (stableItem.ParentId === 0 || stableItem.ItemId === 0) {
    return (
      <MotionLink style={ stableItem.ImageUrl !== "" ? { backgroundImage: `url(${stableItem.ImageUrl})`, backgroundSize: "cover", backgroundPosition: "center", } : {} } key={stableItem.Id} to={stableItem.LinkTo} whileHover={{ scale: 1.02 }} whileTap={{ scale: 0.9 }} className={`${ stableItem.ImageUrl !== "" ? "EM-EMenuCard-with_image_border_color EM-EMenuCard-with_image_border_width EM-EMenuCard-with_image_border_position EM-EMenuCard-with_image_border_rounded" : "EM-EMenuCard-no_image_bg_color EM-EMenuCard-no_image_border_color EM-EMenuCard-no_image_border_width EM-EMenuCard-no_image_border_position EM-EMenuCard-no_image_border_rounded" } dark:border-gxp_dark_3 block w-full`} >
        <div className="relative h-48 rounded-lg overflow-hidden shadow-lg hover:shadow-xl transition-shadow">
          <div className={`${ stableItem.ImageUrl !== "" ? "bg-gradient-to-t from-black/60 to-transparent" : "" } absolute inset-0 h-full w-full`} />
          <div className="absolute bottom-0 left-0 right-0 p-4">
            <h2 className={`${ stableItem.ImageUrl !== "" ? "EM-EMenuCard-with_image_title_color EM-EMenuCard-with_image_title_size EM-EMenuCard-with_image_title_variant EM-EMenuCard-with_image_title_font_family" : "EM-EMenuCard-no_image_title_color EM-EMenuCard-no_image_title_size EM-EMenuCard-no_image_title_variant EM-EMenuCard-no_image_title_font_family" }`} >
              {stableItem.Description}
            </h2>
            {stableItem.Notes && (
              <p className={`${ stableItem.ImageUrl !== "" ? "EM-EMenuCard-with_image_text_color EM-EMenuCard-with_image_text_size EM-EMenuCard-with_image_text_variant EM-EMenuCard-with_image_text_font_family" : "EM-EMenuCard-no_image_text_color EM-EMenuCard-no_image_text_size EM-EMenuCard-no_image_text_variant EM-EMenuCard-no_image_text_font_family" } mt-1`} >
                {stableItem.Notes}
              </p>
            )}
          </div>
        </div>
      </MotionLink>
    );
  }

  const originalPrice = stableItem.AllPrices
    ? getPriceByLevel(stableItem.AllPrices, stableItem.PriceLevel)?.toFixed(2)
    : null;

  return (
    <div id="EM-Card" ref={EMCardRef} onDoubleClick={(e) => handleDoubleClick(e, EMCardRef)} onMouseOver={(e) => handleElementEnter(e, EMCardRef, "EM-Card")} onMouseLeave={(e) => handleElementLeave(e, EMCardRef, "EM-Card")} className={`${ isPreviewMode && isHovered === "EM-Card" ? "diagonal-stripes" : "" } dark:border-gxp_dark_3 dark:bg-gxp_dark_3 p-2 shadow-sm sm:flex group w-4xl EM-Card-bg_color EM-Card-border_color EM-Card-border_width EM-Card-border_position EM-Card-border_rounded`} >
      {stableItem.ImageUrl && (
        <div className="max-sm:flex max-sm:justify-center max-sm:items-center max-sm:mb-4">
          <img src={stableItem.ImageUrl} alt={stableItem.Description} className="w-1/2 sm:w-40 h-auto sm:mr-4" />
        </div>
      )}
      <div className="flex flex-col justify-between items-start gap-2 flex-1 w-full">
        <div className="flex justify-between flex-1 w-full">
          <h4 className="dark:text-white EM-Card-title_color EM-Card-title_size EM-Card-title_variant EM-Card-title_font_family">
            {stableItem.Description}
          </h4>
          <div className="flex items-start">
            {stableItem.hh && originalPrice ? (
              <PriceDisplay originalPrice={originalPrice} discountPrice={stableItem.hh} />
            ) : (
              stableItem.AllPrices && (
                <div className="EM-Card-title_color EM-Card-title_size EM-Card-title_variant EM-Card-title_font_family dark:text-white whitespace-nowrap">
                  {originalPrice}€
                </div>
              )
            )}
          </div>
        </div>
        {stableItem.Notes && (
          <div className="flex justify-between items-end gap-2 w-full">
            <span className="EM-Card-text_color EM-Card-text_size EM-Card-text_variant EM-Card-text_font_family dark:text-white whitespace-pre-line">
              {stableItem.Notes}
            </span>
            {stableItem.Allergens?.length === 0 &&
              service.AllowWellnessReservation &&
              stableItem.IsSpa &&
              stableItem.ItemType === 4 && (
                <button
                  type="button"
                  className="flex justify-end items-center"
                  onClick={() => setIsModalOpen(true)}
                >
                  <NotebookPen
                    height={35}
                    width={35}
                    className="bg-gray-100 dark:bg-gray-100/60 p-1 rounded-lg"
                  />
                </button>
              )}
          </div>
        )}
        {stableItem.AllergensData?.length > 0 && (
          <div className="flex flex-col gap-2 mt-2 w-full">
            {stableItem.AllergensData.map((allergen) => (
              <div
                key={allergen.Code}
                className="flex justify-between items-end gap-2 w-full"
              >
                <div className="flex justify-center items-center">
                  <img
                    src={allergen.ImageUrl}
                    alt={allergen.Description}
                    className="w-4 h-4"
                  />
                  <span className="EM-Card-allergen_text_color EM-Card-allergen_text_size EM-Card-allergen_text_variant EM-Card-allergen_text_font_family EM-Card-allergen_text_bg_color dark:bg-gray-100/60 px-2 py-1 rounded-lg ml-2">
                    {allergen.Description}
                  </span>
                </div>
              </div>
            ))}
            {service.AllowWellnessReservation &&
              stableItem.IsSpa &&
              stableItem.ItemType === 4 && (
                <button
                  type="button"
                  className="flex justify-end items-center EM-Card-allergen_text_color EM-Card-allergen_text_bg_color"
                  onClick={() => setIsModalOpen(true)}
                >
                  <NotebookPen
                    height={35}
                    width={35}
                    className="dark:bg-gray-100/60 p-1 rounded-lg"
                  />
                </button>
              )}
          </div>
        )}
      </div>
      <WellnessFormModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        item={stableItem}
        service={service}
      />
    </div>
  );
};
