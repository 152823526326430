export const OCFormDataCategories = [
  {
    order: 1,
    id: "OC-Page",
  },
  {
    order: 2,
    id: "OC-Header",
  },
  {
    order: 3,
    id: "OC-Stepper",
  },
  {
    order: 4,
    id: "OC-Form",
  },
  {
    order: 5,
    id: "OC-FormField",
  },
  {
    order: 6,
    id: "OC-Button1",
  },
];

export const OCConsentDataCategories = [
  {
    order: 1,
    id: "OC-Page",
  },
  {
    order: 2,
    id: "OC-Header",
  },
  {
    order: 3,
    id: "OC-Stepper",
  },
  {
    order: 4,
    id: "OC-Consent",
  },
  {
    order: 5,
    id: "OC-Button1",
  },
  {
    order: 6,
    id: "OC-Button2",
  },
  {
    order: 7,
    id: "OC-FormField",
  },
];

export const OCgeneralTermDataCategories = [
  {
    order: 1,
    id: "OC-Page",
  },
  {
    order: 2,
    id: "OC-Header",
  },
  {
    order: 3,
    id: "OC-Stepper",
  },
  {
    order: 4,
    id: "OC-GeneralTerm",
  },
  {
    order: 5,
    id: "OC-Button2",
  },
  {
    order: 6,
    id: "OC-FormField",
  },
  {
    order: 7,
    id: "OC-Button1",
  },
];

export const OCSuccessDataCategories = [
  {
    order: 1,
    id: "OC-Page",
  },
  {
    order: 2,
    id: "OC-Header",
  },
];

export const TRGuestDataCategories = [
  {
    order: 1,
    id: "TR-Page",
  },
  {
    order: 2,
    id: "TR-Header",
  },
  {
    order: 3,
    id: "TR-Form",
  },
  {
    order: 4,
    id: "TR-Stepper",
  },
  {
    order: 5,
    id: "TR-Card",
  },
];

export const TRDateDataCategories = [
  {
    order: 1,
    id: "TR-Page",
  },
  {
    order: 2,
    id: "TR-Header",
  },
  {
    order: 3,
    id: "TR-Form",
  },
  {
    order: 4,
    id: "TR-Stepper",
  },
  {
    order: 5,
    id: "TR-Card",
  },
  {
    order: 6,
    id: "TR-Button2",
  },
];

export const TRTimeDataCategories = [
  {
    order: 1,
    id: "TR-Page",
  },
  {
    order: 2,
    id: "TR-Header",
  },
  {
    order: 3,
    id: "TR-Form",
  },
  {
    order: 4,
    id: "TR-Stepper",
  },
  {
    order: 5,
    id: "TR-Card",
  },
  {
    order: 6,
    id: "TR-Button2",
  },
];

export const TRFormDataCategories = [
  {
    order: 1,
    id: "TR-Page",
  },
  {
    order: 2,
    id: "TR-Header",
  },
  {
    order: 3,
    id: "TR-Form",
  },
  {
    order: 4,
    id: "TR-Stepper",
  },
  {
    order: 5,
    id: "TR-FormField",
  },
  {
    order: 6,
    id: "TR-Button1",
  },
  {
    order: 7,
    id: "TR-Button2",
  },

];

export const TRSuccessDataCategories = [
  {
    order: 1,
    id: "TR-Page",
  },
  {
    order: 2,
    id: "TR-Header",
  },
  {
    order: 3,
    id: "TR-Form",
  },
  {
    order: 4,
    id: "TR-Button1",
  },
];

export const MHGeneralDataCategories = [
  {
    order: 1,
    id: "MH-Page",
  },
];

export const MFGeneralDataCategories = [
  {
    order: 1,
    id: "MF-Page",
  },
];

export const HMGeneralDataCategories = [
  {
    order: 1,
    id: "HM-Page",
  },
  {
    order: 2,
    id: "HM-Card",
  },
];

export const RSGeneralDataCategories = [
  {
    order: 1,
    id: "RS-Page",
  },
  {
    order: 2,
    id: "RS-Card",
  },
];

export const CKGeneralDataCategories = [
  {
    order: 1,
    id: "CK-Page",
  },
  {
    order: 2,
    id: "CK-Header",
  },
  {
    order: 3,
    id: "CK-Button1",
  },
  {
    order: 4,
    id: "CK-Table",
  },
];

export const MNGeneralDataCategories = [
  {
    order: 1,
    id: "MN-Page",
  },
  {
    order: 2,
    id: "MN-Header",
  },
  {
    order: 3,
    id: "MN-Button1",
  },
  {
    order: 4,
    id: "MN-FormField",
  },
];

export const QRGeneralDataCategories = [
  {
    order: 1,
    id: "QR-Page",
  },
  {
    order: 2,
    id: "QR-Header",
  },
];

export const HTGeneralDataCategories = [
  {
    order: 1,
    id: "HT-Page",
  },
  {
    order: 2,
    id: "HT-Card",
  },
];

export const EMGeneralDataCategories = [
  {
    order: 1,
    id: "EM-Page",
  },
  {
    order: 2,
    id: "EM-Searchbar",
  },
  {
    order: 3,
    id: "EM-EMenuCard",
  },
  {
    order: 4,
    id: "EM-Card",
  },
];

export const EMItemDataCategories = [
  {
    order: 1,
    id: "EM-Page",
  },
  {
    order: 2,
    id: "EM-Searchbar",
  },
  {
    order: 3,
    id: "EM-EMenuCard-with_image",
  },
  {
    order: 4,
    id: "EM-EMenuCard-no_image",
  },
  {
    order: 5,
    id: "EM-Card",
  },
];

// iFrameCategoriesMap.js
export const iFrameCategoriesMap = {
  OC: {
    default: OCFormDataCategories,
    form: OCFormDataCategories,
    consent: OCConsentDataCategories,
    generalTerm: OCgeneralTermDataCategories,
    success: OCSuccessDataCategories,
  },
  TR: {
    default: TRGuestDataCategories,
    guest: TRGuestDataCategories,
    date: TRDateDataCategories,
    time: TRTimeDataCategories,
    form: TRFormDataCategories,
    success: TRSuccessDataCategories,
  },
  MH: {
    default: MHGeneralDataCategories,
  },
  MF: {
    default: MFGeneralDataCategories,
  },
  HM: {
    default: HMGeneralDataCategories,
  },
  RS: {
    default: RSGeneralDataCategories,
  },
  CK: {
    default: CKGeneralDataCategories,
  },
  MN: {
    default: MNGeneralDataCategories,
  },
  QR: {
    default: QRGeneralDataCategories,
  },
  HT: {
    default: HTGeneralDataCategories,
  },
  EM: {
    default: EMGeneralDataCategories,
    item: EMItemDataCategories,
  }
};
