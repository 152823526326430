import { useTranslation } from "react-i18next";
import { TimePicker } from "../Utilities/TimePicker";

/**
 * @namespace ArrivalTime
 * @memberof module:OnlineCheckIn
 * @description Component for rendering and managing guest arrival time and reservation notes.
 * Includes time selection, notes input, and preview mode functionality.
 *
 * @component
 * @param {Object} props Component properties
 * @param {boolean} props.isHovered Current hover state for preview mode
 * @param {boolean} props.isPreviewMode Indicates if component is in preview mode
 * @param {React.RefObject} props.OCArrivalTimeRef Reference to the arrival time container
 * @param {Function} props.handleElementEnter Handler for mouse enter events
 * @param {Function} props.handleElementLeave Handler for mouse leave events
 * @param {Array<Object>} props.guests Array of guest objects containing arrival data
 * @param {Function} props.setGuests Function to update guests data
 *
 * @returns {JSX.Element} Arrival time and reservation notes input component
 *
 * @example
 * <ArrivalTime
 *   isHovered={false}
 *   isPreviewMode={false}
 *   OCArrivalTimeRef={elementRef}
 *   handleElementEnter={(e, ref, id) => handleHover(e, ref, id)}
 *   handleElementLeave={(e, ref, id) => handleLeave(e, ref, id)}
 *   guests={[{ id: 1, data: { ArrivalTime: "14:00" }}]}
 *   setGuests={updateGuests}
 * />
 *
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @since 1.0.0
 * @version 1.0.0
 */
export const ArrivalTime = ({ handleDoubleClick, isHovered, isPreviewMode, OCArrivalTimeRef, handleElementEnter, handleElementLeave, guests, setGuests }) => {
  const [t] = useTranslation("global");

  /**
   * @function handleGuestChange
   * @memberof module:OnlineCheckIn.ArrivalTime
   * @description Updates specific guest data fields in the guests array.
   * Manages changes for both arrival time and reservation notes.
   *
   * @param {number} id Guest identifier
   * @param {string} fieldName Name of the field to update
   * @param {string} value New value for the field
   * @returns {void}
   *
   * @example
   * handleGuestChange(1, "ArrivalTime", "15:00")
   * handleGuestChange(1, "ReservationNotes", "Late arrival")
   *
   * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @since 1.0.0
   * @version 1.0.0
   */
  const handleGuestChange = (id, fieldName, value) => {
    setGuests((prevGuests) =>
      prevGuests.map((guest) =>
        guest.id === id
          ? { ...guest, data: { ...guest.data, [fieldName]: value } }
          : guest
      )
    );
  };

  const format = 'HH:mm';

  return (
    <>
      {guests && guests.length > 0 && (
        <div id="OC-FormField" ref={OCArrivalTimeRef} onDoubleClick={(e) => handleDoubleClick(e, OCArrivalTimeRef)} onMouseOver={(e) => handleElementEnter(e, OCArrivalTimeRef, "OC-FormField")} onMouseLeave={(e) => handleElementLeave(e, OCArrivalTimeRef, "OC-FormField")} className={`${isPreviewMode && isHovered === "OC-FormField" ? "diagonal-stripes" : ""} flex flex-col space-y-4 max-w-md p-4 w-full`}>
          <div className="mb-4 flex justify-center">
            <div className="w-full max-w-xs">
              <label htmlFor="horaChegada" className="block text-sm font-medium dark:text-white mb-1 text-center" >
              {`${t(`ArrivalTime.arrivaltime`)}`}
              </label>
              <TimePicker value={guests[0].data.ArrivalTime || ""} onChange={handleGuestChange} />
            </div>
          </div>
          <div>
            <input name="ReservationNotes" onChange={(e) => handleGuestChange(0, e.target.name, e.target.value) } value={guests[0].data.ReservationNotes || ""} type="text" id="comentarios" className="w-full OC-FormField-text_color OC-FormField-text_size OC-FormField-text_font_family OC-FormField-text_variant OC-FormField-bg_color OC-FormField-border_color OC-FormField-border_rounded OC-FormField-border_position OC-FormField-border_width dark:border-white dark:bg-gxp_dark_3 dark:text-white shadow-sm focus:ring-2 focus:border-blue-500 focus:ring-blue-500 OC-FormField-placeholder_color OC-FormField-placeholder_font_family OC-FormField-placeholder_variant dark:placeholder:!text-white" placeholder={`${t(`ArrivalTime.additionalcomments`)}`} />
          </div>
        </div>
      )}
    </>
  );
};
