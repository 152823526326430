
/**
 * @memberof module:EMenu.Utils
 * @description Function for extracting a specific price from a price level string.
 * Parses a semicolon-separated string of price levels and returns the price
 * for the specified level.
 *
 * @function getPriceByLevel
 * @param {string} allPrices String containing all price levels (format: "level:price;level:price")
 * @param {number} priceLevel The price level to extract
 * @returns {number|null} The price for the specified level or null if not found
 *
 * @example
 * // Price string with multiple levels
 * const prices = "1:10.99;2:12.99;3:14.99";
 * const level = 2;
 * const price = getPriceByLevel(prices, level);
 * // Returns: 12.99
 *
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @since 1.0.0
 * @version 1.0.0
 */
export const getPriceByLevel = (allPrices, priceLevel) => {
    if (!allPrices) return null;

    // Dividir a string de preços em um array de pares "level:price"
    const pricesList = allPrices.split(';');

    // Encontrar o preço para o nível desejado
    const priceItem = pricesList.find(item => {
      const [level] = item.split(':');
      return parseInt(level) === priceLevel;
    });

    if (!priceItem) return null;

    // Extrair o valor do preço e converter para número
    const [, price] = priceItem.split(':');
    return parseFloat(price);
  };