import { useParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../context/AppContext";
import { toast } from "react-toastify";
import { getWebText } from "../../services";
import { useCookies } from "react-cookie";

/**
* @module WebText
* @description Core module for displaying dynamic web content.
* Handles fetching and rendering of HTML content based on URL parameters.
*/

/**
* @namespace WebText
* @memberof module:WebText
* @description Component responsible for fetching and displaying dynamic web content.
* Manages content retrieval based on language preferences and subscription keys.
* Handles rendering of HTML content like terms, policies, and other dynamic text.
*
* @component
* @returns {JSX.Element} WebText component
*
* @example
* <WebText />
*
* @author Tiago Ferreira <tiago.ferreira@hhs.pt>
* @since 1.0.0
* @version 1.0.0
*/
export const WebText = () => {
  const [state] = useContext(AppContext);   // Retrieves global state from the context, including dark mode settings
  const { key } = useParams();   // Retrieves dynamic URL parameters: platformUid and key, which are used to fetch web content
  const [webText, setWebText] = useState();

/**
  * @function useEffectFetchWebText
  * @memberof module:WebText.WebText
  * @description Effect that manages web text content fetching.
  * Fetches content based on user language, key, and hotel subscription.
  * Handles error states and updates content display.
  *
  * @effect Updates webText state with fetched content
  * @dependencies [key, state.currentHotel, userLanguage]
  *
  * @example
  * useEffect(() => {
  *   if (state.currentHotel.subscriptionKey) {
  *     getWebText(userLanguage, key, subscriptionKey)
  *       .then(data => setWebText(data[0]?.TextPlain))
  *       .catch(error => toast.error("Unable to get hotel info!"));
  *   }
  * }, [key, state.currentHotel, userLanguage]);
  *
  * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
  * @since 1.0.0
  * @version 1.0.0
  */
  useEffect(() => {
    if (state?.currentHotel?.subscriptionKey) {
      getWebText(localStorage.getItem('userLanguage'), key, state.currentHotel.subscriptionKey )
        .then((data) => {
          setWebText(data[0]?.TextPlain);
        })
        .catch((error) => {
          console.error("Error fetching hotel info:", error);
          toast.error("Unable to get hotel info!");
        });
    }
  }, [key, state.currentHotel, localStorage.getItem('userLanguage')]); // eslint-disable-line

  return (
    <div className={`${ state.darkMode ? "" : "" } antialiased dark:text-white h-full HM-bg_color`} >
      <section className="flex-grow p-4">
        <div className={`text-justify`} dangerouslySetInnerHTML={{ __html: webText }} >
        </div>
      </section>
    </div>
  );
};
