import { useParams, useLocation } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import { useContext, useEffect, useRef, useState } from "react";
import { useCookies } from "react-cookie";
import { ReservationComponent } from "./components/ReservationComponent";
import { getDefaultPreviewInitialData, getHotelInitialData, getOutlet, } from "../../services";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { previewCurrentHotel, previewHotelInitialData, previewOutlet, } from "./components/utilities/previewModeData";
import { HeadersPageTR } from "./components/utilities/HeadersPageTR";

/**
 * @module TableReservation
 * @description Module for managing restaurant table reservations.
 * Handles service initialization, data fetching, preview mode, and user interactions.
 * Serves as the main container for the table reservation functionality.
 */

/**
 * @namespace TableReservation
 * @memberof module:TableReservation
 * @description Main component for table reservation system.
 * Manages service data, calendar days, and user interactions.
 *
 * @component
 * @returns {JSX.Element} Complete table reservation interface
 *
 * @example
 * <TableReservation />
 *
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @since 1.0.0
 * @version 1.0.0
 */
export const TableReservation = () => {
  const { key, sUid } = useParams();
  const [state, dispatch] = useContext(AppContext);
  const [cookie, setCookie] = useCookies(["sUid", "currentHotel"]); // eslint-disable-line
  const [service, setService] = useState([]);
  const [daysInCalendar, setDaysInCalendar] = useState();
  const [mainDialCodes, setMainDialCodes] = useState([]);
  const [withoutReservation, setWithoutReservation] = useState(false);
  const [initialData, setInitialData] = useState();
  const location = useLocation();
  const [t] = useTranslation("global");
  const [guest, setGuest] = useState();
  const [guestLanguage, setGuestLanguage] = useState( localStorage.getItem(`userLanguage`) || null );
  const { pathname } = useLocation();
  const isPreviewMode = pathname.includes("/previewmode/");
  const { defaultThemeId } = useParams();
  const [isHovered, setIsHovered] = useState();
  const [currentRef, setCurrentRef] = useState(null);
  const TRPageRef = useRef(null);
  const TRHeaderRef = useRef(null);
  const TRFormRef = useRef(null);
  const TRStepperRef = useRef(null);
  const TRCardRef = useRef(null);
  const TRFormFieldRef = useRef(null);
  const TRButton1Ref = useRef(null);
  const TRButton2Ref = useRef(null);
  const [inspectMode, setInspectMode] = useState( JSON.parse(localStorage.getItem("inspectMode")) === "true" ? true : false );

  const handleDoubleClick = (e, elementRef) => {
    e.stopPropagation();

    // Verifica se o inspectMode está ativo antes de enviar a mensagem
    if (isPreviewMode && inspectMode && elementRef.current) {
      const elementId = elementRef.current.id;

      window.parent.postMessage({
        action: "doubleClick",
        elementId: elementId,
        debugInfo: 'Double click on element to expand category/section'
      }, "*");
    }
  };

  useEffect(() => {
    const handleMessage = (event) => {
      // Log para debug
      // console.log("Mensagem recebida no iframe:", event.data);

      if (event.data.action === "toggleInspectMode") {
        const newMode = event.data.value;
        setInspectMode(newMode);
        localStorage.setItem("inspectMode", newMode.toString());
      }
    };

    window.addEventListener("message", handleMessage);
    return () => window.removeEventListener("message", handleMessage);
  }, []);

    /**
   * @function handleMouseEnter
   * @memberof module:TableReservation.TableReservation
   * @description Handles mouse enter events for preview mode elements.
   * Communicates with parent window for hover states.
   *
   * @param {Event} e Mouse event
   * @param {React.RefObject} elementRef Reference to hovered element
   * @returns {void}
   *
   * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @since 1.0.0
   * @version 1.0.0
   */
  const handleMouseEnter = (e, elementRef) => {
    e.stopPropagation();

    if (isPreviewMode && elementRef.current) {
      const current = elementRef.current;
      window.parent.postMessage(
        {
          action: "hover",
          elementId: current.id,
          elementClass: current.className || "",
          elementTag: current.tagName || "",
          debugInfo: "Hover on specific hoverable element",
        },
        "*"
      );
    }
  };

  const handleMouseLeave = (e, elementRef) => {
    if (isPreviewMode && elementRef.current) {
      const current = elementRef.current;
      window.parent.postMessage(
        {
          action: "hoverOut",
          elementId: current.id,
          elementClass: current.className || "",
          elementTag: current.tagName || "",
          debugInfo: "HoverOut from specific hoverable element",
        },
        "*"
      );
    }
  };

    /**
   * @function handleElementEnter
   * @memberof module:TableReservation.TableReservation
   * @description Manages element hover states and interactions.
   *
   * @param {Event} e Event object
   * @param {React.RefObject} ref Element reference
   * @param {string} id Element identifier
   * @returns {void}
   *
   * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @since 1.0.0
   * @version 1.0.0
   */
  const handleElementEnter = (e, ref, id) => {
    e.preventDefault();
    e.stopPropagation();

    // Se houver um hover ativo em outro elemento
    if (isHovered && isHovered !== id && currentRef) {
      handleMouseLeave(e, currentRef);
    }

    if (inspectMode) {
      // Atualiza o estado para o novo elemento
      setCurrentRef(ref);
      handleMouseEnter(e, ref);
      setIsHovered(id);
    }
  };

  const handleElementLeave = (e, ref, id) => {
    e.preventDefault();
    e.stopPropagation();

    // Apenas limpa o estado se for o elemento atual com hover
    if (isHovered === id) {
      handleMouseLeave(e, ref);
      setIsHovered(null);
      setCurrentRef(null);
    }
  };

  // Função auxiliar para limpar todos os estados
  const clearAllHoverStates = () => {
    if (currentRef) {
      handleMouseLeave(null, currentRef);
    }
    setIsHovered(null);
    setCurrentRef(null);
  };

  /**
   * @function useEffectSetSUid
   * @description A React effect hook that runs when the component mounts or when the `sUid` value changes.
   * This hook checks if the `sUid` parameter is available and sets it as a cookie named "sUid".
   * The cookie is accessible throughout the entire site (indicated by `path: "/"`).
   *
   * @param {string} sUid - The unique identifier for the user session or specific user.
   *
   * @effect This effect will update the cookie whenever the `sUid` changes.
   * It ensures that the current session identifier is stored for reference in future requests.
   *
   * @dependencies [sUid] - The effect depends on `sUid`, meaning it will run whenever `sUid` changes.
   *
   * @example
   * // Example usage of sUid in useEffect
   * useEffect(() => {
   *   if (sUid) {
   *     setCookie("sUid", sUid, { path: "/" });
   *   }
   * }, [sUid]);
   *
   * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @version 1.0.0
   */
  useEffect(() => {
    if (sUid) {
      setCookie("sUid", sUid, { path: "/" });
    }
  }, [sUid]);

  /**
   * @function initializeData
   * @memberof module:TableReservation.TableReservation
   * @description Fetches and initializes all necessary service data.
   * Handles both preview mode and normal mode data loading.
   *
   * @async
   * @returns {Promise<void>}
   *
   * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @since 1.0.0
   * @version 1.0.0
   */
  const initializeData = async () => {
    if (isPreviewMode) {
      setService(previewOutlet.outletInfo[0]);
      setDaysInCalendar(previewOutlet.daysInCalendar);
      setMainDialCodes(previewOutlet.mainDialCodes);
      setInitialData(previewHotelInitialData);
      dispatch({ type: "SET_CURRENT_HOTEL", payload: previewCurrentHotel });
      return;
    }

    if (!key || !state.currentHotel) return;

    try {
      // Fetch outlet data
      const outletData = await getOutlet([key], state.currentHotel);
      // Only fetch hotel data if not already in state
      let hotelData = state.hotelInitialData;
      if (!hotelData) {
        const hotelResponse = await getHotelInitialData(state.currentHotel);
        hotelData = hotelResponse.InitialData;
      }
      setService(outletData.outletInfo[0]);
      setDaysInCalendar(outletData.daysInCalendar);
      setMainDialCodes(outletData.mainDialCodes);
      setInitialData(hotelData);
    } catch (error) {
      console.error("Error initializing data:", error);
      toast.error(t("OnlineCheckIn.errorhotel"));
    }
  };

/**
   * @function useEffectInitialize
   * @memberof module:TableReservation.TableReservation
   * @description Effect that manages initial data loading.
   *
   * @effect Loads service and configuration data
   * @dependencies [key, state.currentHotel, isPreviewMode]
   *
   * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @since 1.0.0
   * @version 1.0.0
   */
  useEffect(() => {
    initializeData();
  }, [key, state.currentHotel, isPreviewMode]); // eslint-disable-line

  useEffect(() => {
    // Verifica se o pathname começa com '/overview'
    if (location.pathname.includes("/treservationdirect")) {
      setWithoutReservation(true);
    }
  }, []);

  /**
   * @function useEffectPreviewMode
   * @memberof module:TableReservation.TableReservation
   * @description Effect that manages preview mode theme updates.
   *
   * @effect Registers global theme update function
   * @dependencies [dispatch]
   *
   * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @since 1.0.0
   * @version 1.0.0
   */
  useEffect(() => {
    // Registrar a função de atualização globalmente
    window.__THEME_UPDATE__ = (themeStyleCode, value) => {
      // console.log('Theme update function called:', { themeStyleCode, value });
      dispatch({
        type: "UPDATE_ACTIVE_THEME_STYLE",
        payload: { themeStyleCode, value },
      });
    };

    return () => {
      delete window.__THEME_UPDATE__;
    };
  }, [dispatch]);

    /**
   * @function useEffectLanguage
   * @memberof module:TableReservation.TableReservation
   * @description Effect that manages user language settings.
   *
   * @effect Updates language settings in localStorage
   * @dependencies [state.userLanguage]
   *
   * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @since 1.0.0
   * @version 1.0.0
   */
  useEffect(() => {
    if (isPreviewMode) {
      const language = localStorage.getItem("userLanguage");
      if (!language) {
        localStorage.setItem("userLanguage", "en-US");
      }
    }
    setGuestLanguage(localStorage.getItem("userLanguage"));
  }, [state.userLanguage]);

  useEffect(() => {
    const fetchDefaultPreviewInitialData = async () => {
      try {
        const InitialData = await getDefaultPreviewInitialData(defaultThemeId);
        dispatch({
          type: "SET_ACTIVE_THEME",
          payload: InitialData.activeTheme,
        });
      } catch (error) {
        console.error("Error loading initialData-AllRoutes:", error);
      }
    };

    if (isPreviewMode) {
      fetchDefaultPreviewInitialData();
    }
  }, [defaultThemeId]); // eslint-disable-line

  return (
    <>
      {service &&
        (key || isPreviewMode) && daysInCalendar && state.currentHotel && (
          <div id={`TR-Page`} ref={TRPageRef} onDoubleClick={(e) => handleDoubleClick(e, TRPageRef)} onMouseOver={(e) => handleElementEnter(e, TRPageRef, "TR-Page") } onMouseLeave={(e) => handleElementLeave(e, TRPageRef, "TR-Page") } className={`${ isPreviewMode && isHovered === "TR-Page" ? "diagonal-stripes" : "" } mx-auto TR-bg_color min-h-screen h-full`} >
            <div id={`TR-Header`} ref={TRHeaderRef} onDoubleClick={(e) => handleDoubleClick(e, TRHeaderRef)} onMouseOver={(e) => handleElementEnter(e, TRHeaderRef, "TR-Header") } onMouseLeave={(e) => handleElementLeave(e, TRHeaderRef, "TR-Header") } className={`${ isPreviewMode && isHovered === "TR-Header" ? "diagonal-stripes" : "" }`} >
              <HeadersPageTR service={service} guest={guest} />
            </div>
            <section className="flex-grow h-full ">
              <ReservationComponent
                TRCardRef={TRCardRef}
                TRFormFieldRef={TRFormFieldRef}
                TRFormRef={TRFormRef}
                TRStepperRef={TRStepperRef}
                TRButton1Ref={TRButton1Ref}
                TRButton2Ref={TRButton2Ref}
                handleElementEnter={handleElementEnter}
                handleElementLeave={handleElementLeave}
                isPreviewMode={isPreviewMode}
                isHovered={isHovered}
                withoutReservation={withoutReservation}
                service={service}
                daysInCalendar={daysInCalendar}
                mainDialCodes={mainDialCodes}
                initialData={initialData}
                handleDoubleClick={handleDoubleClick}
              />
            </section>
          </div>
        )}
    </>
  );
};
