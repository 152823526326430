import React, { useEffect, useRef, useState } from 'react';
import { useContext } from 'react';
import { AppContext } from '../../../../context/AppContext';
import { useLocation } from 'react-router-dom';

export const IframeComponent = ({ selectedTheme, zoom, filteredCategories }) => {
  const [state, dispatch] = useContext(AppContext);
  const [iframeUrl, setIframeUrl] = useState(null);
  const iframeRef = useRef(null);
  const [currentSection, setCurrentSection] = useState(null);
  const { pathname } = useLocation();

  const config = {
    iframeUrl: process.env.NODE_ENV === 'development'
      ? process.env.REACT_APP_IFRAME_URL_DEVELOPMENT
      : process.env.REACT_APP_IFRAME_URL_PRODUCTION
  };

  useEffect(() => {

    const setupIframe = () => {
      const iframe = iframeRef.current;
      if (!iframe || !iframe.contentWindow) return;

      try {
        const iframeDoc = iframe.contentDocument || iframe.contentWindow.document;
        const scriptElement = iframeDoc.createElement('script');
        scriptElement.textContent = `
          (function() {
            let originalVariables = new Map();

            window.addEventListener('message', function(event) {
              if (event.data.source === 'react-devtools-bridge') return;

             // console.log('Iframe received message:', event.data);

             if (event.data.action === 'updateActiveThemeStyle') {
                try {
                  const { themeStyleCode, value } = event.data;
                  console.log('Theme update received in iframe:', { themeStyleCode, value });

                  // Acessar o objeto global do React no iframe
                  if (window.__THEME_UPDATE__) {
                    window.__THEME_UPDATE__(themeStyleCode, value);
                  } else {
                    console.warn('Theme update function not registered');
                  }

                } catch (error) {
                  console.error('Error in iframe:', error);
                }
              }


              if (event.data.action === 'updateVariable') {
                try {
                  const { variable, value } = event.data;

                  // Armazenar o valor original
                  if (!originalVariables.has(variable)) {
                    originalVariables.set(
                      variable,
                      getComputedStyle(document.documentElement).getPropertyValue(variable)
                    );
                  }

                  // Atualizar a variável
                  document.documentElement.style.setProperty(variable, value);

                  console.log('Updated CSS variable:', {
                    variable,
                    value,
                    originalValue: originalVariables.get(variable)
                  });

                  // Enviar confirmação
                  window.parent.postMessage({
                    type: 'STYLE_UPDATE_ATTEMPT',
                    success: true,
                    details: { variable, value }
                  }, '*');

                } catch (error) {
                  console.error('Error updating CSS variable:', error);
                  window.parent.postMessage({
                    type: 'STYLE_UPDATE_ATTEMPT',
                    success: false,
                    error: error.message,
                    details: { variable, value }
                  }, '*');
                }
              }

              // Para múltiplas variáveis
              if (event.data.action === 'updateMultipleVariables') {
                try {
                  const { variables } = event.data;

                  variables.forEach(({ variable, value }) => {
                    // Armazenar valor original
                    if (!originalVariables.has(variable)) {
                      originalVariables.set(
                        variable,
                        getComputedStyle(document.documentElement).getPropertyValue(variable)
                      );
                    }

                    // Atualizar variável
                    document.documentElement.style.setProperty(variable, value);

                    console.log('Updated CSS variable:', {
                      variable,
                      value,
                      originalValue: originalVariables.get(variable)
                    });
                  });

                  // Enviar confirmação
                  window.parent.postMessage({
                    type: 'STYLE_UPDATE_ATTEMPT',
                    success: true,
                    details: { variables }
                  }, '*');

                } catch (error) {
                  console.error('Error updating CSS variables:', error);
                  window.parent.postMessage({
                    type: 'STYLE_UPDATE_ATTEMPT',
                    success: false,
                    error: error.message,
                    details: { variables }
                  }, '*');
                }
              }

              if (event.data.action === 'revertVariable') {
                const { variable } = event.data;
                const originalValue = originalVariables.get(variable);

                if (originalValue !== undefined) {
                  document.documentElement.style.setProperty(variable, originalValue);
                  console.log('Reverted CSS variable:', {
                    variable,
                    value: originalValue
                  });
                }
              }
            });

            window.parent.postMessage({ type: 'IFRAME_SCRIPT_LOADED' }, '*');
          })();
        `;
        iframeDoc.body.appendChild(scriptElement);

        // console.log('Script injected into iframe');
      } catch (error) {
        console.error('Error setting up iframe:', error);
      }
    };

    const handleMessage = (event) => {
      if (event.data.action === "hoverParents") {
        console.log('Hoverable Parents:', event.data.parents);
        // Lógica para aplicar efeito de hover em todos os pais
        event.data.parents.forEach(parent => {
          const element = document.getElementById(parent.elementId);
          if (element) {
            element.classList.add('hover-disabled');
          }
        });
      } else if (event.data.action === "hoverOutParents") {
        console.log('Hoverable Parents on Hover Out:', event.data.parents);
        // Lógica para remover efeito de hover de todos os pais
        event.data.parents.forEach(parent => {
          const element = document.getElementById(parent.elementId);
          if (element) {
            element.classList.remove('hover-disabled');
          }
        });
      }
    };

    window.addEventListener('message', handleMessage);
    const iframe = iframeRef.current;

    if (iframe) {
      iframe.addEventListener('load', setupIframe);
    }

    return () => {
      window.removeEventListener('message', handleMessage);
      if (iframe) {
        iframe.removeEventListener('load', setupIframe);
      }
    };
  }, []);

  useEffect(() => {
  // Se não houver categorias filtradas, define a URL para selectsection
  if (!filteredCategories?.length) {
    setIframeUrl(`${config.iframeUrl}/previewmode/selectsection`);
    return;
  }
    const newSection = filteredCategories[0]?.description;

      const baseUrl = `${config.iframeUrl}/previewmode/headerfooter/${selectedTheme?.id || "1"}`;
      setCurrentSection(newSection);

      switch(newSection) {
        case "Table Reservation":
          setIframeUrl(`${config.iframeUrl}/previewmode/treservationdirect/${selectedTheme?.id || "1"}`);
          break;

        case "Online Check-In":
          setIframeUrl(`${config.iframeUrl}/previewmode/onlinecheckindirect/${selectedTheme?.id || "1"}`);
          break;

        case "Home":
          setIframeUrl(`${config.iframeUrl}/previewmode/previewhome/${selectedTheme?.id || "1"}`);
          break;

        case "Main Header":
        case "Main Footer":
          setIframeUrl(`${baseUrl}?section=${newSection}`);
          break;

        case "Reservation":
          setIframeUrl(`${config.iframeUrl}/previewmode/reservation/${selectedTheme?.id || "1"}`);
          break;

        case "Check-Out":
          setIframeUrl(`${config.iframeUrl}/previewmode/checkout/${selectedTheme?.id || "1"}`);
          break;

        case "Maintenance":
          setIframeUrl(`${config.iframeUrl}/previewmode/maintenance/${selectedTheme?.id || "1"}`);
          break;

        case "QR Code":
          setIframeUrl(`${config.iframeUrl}/previewmode/qrcode/${selectedTheme?.id || "1"}`);
          break;

        case "Hotel Menu":
          setIframeUrl(`${config.iframeUrl}/previewmode/hotel/${selectedTheme?.id || "1"}`);
          break;

        case "EMenu":
          setIframeUrl(`${config.iframeUrl}/previewmode/emenu/${selectedTheme?.id || "1"}`);
          break;

        default:
          setIframeUrl(`${config.iframeUrl}/previewmode/selectsection`);
          break;

    }
  }, [filteredCategories, selectedTheme, config.iframeUrl, currentSection]);

  return (
    <iframe
      style={{
        width: '100%',
        height: '100%',
        transform: `scale(${zoom/100})`,
        transformOrigin: 'center'
      }}
      ref={iframeRef}
      id="Iframe"
      src={iframeUrl}
      className="w-full h-full"
      sandbox="allow-same-origin allow-scripts allow-popups allow-forms allow-modals allow-top-navigation"
    />
  );
};