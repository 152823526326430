import { useState, useContext, useEffect, useRef } from "react";
import { Send } from "lucide-react";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../context/AppContext";
import { useCookies } from "react-cookie";
import { getDefaultPreviewInitialData, getReservationByDetailId, maintenanceRequest } from "../../services";
import { useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Loader } from "../../components/Layout/components/Loader/Loader";
import { previewHomeReservationsData } from "./Utils/previewModeData";
import { HeadersPageMN } from "./Components/HeadersPageMN";

/**
* @module MaintenanceRequest
* @description Core module for handling maintenance requests in the hotel system.
* Manages request submission, form state, and reservation data display.
*/

/**
* @namespace MaintenanceRequest
* @memberof module:MaintenanceRequest
* @description Component responsible for maintenance request functionality.
* Handles form submission, validation, and feedback display.
 * Supports preview mode for theme customization and inspection.
*
* @component
* @returns {JSX.Element} MaintenanceRequest component
*
* @example
* <MaintenanceRequest />
*
* @author Tiago Ferreira <tiago.ferreira@hhs.pt>
* @since 1.0.0
* @version 1.0.0
*/
export const MaintenanceRequest = () => {
  const [request, setRequest] = useState(""); // Holds the content of the maintenance request
  const [submitted, setSubmitted] = useState(false); // Tracks if the request was successfully submitted
  const [state, dispatch] = useContext(AppContext); // Access the app context for global state management
  const [t] = useTranslation("global"); // For translation of strings
  const [reservation, setReservation] = useState(state.reservation); // Holds reservation details
  const [cookie] = useCookies(["sUid", "currentHotel"]); // Fetch cookies for session and hotel info
  const { detailId } = useParams(); // Get reservation detailId from the URL
  const [isSending, setIsSending] = useState(false); // Tracks if the request is being sent

//////////// previewmode ///////////////
const { pathname } = useLocation();
const isPreviewMode = pathname.includes("/previewmode/");
const {defaultThemeId} = useParams()
const [isHovered, setIsHovered] = useState();
const [currentRef, setCurrentRef] = useState(null);
const [inspectMode, setInspectMode] = useState(JSON.parse(localStorage.getItem("inspectMode"))==="true" ? true : false);
const MNPageRef = useRef(null);
const MNHeaderRef = useRef(null);
const MNButton1Ref = useRef(null);
const MNFormFieldRef = useRef(null);

const handleDoubleClick = (e, elementRef) => {
  e.stopPropagation();

  // Verifica se o inspectMode está ativo antes de enviar a mensagem
  if (isPreviewMode && inspectMode && elementRef.current) {
    const elementId = elementRef.current.id;

    window.parent.postMessage({
      action: "doubleClick",
      elementId: elementId,
      debugInfo: 'Double click on element to expand category/section'
    }, "*");
  }
};

/**
 * @function useEffectFetchDefaultPreviewInitialData
* @memberof module:Checkout.Checkout
 * @description Effect that loads the initial preview data for the default theme.
 * Fetches and sets the active theme when the component is in preview mode.
 *
 * @effect Updates the active theme in the global application state
 * @dependencies [defaultThemeId] - Executes when the default theme ID changes
 *
 * @example
 * useEffect(() => {
 *   if (isPreviewMode) {
 *     fetchDefaultPreviewInitialData()
 *   }
 * }, [defaultThemeId]);
 *
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @version 1.0.0
 */
useEffect(() => {
const fetchDefaultPreviewInitialData = async () => {
  try {
    const InitialData = await getDefaultPreviewInitialData(defaultThemeId);
    dispatch({ type: "SET_ACTIVE_THEME", payload: InitialData.activeTheme });
  } catch (error) {
    console.error("Error loading initialData-AllRoutes:", error);
  }
}

if (isPreviewMode) {
  fetchDefaultPreviewInitialData()
}
}, [defaultThemeId]); // eslint-disable-line

/**
* @function handleMouseEnter
* @memberof module:Checkout.Checkout
* @description Manages mouse enter events on elements during preview mode.
* Sends messages to the parent component with information about the focused element.
*
* @param {Event} e - Mouse event
* @param {React.RefObject} elementRef - React element reference
*
* @example
* handleMouseEnter(event, myRef)
*
* @author Tiago Ferreira <tiago.ferreira@hhs.pt>
* @since 1.0.0
* @version 1.0.0
*/
const handleMouseEnter = (e, elementRef) => {
e.stopPropagation();

if (isPreviewMode && elementRef.current) {
  const current = elementRef.current;
  window.parent.postMessage({
    action: "hover",
    elementId: current.id,
    elementClass: current.className || '',
    elementTag: current.tagName || '',
    debugInfo: 'Hover on specific hoverable element'
  }, "*");
}
};

/**
* @function handleMouseLeave
* @memberof module:Checkout.Checkout
* @description Handles mouse leave events for elements in preview mode.
* Sends a message to the parent window with element details when hover is removed.
*
* @param {Event} e - Mouse event object
* @param {React.RefObject} elementRef - Reference to the DOM element
*
* @example
* handleMouseLeave(event, myElementRef)
*
* @author Tiago Ferreira <tiago.ferreira@hhs.pt>
* @since 1.0.0
* @version 1.0.0
*/
const handleMouseLeave = (e, elementRef) => {

if (isPreviewMode && elementRef.current) {
  const current = elementRef.current;
  window.parent.postMessage({
    action: "hoverOut",
    elementId: current.id,
    elementClass: current.className || '',
    elementTag: current.tagName || '',
    debugInfo: 'HoverOut from specific hoverable element'
  }, "*");
}
};

/**
* @function handleElementEnter
* @memberof module:Checkout.Checkout
* @description Manages element hover state entry in preview mode.
* Handles state transitions between different hovered elements and triggers
* appropriate mouse enter events.
*
* @param {Event} e - Mouse event object
* @param {React.RefObject} ref - Reference to the target DOM element
* @param {string} id - Unique identifier for the element
*
* @example
* handleElementEnter(event, elementRef, "element-id")
*
* @author Tiago Ferreira <tiago.ferreira@hhs.pt>
* @since 1.0.0
* @version 1.0.0
*/
const handleElementEnter = (e, ref, id) => {
e.preventDefault();
e.stopPropagation();

// Se houver um hover ativo em outro elemento
if (isHovered && isHovered !== id && currentRef) {
    handleMouseLeave(e, currentRef);
}

// Atualiza o estado para o novo elemento
if(inspectMode){
setCurrentRef(ref);
handleMouseEnter(e, ref);
setIsHovered(id);
}
};

/**
* @function handleElementLeave
* @memberof module:Checkout.Checkout
* @description Manages element hover state exit in preview mode.
* Cleans up hover states and references when leaving an element.
*
* @param {Event} e - Mouse event object
* @param {React.RefObject} ref - Reference to the target DOM element
* @param {string} id - Unique identifier for the element
*
* @example
* handleElementLeave(event, elementRef, "element-id")
*
* @author Tiago Ferreira <tiago.ferreira@hhs.pt>
* @since 1.0.0
* @version 1.0.0
*/
const handleElementLeave = (e, ref, id) => {
e.preventDefault();
e.stopPropagation();

// Apenas limpa o estado se for o elemento atual com hover
if (isHovered === id) {
    handleMouseLeave(e, ref);
    setIsHovered(null);
    setCurrentRef(null);
}
};

/**
* @function useEffectMessageHandler
* @memberof module:Checkout.Checkout
* @description Effect that sets up a message event listener for inspect mode toggling.
* Manages the inspect mode state and persists it to localStorage.
*
* @effect Sets up and cleans up message event listener
* @dependencies []
*
* @example
* useEffect(() => {
*   const handleMessage = (event) => { ... };
*   window.addEventListener("message", handleMessage);
*   return () => window.removeEventListener("message", handleMessage);
* }, []);
*
* @author Tiago Ferreira <tiago.ferreira@hhs.pt>
* @since 1.0.0
* @version 1.0.0
*/
useEffect(() => {
const handleMessage = (event) => {
// Log para debug
// console.log("Mensagem recebida no iframe:", event.data);

if (event.data.action === "toggleInspectMode") {
  const newMode = event.data.value;
  setInspectMode(newMode);
  localStorage.setItem("inspectMode", newMode.toString());
}
};

window.addEventListener("message", handleMessage);
return () => window.removeEventListener("message", handleMessage);
}, []);

/**
* @function useEffectThemeUpdate
* @memberof module:Checkout.Checkout
* @description Effect that registers a global theme update function.
* Allows dynamic theme style updates through a globally accessible function.
* Used in preview mode to listen for theme changes and apply them to the global state.
* The global function is exposed to allow parent windows to trigger theme updates.
*
* @effect Sets up and cleans up global theme update function
* @dependencies [dispatch]
*
* @example
* useEffect(() => {
*   window.__THEME_UPDATE__ = (themeStyleCode, value) => { ... };
*   return () => { delete window.__THEME_UPDATE__; };
* }, [dispatch]);
*
* @author Tiago Ferreira <tiago.ferreira@hhs.pt>
* @since 1.0.0
* @version 1.0.0
*/
useEffect(() => {
// Registrar a função de atualização globalmente
window.__THEME_UPDATE__ = (themeStyleCode, value) => {
  // console.log('Theme update function called:', { themeStyleCode, value });
  dispatch({
    type: 'UPDATE_ACTIVE_THEME_STYLE',
    payload: { themeStyleCode, value }
  });
};
return () => {
  delete window.__THEME_UPDATE__;
};
}, [dispatch]);

//////////////////////////////////////////////////////////////////////////

 /**
  * @function handleSubmit
  * @memberof module:MaintenanceRequest.MaintenanceRequest
  * @description Handles the submission of maintenance requests.
  * Validates input, submits request, and manages submission state.
  *
  * @param {Event} e - Form submission event
  * @returns {Promise<void>}
  *
  * @example
  * handleSubmit(event)
  *
  * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
  * @since 1.0.0
  * @version 1.0.0
  */
  const handleSubmit = async (e) => {
    e.preventDefault();

    if(isPreviewMode){
      setSubmitted(true);
      return;
    }

    if (!request.trim()) return; // Don't send empty requests

    const req = { sUid: cookie.sUid, detailId: detailId, info: request }; // Request payload

    try {
      setIsSending(true); // Set sending state to true
      await maintenanceRequest(state.currentHotel, req); // Send request
      setSubmitted(true); // Mark as submitted
      setRequest(""); // Clear the request field
      toast.success(t(`MaintenanceRequest.success`)); // Show success message
    } catch (error) {
      toast.error(t(`HostFormComplete.formfail`)); // Show error message on failure
      console.error("Maintenance request error:", error);
    } finally {
      setIsSending(false); // Set sending state back to false
    }
  };

 /**
  * @function fetchReservationByDetailId
  * @memberof module:MaintenanceRequest.MaintenanceRequest
  * @description Fetches reservation details using the detail ID.
  * Updates both local and global state with reservation data.
  *
  * @async
  * @returns {Promise<void>}
  *
  * @example
  * await fetchReservationByDetailId()
  *
  * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
  * @since 1.0.0
  * @version 1.0.0
  */
  const fetchReservationByDetailId = async () => {
    if (state.currentHotel?.subscriptionKey && detailId) {
      try {
        const results = await getReservationByDetailId( detailId, state.currentHotel, cookie.sUid );
        setReservation(results[0]); // Set reservation state
        dispatch({ type: "SET_USER_RESERVATION", payload: results[0] }); // Update global context with reservation data
      } catch (error) {
        console.log(error);
        toast.error("Unable to get reservations!"); // Error handling
      }
    }
  };

   /**
  * @function useEffectFetchReservation
  * @memberof module:MaintenanceRequest.MaintenanceRequest
  * @description Effect that triggers reservation fetching when needed.
  *
  * @effect Fetches reservation data if not already present
  * @dependencies [detailId]
  *
  * @example
  * useEffect(() => {
  *   if (!reservation) {
  *     fetchReservationByDetailId();
  *   }
  * }, [detailId]);
  *
  * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
  * @since 1.0.0
  * @version 1.0.0
  */
  useEffect(() => {
    if(isPreviewMode){
      dispatch({ type: "SET_USER_RESERVATION", payload: previewHomeReservationsData[0].reservations[1] });
      setReservation(previewHomeReservationsData[0].reservations[1])
      return;
    }

    if (!reservation) {
      fetchReservationByDetailId();
    }
  }, [detailId,state.currentHotel,cookie.sUid]); // eslint-disable-line

  return (
    <>
      {reservation && (
          <section id="MN-Page" ref={MNPageRef} onDoubleClick={(e) => handleDoubleClick(e, MNPageRef)} onMouseOver={(e) => handleElementEnter(e, MNPageRef, "MN-Page")} onMouseLeave={(e) => handleElementLeave(e, MNPageRef, "MN-Page")} className={`${isPreviewMode && isHovered === "MN-Page" ? "diagonal-stripes" : ""} flex flex-col ${isPreviewMode?" h-screen":"h-full"} dark:bg-gxp_dark_2 MN-bg_color MN-title_color MN-title_size MN-title_variant MN-title_font_family`}>
            <div id="MN-Header" ref={MNHeaderRef} onDoubleClick={(e) => handleDoubleClick(e, MNHeaderRef)} onMouseOver={(e) => handleElementEnter(e, MNHeaderRef, "MN-Header")} onMouseLeave={(e) => handleElementLeave(e, MNHeaderRef, "MN-Header")} className={`${isPreviewMode && isHovered === "MN-Header" ? "diagonal-stripes" : ""}`}>
              <HeadersPageMN title={`${t(`MaintenanceRequest.maintenance`)}`} room={reservation?.room} />
            </div>
            <main className="container mx-auto flex flex-col flex-1">
                <form className="flex flex-col p-6" onSubmit={handleSubmit}>
                  <div className="mb-4">
                    <label className="block dark:text-white font-medium mb-2" > {t("MaintenanceRequest.maintenancerequest")} </label>
                    <textarea id="MN-FormField" ref={MNFormFieldRef} onDoubleClick={(e) => handleDoubleClick(e, MNFormFieldRef)} onMouseOver={(e) => handleElementEnter(e, MNFormFieldRef, "MN-FormField")} onMouseLeave={(e) => handleElementLeave(e, MNFormFieldRef, "MN-FormField")} rows={5} className={`${isPreviewMode && isHovered === "MN-FormField" ? "diagonal-stripes" : ""}  MN-FormField-bg_color MN-FormField-border_color MN-FormField-border_position MN-FormField-border_width MN-FormField-border_rounded MN-FormField-text_color MN-FormField-text_size MN-FormField-text_font_family MN-FormField-text_variant MN-FormField-placeholder_color MN-FormField-placeholder_variant MN-FormField-placeholder_font_family dark:bg-gxp_dark_3 w-full px-3 py-2 dark:text-white dark:placeholder:text-white dark:border-gxp_dark_1 border focus:outline-none focus:border-blue-500`} value={request} onChange={(e) => setRequest(e.target.value)} placeholder={`${t(`MaintenanceRequest.description`)}`} ></textarea>
                  </div>
                  <button id="MN-Button1" ref={MNButton1Ref} onDoubleClick={(e) => handleDoubleClick(e, MNButton1Ref)} onMouseOver={(e) => handleElementEnter(e, MNButton1Ref, "MN-Button1")} onMouseLeave={(e) => handleElementLeave(e, MNButton1Ref, "MN-Button1")} type="submit" disabled={isSending} className={`${isPreviewMode && isHovered === "MN-Button1" ? "diagonal-stripes" : ""} MN-Button1-bg_color MN-Button1-border_color MN-Button1-icon_color text-sm px-4 py-2 cursor-pointer w-40 h-10 rounded-lg transition duration-300 flex items-center justify-center`} >
                    {isSending ? ( <Loader /> ) : ( <> <Send size={18} className="mr-2" />{" "} {`${t(`MaintenanceRequest.send`)}`} </> )}
                  </button>
                </form>
                {submitted && (
                  <div className="mt-4 p-4 bg-green-100 border-l-4 border-green-500 text-green-700 rounded-lg">
                    <p>{`${t(`MaintenanceRequest.success`)}`}</p>
                  </div>
                )}
            </main>
          </section>
      )}
    </>
  );
};
