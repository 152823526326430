import React, { useContext, useEffect, useRef, useState } from "react";
import { RangeSlider } from "../Components/RangeSlider";
import { AppContext } from "../../../context/AppContext";
import { Image, Trash2 } from "lucide-react";
import { Loader } from "../../../components/Layout/components/Loader/Loader";
import { formatStyleValue } from "../../Pages/ThemeDefault/Components/stylePropertyMapping";
import Select from "react-select";
import { hostSelectCustomStyles } from "../../../01_HotelPanel/components/utils/hostSelectStyles";

const fonts = [
  { label: "Arial", value: "Arial, sans-serif" },
  { label: "Helvetica", value: "Helvetica, sans-serif" },
  { label: "Times New Roman", value: "Times New Roman, serif" },
  { label: "Georgia", value: "Georgia, serif" },
  { label: "Verdana", value: "Verdana, sans-serif" },
  { label: "Roboto", value: "Roboto" },
  { label: "Open Sans", value: "Open Sans, sans-serif" },
  { label: "Lato", value: "Lato, sans-serif" },
  { label: "Montserrat", value: "Montserrat, sans-serif" },
  { label: "Poppins", value: "Poppins, sans-serif" },
  { label: "Raleway", value: "Raleway, sans-serif" },
  { label: "Source Sans Pro", value: "Source Sans Pro, sans-serif" },
  { label: "Courier New", value: "Courier New, monospace" },
  { label: "Tahoma", value: "Tahoma, sans-serif" },
  { label: "Calibri", value: "Calibri, sans-serif" },
  { label: "Geist", value: "Geist, system-ui, sans-serif" },
];

const variants = ["None", "Bold", "Italic", "Bold & Italic"];

const positions = [
  "None",
  "Top",
  "Bottom",
  "Left",
  "Right",
  "X-Axis",
  "Y-Axis",
  "All",
];

const rounded = [
  "None",
  "Small",
  "Medium",
  "Large",
  "Extra Large",
  "Round",
  "Full",
];

const location = ["Normal", "On Top"];

export const DynamicValueEditor = ({ type, value, onChange, valueList, setFile, styleSubCategoryCode, styleCategoryCode, }) => {
  const [localValue, setLocalValue] = useState(value);
  const [isUploading, setIsUploading] = useState(false);
  const [error, setError] = useState("");
  const [state, dispatch] = useContext(AppContext);
  const fileInputRef = useRef(null);

  const changeIframeElement = ( styleCategoryCode, styleSubCategoryCode, value ) => {
    // console.log('changeIframeElement called with:', { styleCategoryCode, styleSubCategoryCode, value });

    // Validate input parameters
    if (!styleCategoryCode || !styleSubCategoryCode ) {
      console.error("All parameters are required");
      return;
    }

    const iframe = document.getElementById("Iframe");
    if (!iframe || !iframe.contentWindow) {
      console.warn("Iframe or contentWindow not available");
      return;
    }

    try {
      const themeStyleCode = `${styleCategoryCode}-${styleSubCategoryCode}`;

      if (
        styleSubCategoryCode.includes("is_active") ||
        (styleSubCategoryCode.includes("image") && !styleSubCategoryCode.includes("with_image")&& !styleSubCategoryCode.includes("no_image"))
      ) {
        console.log("Sending updateActiveThemeStyle message:", {
          themeStyleCode,
          value,
        });

        iframe.contentWindow.postMessage(
          {
            action: "updateActiveThemeStyle",
            themeStyleCode,
            value,
          },
          "*"
        );
        return;
      }

      const formattedValues = formatStyleValue(themeStyleCode, value);

      if (!formattedValues) {
        return;
      }

      // Se precisar atualizar múltiplas variáveis
      if (formattedValues.length > 1) {
        // console.log('Sending updateMultipleVariables message')
        iframe.contentWindow.postMessage(
          {
            action: "updateMultipleVariables",
            variables: formattedValues.map(({ variable, value }) => ({
              variable: `--${variable}`,
              value,
            })),
          },
          "*"
        );
      } else {
        // Para uma única variável
        // console.log('Sending update one Variables message')

        const { variable, value: formattedValue } = formattedValues[0];
        iframe.contentWindow.postMessage(
          {
            action: "updateVariable",
            variable: `--${variable}`,
            value: formattedValue,
          },
          "*"
        );
      }
    } catch (error) {
      console.error("Error in changeIframeElement:", error);
    }
  };

  useEffect(() => {
    if (localValue !== value) {
      setLocalValue(value);
    }
  }, [value]);

  const handleChange = (newValue) => {
    if (type === "integer-range") {
      const stringValue = newValue.toString();
      setLocalValue(stringValue);
      changeIframeElement(styleCategoryCode, styleSubCategoryCode, stringValue);
      onChange({ target: { value: stringValue } });
    } else if (type === "image") {
      return;
    } else {
      const actualValue = newValue.target.value;
      setLocalValue(actualValue);
      changeIframeElement(styleCategoryCode, styleSubCategoryCode, actualValue);
      onChange(newValue);
    }
  };

  const handleImageLocalUpload = (event) => {
    const fileLocal = event.target.files[0];
    setFile(fileLocal);

    if (!fileLocal) return;

    // Validar tipo de arquivo
    if (!["image/jpeg", "image/png"].includes(fileLocal.type)) {
      setError("Por favor, selecione apenas imagens JPEG ou PNG");
      return;
    }

    setIsUploading(true);
    setError("");

    // Simular upload e criar URL local
    const reader = new FileReader();
    reader.onloadend = () => {
      setLocalValue(reader.result);
      onChange({ target: { value: reader.result } });
      changeIframeElement(
        styleCategoryCode,
        styleSubCategoryCode,
        reader.result
      );
      setIsUploading(false);
    };

    reader.onerror = () => {
      setError("Erro ao carregar a imagem");
      setIsUploading(false);
    };

    reader.readAsDataURL(fileLocal);
  };

  const handleClearImage = () => {
    // Limpar o arquivo selecionado
    setFile(null);

    // Limpar a pré-visualização da imagem
    setLocalValue("");

    // Limpar qualquer mensagem de erro
    setError("");

    // Informar ao componente pai que o valor foi limpo
    onChange({ target: { value: "" } });

    // Atualizar o iframe com valor vazio
    changeIframeElement(
      styleCategoryCode,
      styleSubCategoryCode,
      ""
    );

    // Resetar o input de arquivo para permitir selecionar o mesmo arquivo novamente
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  switch (type) {
    case "text":
      return (
        <input
          type="text"
          value={localValue || ""}
          onChange={handleChange}
          className="w-full px-2 py-1 host-search-input"
        />
      );

    case "integer":
      return (
        <input
          type="text"
          value={localValue || ""}
          onChange={(e) => {
            const newValue = e.target.value;
            if (newValue === "" || /^\d+$/.test(newValue)) {
              handleChange(e);
            }
          }}
          className="w-full px-2 py-1 host-search-input"
        />
      );

    case "integer-range":
      return (
        <RangeSlider
          value={Number(localValue) || 0}
          onChange={handleChange}
          valueList={valueList}
        />
      );

    case "decimal":
      return (
        <input
          type="text"
          value={localValue || ""}
          onChange={(e) => {
            const newValue = e.target.value;
            if (newValue === "" || /^\d+(\.\d{0,2})?$/.test(newValue)) {
              handleChange(e);
            }
          }}
          className="w-full px-2 py-1 border rounded border-gray-800 text-sm"
        />
      );

    case "color":
      return (
        <div className="flex gap-2 items-center ">
          <input
            type="color"
            value={localValue || "#000000"}
            onChange={handleChange}
            className="w-8 h-6 p-0 border cursor-pointer"
          />
          <input
            type="text"
            value={localValue || ""}
            onChange={handleChange}
            className="w-full host-search-input "
          />
        </div>
      );

    case "list":
      let options;

      switch (true) {
        case styleSubCategoryCode?.includes("_variant"):
          options = variants;
          break;

        case styleSubCategoryCode?.includes("_position"):
          options = positions;
          break;

        case styleSubCategoryCode?.includes("_rounded"):
          options = rounded;
          break;

        case styleSubCategoryCode?.includes("_location"):
          options = location;
          break;

        // Aqui você pode adicionar outros casos
        // case style?.styleSubCategoryCode?.includes('_outroTipo'):
        //   options = outraLista;
        //   break;

        default:
          options = valueList;
      }

      return (
        <Select
          classNamePrefix="select"
          styles={hostSelectCustomStyles}
          isSearchable={false}
          placeholder="Selecione uma opção"
          value={
            localValue
              ? {
                  value: localValue,
                  label: localValue,
                }
              : null
          }
          options={options?.map((option) => ({
            // Converte o array de strings para array de objetos
            value: option,
            label: option,
          }))}
          onChange={(selectedOption) =>
            handleChange({
              target: { value: selectedOption.value },
            })
          } // Simula o evento do select normal
          isDisabled={false}
          noOptionsMessage={() => "Nenhuma opção disponível"}
        />
      );

    case "font-family-list":
      return (
        <Select
          classNamePrefix="select"
          styles={hostSelectCustomStyles}
          isSearchable={false}
          placeholder="Selecione uma opção"
          value={fonts.find((font) => font.value === localValue)} // Encontra a fonte atual
          options={fonts} // Usa diretamente o array de fontes
          onChange={(selectedOption) =>
            handleChange({
              target: { value: selectedOption.value },
            })
          }
          isDisabled={false}
          noOptionsMessage={() => "Nenhuma opção disponível"}
          className="custom-select"
        />
      );

    case "boolean":
      const booleanOptions = ["true", "false"];

      return (
        <Select
          classNamePrefix="select"
          styles={hostSelectCustomStyles}
          isSearchable={false}
          placeholder="Selecione uma opção"
          value={
            localValue
              ? {
                  value: localValue,
                  label: localValue,
                }
              : null
          }
          options={booleanOptions?.map((option) => ({
            // Converte o array de strings para array de objetos
            value: option,
            label: option,
          }))}
          onChange={(selectedOption) =>
            handleChange({
              target: { value: selectedOption.value },
            })
          } // Simula o evento do select normal
          isDisabled={false}
          noOptionsMessage={() => "Nenhuma opção disponível"}
        />
      );

    case "image":
      return (
        <div className="space-y-2">
          {type === "image" && value === "" && (
            <div className="flex items-center space-x-2">
              <label className="relative cursor-pointer">
                <span className="inline-block px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors">
                  {isUploading ? <Loader /> : <Image />}
                </span>
                <input
                  type="file"
                  accept="image/jpeg, image/png" // Especifica apenas JPEG e PNG
                  onChange={handleImageLocalUpload}
                  className="hidden"
                  disabled={isUploading}
                  ref={fileInputRef}
                />
              </label>
            </div>
          )}

          {error && <p className="text-red-500 text-sm">{error}</p>}

          {type === "image" && value !== "" && (
            <div className="mt-2 relative group">
              <img
                src={localValue}
                alt="Uploaded"
                className="max-w-xs w-full h-full rounded object-cover max-h-[150px] overflow-y-hidden"
              />
              <div className="absolute inset-0 bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 transition-opacity flex items-center justify-center gap-x-2">
                <label className="cursor-pointer text-white px-4 py-2 rounded bg-black bg-opacity-50 hover:bg-opacity-75">
                  <Image />
                  <input
                    type="file"
                    accept="image/jpeg, image/png" // Também aqui para o input de substituição
                    onChange={handleImageLocalUpload}
                    className="hidden"
                    disabled={isUploading}
                  />
                </label>
                {localValue && (
                <button
                  onClick={handleClearImage}
                  className=" text-sm text-red-500 hover:text-red-700 px-4 py-2 rounded bg-black bg-opacity-50 hover:bg-opacity-75"
                  type="button"
                >
                  <Trash2 />
                </button>
              )}
              </div>
            </div>
          )}
        </div>
      );

    default:
      return (
        <input
          type="text"
          value={localValue || ""}
          onChange={handleChange}
          className="w-full px-2 py-1 border rounded text-sm"
        />
      );
  }
};
