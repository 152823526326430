import { useContext, useEffect, useRef, useState } from "react";
import { CalendarArrowUp, CalendarArrowDown, Clock, Users, Wifi, Tv, Coffee, Utensils, Snowflake, CreditCard, Euro, Wallet, BedDouble, Package, ListCollapse} from "lucide-react";
import { Link, useLocation, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";
import { getDefaultPreviewInitialData, getHostCheckoutInfo, getReservationByDetailId } from "../../services";
import { toast } from "react-toastify";
import { Slider } from "../../components/Layout/components/Slider/Slider";
import { Loader } from "../../components/Layout/components/Loader/Loader";
import { AppContext } from "../../context/AppContext";

import dayjs from "dayjs";
import { previewCheckoutData, previewCurrentHotel, previewHomeReservationsData } from "./Utils/previewModeData";

/**
* @module HotelReservationDetails
* @description Core module for displaying detailed hotel reservation information.
* Handles reservation data, checkout details, and amenity display.
*/

/**
* @namespace HotelReservationDetails
* @memberof module:HotelReservationDetails
* @description Component responsible for rendering comprehensive reservation details.
* Manages data fetching, balance calculations, and visual presentation.
*
* @component
* @returns {JSX.Element} HotelReservationDetails component
*
* @example
* <HotelReservationDetails />
*
* @author Tiago Ferreira <tiago.ferreira@hhs.pt>
* @since 1.0.0
* @version 1.0.0
*/
export const HotelReservationDetails = () => {

  const reservations = {
    hotelName: "Grand Luxe Hotel",
    roomType: "Deluxe Ocean View Suite",
    checkIn: "2023-07-15",
    checkOut: "2023-07-20",
    guests: 2,
    price: "$1,500",
    images: [
      {src:"https://images.unsplash.com/photo-1549638441-b787d2e11f14?q=80&w=1770&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D", alt: "image1"},
      {src:"https://plus.unsplash.com/premium_photo-1661964402307-02267d1423f5?q=80&w=1973&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D", alt: "image2"},
      {src:"https://images.unsplash.com/photo-1621891334481-5c14b369d9d7?q=80&w=1771&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D", alt: "image3"},
      {src:"https://images.unsplash.com/photo-1559841771-599b6eeaca62?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D", alt: "image4"},
      {src:"https://plus.unsplash.com/premium_photo-1689609949898-5f7a10649fef?q=80&w=1770&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D", alt: "image5"},
    ],
    amenities: [
      { name: "Free Wi-Fi", icon: Wifi },
      { name: "Flat-screen TV", icon: Tv },
      { name: "Coffee maker", icon: Coffee },
      { name: "Mini-bar", icon: Utensils },
      { name: "Air conditioning", icon: Snowflake },
    ],
    description:
      "Experience luxury and comfort in our Deluxe Ocean View Suite. This spacious room offers breathtaking views of the ocean, a king-size bed, and a private balcony. Enjoy modern amenities and personalized service for an unforgettable stay.",
  };
  const {detailId } = useParams();
  const [t] = useTranslation("global");
  const [loadingReservationDetails, setLoadingReservationDetails]= useState(true)
  const [loadingReservationBalance, setLoadingReservationBalance]= useState(true)
  const [state, dispatch] = useContext(AppContext); // eslint-disable-line
  const [reservation, setReservation] = useState(state.reservation)
  const [cookie] = useCookies(['sUid','currentHotel']);
  const [checkoutData, setCheckoutData] = useState();
  const [prevision, setPrevision] = useState()
  const [charges, setCharges] = useState()
  const [paid, setPaid] = useState()

  //////////// previewmode ///////////////
  const { pathname } = useLocation();
  const isPreviewMode = pathname.includes("/previewmode/");
  const {defaultThemeId} = useParams()
  const [isHovered, setIsHovered] = useState();
  const [currentRef, setCurrentRef] = useState(null);
  const [inspectMode, setInspectMode] = useState(JSON.parse(localStorage.getItem("inspectMode"))==="true" ? true : false);
  const RSPageRef = useRef(null);
  const RSCardRef = useRef(null);

  const handleDoubleClick = (e, elementRef) => {
    e.stopPropagation();

    // Verifica se o inspectMode está ativo antes de enviar a mensagem
    if (isPreviewMode && inspectMode && elementRef.current) {
      const elementId = elementRef.current.id;

      console.log("elementId", elementRef.current.id);

      window.parent.postMessage({
        action: "doubleClick",
        elementId: elementId,
        debugInfo: 'Double click on element to expand category/section'
      }, "*");
    }
  };

 /**
   * @function useEffectFetchDefaultPreviewInitialData
   * @memberof module:Home.Home
   * @description Effect that loads the initial preview data for the default theme.
   * Fetches and sets the active theme when the component is in preview mode.
   *
   * @effect Updates the active theme in the global application state
   * @dependencies [defaultThemeId] - Executes when the default theme ID changes
   *
   * @example
   * useEffect(() => {
   *   if (isPreviewMode) {
   *     fetchDefaultPreviewInitialData()
   *   }
   * }, [defaultThemeId]);
   *
   * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @version 1.0.0
   */
 useEffect(() => {
  const fetchDefaultPreviewInitialData = async () => {
    try {
      const InitialData = await getDefaultPreviewInitialData(defaultThemeId);
      dispatch({ type: "SET_ACTIVE_THEME", payload: InitialData.activeTheme });
    } catch (error) {
      console.error("Error loading initialData-AllRoutes:", error);
    }
  }

  if (isPreviewMode) {
    fetchDefaultPreviewInitialData()
  }
}, [defaultThemeId]); // eslint-disable-line

  /**
 * @function handleMouseEnter
 * @memberof module:Home.Home
 * @description Manages mouse enter events on elements during preview mode.
 * Sends messages to the parent component with information about the focused element.
 *
 * @param {Event} e - Mouse event
 * @param {React.RefObject} elementRef - React element reference
 *
 * @example
 * handleMouseEnter(event, myRef)
 *
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @since 1.0.0
 * @version 1.0.0
 */
const handleMouseEnter = (e, elementRef) => {
  e.stopPropagation();

  if (isPreviewMode && elementRef.current) {
    const current = elementRef.current;
    window.parent.postMessage({
      action: "hover",
      elementId: current.id,
      elementClass: current.className || '',
      elementTag: current.tagName || '',
      debugInfo: 'Hover on specific hoverable element'
    }, "*");
  }
};

/**
* @function handleMouseLeave
* @memberof module:Home.Home
* @description Handles mouse leave events for elements in preview mode.
* Sends a message to the parent window with element details when hover is removed.
*
* @param {Event} e - Mouse event object
* @param {React.RefObject} elementRef - Reference to the DOM element
*
* @example
* handleMouseLeave(event, myElementRef)
*
* @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @since 1.0.0
 * @version 1.0.0
 */
const handleMouseLeave = (e, elementRef) => {

  if (isPreviewMode && elementRef.current) {
    const current = elementRef.current;
    window.parent.postMessage({
      action: "hoverOut",
      elementId: current.id,
      elementClass: current.className || '',
      elementTag: current.tagName || '',
      debugInfo: 'HoverOut from specific hoverable element'
    }, "*");
  }
};

/**
* @function handleElementEnter
* @memberof module:Home.Home
* @description Manages element hover state entry in preview mode.
* Handles state transitions between different hovered elements and triggers
* appropriate mouse enter events.
*
* @param {Event} e - Mouse event object
* @param {React.RefObject} ref - Reference to the target DOM element
* @param {string} id - Unique identifier for the element
*
* @example
* handleElementEnter(event, elementRef, "element-id")
*
* @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @since 1.0.0
 * @version 1.0.0
 */
const handleElementEnter = (e, ref, id) => {
  e.preventDefault();
  e.stopPropagation();

  // Se houver um hover ativo em outro elemento
  if (isHovered && isHovered !== id && currentRef) {
      handleMouseLeave(e, currentRef);
  }

  // Atualiza o estado para o novo elemento
  if(inspectMode){
  setCurrentRef(ref);
  handleMouseEnter(e, ref);
  setIsHovered(id);
  }
};

/**
* @function handleElementLeave
* @memberof module:Home.Home
* @description Manages element hover state exit in preview mode.
* Cleans up hover states and references when leaving an element.
*
* @param {Event} e - Mouse event object
* @param {React.RefObject} ref - Reference to the target DOM element
* @param {string} id - Unique identifier for the element
*
* @example
* handleElementLeave(event, elementRef, "element-id")
*
* @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @since 1.0.0
 * @version 1.0.0
 */
const handleElementLeave = (e, ref, id) => {
  e.preventDefault();
  e.stopPropagation();

  // Apenas limpa o estado se for o elemento atual com hover
  if (isHovered === id) {
      handleMouseLeave(e, ref);
      setIsHovered(null);
      setCurrentRef(null);
  }
};

/**
* @function useEffectMessageHandler
* @memberof module:Home.Home
* @description Effect that sets up a message event listener for inspect mode toggling.
* Manages the inspect mode state and persists it to localStorage.
*
* @effect Sets up and cleans up message event listener
* @dependencies []
*
* @example
* useEffect(() => {
*   const handleMessage = (event) => { ... };
*   window.addEventListener("message", handleMessage);
*   return () => window.removeEventListener("message", handleMessage);
* }, []);
*
* @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @since 1.0.0
 * @version 1.0.0
 */
useEffect(() => {
const handleMessage = (event) => {
  // Log para debug
  // console.log("Mensagem recebida no iframe:", event.data);

  if (event.data.action === "toggleInspectMode") {
    const newMode = event.data.value;
    setInspectMode(newMode);
    localStorage.setItem("inspectMode", newMode.toString());
  }
};

window.addEventListener("message", handleMessage);
return () => window.removeEventListener("message", handleMessage);
}, []);

/**
* @function useEffectThemeUpdate
* @memberof module:Home.Home
* @description Effect that registers a global theme update function.
* Allows dynamic theme style updates through a globally accessible function.
* Used in preview mode to listen for theme changes and apply them to the global state.
* The global function is exposed to allow parent windows to trigger theme updates.
*
* @effect Sets up and cleans up global theme update function
* @dependencies [dispatch]
*
* @example
* useEffect(() => {
*   window.__THEME_UPDATE__ = (themeStyleCode, value) => { ... };
*   return () => { delete window.__THEME_UPDATE__; };
* }, [dispatch]);
*
* @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @since 1.0.0
 * @version 1.0.0
 */
 useEffect(() => {
  // Registrar a função de atualização globalmente
  window.__THEME_UPDATE__ = (themeStyleCode, value) => {
    // console.log('Theme update function called:', { themeStyleCode, value });
    dispatch({
      type: 'UPDATE_ACTIVE_THEME_STYLE',
      payload: { themeStyleCode, value }
    });
  };
  return () => {
    delete window.__THEME_UPDATE__;
  };
}, [dispatch]);

//////////////////////////////////////////////////////////////////////////


  /**
  * @function fetchReservations
  * @memberof module:HotelReservationDetails.HotelReservationDetails
  * @description Fetches reservation details if not already present.
  * Updates both local and global state with fetched data.
  *
  * @async
  * @returns {Promise<void>}
  *
  * @example
  * await fetchReservations()
  *
  * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
  * @since 1.0.0
  * @version 1.0.0
  */
  const fetchReservations = async () => {
    try {
      if(!reservation){
        setLoadingReservationDetails(true);
        const result = await getReservationByDetailId(detailId, state.currentHotel, cookie.sUid);
        setReservation(result[0]);
        dispatch({ type: "SET_USER_RESERVATION", payload:result[0] });
      }
    } catch (error) {
      console.log(error)
      toast.error("Unable to get reservations!");
    } finally {
      setLoadingReservationDetails(false);
    }
  }

  /**
  * @function fetchCheckoutInfo
  * @memberof module:HotelReservationDetails.HotelReservationDetails
  * @description Fetches checkout information for active reservations.
  * Updates checkout data state and handles error cases.
  *
  * @async
  * @returns {Promise<void>}
  *
  * @example
  * await fetchCheckoutInfo()
  *
  * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
  * @since 1.0.0
  * @version 1.0.0
  */
  const fetchCheckoutInfo = async () => {
    if(reservation?.GlobalResStatus===10){
      try {
        setLoadingReservationBalance(true);
        const result = await getHostCheckoutInfo(cookie.sUid, detailId, state.currentHotel);
        setCheckoutData(result.data);
      } catch (error) {
        console.log(error)
        toast.error("Unable to get Checkout Info!");
      } finally {
        setLoadingReservationBalance(false);
      }
    }
  }

  /**
  * @function useEffectFetchReservations
  * @memberof module:HotelReservationDetails.HotelReservationDetails
  * @description Effect that triggers reservation data fetching.
  *
  * @effect Fetches reservation data when dependencies change
  * @dependencies [state.currentHotel, cookie.profileKey]
  *
  * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
  * @since 1.0.0
  * @version 1.0.0
  */
  useEffect(() => {

    if(isPreviewMode){
      setReservation(previewHomeReservationsData[0].reservations[1]);
      setLoadingReservationDetails(false);
      return;
    }

    if(state.currentHotel && cookie.profileKey){
    fetchReservations();
    }
  }, [state.currentHotel, cookie.profileKey]); // eslint-disable-line

   /**
  * @function useEffectFetchCheckout
  * @memberof module:HotelReservationDetails.HotelReservationDetails
  * @description Effect that manages checkout info fetching.
  *
  * @effect Fetches checkout information when dependencies change
  * @dependencies [detailId, reservation]
  *
  * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
  * @since 1.0.0
  * @version 1.0.0
  */
  useEffect(() => {
    if(isPreviewMode){
      setCheckoutData(previewCheckoutData);
      setLoadingReservationBalance(false);
      return;
    }

    if(detailId && cookie.sUid && state.currentHotel){
      fetchCheckoutInfo();
    }
  }, [detailId, reservation]); // eslint-disable-line

  useEffect(() => {
    if(isPreviewMode){
      dispatch({ type: "SET_CURRENT_HOTEL", payload:previewCurrentHotel });
      return;
    }

  }, [isPreviewMode]); // eslint-disable-line

   /**
  * @function useEffectCalculateBalances
  * @memberof module:HotelReservationDetails.HotelReservationDetails
  * @description Effect that calculates various balance totals.
  * Handles prevision, charges, and paid amounts calculations.
  *
  * @effect Updates balance states based on checkout data
  * @dependencies [checkoutData]
  *
  * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
  * @since 1.0.0
  * @version 1.0.0
  */
  useEffect(() => {
    if (checkoutData && checkoutData.Lines) {
      const total = checkoutData.Lines.reduce((total, line) => {
        if (line.RecordType === 2) {
          return total + (line.Price);
        }
        return total;
      }, 0);
      setPrevision(total);
    }

    if (checkoutData && checkoutData.Lines) {
      const total = checkoutData.Lines.reduce((total, line) => {
        if (line.RecordType === 0 && line.IsPayment===false && line.Price>0) {
          return total + (line.Price);
        }
        return total;
      }, 0);

      setCharges(total);
    }

    if (checkoutData && checkoutData.Lines) {
      const total = checkoutData.Lines.reduce((total, line) => {
        if (line.RecordType === 0 && line.Price<0) {
          return total + (line.Price);
        }
        return total;
      }, 0);

      setPaid(total);
    }
  }, [checkoutData]); // Recalcula sempre que checkoutData mudar

  return (
    (reservation &&
    <div id="RS-Page" ref={RSPageRef} onDoubleClick={(e) => handleDoubleClick(e, RSPageRef)} onMouseOver={(e) => handleElementEnter(e, RSPageRef, "RS-Page")} onMouseLeave={(e) => handleElementLeave(e, RSPageRef, "RS-Page")} className={` ${isPreviewMode && isHovered === "RS-Page" ? "diagonal-stripes" : ""} dark:bg-gxp_dark_2 flex flex-col md:pt-4 md:px-4 RS-bg_color h-full `} >
      <main className="flex-grow overflow-y-auto max-w-4xl sm:mx-auto ">
          <h1 className="dark:text-white RS-title_size RS-title_font_family RS-title_variant RS-title_color mb-2">{state.currentHotel.name2}</h1>
        <div id="RS-Card" ref={RSCardRef} onDoubleClick={(e) => handleDoubleClick(e, RSCardRef)} onMouseOver={(e) => handleElementEnter(e, RSCardRef, "RS-Card")} onMouseLeave={(e) => handleElementLeave(e, RSCardRef, "RS-Card")} className={` ${isPreviewMode && isHovered === "RS-Card" ? "diagonal-stripes" : ""} dark:border-white max-w-4xl sm:mx-auto p-4 m-2 RS-Card-bg_color RS-Card-border_rounded RS-Card-border_position RS-Card-border_width RS-Card-border_color dark:bg-gxp_dark_3 dark:text-white border shadow-md`}>
          <h2 className="dark:text-white RS-Card-title_size RS-Card-title_font_family RS-Card-title_variant RS-Card-title_color mb-2"><span className="font-bold mr-2">{`${t("reservation")}`}</span>{reservation.resno}</h2>
          <div className="flex flex-wrap mb-6">
            {loadingReservationDetails ?
                 <div className="w-full flex flex-col items-center justify-center py-3">
                 <Loader className={`w-10 h-10`} />
               </div>
               :
            <>
            <div className="w-full md:w-1/2 mb-2 md:mb-0">
              <p className="dark:text-white flex items-center mb-2 RS-Card-text_color RS-Card-text_size RS-Card-text_font_family RS-Card-text_variant"> <CalendarArrowUp className="w-5 h-5 mr-2 RS-Card-icon_color dark:text-gxp_violet_dark" /> <span className="font-bold mr-2">{`${t("HotelReservationDetails.checkin")}`}</span> {dayjs(reservation.checkin).format(t("dayjs_format")) } </p>
              <p className="dark:text-white flex items-center mb-2 RS-Card-text_color RS-Card-text_size RS-Card-text_font_family RS-Card-text_variant"> <CalendarArrowDown className="w-5 h-5 mr-2 RS-Card-icon_color dark:text-gxp_violet_dark" /> <span className="font-bold mr-2">{`${t("HotelReservationDetails.checkout")}`}</span> {dayjs(reservation.checkout).format(t("dayjs_format")) } </p>
              <p className="dark:text-white flex items-center mb-2 RS-Card-text_color RS-Card-text_size RS-Card-text_font_family RS-Card-text_variant"> <Clock className="w-5 h-5 mr-2 RS-Card-icon_color dark:text-gxp_violet_dark" /> {dayjs(reservation.checkout).diff(dayjs(reservation.checkin), 'days')} {`${t("HotelReservationDetails.nights")}`} </p>
            </div>

            <div className="dark:text-white w-full md:w-1/2 max-md:pt-4 mb-2 max-md:border-t">
              <p className="dark:text-white flex items-center mb-2 RS-Card-text_color RS-Card-text_size RS-Card-text_font_family RS-Card-text_variant"> <Users className="w-5 h-5 mr-2 RS-Card-icon_color dark:text-gxp_violet_dark" /> <span className="font-bold mr-2">{`${t("HotelReservationDetails.guests")}`}</span> {reservation.pax + reservation.children + reservation.children2 + reservation.children3} ({reservation.pax}{" "} {reservation.pax < 2 ? `${t("HotelReservationDetails.adult")}` : `${t("HotelReservationDetails.adults")}`} {reservation.children + reservation.children2 + reservation.children3 > 0 ? "," : ""}{" "} {reservation.children + reservation.children2 + reservation.children3 > 0 ? reservation.children + reservation.children2 + reservation.children3 : ""}{" "} {reservation.children + reservation.children2 + reservation.children3 >= 2 ? `${t("HotelReservationDetails.childrens")}` : reservation.children + reservation.children2 + reservation.children3 === 1 ? `${t("HotelReservationDetails.children")}` : ``} ) </p>
              <p className="dark:text-white flex items-center mb-2 RS-Card-text_color RS-Card-text_size RS-Card-text_font_family RS-Card-text_variant"> <BedDouble className="w-5 h-5 mr-2 RS-Card-icon_color dark:text-gxp_violet_dark" /> <span className="font-bold mr-2">{`${t("Checkout.room")}`}</span> {reservation.room || 'Not assigned'} / {reservation.categoryDescription} </p>
              <p className="dark:text-white flex items-center mb-2 RS-Card-text_color RS-Card-text_size RS-Card-text_font_family RS-Card-text_variant"> <Package className="w-5 h-5 mr-2 RS-Card-icon_color dark:text-gxp_violet_dark" /><span className="font-bold mr-2">{`${t("HotelReservationDetails.pack")}`}</span> {reservation.packDescription} </p>
            </div>
            </>}
            {reservation?.GlobalResStatus===10 ?
            loadingReservationBalance ?
              <div className="w-full flex flex-col items-center justify-center py-3">
                <Loader className={`w-10 h-10`} />
              </div>
            :
              <>
                <div className="w-full border-t pt-4">
                  <div className="flex justify-between items-center mb-2">
                    <p className="dark:text-white flex items-center RS-Card-text_color RS-Card-text_size RS-Card-text_font_family RS-Card-text_variant"> <Euro className="w-5 h-5 mr-2 RS-Card-icon_color dark:text-gxp_violet_dark" />{`${t("HotelReservationDetails.rates")}`}</p>
                    <div className="text-right">
                      <span className="dark:text-white RS-Card-text_color RS-Card-text_size RS-Card-text_font_family RS-Card-text_variant">{(parseFloat(prevision)).toFixed(2)}</span><span className="ml-2 dark:text-white RS-Card-text_color RS-Card-text_size RS-Card-text_font_family RS-Card-text_variant">{checkoutData?.CurrencySymbol ?checkoutData?.CurrencySymbol : "€" }</span>
                    </div>
                  </div>

                  <div className="flex justify-between items-center mb-2">
                    <p className="dark:text-white flex items-center RS-Card-text_color RS-Card-text_size RS-Card-text_font_family RS-Card-text_variant"> <Wallet className="w-5 h-5 mr-2 RS-Card-icon_color dark:text-gxp_violet_dark" />{`${t("HotelReservationDetails.charges")}`}</p>
                    <div className="text-right">
                      <span className="dark:text-white RS-Card-text_color RS-Card-text_size RS-Card-text_font_family RS-Card-text_variant">{(parseFloat(charges)).toFixed(2)}</span><span className="ml-2 dark:text-white RS-Card-text_color RS-Card-text_size RS-Card-text_font_family RS-Card-text_variant">{checkoutData?.CurrencySymbol ?checkoutData?.CurrencySymbol : "€" }</span>
                    </div>
                  </div>

                  <div className="flex justify-between items-center mb-2">
                    <p className="dark:text-white flex items-center RS-Card-text_color RS-Card-text_size RS-Card-text_font_family RS-Card-text_variant"> <CreditCard className="w-5 h-5 mr-2 RS-Card-icon_color dark:text-gxp_violet_dark" />{`${t("HotelReservationDetails.paid")}`}</p>
                    <div className="text-right">
                      <span className="dark:text-white RS-Card-text_color RS-Card-text_size RS-Card-text_font_family RS-Card-text_variant">{(parseFloat(paid)).toFixed(2)}</span><span className="ml-2 dark:text-white RS-Card-text_color RS-Card-text_size RS-Card-text_font_family RS-Card-text_variant">{checkoutData?.CurrencySymbol ?checkoutData?.CurrencySymbol : "€" }</span>
                    </div>
                  </div>

                  <div className="flex justify-between items-center font-bold border-t pt-2">
                    <p className="dark:text-white flex items-center RS-Card-text_color RS-Card-text_size RS-Card-text_font_family"> <Euro className="w-5 h-5 mr-2 RS-Card-icon_color dark:text-gxp_violet_dark" />{`${t("HotelReservationDetails.balance")}`}</p>
                    <div className="text-right">
                      <Link to={`/hotel/${cookie.platformUid}/${cookie.detailId}/checkout`} className="flex w-fit items-center bg-white dark:bg-gxp_dark_2  dark:text-white dark:border-white border rounded-lg shadow-md p-2 hover:bg-violet-100 hover:shadow-lg transition-shadow duration-300">
                        <ListCollapse className="w-5 h-5 mr-2 RS-Card-icon_color dark:text-gxp_violet_dark" />
                        <span className="dark:text-white RS-Card-text_color RS-Card-text_size RS-Card-text_font_family">{checkoutData?.Balance ? (parseFloat(checkoutData.Balance)).toFixed(2) : '0.00'}</span><span className="ml-2 dark:text-white RS-Card-text_color RS-Card-text_size RS-Card-text_font_family">{checkoutData?.CurrencySymbol ?checkoutData?.CurrencySymbol : "€" }</span>
                      </Link>
                    </div>
                  </div>
                </div>
              </>
            :
              <div className="w-full border-t pt-4">
                <p className="dark:text-white RS-Card-text_color RS-Card-text_size RS-Card-text_font_family RS-Card-text_variant">{t("HotelReservationDetails.checkinfirst")}</p>
              </div>
            }
          <div className="mb-6 overflow-x-hidden w-screen">
              <Slider data={reservations.images} />
          </div>
          <div className="flex flex-col w-full">
          <div className="mb-6">
            <h3 className="dark:text-white RS-Card-title_size RS-Card-title_font_family RS-Card-title_variant RS-Card-title_color mb-2">{`${t("HotelReservationDetails.roomdescription")}`}</h3>
            <p className="dark:text-white RS-Card-text_color RS-Card-text_size RS-Card-text_font_family RS-Card-text_variant">{reservation.description}</p>
          </div>
          <div>
            <h3 className="dark:text-white RS-Card-title_size RS-Card-title_font_family RS-Card-title_variant RS-Card-title_color mb-2">{`${t("HotelReservationDetails.amenities")}`}</h3>
            <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
              {reservations.amenities.map((amenity, index) => (
                <div key={index} className="flex items-center">
                  <amenity.icon className="w-5 h-5 mr-2 RS-Card-icon_color dark:text-gxp_violet_dark" />
                  <span className="dark:text-white RS-Card-text_color RS-Card-text_size RS-Card-text_font_family RS-Card-text_variant">{amenity.name}</span>
                </div>
              ))}
            </div>
          </div>
          </div>
        </div>
        </div>

      </main>
</div>
    )
  );
};