import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../context/AppContext";
import { Save, Search } from "lucide-react";
import { useTranslation } from "react-i18next";
import { ParametersTable } from "./components/ParametersTable";
import { toast } from "react-toastify";
import { saveParameters } from "../../../services";
import { useParams } from "react-router-dom";
import { Loader } from "../../../components/Layout/components/Loader/Loader";
import { SaveOutlined } from "@ant-design/icons";

export const ParametersHotelPanel = () => {
  const [state, dispatch] = useContext(AppContext);
  const [parameterDescriptions, setParameterDescriptions] = useState([]);
  const [type, setType] = useState("");
  const [parameterDescription, setParameterDescription] = useState(null);
  const [t] = useTranslation("global");
  const [searchValue, setSearchValue] = useState("");
  const [filteredParameters, setFilteredParameters] = useState([]);
  const [isSending, setIsSending] = useState(false);
  const { hotelId } = useParams();
  const [paramsToSave, setParamsToSave] = useState([]);

  const handleSaveParameters = async (parametersArray) => {

    setIsSending(true);

    try {
        const response = await saveParameters(parametersArray);

        if (response.ok) {
            toast.success("Parâmetros salvos com sucesso");
            dispatch({ type: "SET_GLOBAL_REFRESH", payload: state.globalRefresh + 1 });
            setParamsToSave([]);

        }

    } catch (error) {
      console.error('Error saving parameter:', error);
      toast.error("Erro ao salvar parâmetro");

    } finally {
        setIsSending(false);
    }

  }

  useEffect(() => {
    if (searchValue.trim() === "") {
      setFilteredParameters(parameterDescriptions);
    } else {
      const filtered = parameterDescriptions.filter(
        (param) =>
          param.paramCode.toLowerCase().includes(searchValue.toLowerCase()) ||
          param.paramDescription
            .toLowerCase()
            .includes(searchValue.toLowerCase())
      );
      setFilteredParameters(filtered);
    }
  }, [searchValue, parameterDescriptions]);

  useEffect(() => {
    if (state.hotelPanelInitialData?.hotelParameters) {
      setParameterDescriptions(state.hotelPanelInitialData.hotelParameters);
      setFilteredParameters(state.hotelPanelInitialData.hotelParameters);
    }
  }, [state.hotelPanelInitialData]);

  return (
    <div className="flex flex-col h-full">
      <div className="flex justify-end items-center mb-6 pt-1 pr-1">
      <div className="flex space-x-4 w-full justify-end bg-[var(--host-r24-sec-bg-color)] p-2 rounded-md">
      {isSending ? (
             <button
             onClick={() => {
               handleSaveParameters(paramsToSave);
             }}
             className="host-button-primary"
           >
             <Loader className="w-4 h-4" />
           </button>

          ):(
          <button
            onClick={() => {
              handleSaveParameters(paramsToSave);
            }}
            className="flex items-center host-button-primary"
          >
            <SaveOutlined className="w-5 h-5 items-center justify-center" />
          </button>)
          }
          <div className="relative">
            <input
              type="text"
              placeholder={`${t(`freesearch`)}`}
              className="!pl-8 pr-4 py-2 host-search-input"
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}

            />
            <Search className="w-4 h-4 text-gray-400 absolute left-3 top-1.5" />
            </div>
        </div>
      </div>

      <div className="overflow-x-auto h-full">
        <ParametersTable
          parameterDescriptions={filteredParameters}
          setParameterDescriptions={setParameterDescriptions}
          setType={setType}
          setParameterDescription={setParameterDescription}
          showSections={!searchValue.trim()}
          paramsToSave={paramsToSave}
          setParamsToSave={setParamsToSave}
        />
      </div>
    </div>
  );
};
