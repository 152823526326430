import { useTranslation } from "react-i18next";
import { AlertTriangle } from "lucide-react";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../../context/AppContext";
import { CheckFieldVisibility } from "../Utilities/CheckFieldVisibility";

/**
 * @namespace IndividualVatNumber
 * @memberof module:OnlineCheckIn
 * @description Component for managing a guest's individual tax identification number (VAT).
 * Handles input, validation, and country-specific requirements with local state management.
 *
 * @component
 * @param {Object} props Component properties
 * @param {Object} props.guest Guest data containing VAT and nationality information
 * @param {Function} props.handleInvoiceNifChange Function to handle VAT input changes
 * @param {Function} props.validateInvoiceNifOnBlur Function to validate VAT on blur
 * @param {string|boolean} props.error Error message for the VAT field
 * @param {Array<Object>} props.guestFieldsGXP Guest field configuration
 *
 * @returns {JSX.Element} VAT input field component
 *
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @since 1.0.0
 * @version 1.0.0
 */
export const IndividualVatNumber = ({
  guest,
  handleInvoiceNifChange,
  validateInvoiceNifOnBlur,
  error,
  guestFieldsGXP
}) => {
  const [t] = useTranslation("global");
  const [isSameCountry, setIsSameCountry] = useState(false);
  const [state, dispatch] = useContext(AppContext);
  const [labelIsActive, setLabelIsActive] = useState(null);
  const [placeholderIsActive, setPlaceholderIsActive] = useState(null);

  // Local state for VAT number input
  const [localVatNumber, setLocalVatNumber] = useState(guest.data.invoiceEntityVATNumber || "");

  const field = guestFieldsGXP?.find(field => field.Code === "INDIVIDUALVATNUMBER");
  const { isVisible, isMandatory } = CheckFieldVisibility(field, guest, state);

  // Synchronize local state when guest data changes externally
  useEffect(() => {
    setLocalVatNumber(guest.data.invoiceEntityVATNumber || "");
  }, [guest.data.invoiceEntityVATNumber]);

  // Theme-based styling effects
  useEffect(() => {
    setLabelIsActive(
      state.activeTheme.find(
        (style) => style.themeStyleCode === "OC-FormField-label_is_active" && style.isActive
      ).value
    );
    setPlaceholderIsActive(
      state.activeTheme.find(
        (style) => style.themeStyleCode === "OC-FormField-placeholder_is_active" && style.isActive
      ).value
    );
  }, [state.activeTheme]);

  // Country matching effect
  useEffect(() => {
    if (!state?.hotelInitialData?.CountryList || !state?.currentHotel?.hotelCountry)
      return;

    if (!guest?.data?.invoiceEntityCountryIso) return;

    const hotelCountry = state?.hotelInitialData?.CountryList.find(
      (element) => element?.IsoCode === state?.currentHotel?.hotelCountry
    );
    setIsSameCountry(hotelCountry?.IsoCode === guest.data.invoiceEntityCountryIso);
  }, [state?.hotelInitialData, guest.data.invoiceEntityCountryIso]);

  const isRequired = isMandatory;

  // Validation effect
  useEffect(() => {
    if(isSameCountry){
      validateInvoiceNifOnBlur(
        guest.id,
        guest.data.invoiceEntityVATNumber,
        guest.data.invoiceEntityCountryIso
      );
    }
  }, [
    guest.data.invoiceEntityCountryIso,
    guest.data.invoiceEntityVATNumber,
    isSameCountry
  ]);

  // Handle local input changes
  const handleLocalChange = (e) => {
    setLocalVatNumber(e.target.value);
  };

  // Handle blur event to update global state and validate
  const handleBlur = () => {
    // Update global state
    handleInvoiceNifChange(guest.id, localVatNumber);

    // Validate if in same country
    if (isSameCountry) {
      validateInvoiceNifOnBlur(
        guest.id,
        localVatNumber,
        guest.data.invoiceEntityCountryIso
      );
    }
  };

  return (
    <div className="w-full h-full dark:text-white relative">
      {isVisible && (labelIsActive === "true" || labelIsActive === null) && (
        <label
          htmlFor="nif"
          className={`OC-FormField-label_location OC-FormField-label_color OC-FormField-label_size OC-FormField-label_font_family OC-FormField-label_variant OC-Form-bg_color pl-2 rounded-xl inline-block text-center justify-center dark:bg-gxp_dark_3 dark:text-white mb-1`}
        >
          <span className="dark:text-white text-red-600">
            {isRequired ? ` * \u00A0` : ``}
          </span>
          {`${t(`Nif.vatnumber`)}`}{" "}
        </label>
      )}
      <input
        name="invoiceEntityVATNumber"
        required={isRequired}
        onChange={handleLocalChange}
        onBlur={handleBlur}
        value={localVatNumber}
        type="text"
        id="invoiceEntityVATNumber"
        className={`OC-FormField-text_color OC-FormField-text_size OC-FormField-text_font_family OC-FormField-text_variant OC-FormField-bg_color OC-FormField-border_rounded OC-FormField-border_position OC-FormField-border_width dark:bg-gxp_dark_3 dark:text-white w-full px-4 py-2 h-[42px] ${
          error
            ? "border-red-500"
            : "OC-FormField-border_color dark:border-white"
        } focus:ring-2 focus:border-blue-500 transition duration-150 ease-in-out
        OC-FormField-placeholder_color OC-FormField-placeholder_font_family OC-FormField-placeholder_variant`}
        placeholder={
          placeholderIsActive === "true" || placeholderIsActive === null
            ? `${t(`Nif.vatnumber`)} ${isRequired ? `( * )\u00A0` : ``}`
            : ""
        }
      />
      {error && (
        <div className="absolute left-0 bottom-0 transform translate-y-[calc(100%+4px)] flex items-center z-10 w-full">
          <AlertTriangle className="text-red-500 mr-1" size={16} />
          <span className="text-red-500 text-sm">{error}</span>
        </div>
      )}
    </div>
  );
};