import { useState, useMemo, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Select } from "antd";
import { ChevronDown } from "lucide-react";
import { AlertTriangle } from "lucide-react";
import { AppContext } from "../../../../context/AppContext";

/**
 * @namespace Phone
 * @memberof module:OnlineCheckIn
 * @description Component for managing guest phone number input with country dial codes.
 * Provides country selection with flags and dial code integration.
 *
 * @component
 * @param {Object} props Component properties
 * @param {Object} props.initialData Initial configuration with country and dial code data
 * @param {Object} props.guest Current guest data
 * @param {Function} props.handleGuestChange Function to update guest data
 * @param {Array<Object>} props.requiredFields List of required fields
 * @param {string} props.requiredFields[].Field Field identifier
 * @param {boolean} props.requiredFields[].IsMandatory Indicates if field is mandatory
 * @param {string|boolean} props.error Error state for the field
 *
 * @returns {JSX.Element} Phone input with country code selection
 *
 * @example
 * <Phone
 *   initialData={{
 *     CountryList: [{IsoCode: "PT", TranslatedDescription: "Portugal"}],
 *     DialCodes: {"PT": "+351"}
 *   }}
 *   guest={{ id: 1, data: { Mobile: "+351 123456789" }}}
 *   handleGuestChange={(id, field, value) => updateGuest(id, field, value)}
 *   requiredFields={[{ Field: "Phone", IsMandatory: true }]}
 *   error={false}
 * />
 *
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @since 1.0.0
 * @version 1.0.0
 */
export const Phone = ({ initialData, guest, handleGuestChange, requiredFields, error, }) => {
  const [selectedDialCode, setSelectedDialCode] = useState(""); // State to store the selected dial code
  const [phoneNumber, setPhoneNumber] = useState(guest?.data?.Phone || ""); // State to store the phone number
  const [isDialCodeSelected, setIsDialCodeSelected] = useState(false); // State to check if a dial code has been selected
  const [isOpen, setIsOpen] = useState(false);
  const [t] = useTranslation("global");
  const [state, dispatch] = useContext(AppContext);
  const [labelIsActive, setLabelIsActive] = useState(null);
  const [placeholderIsActive, setPlaceholderIsActive] = useState(null);

  /**
 * @function useEffectThemeStyles
 * @memberof module:OnlineCheckIn.Phone
 * @description Effect that manages label and placeholder visibility based on theme settings.
 *
 * @effect Updates labelIsActive and placeholderIsActive states
 * @dependencies [state.activeTheme]
 *
 * @example
 * useEffect(() => {
 *   setLabelIsActive(state.activeTheme.find(style =>
 *     style.themeStyleCode === "OC-FormField-label_is_active" &&
 *     style.isActive
 *   ).value);
 * }, [state.activeTheme]);
 *
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @since 1.0.0
 * @version 1.0.0
 */
  useEffect(() => {
    setLabelIsActive( state.activeTheme.find( (style) => style.themeStyleCode === "OC-FormField-label_is_active" && style.isActive ).value );
    setPlaceholderIsActive( state.activeTheme.find( (style) => style.themeStyleCode === "OC-FormField-placeholder_is_active" && style.isActive ).value );
  }, [state.activeTheme]);

/**
 * @function isRequired
 * @memberof module:OnlineCheckIn.Phone
 * @description Determines if the phone field is required based on validation rules.
 *
 * @type {boolean}
 *
 * @example
 * const isRequired = requiredFields.some(
 *   field => field.Field === "Phone" && field.IsMandatory
 * );
 *
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @since 1.0.0
 * @version 1.0.0
 */
  const isRequired = requiredFields.some(
    (field) => field.Field === "Phone" && field.IsMandatory
  );

  /**
 * @function countries
 * @memberof module:OnlineCheckIn.Phone
 * @description Memoized list of countries with dial codes.
 * Combines country information with corresponding dial codes.
 *
 * @type {Array<Object>}
 *
 * @example
 * const countries = useMemo(() => {
 *   return initialData.CountryList.map(country => ({
 *     ...country,
 *     dialCode: initialData.DialCodes[country.IsoCode] || ""
 *   }));
 * }, [initialData.CountryList, initialData.DialCodes]);
 *
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @since 1.0.0
 * @version 1.0.0
 */
  const countries = useMemo(() => {
    return initialData.CountryList.map((country) => ({
      ...country,
      dialCode: initialData.DialCodes[country.IsoCode] || "",
    }));
  }, [initialData.CountryList, initialData.DialCodes]);

  /**
 * @function handleDialCodeChange
 * @memberof module:OnlineCheckIn.Phone
 * @description Handles country dial code selection.
 * Updates phone number format with new dial code.
 *
 * @param {Object} selected Selected country option
 * @param {string} selected.value Selected dial code
 *
 * @example
 * handleDialCodeChange({ value: "+351" })
 *
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @since 1.0.0
 * @version 1.0.0
 */
  const handleDialCodeChange = (selected) => {
    const dialCode = selected.value; // Aqui extraímos o dialCode selecionado
    setSelectedDialCode(dialCode); // Atualiza o estado com o código de discagem
    setIsDialCodeSelected(true);

    // Atualiza o número de telefone com o código de discagem
    if (phoneNumber) {
      handleGuestChange(
        guest.id,
        "Phone",
        `${dialCode} ${phoneNumber.replace(/^\+\d+\s/, "")}`
      );
    }
  };

  /**
 * @function handlePhoneChange
 * @memberof module:OnlineCheckIn.Phone
 * @description Handles phone number input changes.
 * Combines dial code with phone number input.
 *
 * @param {Event} e Change event from input
 *
 * @example
 * handlePhoneChange({ target: { value: "123456789" }})
 *
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @since 1.0.0
 * @version 1.0.0
 */
  const handlePhoneChange = (e) => {
    const value = e.target.value; // Get the value from the phone input
    // Get the value from the phone input
    setPhoneNumber(value);
    // Call the function to update the guest with the formatted phone number
    handleGuestChange(guest.id, "Phone", `${selectedDialCode} ${value}`); // Pass formatted value to the function
  };

  return (
    <div>
      {(labelIsActive === "true" || labelIsActive === null) && (
        <label
          htmlFor="telefone"
          className={`OC-FormField-label_location_phone OC-FormField-label_color OC-FormField-label_size OC-FormField-label_font_family OC-FormField-label_variant OC-Form-bg_color pl-2  rounded-xl inline-block text-center justify-center dark:bg-gxp_dark_3 dark:text-white mb-1 z-10`}
        >
          {`${t(`Phone.phone`)}`}
          <span className="text-gray-400 dark:text-white ml-2">
            {isRequired ? `( * )\u00A0` : ``}
          </span>{" "}
        </label>
      )}
      <div className="flex h-[43.62px] w-full">
        <div className="flex h-full w-full items-stretch dark:border-gray-600">
          <Select
            suffixIcon={
              <ChevronDown
                className={`OC-FormField-icon_color dark:text-white transition-transform duration-300 ease-in-out ${
                  isOpen ? "rotate-180" : ""
                }`}
              />
            }
            onDropdownVisibleChange={(open) => setIsOpen(open)}
            className={`OC-FormField-text_color OC-FormField-text_size OC-FormField-text_font_family OC-FormField-text_variant OC-FormField-bg_color OC-FormField-border_rounded_phone_select OC-FormField-border_position OC-FormField-border_width dark:bg-gxp_dark_3 dark:text-white w-full px-2 py-2 h-[42px] ${
              error
                ? "border-red-500"
                : "OC-FormField-border_color dark:border-white"
            } focus:ring-2 focus:border-blue-500 transition duration-150 ease-in-out`}
            style={{ width: "150px" }}
            onChange={handleDialCodeChange}
            placeholder={
              <span className="dark:text-gray-400">
                {`${t(`Phone.select`)}`}
              </span>
            }
            optionLabelProp="label"
            popupClassName={`dark:dark`}
            showSearch
            dropdownStyle={{ minWidth: "260px" }}
            labelInValue
            value={ selectedDialCode ? { value: selectedDialCode, label: selectedDialCode } : undefined }
            filterOption={(input, option) => {
              const country = countries.find(
                (country) => country.dialCode === option.value
              );
              return (
                country?.TranslatedDescription?.toLowerCase().includes(
                  input.toLowerCase()
                ) ||
                country?.dialCode?.toLowerCase().includes(input.toLowerCase())
              );
            }}
            virtual={false}
          >
            {countries.map((country) => (
              <Select.Option
                key={country.IsoCode}
                className={`dark:dark`}
                value={country.dialCode}
                label={
                  <div className="flex items-center">
                    {" "}
                    <img
                      src={`https://flagcdn.com/w20/${country.IsoCode.toLowerCase()}.png`}
                      alt={`${country.TranslatedDescription} flag`}
                      className="w-6 h-4 mr-2"
                    />{" "}
                    <span className="text-l">{country.dialCode}</span>{" "}
                  </div>
                }
              >
                <div className="flex items-center">
                  <img
                    src={`https://flagcdn.com/w20/${country.IsoCode.toLowerCase()}.png`}
                    alt={`${country.TranslatedDescription} flag`}
                    className="w-6 h-4 mr-2 "
                  />
                  <span className="text-l dark:text-white">
                    {country.TranslatedDescription}
                  </span>
                  <span className="ml-2 text-l text-gray-500 dark:text-white">
                    {country.dialCode}
                  </span>
                </div>
              </Select.Option>
            ))}
          </Select>

          <div className="justify-center w-full h-full">
            <input
              name="Phone"
              onChange={handlePhoneChange}
              value={phoneNumber}
              type="tel"
              id="telefone"
              className={`OC-FormField-text_color OC-FormField-text_size OC-FormField-text_font_family OC-FormField-text_variant OC-FormField-bg_color OC-FormField-border_rounded_phone OC-FormField-border_position OC-FormField-border_width dark:bg-gxp_dark_3 dark:text-white w-full px-4 py-2 h-[42px] ${
                error
                  ? "border-red-500"
                  : "OC-FormField-border_color dark:border-white"
              } focus:ring-2 focus:border-blue-500 transition duration-150 ease-in-out
              OC-FormField-placeholder_color OC-FormField-placeholder_font_family OC-FormField-placeholder_variant`}
              placeholder={ placeholderIsActive === "true" || placeholderIsActive === null ? `${t(`Phone.phone`)} ${isRequired ? `( * )\u00A0` : ``}` : "" }
            />
          </div>
        </div>
      </div>
      {error && (
        <div className="flex mt-1 items-center">
          {" "}
          <AlertTriangle className="text-red-500 mr-1" size={16} />{" "}
          <span className="text-red-500 text-sm">{`${t(
            `HostForm.fieldRequired`
          )}`}</span>{" "}
        </div>
      )}
    </div>
  );
};
