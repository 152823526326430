/**
 * @memberof module:EMenu.Utils
 * @description Function for organizing and processing menu data into a hierarchical structure.
 * Processes allergens, messages, and creates navigation links for each menu item.
 *
 * @function organizeMenuData
 * @param {Array<Object>} menuDetails Raw menu items data
 * @param {Array<Object>} allergens Available allergens information
 * @param {Array<Object>} messages Available messages for menu items
 * @param {Object} routeParams Navigation parameters
 * @param {string} routeParams.platformUid Platform identifier
 * @param {string} routeParams.detailId Detail identifier
 * @param {string} routeParams.sUid Session identifier
 * @param {string} routeParams.key Menu key
 * @returns {Array<Object>} Processed and organized menu structure
 *
 * @example
 * const organized = organizeMenuData(
 *   menuItems,
 *   allergensList,
 *   messagesList,
 *   { platformUid: 'p1', detailId: 'd1', sUid: 's1', key: 'k1' }
 * );
 *
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @since 1.0.0
 * @version 1.0.0
 */
export const organizeMenuData = (menuDetails, allergens, messages, routeParams, isPreviewMode, defaultThemeId ) => {
    // Função auxiliar para ordenar items por sortOrder
    const sortByOrder = (items) => {
      return items.sort((a, b) => a.SortOrder - b.SortOrder);
    };

    // Filtrar e ordenar os parents principais
    const mainParents = sortByOrder(menuDetails.filter(item => item.ParentId === 0));

    const processItem = (item) => {
      const processedItem = { ...item };

      // Processar allergens
      if (item.Allergens && item.Allergens.length > 0) {
        processedItem.AllergensData = item.Allergens.map(code =>
          allergens.find(a => a.Code === code)
        ).filter(Boolean);
      }

      // Processar messages
      if (item.MessageIds && item.MessageIds.length > 0) {
        processedItem.MessagesData = item.MessageIds.map(id =>
          messages.find(m => m.Id === id)
        ).filter(Boolean);
      }

      if(isPreviewMode){
        processedItem.LinkTo = `/previewmode/emenu/${defaultThemeId}/${item.Id}`;
      }else{
        processedItem.LinkTo = `/hotel/${routeParams.platformUid}/${routeParams.detailId}/emenu/${routeParams.sUid}/${routeParams.key}/${item.Id}`;
      }

      return processedItem;
    };

    const findChildren = (parentId) => {
      // Filtrar os filhos e ordená-los por sortOrder
      const children = sortByOrder(menuDetails.filter(item => item.ParentId === parentId));

      return children.map(child => {
        const processedChild = processItem(child);
        if (child.ItemId === 0) {
          processedChild.Children = findChildren(child.Id);
        }
        return processedChild;
      });
    };

    return mainParents.map(parent => {
      const processedParent = processItem(parent);
      processedParent.Children = findChildren(parent.Id);
      return processedParent;
    });
  };