import { ArrowBigLeftDash, Save, X } from "lucide-react";
import { motion, AnimatePresence } from "framer-motion";
import { useCallback, useContext, useEffect, useState } from "react";
import { saveParameterDescription } from "../../../services";
import { AppContext } from "../../../context/AppContext";
import { Loader } from "../../../components/Layout/components/Loader/Loader";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { parameterTypes } from "../utils/parameterTypes";
import { ValueListEditor } from "../utils/ValueListEditor";
import { PasswordInput } from "../utils/PasswordInput";
import { DefaultValueInput } from "../utils/DefaultValueInput";
import { CloseCircleOutlined, SaveOutlined } from "@ant-design/icons";
import Select from "react-select";
import { hostSelectCustomStylesModal } from "../../../01_HotelPanel/components/utils/hostSelectStyles";

export const ParameterDescriptionModal = ({ isParameterDescriptionModalOpen, onCloseParameterDescriptionModal, title, type, parameterDescription, }) => {
  const [isSending, setIsSending] = useState(false);
  const [state, dispatch] = useContext(AppContext);
  const [saveStatus, setSaveStatus] = useState({
    status: null,
    message: "",
  });
  const [formData, setFormData] = useState({
    id: "",
    paramCode: "",
    paramDescription: "",
    defaultValue: "",
    valueType: "",
    valueList: [],
    isActive: "",
    user: "",
  });
  const location = useLocation();
  const isParametersPage = location.pathname.includes("/parameters/parameters");
  const [t] = useTranslation("global");

    // Movemos handleChange para fora do componente principal
    const handleChange = useCallback((e) => {
      e.stopPropagation();
      const { name, value, type, checked } = e.target;

      if (name === "paramCode" && type !== "checkbox") {
        // ... código de verificação do paramCode ...
      }

      setFormData(prev => ({
        ...prev,
        [name]: type === "checkbox" ? checked : value,
      }));
    }, []);

  console.log(formData);

  const postParameterDescription = async () => {
    try {
      setIsSending(true); // Set sending state to true
      await saveParameterDescription(formData); // Send request
      setSaveStatus({
        status: "success",
        message: `${t(`Admin.parameters.success`)}`,
      });
      return true;
    } catch (error) {
      console.error("Erro ao guardar o parâmetro:", error);
      setSaveStatus({
        status: "error",
        message: error.message || `${t(`Admin.parameters.error`)}`,
      });
      return false;
    } finally {
      setIsSending(false); // Set sending state back to false
    }
  };

  const handleParameterDescriptionSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation(); // Add this to prevent event bubbling
    setIsSending(true);
    if (type === "add") {
      const success = await postParameterDescription(formData);

      if (success) {
        // Aguarda 2 segundos para mostrar o feedback antes de fechar
        setTimeout(() => {
          if (isParametersPage) {
            dispatch({
              type: "SET_GLOBAL_REFRESH",
              payload: state.globalRefresh + 1,
            });
          }
          onCloseParameterDescriptionModal();
        }, 2000);
      }
    } else if (type === "edit") {
      const success = await postParameterDescription(formData);

      if (success) {
        setTimeout(() => {
          if (isParametersPage) {
            dispatch({
              type: "SET_GLOBAL_REFRESH",
              payload: state.globalRefresh + 1,
            });
          }
          onCloseParameterDescriptionModal();
        }, 2000);
      }
    }
  };

  useEffect(() => {
    if (type === "add") {
      setFormData({
        id: 0,
        paramCode: "",
        paramDescription: "",
        defaultValue: "",
        valueType: "",
        valueList: [],
        isActive: true,
        user: state.adminLoggedIn.username,
      });
      setSaveStatus({
        status: null,
        message: "",
      });
    } else if (type === "edit") {
      setFormData({
        id: parameterDescription.id,
        paramCode: parameterDescription.paramCode,
        paramDescription: parameterDescription.paramDescription,
        defaultValue: parameterDescription.defaultValue,
        valueType: parameterDescription.valueType,
        valueList: parameterDescription.valueList,
        isActive: parameterDescription.isActive,
        user: state.adminLoggedIn.username,
      });
      setSaveStatus({
        status: null,
        message: "",
      });
    }
  }, [isParameterDescriptionModalOpen]);


  return (
    <AnimatePresence>
      {isParameterDescriptionModalOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.2 }}
          className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[70]"
        >
          <motion.div
            initial={{ scale: 0.95, opacity: 0, y: 20 }}
            animate={{ scale: 1, opacity: 1, y: 0 }}
            exit={{ scale: 0.95, opacity: 0, y: 20 }}
            transition={{
              duration: 0.2,
              ease: "easeOut",
            }}
            className="bg-white rounded-lg shadow-xl w-full max-w-md"
          >
            <motion.div
              className="flex items-center justify-between p-4 border-b"
              initial={{ y: -10, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ delay: 0.1 }}
            >
              <h2 className="host-title-modal">{title}</h2>
              <motion.button
                onClick={onCloseParameterDescriptionModal}
                className="p-1 hover:bg-gray-100 rounded-full"
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
              >
                <CloseCircleOutlined className="w-5 h-5 items-center justify-center" />
              </motion.button>
            </motion.div>

            <motion.div
              className="p-4"
              initial={{ y: 10, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ delay: 0.15 }}
            >
              <form
                onSubmit={(e) => {
                  handleParameterDescriptionSubmit(e);
                }}
                className="space-y-4"
              >
                <div>
                  <label className="block host-text-modal">{`${t(
                    `Admin.parameters.code`
                  )}`}</label>
                  <input
                    type="text"
                    name="paramCode"
                    value={formData.paramCode}
                    onChange={handleChange}
                    className={`mt-1 block w-full host-search-input ${
                      type === "edit" ? "bg-gray-100" : ""
                    }`}
                    required
                    disabled={type === "edit"}
                  />
                </div>

                <div>
                  <label className="block host-text-modal">{`${t(
                    `Admin.parameters.description`
                  )}`}</label>
                  <input
                    type="text"
                    name="paramDescription"
                    value={formData.paramDescription}
                    onChange={handleChange}
                    className="mt-1 block w-full host-search-input"
                    required
                  />
                </div>

                <div>
                  <label className="block host-text-modal">{`${t(
                    `Admin.parameters.defaultValue`
                  )}`}</label>
                  <DefaultValueInput formData={formData} setFormData={setFormData} />
                </div>

                <div>
                  <label className="block host-text-modal">{`${t(
                    `Admin.parameters.type`
                  )}`}</label>
                  <Select
                    classNamePrefix="select"
                    className="w-full mt-1"
                    styles={hostSelectCustomStylesModal}
                    isSearchable={false}
                    name="valueType"
                    placeholder={`${t(`Admin.parameterDescriptionModal.selectType`)}`}
                    value={
                      formData.valueType ?
                      { value: formData.valueType, label: formData.valueType } :
                      null
                    }
                    options={parameterTypes?.map(type => ({
                      value: type,
                      label: type
                    }))}
                    onChange={(selectedOption) => {
                      // Update formData directly without using handleChange
                      // This avoids the stopPropagation error
                      setFormData({
                        ...formData,
                        defaultValue: "",
                        valueList: [],
                        valueType: selectedOption ? selectedOption.value : "",
                      });
                    }}
                    inputProps={{
                      style: { textIndent: "0", marginLeft: "0" },
                    }}
                  />
                </div>

                {formData.valueType === "boolean" || formData.valueType === "list" && (
                    <div>
                      <label className="block host-text-modal">{`${t(
                        `Admin.parameters.listValues`
                      )}`}</label>
                      <ValueListEditor
                        editForm={formData}
                        setEditForm={setFormData}
                      />
                    </div>
                  )}

                {type === "edit" && (
                  <div className="flex items-center">
                    <input
                      type="checkbox"
                      id="isActive"
                      name="isActive"
                      checked={formData.isActive}
                      onChange={handleChange}
                      className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                    />
                    <label
                      htmlFor="isActive"
                      className="ml-2 block host-text-modal"
                    >
                      {`${t(`Admin.parameters.active`)}`}
                    </label>
                  </div>
                )}

                {/* Status Message */}
                {saveStatus.status && (
                  <div
                    className={`p-3 rounded-md ${
                      saveStatus.status === "success"
                        ? "bg-green-50 text-green-700"
                        : "bg-red-50 text-red-700"
                    }`}
                  >
                    {saveStatus.message}
                  </div>
                )}

                <div className="flex justify-end space-x-3 pt-4">
                  {isSending ? (
                    <button
                      type="submit"
                      className="px-4 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    >
                      <Loader />
                    </button>
                  ) : (
                    <button
                      type="submit"
                      disabled={saveStatus.status === "error" || isSending}
                      className="px-4 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    >
                      <SaveOutlined className="w-5 h-5 items-center justify-center" />
                      </button>
                  )}
                </div>
              </form>
            </motion.div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};
