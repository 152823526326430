/**
 * @namespace Utils
 * @memberof module:EMenu
 * @description Recursive function for finding an item by ID in a nested menu structure.
 * Traverses through the entire tree of items and their children to locate a specific item.
 *
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @since 1.0.0
 * @version 1.0.0
 */

/**
 * @memberof module:EMenu.Utils
 * @description Recursive function for finding an item by ID in a nested menu structure.
 * Traverses through the entire tree of items and their children to locate a specific item.
 *
 * @function findItemById
 * @param {Array<Object>} items Array of menu items with potential children
 * @param {string} targetId ID of the item to find
 * @returns {Object|null} Found item or null if not found
 *
 * @example
 * const menuItems = [
 *   {
 *     Id: '1',
 *     Description: 'Category',
 *     Children: [
 *       { Id: '2', Description: 'Item' }
 *     ]
 *   }
 * ];
 * const item = findItemById(menuItems, '2');
 * // Returns: { Id: '2', Description: 'Item' }
 *
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @since 1.0.0
 * @version 1.0.0
 */
export const findItemById = (items, targetId) => {
    // Função recursiva para procurar em toda a árvore
    const findInTree = (items) => {
      for (const item of items) {
        // Verifica se é o item que procuramos
        if (item.Id.toString() === targetId) {
          return item;
        }

        // Se tiver filhos, procura recursivamente neles
        if (item.Children && item.Children.length > 0) {
          const found = findInTree(item.Children);
          if (found) return found;
        }
      }
      return null;
    };

    return findInTree(items);
  };