import React, { useState, useEffect, useRef } from 'react';
import { Clock } from 'lucide-react';
import { useTranslation } from 'react-i18next';

/**
 * @namespace TimePicker
 * @memberof module:OnlineCheckIn
 * @description Component for selecting a time value using a dropdown picker.
 * This component allows the user to choose hours and minutes separately and
 * combines them into a single time value.
 *
 * @component
 * @param {Object} props - The properties passed to the component.
 * @param {string} props.value - The current time value (format: "HH:mm").
 * @param {function} props.onChange - Function to handle time changes.
 * @param {number} [props.hoursStep=1] - The step increment for hours.
 * @param {number} [props.minutesStep=15] - The step increment for minutes.
 * @param {string} [props.className=""] - Additional CSS classes for styling.
 *
 * @returns {JSX.Element} The rendered time picker component.
 *
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @version 1.0.0
 * @since 1.0.0
 *
 * @example
 * <TimePicker
 *   value={selectedTime}
 *   onChange={handleTimeChange}
 *   hoursStep={1}
 *   minutesStep={30}
 *   className="custom-class"
 * />
 */
export const TimePicker = ({ value, onChange, hoursStep = 1, minutesStep = 15, className = "" }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedHours, setSelectedHours] = useState("00");
  const [selectedMinutes, setSelectedMinutes] = useState("00");
  const [dropdownPosition, setDropdownPosition] = useState('bottom');
  const pickerRef = useRef(null);
  const dropdownRef = useRef(null);
  const hoursContainerRef = useRef(null);
  const minutesContainerRef = useRef(null);
  const [t] = useTranslation("global");

  // Gera as opções de horas
  const hours = Array.from({ length: Math.floor(24 / hoursStep) }, (_, i) =>
    (i * hoursStep).toString().padStart(2, '0')
  );

  // Gera as opções de minutos
  const minutes = Array.from({ length: Math.floor(60 / minutesStep) }, (_, i) =>
    (i * minutesStep).toString().padStart(2, '0')
  );

  /**
   * @function useEffectClickOutside
   * @memberof module:OnlineCheckIn.TimePicker
   * @param {Object} pickerRef - The ref to the time picker component.
   *
   * @description Effect that closes the time picker when clicking outside of it.
   *
   * @effect Closes the time picker when clicking outside.
   * @dependencies [pickerRef]
   *
   * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @version 1.0.0
   * @since 1.0.0
   */
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (pickerRef.current && !pickerRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

 /**
   * @function useEffectUpdateSelectedValues
   * @memberof module:OnlineCheckIn.TimePicker
   * @param {string} value - The current time value.
   *
   * @description Effect that updates the selected hours and minutes when the value prop changes.
   *
   * @effect Updates the selected hours and minutes.
   * @dependencies [value]
   *
   * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @version 1.0.0
   * @since 1.0.0
   */
  useEffect(() => {
    if (value) {
      const [hours, minutes] = value.split(':');
      setSelectedHours(hours);
      setSelectedMinutes(minutes);
    }
  }, [value]);

  /**
   * @function useEffectDropdownPosition
   * @memberof module:OnlineCheckIn.TimePicker
   * @param {boolean} isOpen - The open state of the time picker.
   * @param {Object} pickerRef - The ref to the time picker component.
   * @param {Object} dropdownRef - The ref to the dropdown component.
   *
   * @description Effect that adjusts the position of the dropdown based on available space.
   *
   * @effect Adjusts the position of the dropdown.
   * @dependencies [isOpen, pickerRef, dropdownRef]
   *
   * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @version 1.0.0
   * @since 1.0.0
   */
  useEffect(() => {
        if (isOpen && pickerRef.current && dropdownRef.current) {
          const pickerRect = pickerRef.current.getBoundingClientRect();
          const dropdownHeight = dropdownRef.current.offsetHeight;
          const windowHeight = window.innerHeight;
          const spaceBelow = windowHeight - pickerRect.bottom;
          const spaceAbove = pickerRect.top;

          // Se não houver espaço suficiente abaixo e houver mais espaço acima
          if (spaceBelow < dropdownHeight && spaceAbove > spaceBelow) {
            setDropdownPosition('top');
          } else {
            setDropdownPosition('bottom');
          }
        }
      }, [isOpen]);

    /**
   * @function handleTimeChange
   * @memberof module:OnlineCheckIn.TimePicker
   * @param {string} hours - The selected hours.
   * @param {string} minutes - The selected minutes.
   *
   * @description Handles the time change when hours or minutes are selected.
   * Combines the selected hours and minutes into a single time value and calls the onChange function.
   *
   * @returns {void}
   *
   * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @version 1.0.0
   * @since 1.0.0
   */
  const handleTimeChange = (hours, minutes) => {
    const newTime = `${hours}:${minutes}`;
    onChange(0,"ArrivalTime",newTime);
  };

    /**
   * @function scrollToSelected
   * @memberof module:OnlineCheckIn.TimePicker
   * @param {Object} containerRef - The ref to the container element.
   * @param {string} selectedValue - The selected value to scroll to.
   *
   * @description Scrolls to the selected value within the container element.
   *
   * @returns {void}
   *
   * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @version 1.0.0
   * @since 1.0.0
   */
  const scrollToSelected = (containerRef, selectedValue) => {
    const selectedElement = containerRef.current?.querySelector(`[data-value="${selectedValue}"]`);
    if (selectedElement) {
      selectedElement.scrollIntoView({ block: 'center', behavior: 'smooth' });
    }
  };

 /**
   * @function useEffectScrollToSelected
   * @memberof module:OnlineCheckIn.TimePicker
   * @param {boolean} isOpen - The open state of the time picker.
   * @param {string} selectedHours - The selected hours.
   * @param {string} selectedMinutes - The selected minutes.
   *
   * @description Effect that scrolls to the selected hours and minutes when the dropdown opens.
   *
   * @effect Scrolls to the selected hours and minutes.
   * @dependencies [isOpen, selectedHours, selectedMinutes]
   *
   * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @version 1.0.0
   * @since 1.0.0
   */
  useEffect(() => {
        if (isOpen) {
          scrollToSelected(hoursContainerRef, selectedHours);
          scrollToSelected(minutesContainerRef, selectedMinutes);
        }
      }, [isOpen, selectedHours, selectedMinutes]);

  return (
    <div className="relative dark:bg-gxp_dark_3 dark:text-white dark:border-white OC-FormField-text_color OC-FormField-text_size OC-FormField-text_font_family OC-FormField-text_variant OC-FormField-bg_color OC-FormField-border_rounded OC-FormField-border_position OC-FormField-border_width OC-FormField-border_color " ref={pickerRef}>
      {/* Input principal */}
      <div className={`flex items-center p-2 cursor-pointer  transition-colors`} onClick={() => setIsOpen(!isOpen)} >
        <span className="flex-1 text-center">
          {selectedHours}:{selectedMinutes}
        </span>
        <Clock className="w-4 h-4 mr-2 OC-FormField-icon_color dark:text-white" />
      </div>

      {/* Dropdown do picker */}
      {isOpen && (
        <div ref={dropdownRef} className={`absolute ${ dropdownPosition === 'top' ? 'bottom-full mb-1' : 'top-full mt-1' } dark:bg-gxp_dark_1 dark:text-white dark:border-white bg-white border rounded-lg shadow-lg z-50 flex time-picker-dropdown`}>
          {/* Coluna das horas */}
          <div className="w-16 border-r">
            <div className="p-2 text-center text-sm OC-FormField-text_color dark:text-white border-b">
            {`${t(`hour`)}`}
            </div>
            <div ref={hoursContainerRef} className="h-48 overflow-y-auto time-picker-scrollbar">
              {hours.map((hour) => (
                <div
                  key={hour}
                  data-value={hour}
                  className={`p-2 text-center cursor-pointer hover:bg-gray-300 dark:hover:bg-gxp_dark_3 transition-colors
                    ${selectedHours === hour ? 'OC-bg_color dark:bg-gxp_dark_3 font-medium' : ''}`}
                  onClick={() => {
                    setSelectedHours(hour);
                    handleTimeChange(hour, selectedMinutes);
                  }}

                >
                  {hour}
                </div>
              ))}
            </div>
          </div>

          {/* Coluna dos minutos */}
          <div className="w-16">
            <div className="p-2 text-center text-sm OC-FormField-text_color dark:text-white border-b">
            {`${t(`minute`)}`}
            </div>
            <div ref={minutesContainerRef} className="h-48 overflow-y-auto time-picker-scrollbar">
              {minutes.map((minute) => (
                <div
                  key={minute}
                  data-value={minute}
                  className={`p-2 text-center cursor-pointer hover:bg-gray-300 dark:hover:bg-gxp_dark_3 transition-colors
                    ${selectedMinutes === minute ? 'OC-bg_color dark:bg-gxp_dark_3 font-medium' : ''}`}
                  onClick={() => {
                    setSelectedMinutes(minute);
                    handleTimeChange(selectedHours, minute);
                  }}

                >
                  {minute}
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};



