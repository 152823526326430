import { motion } from "framer-motion";
import React from "react";
import { Link } from "react-router-dom";

/**
 * @namespace MenuHome
 * @memberof module:EMenu
 * @description Component for rendering a grid of animated category cards.
 * Displays menu categories with images or fallback styling, supporting
 * hover animations and responsive layout.
 *
 * @component
 * @param {Object} props Component properties
 * @param {Array<Object>} props.categories Array of category objects
 * @param {string} props.categories[].Id Unique identifier for the category
 * @param {string} props.categories[].Description Category title/description
 * @param {string} props.categories[].Notes Optional additional information
 * @param {string} props.categories[].ImageUrl Optional URL for category image
 * @param {string} props.categories[].LinkTo Navigation path for the category
 *
 * @returns {JSX.Element} Grid of animated category cards
 *
 * @example
 * const categories = [
 *   {
 *     Id: '1',
 *     Description: 'Main Courses',
 *     Notes: 'Traditional dishes',
 *     ImageUrl: '/images/main.jpg',
 *     LinkTo: '/menu/main'
 *   }
 * ];
 *
 * <MenuHome categories={categories} />
 *
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @since 1.0.0
 * @version 1.0.0
 */
export const MenuHome = ({
  categories,
  isPreviewMode,
  isHovered,
  handleElementEnter,
  handleElementLeave,
  EMEMenuCardRef,
}) => {
  const MotionLink = motion.create(Link);

  return (
    <div className="grid gap-4 max-w-4xl mx-auto p-2">
      {categories.map((category) => (
        <MotionLink id="EM-EMenuCard" ref={EMEMenuCardRef} onMouseOver={(e) => handleElementEnter(e, EMEMenuCardRef, "EM-EMenuCard") } onMouseLeave={(e) => handleElementLeave(e, EMEMenuCardRef, "EM-EMenuCard") } style={ category.ImageUrl !== "" ? { backgroundImage: `url(${category.ImageUrl})`, backgroundSize: "cover", backgroundPosition: "center", } : {} } key={category.Id} to={category.LinkTo} whileHover={{ scale: 1.02 }} whileTap={{ scale: 0.9 }} className={`${ isPreviewMode && isHovered === "EM-EMenuCard" ? "diagonal-stripes" : "" } ${ category.ImageUrl !== "" ? "EM-EMenuCard-with_image_border_color EM-EMenuCard-with_image_border_width EM-EMenuCard-with_image_border_position EM-EMenuCard-with_image_border_rounded" : "EM-EMenuCard-no_image_bg_color EM-EMenuCard-no_image_border_color EM-EMenuCard-no_image_border_width EM-EMenuCard-no_image_border_position EM-EMenuCard-no_image_border_rounded" } h-48 border dark:border-gxp_dark_3`} >
          <div className={`${ category.ImageUrl !== "" ? "EM-EMenuCard-with_image_border_rounded" : "EM-EMenuCard-no_image_border_rounded" } relative h-48 overflow-hidden hover:shadow-xl transition-shadow`} >
            <div className={`${ category.ImageUrl !== "" ? "bg-gradient-to-t from-black/60 to-transparent" : "" } absolute inset-0 h-full w-full`} />
            <div className="absolute bottom-0 left-0 right-0 p-4">
              <h2 className={`${ category.ImageUrl !== "" ? "EM-EMenuCard-with_image_title_color EM-EMenuCard-with_image_title_size EM-EMenuCard-with_image_title_variant EM-EMenuCard-with_image_title_font_family" : "EM-EMenuCard-no_image_title_color EM-EMenuCard-no_image_title_size EM-EMenuCard-no_image_title_variant EM-EMenuCard-no_image_title_font_family" }`} >
                {category.Description}
              </h2>
              {category.Notes && (
                <p className={`${ category.ImageUrl !== "" ? "EM-EMenuCard-with_image_text_color EM-EMenuCard-with_image_text_size EM-EMenuCard-with_image_text_variant EM-EMenuCard-with_image_text_font_family" : "EM-EMenuCard-no_image_text_color EM-EMenuCard-no_image_text_size EM-EMenuCard-no_image_text_variant EM-EMenuCard-no_image_text_font_family" } mt-1`} >
                  {category.Notes}
                </p>
              )}
            </div>
          </div>
        </MotionLink>
      ))}
    </div>
  );
};
