
import { useTranslation } from "react-i18next";
import { useContext, useEffect, useState } from "react";
import { AlertTriangle } from "lucide-react";
import { AppContext } from "../../../../context/AppContext";
import { CheckFieldVisibility } from "../Utilities/CheckFieldVisibility";

export const Email = ({ handleGuestChange, guest, error, emailError, setEmailError, guestFieldsGXP }) => {
  const [t] = useTranslation("global");
  const [state, dispatch] = useContext(AppContext);
  const [labelIsActive, setLabelIsActive] = useState(null);
  const [placeholderIsActive, setPlaceholderIsActive] = useState(null);
  // Estado local para o valor do email
  const [localEmail, setLocalEmail] = useState(guest.data.Email || "");

  const field = guestFieldsGXP?.find(field => field.Code === "EMAIL1");
  const { isVisible, isMandatory } = CheckFieldVisibility(field, guest, state);

  // Sincronizar o estado local quando o valor do guest mudar externamente
  useEffect(() => {
    setLocalEmail(guest.data.Email || "");
  }, [guest.data.Email]);

  useEffect(() => {
    setLabelIsActive(state.activeTheme.find(
      (style) => style.themeStyleCode === "OC-FormField-label_is_active" && style.isActive
    ).value);

    setPlaceholderIsActive(state.activeTheme.find(
      (style) => style.themeStyleCode === "OC-FormField-placeholder_is_active" && style.isActive
    ).value);
  }, [state.activeTheme]);

  const isRequired = isMandatory;

  const validateEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  // Função para lidar com a digitação do usuário (apenas atualiza o estado local)
  const handleLocalChange = (e) => {
    setLocalEmail(e.target.value);
  };

  // Função para validar e atualizar o valor no estado global quando o campo perde o foco
  const handleBlur = () => {
    // Validar o email
    if (localEmail && !validateEmail(localEmail)) {
      setEmailError(`${t(`Email.error`)}`);
    } else {
      setEmailError("");
    }

    // Atualizar o valor no estado global apenas quando o campo perde o foco
    handleGuestChange(guest.id, "Email", localEmail);
  };

  return (
    <div className="relative">
      {isVisible && (labelIsActive === "true" || labelIsActive === null) && (
        <label
          htmlFor="email"
          className={`OC-FormField-label_location OC-FormField-label_color OC-FormField-label_size OC-FormField-label_font_family OC-FormField-label_variant OC-Form-bg_color pl-2 rounded-xl inline-block text-center justify-center dark:bg-gxp_dark_3 dark:text-white mb-1`}
        >
          <span className="dark:text-white text-red-600">
            {isRequired ? ` * \u00A0` : ``}
          </span>
          {`${t(`Email.email`)}`}
        </label>
      )}
      <input
        name="Email"
        required={isRequired}
        onChange={handleLocalChange}
        onBlur={handleBlur}
        value={localEmail}
        type="email"
        id="email"
        className={`OC-FormField-text_color OC-FormField-text_size OC-FormField-text_font_family OC-FormField-text_variant OC-FormField-bg_color OC-FormField-border_rounded OC-FormField-border_position OC-FormField-border_width dark:bg-gxp_dark_3 dark:text-white w-full px-4 py-2 h-[42px] ${
          error
            ? "border-red-500"
            : "OC-FormField-border_color dark:border-white"
        } focus:ring-2 focus:border-blue-500 transition duration-150 ease-in-out
        OC-FormField-placeholder_color OC-FormField-placeholder_font_family OC-FormField-placeholder_variant`}
        placeholder={placeholderIsActive === "true" || placeholderIsActive === null
          ? `${t(`Email.email`)} ${isRequired ? `( * )\u00A0` : ``}`
          : ""}
      />
      {emailError && (
        <div className="flex mt-1 items-center">
          <AlertTriangle className="text-red-500 mr-1" size={16} />
          <span className="text-red-500 text-sm">{emailError}</span>
        </div>
      )}
      {error && (
        <div className="absolute left-0 bottom-0 transform translate-y-[calc(100%+4px)] flex items-center z-10 w-full">
          <AlertTriangle className="text-red-500 mr-1" size={16} />
          <span className="text-red-500 text-sm">{`${t(`HostForm.fieldRequired`)}`}</span>
        </div>
      )}
    </div>
  );
};