import { Info, ArrowBigLeftDash, ArrowBigRightIcon } from "lucide-react";
import { useState, useEffect, useCallback, useRef } from "react";
import { SignaturePadComp } from "./Utilities/SignaturePadComp";
import { useTranslation } from "react-i18next";

/**
 * @namespace Consent
 * @memberof module:OnlineCheckIn
 * @description Component for managing guest consent agreements.
 * This component displays mandatory and optional consents, allows the user
 * to agree to them, and captures a signature.
 *
 * @component
 * @param {Object} props - The properties passed to the component.
 * @param {number} props.activeTab - The currently active tab index.
 * @param {boolean} props.showConsent - Flag to determine if the consent view is shown.
 * @param {boolean} props.showGeneralTerms - Flag to determine if general terms view is shown.
 * @param {function} props.previous - Function to navigate to the previous step.
 * @param {function} props.next - Function to navigate to the next step.
 * @param {Object} props.guest - The guest object containing consent data.
 * @param {Array<Object>} props.guests - An array of guest objects.
 * @param {function} props.setGuests - Function to update the guests' data.
 *
 * @returns {JSX.Element} The rendered consent component.
 *
 * @example
 * <Consent
 *   activeTab={0}
 *   showConsent={true}
 *   showGeneralTerms={false}
 *   previous={previousFunction}
 *   next={nextFunction}
 *   guest={guestObject}
 *   guests={guestsArray}
 *   setGuests={setGuestsFunction}
 * />
 *
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @version 1.0.0
 * @since 1.0.0
 */
export const Consent = ({handleDoubleClick, OCButton1Ref, isPreviewMode, OCButton2Ref, isHovered, handleElementEnter, handleElementLeave, activeTab, showConsent, showGeneralTerms, previous, next, guest, guests, setGuests, titles, militaryMode }) => {
  const [allConsent, setAllConsent] = useState(false);
  const [visibleConsentIds, setVisibleConsentIds] = useState([]);
  const [mandatoryConsentValid, setMandatoryConsentValid] = useState(true); // State to store the validity of mandatory consents
  const [t] = useTranslation("global");
  const consentRef = useRef(null);

/**
 * @function handleNext
 * @memberof module:OnlineCheckIn.Consent
 * @description Handles the next button click event in the consent page.
 * Implements different behaviors based on:
 * - Preview mode: Skips validation and posts message to parent window
 * - Secondary guests with FillLater=true: Skips validation
 * - Regular flow: Validates mandatory consents and signature
 *
 * @param {Event} e - The event object for the button click
 *
 * @returns {void}
 *
 * @throws {Error} Implicitly throws if translation function t() fails
 *
 * @example
 * // Preview mode navigation
 * handleNext(event) // Posts message and proceeds
 *
 * @example
 * // Secondary guest with FillLater=true
 * handleNext(event) // Skips validation and proceeds
 *
 * @example
 * // Regular flow with missing consents
 * handleNext(event) // Shows consent alert and stops
 *
 * @example
 * // Regular flow with missing signature
 * handleNext(event) // Shows signature alert and stops
 *
 * @see {@link next} For the navigation function called after validation
 * @see {@link handleElementLeave} For preview mode button state handling
 *
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @version 1.0.0
 * @since 1.0.0
 */
  const handleNext = (e) => {
    if (isPreviewMode) {
      handleElementLeave(e, OCButton1Ref, "OC-Button1");
      next(e, guest.id);

      window.parent.postMessage({
        action: "OC-GeneralTermPage",
        debugInfo: 'Change to General Terms Page'
      }, "*");

      return;
    }

      // Skip validation for secondary guests with FillLater=true
  if (guest.id !== 0 && guest.data.FillLater) {
    next(e, guest.id);
    return;
  }

    if (!mandatoryConsentValid) {
      alert(t(`Consent.consentsalert`));
      return;
    }

    if (!guest.data.GuestSignatureBase64) {
      alert(t(`Consent.signaturealert`));
      return;
    }

    next(e, guest.id);
  };

  /**
 * @function handlePrevious
 * @memberof module:OnlineCheckIn.Consent
 * @param {Event} e - The event object.
 *
 * @description Handles the previous button click event.
 * If in preview mode, it navigates to the previous step.
 * Otherwise, it navigates to the previous step without any additional checks.
 *
 * @returns {void}
 *
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @version 1.0.0
 * @since 1.0.0
 */
  const handlePrevious = (e) => {

    if (isPreviewMode) {
      handleElementLeave(e, OCButton2Ref, "OC-Button2");
      previous(e, guest.id);

      window.parent.postMessage({
        action: "OC-FormPage",
        debugInfo: 'Change to Form Page'
      }, "*");

      return;
    }

    previous(e, guest.id);
  };

/**
 * @function toggleVisibility
 * @memberof module:OnlineCheckIn.Consent
 * @param {string} consentId - The unique identifier of the consent to toggle.
 *
 * @description Toggles the visibility of a specific consent's detailed description.
 * This function adds or removes the consentId from the list of visible consents.
 *
 * @returns {void}
 *
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @version 1.0.0
 * @since 1.0.0
 */
  const toggleVisibility = (consentId) => {
    setVisibleConsentIds((prev) => {
      if (prev.includes(consentId)) {
        return prev.filter((id) => id !== consentId); // Remove the consentId if it is already visible
      } else {
        return [...prev, consentId]; // Adds the consentId to make it visible
      }
    });
  };

/**
 * @function handleAllConsent
 * @memberof module:OnlineCheckIn.Consent
 *
 * @description Handles the agreement to all consents at once.
 * This function toggles the agreement status of all consents for the guest
 * based on whether they have already agreed to them or not.
 *
 * @returns {void}
 *
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @version 1.0.0
 * @since 1.0.0
 */
  const handleAllConsent = useCallback(() => {
    const allAgreed = guest.data?.Consents?.every(
      (consent) => consent.GuestHasConsent
    );
    const updatedConsents = guest.data?.Consents?.map((consent) => ({
      ...consent,
      GuestHasConsent: !allAgreed, // Set all to true or false
    }));

    setGuests((prevGuests) =>
      prevGuests.map((g) =>
        g.id === guest.id
          ? { ...g, data: { ...g.data, Consents: updatedConsents } }
          : g
      )
    );
  }, [guest.data.Consents, guest.id, setGuests]);

/**
 * @function handleGuestChange
 * @memberof module:OnlineCheckIn.Consent
 * @param {number} guestId - The unique identifier of the guest to be updated.
 * @param {string} consentId - The unique identifier of the consent being changed.
 * @param {boolean} isChecked - The new agreement status of the consent.
 *
 * @description Handles changes to individual guest consent agreements.
 * This function updates the specific consent for a guest based on their agreement.
 *
 * @returns {void}
 *
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @version 1.0.0
 * @since 1.0.0
 */
  const handleGuestChange = useCallback(
    (guestId, consentId, isChecked) => {
      setGuests((prevGuests) =>
        prevGuests.map((g) => {
          if (g.id === guestId) {
            const updatedConsents = g.data.Consents.map((consent) => {
              if (consent.ConsentId === consentId) {
                return { ...consent, GuestHasConsent: isChecked }; // Updates the GuestHasConsent state
              }
              return consent; // Returns the unmodified consent
            });
            return { ...g, data: { ...g.data, Consents: updatedConsents } }; // Returns the updated guest
          }
          return g; // Returns the unmodified guest
        })
      );
    },
    [setGuests]
  );

/**
 * @function validateMandatoryConsents
 * @memberof module:OnlineCheckIn.Consent
 *
 * @description Validates if all mandatory consents have been agreed to.
 * This function checks if all mandatory consents are marked as agreed by the guest.
 *
 * @returns {void}
 *
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @version 1.0.0
 * @since 1.0.0
 */
  const validateMandatoryConsents = useCallback(() => {
    const allMandatoryAgreed = guest.data?.Consents?.filter(
      (consent) => consent.IsMandatory
    ).every((consent) => consent.GuestHasConsent);
    setMandatoryConsentValid(allMandatoryAgreed);
  }, [guest.data?.Consents]);

  /**
 * @function useEffectMandatoryConsentsValidation
 * @memberof module:OnlineCheckIn.Consent
 * @param {Array} guest.data?.Consents - Array of guest consents.
 * @param {function} validateMandatoryConsents - Function to validate mandatory consents.
 *
 * @description Effect that validates mandatory consents whenever there is a change.
 *
 * @effect Validates mandatory consents and updates allConsent state.
 * @dependencies [guest.data?.Consents, validateMandatoryConsents]
 *
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @version 1.0.0
 * @since 1.0.0
 */
  useEffect(() => {
    const allAgreed = guest.data?.Consents?.every(
      (consent) => consent.GuestHasConsent
    );

    setAllConsent(allAgreed);
    validateMandatoryConsents(); // Validates mandatory consents whenever there is a change
  }, [guest.data?.Consents, validateMandatoryConsents]);

  return (
    <div className="p-2 mx-auto">
        <h2 className="OC-title_color OC-title_size OC-title_font_family OC-title_variant text-center dark:text-white mb-4">{`${t(`Consent.consents`)} - ${guest.id === 0 ? guest.data.FirstName+' '+guest.data.LastName : guest.title}`}</h2>
        <div id="OC-Consent" ref={consentRef} onDoubleClick={(e) => handleDoubleClick(e, consentRef)} onMouseOver={(e) => handleElementEnter(e, consentRef, "OC-Consent")} onMouseLeave={(e) => handleElementLeave(e, consentRef, "OC-Consent")} className={`${isPreviewMode && isHovered === "OC-Consent" ? "diagonal-stripes" : ""} OC-Consent-bg_color OC-Consent-border_color OC-Consent-border_rounded OC-Consent-border_position OC-Consent-border_width max-w-2xl mx-auto px-6 py-4 dark:bg-gxp_dark_3`}>
          <p className="OC-Consent-title_color OC-Consent-title_size OC-Consent-title_font_family OC-Consent-title_variant mb-6 dark:text-white">{`${t(`Consent.accept`)}`}</p>
          <div className="mb-6">
            <div className="flex items-center mb-4 ">
              <input type="checkbox" checked={allConsent} onChange={handleAllConsent} className="cursor-pointer mr-2" />
              <span className="OC-Consent-text_color OC-Consent-text_size OC-Consent-text_font_family OC-Consent-text_variant dark:text-white">{`${t(`Consent.agreeall`)}`}</span>
            </div>
            <div className="mb-4">
              <p className="OC-Consent-title_color OC-Consent-title_size OC-Consent-title_font_family OC-Consent-title_variant dark:text-white mb-2">{`${t(`Consent.mandatory`)}`}</p>
              <div className="flex flex-col">
                {guest.data?.Consents?.filter(
                  (consent) => consent.IsMandatory
                ).map((consent) => (
                  <div key={consent.ConsentId}>
                    <div className="flex items-center dark:text-white w-full">
                      <input type="checkbox" checked={consent.GuestHasConsent} onChange={(e) => handleGuestChange( guest.id, consent.ConsentId, e.target.checked ) } className="cursor-pointer mr-2" />
                      <div className="flex justify-between w-full">
                        <span className="dark:text-white OC-Consent-text_color OC-Consent-text_size OC-Consent-text_font_family OC-Consent-text_variant w-full">{consent.TextTitle}</span>
                        <div className="">
                          <Info onClick={() => toggleVisibility(consent.ConsentId)} className="cursor-pointer OC-Consent-icon_color dark:text-white ml-2 w-4 h-4" />
                        </div>
                      </div>
                    </div>
                    {visibleConsentIds.includes(consent.ConsentId) && (
                      <p className="OC-Consent-text_color OC-Consent-text_size OC-Consent-text_font_family OC-Consent-text_variant mt-2 mb-3 dark:text-white">{consent.TextLong}</p>
                    )}
                  </div>
                ))}
              </div>
            </div>

            <div>
              <p className="OC-Consent-title_color OC-Consent-title_size OC-Consent-title_font_family OC-Consent-title_variant dark:text-white mb-2">{`${t(`Consent.optional`)}`}</p>
              <div className="flex flex-col">
                {guest.data?.Consents?.filter(
                  (consent) => !consent.IsMandatory
                ).map((consent) => (
                  <div key={consent.ConsentId}>
                    <div className="flex items-center dark:text-white w-full ">
                      <input type="checkbox" checked={consent.GuestHasConsent} onChange={(e) => handleGuestChange( guest.id, consent.ConsentId, e.target.checked ) } className="cursor-pointer mr-2" />
                      <div className="flex justify-between w-full">
                        <span className="dark:text-white OC-Consent-text_color OC-Consent-text_size OC-Consent-text_font_family OC-Consent-text_variant w-full">{consent.TextTitle}</span>
                        <div className="">

                          <Info onClick={() => toggleVisibility(consent.ConsentId)} className="cursor-pointer OC-Consent-icon_color dark:text-white ml-2 w-4 h-4" />
                        </div>
                      </div>
                    </div>
                    {visibleConsentIds.includes(consent.ConsentId) && (
                      <p className="OC-Consent-text_color OC-Consent-text_size OC-Consent-text_font_family OC-Consent-text_variant mt-2 mb-3 dark:text-white">{consent.TextLong}</p>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div>
            <SignaturePadComp activeTab={activeTab} handleGuestChange={handleGuestChange} guest={guest} setGuests={setGuests} titles={titles} militaryMode={militaryMode} />
          </div>
          <div className="flex justify-between">
            <button id="OC-Button2" ref={OCButton2Ref} onDoubleClick={(e) => handleDoubleClick(e, OCButton2Ref)} onMouseOver={(e) => handleElementEnter(e, OCButton2Ref, "OC-Button2")} onMouseLeave={(e) => handleElementLeave(e, OCButton2Ref, "OC-Button2")} type="button" onClick={handlePrevious} className={`${isPreviewMode && isHovered === "OC-Button2" ? "diagonal-stripes" : ""} py-2 px-4 border dark:bg-white ${ !showGeneralTerms && !showConsent && activeTab === 0 ? "bg-gray-400 cursor-not-allowed" : "OC-Button2-bg_color dark:bg-white" } dark:text-gxp_violet dark:border-gxp_violet OC-Button2-icon_color OC-Button2-border_color rounded`} >
              <ArrowBigLeftDash />
            </button>
            <button id="OC-Button1" ref={OCButton1Ref} onDoubleClick={(e) => handleDoubleClick(e, OCButton1Ref)} onMouseOver={(e) => handleElementEnter(e, OCButton1Ref, "OC-Button1")} onMouseLeave={(e) => handleElementLeave(e, OCButton1Ref, "OC-Button1")} type="button" onClick={handleNext} className={`${isPreviewMode && isHovered === "OC-Button1" ? "diagonal-stripes" : ""} py-2 px-4 ${ allConsent || isPreviewMode ? "OC-Button1-bg_color dark:bg-gxp_violet" : "bg-gray-400 cursor-not-allowed" } dark:text-white dark:border-white OC-Button1-icon_color OC-Button1-border_color rounded`} >
              <ArrowBigRightIcon />
            </button>
          </div>
        </div>
    </div>
  );
};
