import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { AlertCircle, AlertTriangle, CheckCircle2 } from "lucide-react";

/**
 * @namespace SliderCardHotel
 * @memberof module:HotelHome
 * @description Component responsible for rendering individual hotel service cards.
 * Supports image and icon-based layouts with completion indicators and animations.
 *
 * @component
 * @param {Object} props - Component props
 * @param {Object} props.subItem - Service item data to be displayed
 * @param {number} props.index - Item index for animation sequencing
 *
 * @returns {JSX.Element} SliderCardHotel component
 *
 * @example
 * <SliderCardHotel
 *   subItem={serviceData}
 *   index={0}
 * />
 *
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @since 1.0.0
 * @version 1.0.0
 */
export const SliderCardHotel = ({ subItem, index }) => {
  const MotionLink = motion.create(Link);

  /**
   * @function getCompletionIndicator
   * @memberof module:HotelHome.SliderCardHotel
   * @description Determines and returns the appropriate completion indicator icon.
   * Provides visual feedback based on completion rate thresholds.
   *
   * @param {number} rate - Completion rate percentage
   * @returns {JSX.Element} Icon component based on completion rate
   *
   * @example
   * getCompletionIndicator(75)
   *
   * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @since 1.0.0
   * @version 1.0.0
   */
  const getCompletionIndicator = (rate) => {
    if (rate <= 40) {
      return (
        <AlertCircle
          className="w-5 h-5 text-red-500"
          aria-label="Low completion rate"
        />
      );
    } else if (rate <= 70) {
      return (
        <AlertTriangle
          className="w-5 h-5 text-yellow-500"
          aria-label="Medium completion rate"
        />
      );
    } else {
      return (
        <CheckCircle2
          className="w-5 h-5 text-green-500 dark:text-green-300"
          aria-label="High completion rate"
        />
      );
    }
  };

  /**
   * @constant {Array} typesToStrikethrough
   * @memberof module:HotelHome.SliderCardHotel
   * @description Array of service types that should be displayed with strikethrough styling.
   * Used to visually indicate unavailable or deprecated services.
   *
   * @type {Array<string>}
   *
   * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @since 1.0.0
   * @version 1.0.0
   */
  const typesToStrikethrough = [];

  return (
    <MotionLink style={ subItem.ImagesUrl.length > 0 ? { backgroundImage: `url(${subItem.ImagesUrl[0]})`, backgroundSize: "cover", backgroundPosition: "center", } : {} } key={subItem.Id} initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }} transition={{ delay: index * 0.1, duration: 0.2 }} to={subItem.LinkTo} className={`${ subItem.ImagesUrl.length > 0 ? "" : "HT-Card-bg_color " } dark:border-gxp_dark_3 h-40 HT-Card-border_color HT-Card-border_rounded HT-Card-border_width HT-Card-border_position HT-Card-border_rounded block shadow-lg hover:shadow-xl transition-shadow relative `} whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.98 }} >
      <div className={`${ subItem.ImagesUrl.length > 0 ? "bg-gradient-to-t from-black/80 to-transparent rounded-[inherit] inset-0" : "" } absolute h-full w-full `} />
      <div className="flex flex-col w-full h-full">
        {subItem.ImagesUrl.length > 0 ? (
          <div className="absolute bottom-0 left-0 right-0 p-4 text-left">
            <h2 className="HT-Card-title_color HT-Card-title_size_small HT-Card-title_font_family HT-Card-title_variant z-10">
              {subItem.Description}
            </h2>
          </div>
        ) : (
          <div className="flex flex-col items-center justify-center h-full w-full">
            <span dangerouslySetInnerHTML={{ __html: subItem.IconTag.replace( /<i /, '<i style="font-size: 25px" ' ), }} className="HT-Card-icon_color dark:text-violet-400" />
            <span className={`HT-Card-text_color HT-Card-text_size HT-Card-text_font_family HT-Card-text_variant`} >
              {subItem.Description}
            </span>
          </div>
        )}
        {subItem.rate && (
          <div className="flex items-center gap-2 absolute right-4 top-5">
            {getCompletionIndicator(subItem.rate)}
            <span className={`text-sm text-gray-600 ${ subItem.rate <= 40 ? "text-red-500" : subItem.rate <= 70 ? "text-yellow-500" : "text-green-500 dark:text-green-300" }`} >
              {subItem.rate}%
            </span>
          </div>
        )}
      </div>
    </MotionLink>
  );
};
