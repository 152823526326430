import { useTranslation } from "react-i18next";
import { AlertTriangle } from "lucide-react";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../../context/AppContext";
import { CheckFieldVisibility } from "../Utilities/CheckFieldVisibility";

/**
 * @namespace Nif
 * @memberof module:OnlineCheckIn
 * @description Component for managing a guest's tax identification number (NIF/VAT).
 * Handles input, validation, and country-specific requirements.
 */
export const Nif = ({ guest, handleNifChange, validateNifOnBlur, error, guestFieldsGXP }) => {
  const [t] = useTranslation("global");
  const [isSameCountry, setIsSameCountry] = useState(false);
  const [state, dispatch] = useContext(AppContext);
  const [labelIsActive, setLabelIsActive] = useState(null);
  const [placeholderIsActive, setPlaceholderIsActive] = useState(null);
  // Estado local para o NIF
  const [localNif, setLocalNif] = useState(guest.data.FiscalNumber || "");

  const field = guestFieldsGXP?.find(field => field.Code === "VATNUMBER");
  const { isVisible, isMandatory } = CheckFieldVisibility(field, guest, state);

  // Sincronizar o estado local quando o valor do guest mudar externamente
  useEffect(() => {
    setLocalNif(guest.data.FiscalNumber || "");
  }, [guest.data.FiscalNumber]);

  useEffect(() => {
    setLabelIsActive(state.activeTheme.find(
      (style) => style.themeStyleCode === "OC-FormField-label_is_active" && style.isActive
    ).value);

    setPlaceholderIsActive(state.activeTheme.find(
      (style) => style.themeStyleCode === "OC-FormField-placeholder_is_active" && style.isActive
    ).value);
  }, [state.activeTheme]);

  useEffect(() => {
    if (!state?.hotelInitialData?.CountryList || !state?.currentHotel?.hotelCountry)
      return;

    if (!guest?.data?.CountryIso) return;

    const hotelCountry = state?.hotelInitialData?.CountryList.find(
      (element) => element?.IsoCode === state?.currentHotel?.hotelCountry
    );
    setIsSameCountry(hotelCountry?.IsoCode === guest.data.NationalityIso);
  }, [state?.hotelInitialData, guest]);

  const isRequired = isMandatory;

  useEffect(() => {
    if(isSameCountry){
      validateNifOnBlur(guest.id, guest.data.FiscalNumber, guest.data.NationalityIso);
    }
  }, [guest.data.NationalityIso, isSameCountry]);

  // Função para lidar com a digitação do usuário (apenas atualiza o estado local)
  const handleLocalChange = (e) => {
    setLocalNif(e.target.value);
  };

  // Função para atualizar o valor no estado global quando o campo perde o foco
  const handleBlur = () => {
    // Atualizar o valor no estado global
    handleNifChange(guest.id, localNif);

    // Validar o NIF
    validateNifOnBlur(guest.id, localNif, guest.data.NationalityIso);
  };

  return (
    <div className="w-full h-full dark:text-white relative">
      {isVisible && (labelIsActive === "true" || labelIsActive === null) && (
        <label
          htmlFor="nif"
          className={`OC-FormField-label_location OC-FormField-label_color OC-FormField-label_size OC-FormField-label_font_family OC-FormField-label_variant OC-Form-bg_color pl-2 rounded-xl inline-block text-center justify-center dark:bg-gxp_dark_3 dark:text-white mb-1`}
        >
          <span className="dark:text-white text-red-600">
            {isRequired ? ` * \u00A0` : ``}
          </span>
          {`${t(`Nif.vatnumber`)}`}
        </label>
      )}
      <input
        name="FiscalNumber"
        required={isRequired}
        onChange={handleLocalChange}
        onBlur={handleBlur}
        value={localNif}
        type="text"
        id="nif"
        className={`OC-FormField-text_color OC-FormField-text_size OC-FormField-text_font_family OC-FormField-text_variant OC-FormField-bg_color OC-FormField-border_rounded OC-FormField-border_position OC-FormField-border_width dark:bg-gxp_dark_3 dark:text-white w-full px-4 py-2 h-[42px] ${
          error
            ? "border-red-500"
            : "OC-FormField-border_color dark:border-white"
        } focus:ring-2 focus:border-blue-500 transition duration-150 ease-in-out
        OC-FormField-placeholder_color OC-FormField-placeholder_font_family OC-FormField-placeholder_variant`}
        placeholder={placeholderIsActive === "true" || placeholderIsActive === null
          ? `${t(`Nif.vatnumber`)} ${isRequired ? `( * )\u00A0` : ``}`
          : ""}
      />
      {error && (
        <div className="absolute left-0 bottom-0 transform translate-y-[calc(100%+4px)] flex items-center z-10 w-full">
          <AlertTriangle className="text-red-500 mr-1" size={16} />
          <span className="text-red-500 text-sm">{error}</span>
        </div>
      )}
    </div>
  );
};