import React, { useContext, useEffect, useState } from "react";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, PieChart, Pie, Cell, } from "recharts";
import { Hotel, Building, Bed, ShoppingBag, Globe } from "lucide-react";
import { AppContext } from "../../context/AppContext";
import { countryList } from "../Components/utils/countryList";

const Card = ({ children, className = "" }) => (
  <div
    className={`bg-white rounded-lg border-[var(--host-r24-border-color)] border shadow-md p-6 ${className}`}
  >
    {children}
  </div>
);

export const Dashboard = () => {
  const [state, dispatch] = useContext(AppContext);
  const [dashboardData, setDashboardData] = useState({
    hotels: 0,
    hotelGroups: 0,
    rooms: {
      total: 0,
      average: 0,
    },
    pos: {
      total: 0,
      average: 0,
    },
    countries: [],
  });

  const [combinedStats, setCombinedStats] = useState([]);

  const COLORS = [
    "#0088FE",
    "#00C49F",
    "#FFBB28",
    "#FF8042",
    "#8884d8",
    "#1f77b4",
    "#ff7f0e",
  ];

  useEffect(() => {
    if (state.adminInitialData) {
      const { hotels = [], groupHotels = [] } = state.adminInitialData;

      // Total de hotéis
      const totalHotels = hotels.length;

      // Calcular grupos ativos (que têm mais de um hotel associado)
      // Contar quantos hotéis pertencem a cada grupo
      const groupCounts = {};
      hotels.forEach((hotel) => {
        if (hotel.groupId) {
          groupCounts[hotel.groupId] = (groupCounts[hotel.groupId] || 0) + 1;
        }
      });

      // Filtrar apenas grupos com mais de um hotel
      const activeGroupIds = Object.keys(groupCounts).filter(
        (groupId) => groupCounts[groupId] > 1
      );

      const activeGroups = groupHotels.filter((group) =>
        activeGroupIds.includes(group.groupId)
      );

      const totalActiveGroups = activeGroups.length;

      // Calcular total e média de quartos
      let totalRooms = 0;
      hotels.forEach((hotel) => {
        if (hotel.rooms && !isNaN(hotel.rooms)) {
          totalRooms += hotel.rooms;
        }
      });
      const averageRooms =
        totalHotels > 0 ? Math.round(totalRooms / totalHotels) : 0;

      // Calcular total e média de POS
      let totalPOS = 0;
      hotels.forEach((hotel) => {
        if (hotel.posQuantity && !isNaN(hotel.posQuantity)) {
          totalPOS += hotel.posQuantity;
        }
      });
      const averagePOS =
        totalHotels > 0 ? Math.round((totalPOS / totalHotels) * 10) / 10 : 0;

      // Calcular hotéis por país
      const countryMap = {};
      hotels.forEach((hotel) => {
        const country = countryList.find(c => c.IsoCode === hotel.hotelCountry)?.NonTranslatedDescription || "Desconhecido";
        countryMap[country] = (countryMap[country] || 0) + 1;
      });

      const countriesData = Object.entries(countryMap)
        .map(([country, count]) => ({
          country,
          hotels: count,
        }))
        .sort((a, b) => b.hotels - a.hotels); // Ordenar por quantidade

      // Atualizar o estado com todos os dados calculados
      setDashboardData({
        hotels: totalHotels,
        hotelGroups: totalActiveGroups,
        rooms: {
          total: totalRooms,
          average: averageRooms,
        },
        pos: {
          total: totalPOS,
          average: averagePOS,
        },
        countries: countriesData,
      });
    }
  }, [state.adminInitialData]);

  useEffect(() => {
    // Atualizar os cards combinados quando dashboardData mudar
    setCombinedStats([
      {
        title: "Hotéis",
        values: [
          { label: "Total", value: dashboardData.hotels.toString() },
          { label: "Grupos", value: dashboardData.hotelGroups.toString() },
        ],
        icon: <Hotel className="w-5 h-5" />,
      },
      {
        title: "Quartos",
        values: [
          { label: "Total", value: dashboardData.rooms.total.toString() },
          {
            label: "Média por Hotel",
            value: dashboardData.rooms.average.toString(),
          },
        ],
        icon: <Bed className="w-5 h-5" />,
      },
      {
        title: "POS",
        values: [
          { label: "Total", value: dashboardData.pos.total.toString() },
          {
            label: "Média por Hotel",
            value: dashboardData.pos.average.toString(),
          },
        ],
        icon: <ShoppingBag className="w-5 h-5" />,
      },
    ]);
  }, [dashboardData]);

  return (
    <div className="p-4 space-y-4">
      {/* Stats Cards - 3 cards combinados */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {combinedStats.map((stat, index) => (
          <Card key={index}>
            <div className="flex items-center mb-4">
              <div className="p-2 rounded-full bg-blue-100 mr-3">
                {stat.icon}
              </div>
              <h3 className="text-lg font-semibold">{stat.title}</h3>
            </div>

            <div className="space-y-3">
              {stat.values.map((item, i) => (
                <div
                  key={i}
                  className="flex justify-between items-center border-b pb-2 last:border-0"
                >
                  <span className="text-sm text-gray-600">{item.label}:</span>
                  <span className="text-xl font-bold">{item.value}</span>
                </div>
              ))}
            </div>
          </Card>
        ))}

        {/* Gráfico de Hotéis por País */}
        <Card>
          <div className="mb-4">
            <h3 className="text-lg font-semibold flex items-center">
              <div className="p-2 rounded-full bg-blue-100 mr-3">
                <Globe className="w-5 h-5" />
              </div>
              Hotéis por País
            </h3>
          </div>

          <div className="flex flex-col w-full">
            {/* Gráfico de pizza com altura fixa */}
            <div className="w-full" style={{ height: "200px" }}>
              <ResponsiveContainer width="100%" height="100%">
                <PieChart>
                  <Pie
                    data={dashboardData.countries}
                    innerRadius={50}
                    outerRadius={70}
                    paddingAngle={5}
                    dataKey="hotels"
                    nameKey="country"
                  >
                    {dashboardData.countries.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={COLORS[index % COLORS.length]}
                      />
                    ))}
                  </Pie>
                  <Tooltip />
                </PieChart>
              </ResponsiveContainer>
            </div>

            {/* Legenda com altura flexível */}
            <div className="mt-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2 w-full">
              {dashboardData.countries.map((country, index) => (
                <div key={index} className="flex items-center">
                  <div
                    className="w-3 h-3 rounded-full mr-2 flex-shrink-0"
                    style={{ backgroundColor: COLORS[index % COLORS.length] }}
                  />
                  <span className="text-sm">
                    {country.country}: {country.hotels} hotéis
                  </span>
                </div>
              ))}
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
};
