import { Search, X, Check, AlertTriangle } from "lucide-react";
import { motion, AnimatePresence } from "framer-motion";
import { useEffect, useState } from "react";
import { getHostCep } from "../../../../services";
import { useTranslation } from "react-i18next";

/**
 * @namespace PostalCodeModal
 * @memberof module:OnlineCheckIn
 * @param {Object} props
 * @param {boolean} props.isOpen - Flag indicating if the modal is open
 * @param {Function} props.onClose - Function to close the modal
 * @param {Function} props.onConfirm - Function to confirm postal code selection
 * @param {Object} props.initialData - Initial data including region list
 * @param {Object} props.guest - Current guest object
 * @param {Function} props.handleGuestChange - Function to update guest data
 *
 * @description Modal component for searching and selecting postal codes
 *
 * @returns {JSX.Element} Rendered postal code search modal
 *
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @version 1.0.0
 * @since 1.0.0
 */
export const PostalCodeModal = ({
  isOpen,
  onClose,
  onConfirm,
  initialData,
  guest,
  handleGuestChange,
}) => {
  const [postalCode, setPostalCode] = useState("");
  const [data, setData] = useState();
  const [error, setError] = useState("");
  const [t] = useTranslation("global");
  const [region, setRegion] = useState();
  const [address, setAddress] = useState({
    Address1: "",
    AddressState: "",
    Town: "",
    RegionCode: "",
    RegionId: "",
  });

  /**
   * @function handleSubmit
   * @memberof module:OnlineCheckIn.PostalCodeModal
   *
   * @description Updates guest address fields with selected postal code information
   * and closes the modal
   *
   * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @version 1.0.0
   * @since 1.0.0
   */
  const handleSubmit = () => {
    handleGuestChange(guest.id, "Address1", address.Address1);
    handleGuestChange(guest.id, "AddressState", address.AddressState);
    handleGuestChange(guest.id, "Town", address.Town);
    handleGuestChange(guest.id, "RegionCode", address.RegionCode);
    handleGuestChange(guest.id, "RegionId", address.RegionId);
    onClose();
  };

  /**
   * @function fetchHostCep
   * @memberof module:OnlineCheckIn.PostalCodeModal
   * @async
   *
   * @description Fetches postal code information from the server
   *
   * @throws {Error} When postal code retrieval fails
   *
   * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @version 1.0.0
   * @since 1.0.0
   */
  const fetchHostCep = async () => {
    try {
      const result = await getHostCep(postalCode);
      setData(result.data);
    } catch (error) {
      console.error("Error loading host cep:", error);
      setError(t(`PostalCodeModal.error`));
    }
  };

  /**
   * @function handlePostalCodeChange
   * @memberof module:OnlineCheckIn.PostalCodeModal
   * @param {Event} e - Input change event
   *
   * @description Formats the postal code input, removing non-numeric characters
   * and adding a hyphen in the correct position
   *
   * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @version 1.0.0
   * @since 1.0.0
   */
  const handlePostalCodeChange = (e) => {
    let value = e.target.value.replace(/\D/g, ""); // Remove tudo que não é número

    // Adiciona o hífen após os primeiros 5 dígitos
    if (value.length > 5) {
      value = value.slice(0, 5) + "-" + value.slice(5);
    }

    // Limita a 9 caracteres (00000-000)
    value = value.slice(0, 9);

    setPostalCode(value);
    setError(""); // Limpa erro ao digitar
  };

  /**
   * @function validateAndSearch
   * @memberof module:OnlineCheckIn.PostalCodeModal
   *
   * @description Validates postal code format and triggers postal code search
   * Performs input validation before fetching postal code details
   *
   * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @version 1.0.0
   * @since 1.0.0
   */
  const validateAndSearch = () => {
    // Regex para validar o formato do CEP (00000-000)
    const cepRegex = /^\d{5}-\d{3}$/;

    if (!postalCode) {
      setError("Por favor, insira um código postal.");
      return;
    }

    if (!cepRegex.test(postalCode)) {
      setError("Formato inválido. Use: 00000-000");
      return;
    }

    // Se passou na validação, simula busca
    fetchHostCep();

    setError("");
  };

  /**
   * @function useEffectAddressUpdate
   * @memberof module:OnlineCheckIn.PostalCodeModal
   * @param {Object} data - Postal code search result
   * @param {Array} initialData.RegionList - List of regions
   *
   * @description Effect that updates address state when postal code data is retrieved
   *
   * @effect Populates address fields based on postal code search result
   * @dependencies [data, initialData.RegionList]
   *
   * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @version 1.0.0
   * @since 1.0.0
   */
  useEffect(() => {
    if (data) {
      // Encontra a região imediatamente
      const newRegion = initialData.RegionList.find((c) => c.Code === data.uf);

      // Atualiza todos os dados de uma vez
      setAddress((prevAddress) => ({
        ...prevAddress,
        Address1: data.logradouro || "",
        AddressState: data.bairro || "",
        Town: data.localidade || "",
        RegionCode: data.uf || "",
        RegionId: newRegion?.Id || "",
      }));
    }
  }, [data, initialData.RegionList]);

  if (!isOpen) return null;

  return (
    <AnimatePresence>
      <motion.div
        className="fixed inset-0 bg-black/50 backdrop-blur-sm z-50 flex items-center justify-center"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <motion.div
          className="bg-white dark:bg-gxp_dark_3 rounded-lg shadow-lg p-6 max-w-md w-full mx-4"
          initial={{ scale: 0.9, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          exit={{ scale: 0.9, opacity: 0 }}
        >
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-lg font-semibold dark:text-white">{`${t(
              `PostalCodeModal.search`
            )}`}</h2>
            <button
              type="button"
              onClick={onClose}
              className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-white"
            >
              <X size={20} />
            </button>
          </div>

          <div className="mb-6">
            <label className="block text-sm font-medium text-gray-700 dark:text-white mb-2">
              {`${t(`Address.zipcode`)}`}
            </label>
            <div className="flex gap-2">
              <input
                type="text"
                value={postalCode}
                onChange={handlePostalCodeChange}
                className="flex-1 p-2 border border-gray-300 dark:border-gray-600 rounded-md dark:bg-gxp_dark_2 dark:text-white"
                placeholder="00000-000"
              />
              <button
                type="button"
                onClick={validateAndSearch}
                className="p-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
              >
                <Search size={20} />
              </button>
            </div>
            {error && (
              <motion.div
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                className="flex items-center gap-1 mt-2 text-red-500"
              >
                <AlertTriangle size={16} />
                <span className="text-sm">{error}</span>
              </motion.div>
            )}
          </div>

          <div className="space-y-4 mb-6">
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-white">
                {`${t(`Address.address1`)}`}:
              </label>
              <p className="text-gray-900 dark:text-gray-200">
                {address.Address1}
              </p>
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-white">
                {`${t(`Address.addressState`)}`}:
              </label>
              <p className="text-gray-900 dark:text-gray-200">
                {address.AddressState}
              </p>
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-white">
                {`${t(`Address.city`)}`}:
              </label>
              <p className="text-gray-900 dark:text-gray-200">{address.Town}</p>
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-white">
                {`${t(`Address.region`)}`}:
              </label>
              <p className="text-gray-900 dark:text-gray-200">
                {address.RegionCode}
              </p>
            </div>
          </div>

          <div className="flex justify-end">
            <button
              onClick={handleSubmit}
              className="flex items-center gap-2 bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600"
            >
              <Check size={20} />
              Confirmar
            </button>
          </div>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};
