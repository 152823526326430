export async function registerUser(request){
    const requestOptions = {
        method:"POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers:{"Content-Type":"application/json"},
        body: JSON.stringify(request)
    }
    const response = await fetch(`${process.env.REACT_APP_BACKEND_IP}/auth/register`, requestOptions)

    const data = await response.json()

    if(!response.ok){
        throw data // Aqui lançamos o objeto completo que contém a mensagem
    }

    return data
}

export async function loginUser(request){
    const requestOptions = {
        method:"POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "include",
        headers:{"Content-Type":"application/json"},
        body: JSON.stringify(request)
    }
    const response = await fetch(`${process.env.REACT_APP_BACKEND_IP}/auth/login`, requestOptions)

    const data = await response.json()

    if(!response.ok){
        throw data // Aqui lançamos o objeto completo que contém a mensagem
    }

    return data
}

export async function getUser(){
    const requestOptions = {
        method:"GET",
        mode: "cors",
        cache: "no-cache",
        credentials: "include",
        headers:{"Content-Type":"application/json"}
    }

    const response = await fetch(`${process.env.REACT_APP_BACKEND_IP}/auth/user`, requestOptions)
    if(!response.ok){
        throw {message: response.statusText, status: response.status}//eslint-disable-line
    }
    const data = await response.json()
    return data
}

export async function logoutUser(){
    const requestOptions = {
        method:"POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "include",
        headers:{"Content-Type":"application/json"},
    }
    const response = await fetch(`${process.env.REACT_APP_BACKEND_IP}/auth/logout`, requestOptions)

    const data = await response.json()

    if(!response.ok){
        throw data // Aqui lançamos o objeto completo que contém a mensagem
    }

    return data
}

export async function getHotelInfo(subscriptionKey){
    const requestOptions = {
        method:"GET",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers:{"Content-Type":"application/json",
                 "Ocp-Apim-Subscription-Key": subscriptionKey,}
    }
    const response = await fetch(`${process.env.REACT_APP_BACKEND_IP}/gxplocal/bridge/Gxp/hotelinfo`, requestOptions)
    if(!response.ok){
        throw {message: response.statusText, status: response.status}//eslint-disable-line
    }
    const data = await response.json()
    return data
}