import { AlertCircle, AlertTriangle, CheckCircle2, ChevronLeft } from "lucide-react";
import { motion, AnimatePresence } from "framer-motion";
import { Link } from "react-router-dom";
import { useEffect } from "react";

/**
* @namespace SubMenu
* @memberof module:HotelHome
* @description Component responsible for displaying submenu items with animations.
* Provides back navigation and renders service items in grid layout.
*
* @component
* @param {Object} props - Component props
* @param {Object} props.menu - Menu data containing submenu items
* @param {Function} props.onBack - Callback function for back navigation
* @param {string} props.posSection - Section position identifier
* @param {Function} props.setActiveSection - Function to update active section
* @param {Function} props.setIsExiting - Function to handle exit animations
*
* @returns {JSX.Element} SubMenu component
*
* @example
* <SubMenu
*   menu={menuData}
*   onBack={handleBack}
*   posSection="SUBMENU"
*   setActiveSection={setSection}
*   setIsExiting={handleExit}
* />
*
* @author Tiago Ferreira <tiago.ferreira@hhs.pt>
* @since 1.0.0
* @version 1.0.0
*/
export const SubMenu = ({ menu, onBack, posSection, setActiveSection, setIsExiting }) => {
  const MotionLink = motion.create(Link);

  /**
  * @function getCompletionIndicator
  * @memberof module:HotelHome.SubMenu
  * @description Returns appropriate icon based on completion rate.
  * Provides visual feedback for completion status.
  *
  * @param {number} rate - Completion rate percentage
  * @returns {JSX.Element} Indicator icon component
  *
  * @example
  * getCompletionIndicator(75)
  *
  * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
  * @since 1.0.0
  * @version 1.0.0
  */
   const getCompletionIndicator = (rate) => {
     if (rate <= 40) {
       return ( <AlertCircle className="w-5 h-5 text-red-500" aria-label="Low completion rate" /> );
     } else if (rate <= 70) {
       return (
         <AlertTriangle className="w-5 h-5 text-yellow-500" aria-label="Medium completion rate" /> );
     } else {
       return (
         <CheckCircle2 className="w-5 h-5 text-green-500" aria-label="High completion rate" />
       );
     }
   };

   /**
  * @function useEffectSetActive
  * @memberof module:HotelHome.SubMenu
  * @description Effect that sets the active section on component mount.
  *
  * @effect Updates active section
  * @dependencies []
  *
  * @example
  * useEffect(() => {
  *   setActiveSection(posSection)
  * }, [])
  *
  * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
  * @since 1.0.0
  * @version 1.0.0
  */
   useEffect(()=>{
       setActiveSection(posSection)
   },[])

 /**
  * @constant {Array} typesToStrikethrough
  * @memberof module:HotelHome.SubMenu
  * @description Array of service types that should be displayed with strikethrough styling.
  * Used to visually indicate unavailable or deprecated services.
  *
  * @type {Array<string>}
  *
  * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
  * @since 1.0.0
  * @version 1.0.0
  */

   return (
     <motion.div initial={{ opacity: 0, x: 100 }} animate={{ opacity: 1, x: 0 }} exit={{ opacity: 0, x: -100 }} transition={{ duration: 0.3 }} className="w-full h-full" >
       {/* Header */}
       <div className="flex items-center gap-4 mb-6 ">
         <motion.button onClick={() => { onBack(); setIsExiting(true); setActiveSection(null) }} whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }} className="p-2 rounded-lg bg-white dark:bg-gxp_dark_3 dark:text-white dark:border-gxp_dark_1 border shadow" >
           <ChevronLeft />
         </motion.button>
         <h2 className="text-xl font-semibold dark:text-white">{menu.Description}</h2>
       </div>

       {/* Grid de subitems */}
       <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
         {menu.subItems.map((subItem, index) => (
           <MotionLink style={subItem.ImagesUrl.length > 0 ? { backgroundImage: `url(${subItem.ImagesUrl[0]})`, backgroundSize: 'cover', backgroundPosition: 'center' } : {}} key={subItem.Id} initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }} transition={{ delay: index * 0.1, duration: 0.2 }} to={subItem.LinkTo} className={`${subItem.ImagesUrl.length > 0 ? "":"HT-Card-bg_color "} dark:border-gxp_dark_3 h-48 HT-Card-border_color HT-Card-border_rounded HT-Card-border_width HT-Card-border_position HT-Card-border_rounded block shadow-lg hover:shadow-xl transition-shadow relative `} whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.98 }} >
           <div className={`${subItem.ImagesUrl.length > 0 ? "bg-gradient-to-t from-black/80 to-transparent rounded-[inherit] inset-0" : ""} absolute h-full w-full `} />
           <div className="flex flex-col w-full h-full">
           {subItem.ImagesUrl.length > 0 ? (
               <div className="absolute bottom-0 left-0 right-0 p-4 text-left">
                 <h2 className="HT-Card-title_color HT-Card-title_size_small HT-Card-title_font_family HT-Card-title_variant z-10">
                   {subItem.Description}
                 </h2>
               </div>
             ):(
               <div className="flex flex-col items-center justify-center h-full w-full">
                 <span dangerouslySetInnerHTML={{ __html: subItem.IconTag.replace(/<i /, '<i style="font-size: 25px" ') }} className="HT-Card-icon_color dark:text-violet-400" />
                 <span className={`HT-Card-text_color HT-Card-text_size HT-Card-text_font_family HT-Card-text_variant`} >{subItem.Description}</span>
               </div>
             )
           }
         {subItem.rate && (
           <div className="flex items-center gap-2 absolute right-4 top-5">
             {getCompletionIndicator( subItem.rate )}
             <span className={`text-sm text-gray-600 ${ subItem.rate <= 40 ? "text-red-500" : subItem.rate <= 70 ? "text-yellow-500" : "text-green-500 dark:text-green-300" }`} >
               {subItem.rate}%
             </span>
           </div>
         )}
         </div>

     </MotionLink>
         ))}
       </div>
     </motion.div>
   );
 };