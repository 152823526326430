import React, { useState, useEffect, useContext } from "react";
import { Smartphone, Tablet, Monitor, UploadIcon, ChevronsLeft, ChevronsRight, } from "lucide-react";
import { SidebarThemeDefault } from "./Components/SidebarThemeDefault";
import { ThemeDefaultModal } from "../../Components/Modal/ThemeDefaultModal";
import { formatCategories } from "./Components/formatCategories";
import { AppContext } from "../../../context/AppContext";
import { saveThemeLines, uploadImage, } from "../../../services";
import { toast } from "react-toastify";
import { Loader } from "../../../components/Layout/components/Loader/Loader";
import { refreshData } from "../../Components/utils/refreshData";
import { useTranslation } from "react-i18next";
import { IframeComponent } from "./Components/IframeComponent";
import { formatStyleValue } from "./Components/stylePropertyMapping";
import { DropdownMenu } from "./Components/DropDownMenu";
import { Tooltip } from "react-tooltip";
import { ConfirmModal } from "../../Components/Modal/ConfirmModal";

export const ThemeDefault = () => {
  const [state, dispatch] = useContext(AppContext);
  const [expandedCategories, setExpandedCategories] = useState([]);
  const [styleValues, setStyleValues] = useState({});
  const [themes, setThemes] = useState([]);
  const [selectedTheme, setSelectedTheme] = useState( JSON.parse(localStorage.getItem("themeDefaultSelected")) || themes[0] );
  const [isThemeDefaultModalOpen, setIsThemeDefaultModalOpen] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [confirmModalType, setConfirmModalType] = useState(null);
  const [categories, setCategories] = useState([]);
  const [isSending, setIsSending] = useState(false);
  const [themeDefaultLines, setThemeDefaultLines] = useState([]);
  const [modalType, setModalType] = useState();
  const [t] = useTranslation("global");
  const [file, setFile] = useState(null);
  const previewSizes = [
    { id: "mobile", label: t("Admin.ThemeDefault.mobile"), w: 375, h: 667 },
    { id: "tablet", label: t("Admin.ThemeDefault.tablet"), w: 960, h: 540 },
    { id: "desktop", label: t("Admin.ThemeDefault.desktop"), w: 1280, h: 720 },
  ];
  const sizeIcons = {
    mobile: <Smartphone className="w-5 h-5" />,
    tablet: <Tablet className="w-5 h-5" />,
    desktop: <Monitor className="w-5 h-5" />,
  };
  const [previewSize, setPreviewSize] = useState("desktop");
  const [activePreviewSize, setActivePreviewSize] = useState(
    JSON.parse(localStorage.getItem("previewSizes")) || previewSizes
  );
  const [previewSizeW, setPreviewSizeW] = useState(
    activePreviewSize.find((size) => size.id === previewSize).w
  );
  const [previewSizeH, setPreviewSizeH] = useState(
    activePreviewSize.find((size) => size.id === previewSize).h
  );
  const [zoom, setZoom] = useState(100);
  const zoomOptions = [25, 50, 75, 100, 125, 150];
  const [isPreviewBarOpen, setIsPreviewBarOpen] = useState(false);
  const [filteredCategories, setFilteredCategories] = useState([]);

  const openConfirmModal = (type) => {
    setConfirmModalType(type);
    setIsConfirmModalOpen(true);
  };

  // Função para salvar no localStorage
  const saveThemeLinesToLocalStorage = () => {
    localStorage.setItem(
      `themeDefaultLines`,
      JSON.stringify(themeDefaultLines)
    );
  };

  useEffect(() => {
    const loadData = async () => {
      if (selectedTheme) {
        await refreshData({ setIsSending, dispatch });
      }
    };
    loadData();
  }, [selectedTheme]);

  const applyThemeDefaultLines = (lines) => {
    if (!lines || !lines.length || !selectedTheme) return;

    // Filtrar apenas as linhas do tema selecionado
    const themeLines = lines.filter(
      (line) => line.themeDescriptionId === selectedTheme.id
    );

    if (!themeLines.length) {
      console.log("No theme lines found for selected theme:", selectedTheme.id);
      return;
    }

    // Primeiro, vamos criar uma cópia atualizada das categorias
    const updatedCategories = categories.map((category) => ({
      ...category,
      subCategories: category.subCategories.map((subCategory) => {
        // Procurar se existe uma linha para esta subCategoria
        const matchingLine = themeLines.find((line) => {
          const themeStyle = state.adminInitialData.themeStyles.find(
            (style) => style.id === line.themeStyleId
          );
          return themeStyle && themeStyle.styleSubCategoryId === subCategory.id;
        });

        // Se encontrou uma linha, atualizar o valor
        if (matchingLine) {
          return {
            ...subCategory,
            value: matchingLine.value,
          };
        }
        return subCategory;
      }),
    }));

    // Atualizar o estado das categorias
    setCategories(updatedCategories);

    themeLines.forEach((line) => {
      // Encontrar o themeStyle correspondente
      const themeStyle = state.adminInitialData.themeStyles.find(
        (style) => style.id === line.themeStyleId
      );

      if (themeStyle) {
        // Encontrar categoria e subcategoria
        const category = state.adminInitialData.styleCategories.find(
          (cat) => cat.id === themeStyle.styleCategoryId
        );
        const subCategory = state.adminInitialData.styleSubCategories.find(
          (subCat) => subCat.id === themeStyle.styleSubCategoryId
        );

        if (category && subCategory) {
          const editorProps = {
            type: themeStyle.valueType,
            value: line.value,
            styleSubCategoryCode: subCategory.styleSubCategoryCode,
            styleCategoryCode: category.styleCategoryCode,
          };

          const iframe = document.getElementById("Iframe");
          if (iframe && iframe.contentWindow) {
            const changeIframeElement = (
              styleCategoryCode,
              styleSubCategoryCode,
              value
            ) => {
              if (!styleCategoryCode || !styleSubCategoryCode ) return;

              try {
                // Cria o código completo do tema
                const themeStyleCode = `${styleCategoryCode}-${styleSubCategoryCode}`;

                // Verifica se é uma imagem ou is_active
                if (
                  styleSubCategoryCode.includes("is_active") ||
                  themeStyleCode.includes("image")
                ) {
                  iframe.contentWindow.postMessage(
                    {
                      action: "updateActiveThemeStyle",
                      themeStyleCode,
                      value,
                    },
                    "*"
                  );
                } else {
                  const formattedValues = formatStyleValue(
                    styleSubCategoryCode,
                    value
                  );

                  if (!formattedValues) return;

                  if (formattedValues.length > 1) {
                    iframe.contentWindow.postMessage(
                      {
                        action: "updateMultipleVariables",
                        variables: formattedValues.map(
                          ({ variable, value }) => ({
                            variable: `--${styleCategoryCode}-${variable}`,
                            value,
                          })
                        ),
                      },
                      "*"
                    );
                  } else {
                    const { variable, value: formattedValue } =
                      formattedValues[0];
                    iframe.contentWindow.postMessage(
                      {
                        action: "updateVariable",
                        variable: `--${styleCategoryCode}-${variable}`,
                        value: formattedValue,
                      },
                      "*"
                    );
                  }
                }
              } catch (error) {
                console.error("Error updating iframe:", error);
              }
            };

            // Chamar a função com os valores
            changeIframeElement(
              editorProps.styleCategoryCode,
              editorProps.styleSubCategoryCode,
              editorProps.value
            );

            // Atualizar themeDefaultLines
            setThemeDefaultLines(lines);
          }
        }
      }
    });
  };

  const resetToDefaultValues = async () => {
    try {
      // Limpar localStorage
      localStorage.removeItem(`themeDefaultLines`);
      setThemeDefaultLines([]);

      // Fazer novo pedido à API
      await refreshData({ setIsSending, dispatch, state, forceRefresh: true });

      // Recarregar iframe
      const iframe = document.getElementById("Iframe");
      if (iframe) {
        iframe.src = iframe.src;
      }
    } catch (error) {
      console.error("Error resetting values:", error);
      toast.error("Error resetting to default values");
    }
  };

  const postStyleSubCategory = async () => {
    try {
      setIsSending(true);

      // Primeiro, vamos encontrar todos os themeStyles para verificar seus tipos
      const themeStyles = state.adminInitialData.themeStyles;

      // Criar uma cópia do array que vamos modificar
      let updatedThemeDefaultLines = [...themeDefaultLines];

      // Para cada linha, verificar se é uma imagem e fazer upload se necessário
      for (let i = 0; i < updatedThemeDefaultLines.length; i++) {
        const line = updatedThemeDefaultLines[i];

        // Encontrar o themeStyle correspondente
        const themeStyle = themeStyles.find(
          (style) => style.id === line.themeStyleId
        );

        if (themeStyle && themeStyle.valueType === "image" && file) {
          try {
            // Obtém a extensão do arquivo original
            const extension = file.name.split(".").pop();

            // Obtém o nome do novo arquivo
            const categoryName = state.adminInitialData.styleCategories.find(
              (category) => category.id === themeStyle.styleCategoryId
            ).styleCategoryCode;

            const subcategoryName =
              state.adminInitialData.styleSubCategories.find(
                (subcategory) =>
                  subcategory.id === themeStyle.styleSubCategoryId
              ).styleSubCategoryCode;

            // Cria um novo arquivo com o nome desejado
            const renamedFile = new File(
              [file],
              `Default-${selectedTheme.id}-${categoryName}-${subcategoryName}.${extension}`,
              { type: file.type }
            );

            // Faz o upload da imagem
            const imageUrl = await uploadImage(renamedFile);

            // Atualiza o valor na linha com a URL da imagem
            updatedThemeDefaultLines[i] = {
              ...line,
              value: imageUrl.imageUrl,
            };
          } catch (error) {
            console.error("Erro ao fazer upload da imagem:", error);
            throw new Error("Erro ao fazer upload da imagem");
          }
        }
      }

      const response = await saveThemeLines(updatedThemeDefaultLines); // Send request

      if (response.message) {
        toast.success(`${t(`Admin.ThemeDefault.success`)}`);
      }
    } catch (error) {
      console.error(`${t(`Admin.ThemeDefault.error`)}: `, error);
      toast.error(`${t(`Admin.ThemeDefault.error`)}`);
    } finally {
      setIsSending(false); // Set sending state back to false
    }
  };

  const confirmModalConfigs = {
    resetDefault: {
      title: t('Admin.ThemeDefault.confirmReset'),
      functionToExecute: resetToDefaultValues,
    },
    publishTheme: {
      title: t('Admin.ThemeDefault.confirmPublish'),
      functionToExecute: postStyleSubCategory,
    }
    // Adicione mais configurações conforme necessário
  };

  // Adicionar este useEffect para monitorar mudanças no tema selecionado
  useEffect(() => {
    const loadData = async () => {
      if (selectedTheme) {
        await refreshData({ setIsSending, dispatch, state });
      }
    };
    loadData();
  }, [selectedTheme]);

  // Este useEffect atualiza as categorias quando adminInitialData muda
  useEffect(() => {
    if (state.adminInitialData && selectedTheme) {
      const formattedCategories = formatCategories(
        selectedTheme,
        state.adminInitialData
      );
      setCategories(formattedCategories);
    }
  }, [state.adminInitialData, selectedTheme, state.globalRefresh, expandedCategories]);

  useEffect(() => {
    if (!selectedTheme && themes?.length) {
      setSelectedTheme(
        JSON.parse(localStorage.getItem("themeDefaultSelected")) || themes[0]
      );
    }
  }, [themes, state.globalRefresh]);

  useEffect(() => {
    setThemes(state.adminInitialData?.themeDescriptions);
  }, [state.adminInitialData, state.globalRefresh]);

  useEffect(() => {
    localStorage.setItem("previewSizes", JSON.stringify(activePreviewSize));
  }, [activePreviewSize]);

  useEffect(() => {
    setPreviewSizeW( activePreviewSize.find((size) => size.id === previewSize).w );
    setPreviewSizeH( activePreviewSize.find((size) => size.id === previewSize).h );
  }, [previewSize]);

  useEffect(() => {
    setPreviewSizeW( activePreviewSize.find((size) => size.id === previewSize).w );
    setPreviewSizeH( activePreviewSize.find((size) => size.id === previewSize).h );
  }, [activePreviewSize]);

  const toggleCategory = (categoryId) => {
    setExpandedCategories(
      (prev) =>
        prev.includes(categoryId)
          ? [] // Fecha a categoria se já estiver aberta
          : [categoryId] // Substitui a categoria aberta pela nova
    );
  };


  return (
    <div className="flex h-full bg-gray-100 overflow-hidden ">
      <SidebarThemeDefault
        filteredCategories={filteredCategories}
        setFilteredCategories={setFilteredCategories}
        file={file}
        setFile={setFile}
        themeDefaultLines={themeDefaultLines}
        setThemeDefaultLines={setThemeDefaultLines}
        themes={themes}
        selectedTheme={selectedTheme}
        setSelectedTheme={setSelectedTheme}
        setIsThemeDefaultModalOpen={setIsThemeDefaultModalOpen}
        categories={categories}
        setCategories={setCategories}
        expandedCategories={expandedCategories}
        toggleCategory={toggleCategory}
        setModalType={setModalType}
      />

      {/* Preview Section */}
      <div className="flex-1 p-4 relative rounded-lg overflow-scroll flex flex-col items-center justify-center">
        {/* Controls flutuantes */}
        <div className={`absolute top-7 left-6 z-10 flex items-center bg-white rounded-lg shadow p-2 ${ isPreviewBarOpen ? "w-auto" : "w-12" } transition-all duration-500 ease-in-out `} >
          <div className={`${ isPreviewBarOpen ? "border-r" : "" }  pr-4 flex flex-col items-center justify-center`} >
            {isPreviewBarOpen ? (
              <button
                data-tooltip-id={`previewBar`}
                data-tooltip-content={`${t( `Admin.ThemeDefault.previewBarClose` )}`}
                data-tooltip-place="top-start"
                onClick={() => setIsPreviewBarOpen(false)}
                className="p-2 rounded-md hover:bg-gray-200 relative"
              >
                <ChevronsLeft className="w-4 h-4" />
                <Tooltip
                  id="previewBar"
                  style={{padding: "4px 8px", backgroundColor: "var(--host-r24-tooltip-background)", borderRadius: "6px",    transition: "opacity 0.3s ease-in-out, transform 0.3s ease-in-out"}}
                  offset={5}
                  className="z-[100]"
                  positionStrategy="fixed"
                />
              </button>
            ) : (
              <button
                onClick={() => setIsPreviewBarOpen(true)}
                data-tooltip-id={`previewBar`}
                data-tooltip-content={`${t( `Admin.ThemeDefault.previewBarOpen` )}`}
                data-tooltip-place="top-start"
                className="p-2 rounded-md hover:bg-gray-200 relative"
              >
                <ChevronsRight className="w-4 h-4" />
                <Tooltip
                  id="previewBar"
                  style={{padding: "4px 8px", backgroundColor: "var(--host-r24-tooltip-background)", borderRadius: "6px",    transition: "opacity 0.3s ease-in-out, transform 0.3s ease-in-out"}}
                  offset={5}
                />
              </button>
            )}
          </div>

          <div className={`flex ${ isPreviewBarOpen ? "opacity-100" : "opacity-0 w-0 overflow-hidden" } transition-all duration-500 ease-[cubic-bezier(0.4, 0, 0.2, 1)] `} >
            <div className="flex items-center justify-center border-r px-4 gap-2">
              {previewSizes.map((size) => (
                <div key={size.id} className="flex items-center justify-center">
                  <button
                    onClick={() => setPreviewSize(size.id)}
                    className={`p-2 rounded transition-colors ${ previewSize === size.id ? "bg-blue-50 text-blue-600" : "hover:bg-gray-50" }`}
                    data-tooltip-id={`previewSize-${size.id}`}
                    data-tooltip-content={size.label}
                    data-tooltip-place="top"
                  >
                    {sizeIcons[size.id]}
                  </button>
                  <Tooltip
                    id={`previewSize-${size.id}`}
                    style={{padding: "4px 8px", backgroundColor: "var(--host-r24-tooltip-background)", borderRadius: "6px",    transition: "opacity 0.3s ease-in-out, transform 0.3s ease-in-out"}}
                    offset={5}
                  />
                </div>
              ))}
            </div>

            <div className="flex items-center justify-center border-r px-4">
              <input
                type="number"
                value={previewSizeW}
                onChange={(e) => setPreviewSizeW(e.target.value)}
                onBlur={(e) => { const value = parseInt(e.target.value); setActivePreviewSize((prev) => prev.map((size) => size.id === previewSize ? { ...size, w: value } : size ) ); }}
                onKeyDown={(e) => { if (e.key === "Enter") { e.target.blur(); } }}
                className="w-16 p-1 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500 text-center"
              />
              <span className="text-gray-500 px-2">×</span>
              <input
                type="number"
                onChange={(e) => setPreviewSizeH(e.target.value)}
                onBlur={(e) => { const value = parseInt(e.target.value); setActivePreviewSize((prev) => prev.map((size) => size.id === previewSize ? { ...size, h: value } : size ) ); }}
                onKeyDown={(e) => { if (e.key === "Enter") { e.target.blur(); } }}
                value={previewSizeH}
                className="w-16 p-1 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500 text-center"
              />
            </div>

            <div className="flex items-center justify-center gap-4 pl-4">
              <DropdownMenu
                onSave={() => saveThemeLinesToLocalStorage()}
                onLoad={() => applyThemeDefaultLines( JSON.parse(localStorage.getItem(`themeDefaultLines`)) ) }
                onDelete={() => openConfirmModal('resetDefault')}
                />

              {isSending ? (
                <button disabled className="!p-0 host-grid-button !w-[32px] !h-[32px] flex items-center justify-center" >
                  <Loader />
                </button>
              ) : (
                <>
                  <button
                    data-tooltip-id={`upload`}
                    data-tooltip-content={`${t(`Admin.ThemeDefault.upload`)}`}
                    data-tooltip-place="top"
                    onClick={() => openConfirmModal('publishTheme')}
                    className="!p-0 host-grid-button !w-[32px] !h-[32px] flex items-center justify-center"
                  >
                    <UploadIcon className="w-4 h-4" />
                  </button>
                  <Tooltip
                    id={`upload`}
                    style={{padding: "4px 8px", backgroundColor: "var(--host-r24-tooltip-background)", borderRadius: "6px",    transition: "opacity 0.3s ease-in-out, transform 0.3s ease-in-out"}}
                    offset={5}
                  />
                </>
              )}
            </div>
          </div>
        </div>

        {/* Preview Content */}
        <div
          style={{ width: `${ activePreviewSize.find((size) => size.id === previewSize).w }px`, height: `${ activePreviewSize.find((size) => size.id === previewSize).h }px`, }}
          className={`bg-white rounded-lg transition-all duration-300 ease-in-out mx-auto min-h-[400px] shadow-lg h-full`}
        >
          <IframeComponent
            filteredCategories={filteredCategories}
            selectedTheme={selectedTheme}
            zoom={zoom}
          />
        </div>
      </div>

      <ThemeDefaultModal
        isThemeDefaultModalOpen={isThemeDefaultModalOpen}
        onCloseThemeDefaultModal={() => setIsThemeDefaultModalOpen(false)}
        title={t(`Admin.ThemeDefaultModal.newDefaultTheme`)}
        type={modalType}
        themeDefault={modalType === "add" ? null : selectedTheme}
        setSelectedTheme={setSelectedTheme}
      />
      <ConfirmModal
        isConfirmModalOpen={isConfirmModalOpen}
        onCloseConfirmModal={() => {
          setIsConfirmModalOpen(false);
          setConfirmModalType(null);
        }}
        config={confirmModalType ? confirmModalConfigs[confirmModalType] : {}}
      />
</div>
  );
};
