import { useTranslation } from "react-i18next";
import { useState, useEffect, useContext } from "react";
import { AlertTriangle } from "lucide-react";
import { AppContext } from "../../../../context/AppContext";
import { CheckFieldVisibility } from "../Utilities/CheckFieldVisibility";

/**
 * @namespace IndividualAddress
 * @memberof module:OnlineCheckIn
 * @description Component for managing guest individual address information.
 * Includes input field with local state management, validation, and theme-based styling.
 *
 * @component
 * @param {Object} props - The properties passed to the component
 * @param {Object} props.guest - The guest object containing address data
 * @param {function} props.handleGuestChange - Function to handle changes in guest data
 * @param {Object} [props.error] - Validation error object
 * @param {Array<Object>} props.guestFieldsGXP - Guest field configuration
 *
 * @returns {JSX.Element} The rendered individual address input field
 *
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @version 1.0.0
 * @since 1.0.0
 */
export const IndividualAddress = ({
  guest,
  handleGuestChange,
  error,
  guestFieldsGXP
}) => {
  const [t] = useTranslation("global");
  const [state, dispatch] = useContext(AppContext);
  const [labelIsActive, setLabelIsActive] = useState(null);
  const [placeholderIsActive, setPlaceholderIsActive] = useState(null);

  // Local state for individual address input
  const [localAddress, setLocalAddress] = useState(guest.data.invoiceEntityAddress1 || "");

  const field = guestFieldsGXP?.find(field => field.Code === "INDIVIDUALADDRESS");
  const { isVisible, isMandatory } = CheckFieldVisibility(field, guest, state);

  // Synchronize local state when guest data changes externally
  useEffect(() => {
    setLocalAddress(guest.data.invoiceEntityAddress1 || "");
  }, [guest.data.invoiceEntityAddress1]);

  // Theme-based styling effects
  useEffect(() => {
    setLabelIsActive(
      state.activeTheme.find(
        (style) => style.themeStyleCode === "OC-FormField-label_is_active" && style.isActive
      ).value
    );
    setPlaceholderIsActive(
      state.activeTheme.find(
        (style) => style.themeStyleCode === "OC-FormField-placeholder_is_active" && style.isActive
      ).value
    );
  }, [state.activeTheme]);

  const isRequiredCompanyAddress = isMandatory;

  // Handle local input changes
  const handleLocalChange = (e) => {
    setLocalAddress(e.target.value);
  };

  // Handle blur event to update global state
  const handleBlur = () => {
    handleGuestChange(guest.id, "invoiceEntityAddress1", localAddress);
  };

  return (
    <>
      <div className="relative">
        {isVisible && (labelIsActive === "true" || labelIsActive === null) && (
          <label
            className={`OC-FormField-label_location OC-FormField-label_color OC-FormField-label_size OC-FormField-label_font_family OC-FormField-label_variant OC-Form-bg_color pl-2 rounded-xl inline-block text-center justify-center dark:bg-gxp_dark_3 dark:text-white mb-1`}
          >
            <span className="dark:text-white text-red-600">
              {isRequiredCompanyAddress ? ` * \u00A0` : ``}
            </span>
            {`${t(`Address.address1`)}`}
          </label>
        )}
        <input
          name="invoiceEntityAddress1"
          required={isRequiredCompanyAddress}
          onChange={handleLocalChange}
          onBlur={handleBlur}
          value={localAddress}
          type="text"
          id="invoiceEntityAddress1"
          className={`OC-FormField-text_color OC-FormField-text_size OC-FormField-text_font_family OC-FormField-text_variant OC-FormField-bg_color OC-FormField-border_rounded OC-FormField-border_position OC-FormField-border_width dark:bg-gxp_dark_3 dark:text-white w-full px-4 py-2 h-[42px] ${
            error
              ? "border-red-500"
              : "OC-FormField-border_color dark:border-white"
          } focus:ring-2 focus:border-blue-500 transition duration-150 ease-in-out
          OC-FormField-placeholder_color OC-FormField-placeholder_font_family OC-FormField-placeholder_variant`}
          placeholder={
            placeholderIsActive === "true" || placeholderIsActive === null
              ? `${t(`Address.address1`)} ${isRequiredCompanyAddress ? `( * )\u00A0` : ``}`
              : ""
          }
        />
        {error && (
          <div className="absolute left-0 bottom-0 transform translate-y-[calc(100%+4px)] flex items-center z-10 w-full">
            <AlertTriangle className="text-red-500 mr-1" size={16} />
            <span className="text-red-500 text-sm">
              {`${t(`HostForm.fieldRequired`)}`}
            </span>
          </div>
        )}
      </div>
    </>
  );
};