import React from "react";
import { useTranslation } from "react-i18next";

export const SelectSection = () => {
  const { t } = useTranslation();

  return (
    <div className="flex-1 flex items-center justify-center h-full min-h-[50vh]">
      <p className="text-gray-500 text-2xl font-medium px-4 py-2">
        {t(`HotelPanel.Theme.selectSection`)}
      </p>
    </div>
  );
};
