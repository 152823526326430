import React, { useState, useRef, useEffect } from 'react';
import { X } from 'lucide-react';
import { useTranslation } from 'react-i18next';

export const ValueListEditor = ({editForm, setEditForm}) => {
  const [values, setValues] = useState();
  const [inputValue, setInputValue] = useState('');
  const inputRef = useRef(null);
  const [t] = useTranslation("global");

  useEffect(() => {
    if (editForm.valueList) {
      setValues(editForm.valueList);
    }
  }, [editForm]);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && inputValue.trim()) {
      e.preventDefault();
      setEditForm({...editForm, valueList: [...values, inputValue.trim()]});
      setInputValue('');
    } else if (e.key === 'Backspace' && !inputValue && values?.length > 0) {
      setEditForm({...editForm, valueList: values.slice(0, -1)});
    }
  };

  const removeValue = (indexToRemove) => {
    setEditForm({...editForm, valueList: values?.filter((_, index) => index !== indexToRemove)});
  };

  const handleContainerClick = () => {
    inputRef.current?.focus();
  };

  return (
    <div className="w-full">
      <div className="relative">
        <div onClick={handleContainerClick} className="!h=[32px] w-full host-search-input !p-0" >
          <div className="flex flex-wrap gap-2 items-center">
            {values && values.map((value, index) => (
              <span
                key={index}
                className="flex items-center gap-1 px-2 py-1 bg-blue-100 rounded-md text-sm text-blue-800"
              >
                {value}
                <button
                  type="button"
                  onClick={(e) => {
                    e.stopPropagation();
                    removeValue(index);
                  }}
                  className="hover:text-red-600 transition-colors"
                >
                  <X size={14} />
                </button>
              </span>
            ))}
            <input
              ref={inputRef}
              type="text"
              value={inputValue}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
              className="flex-1 min-w-[60px] !h-[32px] outline-none bg-transparent border-none focus:ring-0 " style={{ color: "var(--text-color)", borderRadius: "6px", fontSize: "12px" }}
              placeholder={!values?.length ? `${t(`Admin.ValueListEditor.values`)}` : ""}
            />
          </div>
        </div>
      </div>
      <p className="mt-4 host-text-modal">
        {`${t(`Admin.ValueListEditor.actualValues`)}: `}{values?.join('; ')}
      </p>
    </div>
  );
};