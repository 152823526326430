import { useTranslation } from "react-i18next";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../../context/AppContext";

/**
 * @namespace HeadersPageOC
 * @memberof module:OnlineCheckIn
 * @description Component for rendering the header section of the online check-in page.
 * This component displays the hotel logo, title, guest information, and room number.
 *
 * @component
 * @param {Object} props - The properties passed to the component.
 * @param {string} props.title - The title of the online check-in page.
 * @param {string} props.room - The room number of the guest.
 * @param {boolean} props.militaryMode - Flag indicating if military mode is active.
 * @param {Object} props.guest - The guest object containing guest information.
 * @param {Object} props.post - The post object containing post information.
 *
 * @returns {JSX.Element} The rendered header section of the online check-in page.
 *
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @version 1.0.0
 * @since 1.0.0
 *
 * @example
 * <HeadersPageOC
 *   title="Online Check-In"
 *   room="101"
 *   militaryMode={false}
 *   guest={guestObject}
 *   post={postObject}
 * />
 */
export const HeadersPageOC = ({ title, room, militaryMode, guest, post }) => {
  const [t] = useTranslation("global");
  const [state] = useContext(AppContext);
  const [imageUrl, setImageUrl] = useState("");
  const [logoUrl, setLogoUrl] = useState("");

  /**
   * @function useEffectThemeStyles
   * @memberof module:OnlineCheckIn.HeadersPageOC
   * @param {Array} state.activeTheme - The active theme styles.
   *
   * @description Effect that updates the image URL and logo URL based on the active theme styles.
   *
   * @effect Updates the imageUrl and logoUrl states based on the active theme styles.
   * @dependencies [state.activeTheme]
   *
   * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @version 1.0.0
   * @since 1.0.0
   */
  useEffect(() => {
    if (state.activeTheme) {
      const imageUrl = state.activeTheme?.find(
        (theme) => theme.themeStyleCode === "OC-Header-image"
      )?.value;
      setImageUrl(imageUrl);
    }

    if (state.activeTheme) {
      const logoUrl = state.activeTheme?.find(
        (theme) => theme.themeStyleCode === "OC-Header-logo_image"
      )?.value;
      setLogoUrl(logoUrl);
    }
  }, [state.activeTheme]);

  return (
    <section>
      <div className="sm:hidden">
        <div
          className="flex-col text-white flex justify-between bg-cover h-40"
          style={{ backgroundImage: `url(${imageUrl})` }}
        >
          <div className="flex justify-center items-center h-full w-full">
            { logoUrl!=="" &&
            <img src={logoUrl } className={`w-1/4`} alt="logo" />
            }
          </div>
          <div className="flex ml-6">
            <h1
              className={`dark:text-white OC-Header-title_color OC-Header-title_size OC-Header-title_font_family OC-Header-title_variant mb-2 sm:mb-4`}
            >
              {" "}
              {title}{" "}
            </h1>
          </div>
        </div>
        <div className="dark:bg-gxp_dark_1 dark:border-white dark:text-white OC-Header-text_color OC-Header-text_size OC-Header-text_font_family OC-Header-text_variant OC-Header-bar_bg_color OC-Header-bar_border_color OC-Header-bar_border_width OC-Header-bar_border_rounded OC-Header-bar_border_position w-full py-4 items-center flex justify-between">
          {militaryMode && post ? (
            <h1 className="ml-6">{`${post.Description} ${guest?.LastName}`}</h1>
          ) : (
            <h1 className="ml-6">
              {" "}
              {t("Checkout.welcome")} {guest?.FirstName} {guest?.LastName}{" "}
            </h1>
          )}
          <span className="ml-6">
            {" "}
            {room === "" || room === undefined || room === null
              ? ""
              : `${t("Checkout.room")} ${room}`}{" "}
          </span>
        </div>
      </div>

      <div className="max-sm:hidden lg:hidden">
        <div
          className="flex-col text-white flex justify-between bg-cover h-52"
          style={{ backgroundImage: `url(${imageUrl})` }}
        >
          <div className="flex justify-center items-center h-full w-ful">
          { logoUrl!=="" &&
            <img src={logoUrl} className={`h-1/2`} alt="logo" />
          }
          </div>
          <div className="flex ml-6">
            <h1
              className={`dark:text-white OC-Header-title_color OC-Header-title_size OC-Header-title_font_family OC-Header-title_variant mb-2 sm:mb-4`}
            >
              {" "}
              {title}{" "}
            </h1>
          </div>
        </div>
        <div className="dark:bg-gxp_dark_1 dark:border-white dark:text-white OC-Header-text_color OC-Header-text_size OC-Header-text_font_family OC-Header-text_variant OC-Header-bar_bg_color OC-Header-bar_border_color OC-Header-bar_border_width OC-Header-bar_border_rounded OC-Header-bar_border_position w-full py-4 items-center flex justify-between">
          {militaryMode && post ? (
            <h1 className="ml-6">{`${post.Description} ${guest?.LastName}`}</h1>
          ) : (
            <h1 className="ml-6">
              {" "}
              {t("Checkout.welcome")} {guest?.FirstName} {guest?.LastName}{" "}
            </h1>
          )}
          <span className="ml-6">
            {" "}
            {room === "" || room === undefined || room === null
              ? ""
              : `${t("Checkout.room")} ${room}`}
          </span>
        </div>
      </div>

      <div className="max-lg:hidden">
        <div
          className="flex-col text-white flex justify-between bg-cover h-52"
          style={{ backgroundImage: `url(${imageUrl})` }}
        >
          <div className="flex justify-center items-center h-full w-ful">
          { logoUrl!=="" &&
            <img src={logoUrl} className={`h-2/3`} alt="logo" />
          }
          </div>
          <div className="flex ml-6">
            <h1
              className={`dark:text-white OC-Header-title_color OC-Header-title_size font-bold OC-Header-title_font_family OC-Header-title_variant mb-2 sm:mb-4`}
            >
              {" "}
              {title}{" "}
            </h1>
          </div>
        </div>
        <div className="dark:bg-gxp_dark_1 dark:border-white dark:text-white OC-Header-text_color OC-Header-text_size OC-Header-text_font_family OC-Header-text_variant OC-Header-bar_bg_color OC-Header-bar_border_color OC-Header-bar_border_width OC-Header-bar_border_rounded OC-Header-bar_border_position w-full py-4 items-center flex justify-between">
          {militaryMode && post ? (
            <h1 className="ml-6">{`${post.Description} ${guest?.LastName}`}</h1>
          ) : (
            <h1 className="ml-6">
              {" "}
              {t("Checkout.welcome")} {guest?.FirstName} {guest?.LastName}{" "}
            </h1>
          )}
          <span className="ml-6">
            {" "}
            {room === "" || room === undefined || room === null
              ? ""
              : `${t("Checkout.room")} ${room}`}{" "}
          </span>
        </div>
      </div>
    </section>
  );
};
