export const countryList = [
        {
            "Id": 33,
            "IsoCode": "AD",
            "IsoCode3LetterDocumentReader": "AND",
            "NonTranslatedDescription": "Andorra",
            "TranslatedDescription": "Andorra"
        },
        {
            "Id": 83,
            "IsoCode": "AE",
            "IsoCode3LetterDocumentReader": "ARE",
            "NonTranslatedDescription": "Emiratos Árabes Unidos",
            "TranslatedDescription": "United Arab Emirates"
        },
        {
            "Id": 27,
            "IsoCode": "AF",
            "IsoCode3LetterDocumentReader": "AFG",
            "NonTranslatedDescription": "Afeganistão",
            "TranslatedDescription": "Afghanistan"
        },
        {
            "Id": 37,
            "IsoCode": "AG",
            "IsoCode3LetterDocumentReader": "ATG",
            "NonTranslatedDescription": "Antígua e Barbuda",
            "TranslatedDescription": "Antigua and Barbuda"
        },
        {
            "Id": 35,
            "IsoCode": "AI",
            "IsoCode3LetterDocumentReader": "AIA",
            "NonTranslatedDescription": "Anguila",
            "TranslatedDescription": "Anguilla"
        },
        {
            "Id": 31,
            "IsoCode": "AL",
            "IsoCode3LetterDocumentReader": "ALB",
            "NonTranslatedDescription": "Albânia",
            "TranslatedDescription": "Albania"
        },
        {
            "Id": 41,
            "IsoCode": "AM",
            "IsoCode3LetterDocumentReader": "ARM",
            "NonTranslatedDescription": "Arménia",
            "TranslatedDescription": "Armenia"
        },
        {
            "Id": 38,
            "IsoCode": "AN",
            "IsoCode3LetterDocumentReader": "ANT",
            "NonTranslatedDescription": "Antilhas holandesas",
            "TranslatedDescription": "Netherlands Antilles"
        },
        {
            "Id": 34,
            "IsoCode": "AO",
            "IsoCode3LetterDocumentReader": "AGO",
            "NonTranslatedDescription": "Angola",
            "TranslatedDescription": "Angola"
        },
        {
            "Id": 36,
            "IsoCode": "AQ",
            "IsoCode3LetterDocumentReader": "ATA",
            "NonTranslatedDescription": "Antárctica",
            "TranslatedDescription": "Antarctica"
        },
        {
            "Id": 40,
            "IsoCode": "AR",
            "IsoCode3LetterDocumentReader": "ARG",
            "NonTranslatedDescription": "Argentina",
            "TranslatedDescription": "Argentina"
        },
        {
            "Id": 197,
            "IsoCode": "AS",
            "IsoCode3LetterDocumentReader": "ASM",
            "NonTranslatedDescription": "Samoa Americana",
            "TranslatedDescription": "American Samoa"
        },
        {
            "Id": 1,
            "IsoCode": "AT",
            "IsoCode3LetterDocumentReader": "AUT",
            "NonTranslatedDescription": "Áustria",
            "TranslatedDescription": "Austria"
        },
        {
            "Id": 43,
            "IsoCode": "AU",
            "IsoCode3LetterDocumentReader": "AUS",
            "NonTranslatedDescription": "Austrália",
            "TranslatedDescription": "Australia"
        },
        {
            "Id": 42,
            "IsoCode": "AW",
            "IsoCode3LetterDocumentReader": "ABW",
            "NonTranslatedDescription": "Aruba",
            "TranslatedDescription": "Aruba"
        },
        {
            "Id": 247,
            "IsoCode": "AX",
            "IsoCode3LetterDocumentReader": "ALA",
            "NonTranslatedDescription": "Ilhas Aland",
            "TranslatedDescription": "Ilhas Aland"
        },
        {
            "Id": 44,
            "IsoCode": "AZ",
            "IsoCode3LetterDocumentReader": "AZE",
            "NonTranslatedDescription": "Azerbaijão",
            "TranslatedDescription": "Azerbaijan"
        },
        {
            "Id": 54,
            "IsoCode": "BA",
            "IsoCode3LetterDocumentReader": "BIH",
            "NonTranslatedDescription": "Bósnia e Herzegovina",
            "TranslatedDescription": "Bosnia and Herzegovina"
        },
        {
            "Id": 47,
            "IsoCode": "BB",
            "IsoCode3LetterDocumentReader": "BRB",
            "NonTranslatedDescription": "Barbados",
            "TranslatedDescription": "Barbados"
        },
        {
            "Id": 46,
            "IsoCode": "BD",
            "IsoCode3LetterDocumentReader": "BGD",
            "NonTranslatedDescription": "Bangladesh",
            "TranslatedDescription": "Bangladesh"
        },
        {
            "Id": 9,
            "IsoCode": "BE",
            "IsoCode3LetterDocumentReader": "BEL",
            "NonTranslatedDescription": "Bélgica",
            "TranslatedDescription": "Belgium"
        },
        {
            "Id": 58,
            "IsoCode": "BF",
            "IsoCode3LetterDocumentReader": "BFA",
            "NonTranslatedDescription": "Burkina Faso",
            "TranslatedDescription": "Burkina Faso"
        },
        {
            "Id": 57,
            "IsoCode": "BG",
            "IsoCode3LetterDocumentReader": "BGR",
            "NonTranslatedDescription": "Bulgária",
            "TranslatedDescription": "Bulgaria"
        },
        {
            "Id": 48,
            "IsoCode": "BH",
            "IsoCode3LetterDocumentReader": "BHR",
            "NonTranslatedDescription": "Barém",
            "TranslatedDescription": "Bahrain"
        },
        {
            "Id": 59,
            "IsoCode": "BI",
            "IsoCode3LetterDocumentReader": "BDI",
            "NonTranslatedDescription": "Burundi",
            "TranslatedDescription": "Burundi"
        },
        {
            "Id": 50,
            "IsoCode": "BJ",
            "IsoCode3LetterDocumentReader": "BEN",
            "NonTranslatedDescription": "Benin",
            "TranslatedDescription": "Benin"
        },
        {
            "Id": 252,
            "IsoCode": "BL",
            "IsoCode3LetterDocumentReader": "BLM",
            "NonTranslatedDescription": "São Bartolomeu",
            "TranslatedDescription": "Saint Barthélemy"
        },
        {
            "Id": 51,
            "IsoCode": "BM",
            "IsoCode3LetterDocumentReader": "BMU",
            "NonTranslatedDescription": "Bermuda",
            "TranslatedDescription": "Bermuda"
        },
        {
            "Id": 56,
            "IsoCode": "BN",
            "IsoCode3LetterDocumentReader": "BRN",
            "NonTranslatedDescription": "Brunei Darussalam",
            "TranslatedDescription": "Brunei Darussalam"
        },
        {
            "Id": 53,
            "IsoCode": "BO",
            "IsoCode3LetterDocumentReader": "BOL",
            "NonTranslatedDescription": "Bolívia",
            "TranslatedDescription": "Bolivia"
        },
        {
            "Id": 254,
            "IsoCode": "BQ",
            "IsoCode3LetterDocumentReader": "BES",
            "NonTranslatedDescription": "Bonaire, Saba e Santo Eustáquio",
            "TranslatedDescription": "Bonaire, Sint Eustatius and Saba"
        },
        {
            "Id": 10,
            "IsoCode": "BR",
            "IsoCode3LetterDocumentReader": "BRA",
            "NonTranslatedDescription": "Brasil",
            "TranslatedDescription": "Brazil"
        },
        {
            "Id": 45,
            "IsoCode": "BS",
            "IsoCode3LetterDocumentReader": "BHS",
            "NonTranslatedDescription": "Bahamas",
            "TranslatedDescription": "Bahamas"
        },
        {
            "Id": 60,
            "IsoCode": "BT",
            "IsoCode3LetterDocumentReader": "BTN",
            "NonTranslatedDescription": "Butão",
            "TranslatedDescription": "Bhutan"
        },
        {
            "Id": 113,
            "IsoCode": "BV",
            "IsoCode3LetterDocumentReader": "BVT",
            "NonTranslatedDescription": "Ilhas Bouvet",
            "TranslatedDescription": "Bouvet Island"
        },
        {
            "Id": 55,
            "IsoCode": "BW",
            "IsoCode3LetterDocumentReader": "BWA",
            "NonTranslatedDescription": "Botswana",
            "TranslatedDescription": "Botswana"
        },
        {
            "Id": 52,
            "IsoCode": "BY",
            "IsoCode3LetterDocumentReader": "BLR",
            "NonTranslatedDescription": "Bielorrússia",
            "TranslatedDescription": "Belarus"
        },
        {
            "Id": 49,
            "IsoCode": "BZ",
            "IsoCode3LetterDocumentReader": "BLZ",
            "NonTranslatedDescription": "Belize",
            "TranslatedDescription": "Belize"
        },
        {
            "Id": 11,
            "IsoCode": "CA",
            "IsoCode3LetterDocumentReader": "CAN",
            "NonTranslatedDescription": "Canadá",
            "TranslatedDescription": "Canada"
        },
        {
            "Id": 116,
            "IsoCode": "CC",
            "IsoCode3LetterDocumentReader": "CCK",
            "NonTranslatedDescription": "Ilhas Cocos (Keeling)",
            "TranslatedDescription": "Cocos (Keeling) Islands"
        },
        {
            "Id": 73,
            "IsoCode": "CD",
            "IsoCode3LetterDocumentReader": "COD",
            "NonTranslatedDescription": "Congo (República Democrática do)",
            "TranslatedDescription": "Congo, the Democratic Republic of the"
        },
        {
            "Id": 66,
            "IsoCode": "CF",
            "IsoCode3LetterDocumentReader": "CAF",
            "NonTranslatedDescription": "Centro-Africana (República)",
            "TranslatedDescription": "Central African Republic"
        },
        {
            "Id": 72,
            "IsoCode": "CG",
            "IsoCode3LetterDocumentReader": "COG",
            "NonTranslatedDescription": "Congo",
            "TranslatedDescription": "Congo"
        },
        {
            "Id": 19,
            "IsoCode": "CH",
            "IsoCode3LetterDocumentReader": "CHE",
            "NonTranslatedDescription": "Suíça",
            "TranslatedDescription": "Switzerland"
        },
        {
            "Id": 76,
            "IsoCode": "CI",
            "IsoCode3LetterDocumentReader": "CIV",
            "NonTranslatedDescription": "Costa do Marfim",
            "TranslatedDescription": "Cote D'Ivoire"
        },
        {
            "Id": 117,
            "IsoCode": "CK",
            "IsoCode3LetterDocumentReader": "COK",
            "NonTranslatedDescription": "Ilhas Cook",
            "TranslatedDescription": "Cook Islands"
        },
        {
            "Id": 68,
            "IsoCode": "CL",
            "IsoCode3LetterDocumentReader": "CHL",
            "NonTranslatedDescription": "Chile",
            "TranslatedDescription": "Chile"
        },
        {
            "Id": 62,
            "IsoCode": "CM",
            "IsoCode3LetterDocumentReader": "CMR",
            "NonTranslatedDescription": "Camarões",
            "TranslatedDescription": "Cameroon"
        },
        {
            "Id": 25,
            "IsoCode": "CN",
            "IsoCode3LetterDocumentReader": "CHN",
            "NonTranslatedDescription": "China",
            "TranslatedDescription": "China"
        },
        {
            "Id": 70,
            "IsoCode": "CO",
            "IsoCode3LetterDocumentReader": "COL",
            "NonTranslatedDescription": "Colômbia",
            "TranslatedDescription": "Colombia"
        },
        {
            "Id": 77,
            "IsoCode": "CR",
            "IsoCode3LetterDocumentReader": "CRI",
            "NonTranslatedDescription": "Costa Rica",
            "TranslatedDescription": "Costa Rica"
        },
        {
            "Id": 79,
            "IsoCode": "CU",
            "IsoCode3LetterDocumentReader": "CUB",
            "NonTranslatedDescription": "Cuba",
            "TranslatedDescription": "Cuba"
        },
        {
            "Id": 61,
            "IsoCode": "CV",
            "IsoCode3LetterDocumentReader": "CPV",
            "NonTranslatedDescription": "Cabo Verde",
            "TranslatedDescription": "Cape Verde"
        },
        {
            "Id": 255,
            "IsoCode": "CW",
            "IsoCode3LetterDocumentReader": "CUW",
            "NonTranslatedDescription": "Curaçau",
            "TranslatedDescription": "Curaçau"
        },
        {
            "Id": 115,
            "IsoCode": "CX",
            "IsoCode3LetterDocumentReader": "CXR",
            "NonTranslatedDescription": "Ilhas Christmas",
            "TranslatedDescription": "Christmas Island"
        },
        {
            "Id": 69,
            "IsoCode": "CY",
            "IsoCode3LetterDocumentReader": "CYP",
            "NonTranslatedDescription": "Chipre",
            "TranslatedDescription": "Cyprus"
        },
        {
            "Id": 191,
            "IsoCode": "CZ",
            "IsoCode3LetterDocumentReader": "CZE",
            "NonTranslatedDescription": "República Checa",
            "TranslatedDescription": "Czech Republic"
        },
        {
            "Id": 8,
            "IsoCode": "DE",
            "IsoCode3LetterDocumentReader": "DEU",
            "NonTranslatedDescription": "Alemanha",
            "TranslatedDescription": "Germany"
        },
        {
            "Id": 136,
            "IsoCode": "DJ",
            "IsoCode3LetterDocumentReader": "DJI",
            "NonTranslatedDescription": "Jibuti",
            "TranslatedDescription": "Djibouti"
        },
        {
            "Id": 16,
            "IsoCode": "DK",
            "IsoCode3LetterDocumentReader": "DNK",
            "NonTranslatedDescription": "Dinamarca",
            "TranslatedDescription": "Denmark"
        },
        {
            "Id": 80,
            "IsoCode": "DM",
            "IsoCode3LetterDocumentReader": "DMA",
            "NonTranslatedDescription": "Domínica",
            "TranslatedDescription": "Dominica"
        },
        {
            "Id": 192,
            "IsoCode": "DO",
            "IsoCode3LetterDocumentReader": "DOM",
            "NonTranslatedDescription": "República Dominicana",
            "TranslatedDescription": "Dominican Republic"
        },
        {
            "Id": 32,
            "IsoCode": "DZ",
            "IsoCode3LetterDocumentReader": "DZA",
            "NonTranslatedDescription": "Argélia",
            "TranslatedDescription": "Algeria"
        },
        {
            "Id": 84,
            "IsoCode": "EC",
            "IsoCode3LetterDocumentReader": "ECU",
            "NonTranslatedDescription": "Equador",
            "TranslatedDescription": "Ecuador"
        },
        {
            "Id": 88,
            "IsoCode": "EE",
            "IsoCode3LetterDocumentReader": "EST",
            "NonTranslatedDescription": "Estónia",
            "TranslatedDescription": "Estonia"
        },
        {
            "Id": 81,
            "IsoCode": "EG",
            "IsoCode3LetterDocumentReader": "EGY",
            "NonTranslatedDescription": "Egipto",
            "TranslatedDescription": "Egypt"
        },
        {
            "Id": 206,
            "IsoCode": "EH",
            "IsoCode3LetterDocumentReader": "ESH",
            "NonTranslatedDescription": "Sara Ocidental",
            "TranslatedDescription": "Western Sahara"
        },
        {
            "Id": 85,
            "IsoCode": "ER",
            "IsoCode3LetterDocumentReader": "ERI",
            "NonTranslatedDescription": "Eritreia",
            "TranslatedDescription": "Eritrea"
        },
        {
            "Id": 7,
            "IsoCode": "ES",
            "IsoCode3LetterDocumentReader": "ESP",
            "NonTranslatedDescription": "Espanha",
            "TranslatedDescription": "Spain"
        },
        {
            "Id": 89,
            "IsoCode": "ET",
            "IsoCode3LetterDocumentReader": "ETH",
            "NonTranslatedDescription": "Etiópia",
            "TranslatedDescription": "Ethiopia"
        },
        {
            "Id": 91,
            "IsoCode": "FI",
            "IsoCode3LetterDocumentReader": "FIN",
            "NonTranslatedDescription": "Finlândia",
            "TranslatedDescription": "Finland"
        },
        {
            "Id": 120,
            "IsoCode": "FJ",
            "IsoCode3LetterDocumentReader": "FJI",
            "NonTranslatedDescription": "Ilhas Fiji",
            "TranslatedDescription": "Fiji"
        },
        {
            "Id": 118,
            "IsoCode": "FK",
            "IsoCode3LetterDocumentReader": "FLK",
            "NonTranslatedDescription": "Ilhas Falkland (Malvinas)",
            "TranslatedDescription": "Falkland Islands (Malvinas)"
        },
        {
            "Id": 164,
            "IsoCode": "FM",
            "IsoCode3LetterDocumentReader": "FSM",
            "NonTranslatedDescription": "Micronésia (Estados Federados da)",
            "TranslatedDescription": "Micronesia, Federated States of"
        },
        {
            "Id": 119,
            "IsoCode": "FO",
            "IsoCode3LetterDocumentReader": "FRO",
            "NonTranslatedDescription": "Ilhas Faroé",
            "TranslatedDescription": "Faroe Islands"
        },
        {
            "Id": 13,
            "IsoCode": "FR",
            "IsoCode3LetterDocumentReader": "FRA",
            "NonTranslatedDescription": "França",
            "TranslatedDescription": "France"
        },
        {
            "Id": 92,
            "IsoCode": "GA",
            "IsoCode3LetterDocumentReader": "GAB",
            "NonTranslatedDescription": "Gabão",
            "TranslatedDescription": "Gabon"
        },
        {
            "Id": 6,
            "IsoCode": "GB",
            "IsoCode3LetterDocumentReader": "GBR",
            "NonTranslatedDescription": "Reino Unido",
            "TranslatedDescription": "United Kingdom"
        },
        {
            "Id": 98,
            "IsoCode": "GD",
            "IsoCode3LetterDocumentReader": "GRD",
            "NonTranslatedDescription": "Granada",
            "TranslatedDescription": "Grenada"
        },
        {
            "Id": 95,
            "IsoCode": "GE",
            "IsoCode3LetterDocumentReader": "GEO",
            "NonTranslatedDescription": "Geórgia",
            "TranslatedDescription": "Georgia"
        },
        {
            "Id": 104,
            "IsoCode": "GF",
            "IsoCode3LetterDocumentReader": "GUF",
            "NonTranslatedDescription": "Guiana Francesa",
            "TranslatedDescription": "French Guiana"
        },
        {
            "Id": 248,
            "IsoCode": "GG",
            "IsoCode3LetterDocumentReader": "GGY",
            "NonTranslatedDescription": "Guernsey",
            "TranslatedDescription": "Guernsey"
        },
        {
            "Id": 94,
            "IsoCode": "GH",
            "IsoCode3LetterDocumentReader": "GHA",
            "NonTranslatedDescription": "Gana",
            "TranslatedDescription": "Ghana"
        },
        {
            "Id": 97,
            "IsoCode": "GI",
            "IsoCode3LetterDocumentReader": "GIB",
            "NonTranslatedDescription": "Gibraltar",
            "TranslatedDescription": "Gibraltar"
        },
        {
            "Id": 99,
            "IsoCode": "GL",
            "IsoCode3LetterDocumentReader": "GRL",
            "NonTranslatedDescription": "Gronelândia",
            "TranslatedDescription": "Greenland"
        },
        {
            "Id": 93,
            "IsoCode": "GM",
            "IsoCode3LetterDocumentReader": "GMB",
            "NonTranslatedDescription": "Gâmbia",
            "TranslatedDescription": "Gambia"
        },
        {
            "Id": 105,
            "IsoCode": "GN",
            "IsoCode3LetterDocumentReader": "GIN",
            "NonTranslatedDescription": "Guiné",
            "TranslatedDescription": "Guinea"
        },
        {
            "Id": 100,
            "IsoCode": "GP",
            "IsoCode3LetterDocumentReader": "GLP",
            "NonTranslatedDescription": "Guadalupe",
            "TranslatedDescription": "Guadeloupe"
        },
        {
            "Id": 106,
            "IsoCode": "GQ",
            "IsoCode3LetterDocumentReader": "GNQ",
            "NonTranslatedDescription": "Guiné Equatorial",
            "TranslatedDescription": "Equatorial Guinea"
        },
        {
            "Id": 24,
            "IsoCode": "GR",
            "IsoCode3LetterDocumentReader": "GRC",
            "NonTranslatedDescription": "Grécia",
            "TranslatedDescription": "Greece"
        },
        {
            "Id": 96,
            "IsoCode": "GS",
            "IsoCode3LetterDocumentReader": "SGS",
            "NonTranslatedDescription": "Georgia do Sul e Ilhas Sandwich",
            "TranslatedDescription": "South Georgia and the South Sandwich Islands"
        },
        {
            "Id": 102,
            "IsoCode": "GT",
            "IsoCode3LetterDocumentReader": "GTM",
            "NonTranslatedDescription": "Guatemala",
            "TranslatedDescription": "Guatemala"
        },
        {
            "Id": 101,
            "IsoCode": "GU",
            "IsoCode3LetterDocumentReader": "GUM",
            "NonTranslatedDescription": "Guam",
            "TranslatedDescription": "Guam"
        },
        {
            "Id": 107,
            "IsoCode": "GW",
            "IsoCode3LetterDocumentReader": "GNB",
            "NonTranslatedDescription": "Guiné-Bissau",
            "TranslatedDescription": "Guinea-Bissau"
        },
        {
            "Id": 103,
            "IsoCode": "GY",
            "IsoCode3LetterDocumentReader": "GUY",
            "NonTranslatedDescription": "Guiana",
            "TranslatedDescription": "Guyana"
        },
        {
            "Id": 110,
            "IsoCode": "HK",
            "IsoCode3LetterDocumentReader": "HKG",
            "NonTranslatedDescription": "Hong Kong",
            "TranslatedDescription": "Hong Kong"
        },
        {
            "Id": 121,
            "IsoCode": "HM",
            "IsoCode3LetterDocumentReader": "HMD",
            "NonTranslatedDescription": "Ilhas Heard e Ilhas McDonald",
            "TranslatedDescription": "Heard Island and Mcdonald Islands"
        },
        {
            "Id": 109,
            "IsoCode": "HN",
            "IsoCode3LetterDocumentReader": "HND",
            "NonTranslatedDescription": "Honduras",
            "TranslatedDescription": "Honduras"
        },
        {
            "Id": 78,
            "IsoCode": "HR",
            "IsoCode3LetterDocumentReader": "HRV",
            "NonTranslatedDescription": "Croácia",
            "TranslatedDescription": "Croatia"
        },
        {
            "Id": 108,
            "IsoCode": "HT",
            "IsoCode3LetterDocumentReader": "HTI",
            "NonTranslatedDescription": "Haiti",
            "TranslatedDescription": "Haiti"
        },
        {
            "Id": 111,
            "IsoCode": "HU",
            "IsoCode3LetterDocumentReader": "HUN",
            "NonTranslatedDescription": "Hungria",
            "TranslatedDescription": "Hungary"
        },
        {
            "Id": 130,
            "IsoCode": "ID",
            "IsoCode3LetterDocumentReader": "IDN",
            "NonTranslatedDescription": "Indonésia",
            "TranslatedDescription": "Indonesia"
        },
        {
            "Id": 15,
            "IsoCode": "IE",
            "IsoCode3LetterDocumentReader": "IRL",
            "NonTranslatedDescription": "Irlanda",
            "TranslatedDescription": "Ireland"
        },
        {
            "Id": 134,
            "IsoCode": "IL",
            "IsoCode3LetterDocumentReader": "ISR",
            "NonTranslatedDescription": "Israel",
            "TranslatedDescription": "Israel"
        },
        {
            "Id": 249,
            "IsoCode": "IM",
            "IsoCode3LetterDocumentReader": "IMN",
            "NonTranslatedDescription": "Ilha de Man",
            "TranslatedDescription": "Ilha de Man"
        },
        {
            "Id": 129,
            "IsoCode": "IN",
            "IsoCode3LetterDocumentReader": "IND",
            "NonTranslatedDescription": "Índia",
            "TranslatedDescription": "India"
        },
        {
            "Id": 222,
            "IsoCode": "IO",
            "IsoCode3LetterDocumentReader": "IOT",
            "NonTranslatedDescription": "Território Britânico do Oceano Índico",
            "TranslatedDescription": "British Indian Ocean Territory"
        },
        {
            "Id": 132,
            "IsoCode": "IQ",
            "IsoCode3LetterDocumentReader": "IRQ",
            "NonTranslatedDescription": "Iraque",
            "TranslatedDescription": "Iraq"
        },
        {
            "Id": 131,
            "IsoCode": "IR",
            "IsoCode3LetterDocumentReader": "IRN",
            "NonTranslatedDescription": "Irão (República Islâmica)",
            "TranslatedDescription": "Iran, Islamic Republic of"
        },
        {
            "Id": 133,
            "IsoCode": "IS",
            "IsoCode3LetterDocumentReader": "ISL",
            "NonTranslatedDescription": "Islândia",
            "TranslatedDescription": "Iceland"
        },
        {
            "Id": 18,
            "IsoCode": "IT",
            "IsoCode3LetterDocumentReader": "ITA",
            "NonTranslatedDescription": "Itália",
            "TranslatedDescription": "Italy"
        },
        {
            "Id": 250,
            "IsoCode": "JE",
            "IsoCode3LetterDocumentReader": "JEY",
            "NonTranslatedDescription": "Jersey",
            "TranslatedDescription": "Jersey"
        },
        {
            "Id": 135,
            "IsoCode": "JM",
            "IsoCode3LetterDocumentReader": "JAM",
            "NonTranslatedDescription": "Jamaica",
            "TranslatedDescription": "Jamaica"
        },
        {
            "Id": 137,
            "IsoCode": "JO",
            "IsoCode3LetterDocumentReader": "JOR",
            "NonTranslatedDescription": "Jordânia",
            "TranslatedDescription": "Jordan"
        },
        {
            "Id": 23,
            "IsoCode": "JP",
            "IsoCode3LetterDocumentReader": "JPN",
            "NonTranslatedDescription": "Japão",
            "TranslatedDescription": "Japan"
        },
        {
            "Id": 139,
            "IsoCode": "KE",
            "IsoCode3LetterDocumentReader": "KEN",
            "NonTranslatedDescription": "Kenya",
            "TranslatedDescription": "Kenya"
        },
        {
            "Id": 190,
            "IsoCode": "KG",
            "IsoCode3LetterDocumentReader": "KGZ",
            "NonTranslatedDescription": "Quirguizistão",
            "TranslatedDescription": "Kyrgyzstan"
        },
        {
            "Id": 63,
            "IsoCode": "KH",
            "IsoCode3LetterDocumentReader": "KHM",
            "NonTranslatedDescription": "Camboja",
            "TranslatedDescription": "Cambodia"
        },
        {
            "Id": 140,
            "IsoCode": "KI",
            "IsoCode3LetterDocumentReader": "KIR",
            "NonTranslatedDescription": "Kiribati",
            "TranslatedDescription": "Kiribati"
        },
        {
            "Id": 71,
            "IsoCode": "KM",
            "IsoCode3LetterDocumentReader": "COM",
            "NonTranslatedDescription": "Comores",
            "TranslatedDescription": "Comoros"
        },
        {
            "Id": 201,
            "IsoCode": "KN",
            "IsoCode3LetterDocumentReader": "KNA",
            "NonTranslatedDescription": "São Cristóvão e Nevis",
            "TranslatedDescription": "Saint Kitts and Nevis"
        },
        {
            "Id": 75,
            "IsoCode": "KP",
            "IsoCode3LetterDocumentReader": "PRK",
            "NonTranslatedDescription": "Coreia (República Popular Democrática da)",
            "TranslatedDescription": "Korea, Democratic People's Republic of"
        },
        {
            "Id": 74,
            "IsoCode": "KR",
            "IsoCode3LetterDocumentReader": "KOR",
            "NonTranslatedDescription": "Coreia (República da)",
            "TranslatedDescription": "Korea, Republic of"
        },
        {
            "Id": 141,
            "IsoCode": "KW",
            "IsoCode3LetterDocumentReader": "KWT",
            "NonTranslatedDescription": "Kuwait",
            "TranslatedDescription": "Kuwait"
        },
        {
            "Id": 114,
            "IsoCode": "KY",
            "IsoCode3LetterDocumentReader": "CYM",
            "NonTranslatedDescription": "Ilhas Caimão",
            "TranslatedDescription": "Cayman Islands"
        },
        {
            "Id": 65,
            "IsoCode": "KZ",
            "IsoCode3LetterDocumentReader": "KAZ",
            "NonTranslatedDescription": "Cazaquistão",
            "TranslatedDescription": "Kazakhstan"
        },
        {
            "Id": 142,
            "IsoCode": "LA",
            "IsoCode3LetterDocumentReader": "LAO",
            "NonTranslatedDescription": "Laos (Rep. Popular Democrática do)",
            "TranslatedDescription": "Lao People's Democratic Republic"
        },
        {
            "Id": 145,
            "IsoCode": "LB",
            "IsoCode3LetterDocumentReader": "LBN",
            "NonTranslatedDescription": "Líbano",
            "TranslatedDescription": "Lebanon"
        },
        {
            "Id": 199,
            "IsoCode": "LC",
            "IsoCode3LetterDocumentReader": "LCA",
            "NonTranslatedDescription": "Santa Lúcia",
            "TranslatedDescription": "Saint Lucia"
        },
        {
            "Id": 148,
            "IsoCode": "LI",
            "IsoCode3LetterDocumentReader": "LIE",
            "NonTranslatedDescription": "Liechtenstein",
            "TranslatedDescription": "Liechtenstein"
        },
        {
            "Id": 213,
            "IsoCode": "LK",
            "IsoCode3LetterDocumentReader": "LKA",
            "NonTranslatedDescription": "Sri Lanka",
            "TranslatedDescription": "Sri Lanka"
        },
        {
            "Id": 146,
            "IsoCode": "LR",
            "IsoCode3LetterDocumentReader": "LBR",
            "NonTranslatedDescription": "Libéria",
            "TranslatedDescription": "Liberia"
        },
        {
            "Id": 143,
            "IsoCode": "LS",
            "IsoCode3LetterDocumentReader": "LSO",
            "NonTranslatedDescription": "Lesoto",
            "TranslatedDescription": "Lesotho"
        },
        {
            "Id": 149,
            "IsoCode": "LT",
            "IsoCode3LetterDocumentReader": "LTU",
            "NonTranslatedDescription": "Lituânia",
            "TranslatedDescription": "Lithuania"
        },
        {
            "Id": 22,
            "IsoCode": "LU",
            "IsoCode3LetterDocumentReader": "LUX",
            "NonTranslatedDescription": "Luxemburgo",
            "TranslatedDescription": "Luxembourg"
        },
        {
            "Id": 144,
            "IsoCode": "LV",
            "IsoCode3LetterDocumentReader": "LVA",
            "NonTranslatedDescription": "Letónia",
            "TranslatedDescription": "Latvia"
        },
        {
            "Id": 147,
            "IsoCode": "LY",
            "IsoCode3LetterDocumentReader": "LBY",
            "NonTranslatedDescription": "Líbia (Jamahiriya Árabe da)",
            "TranslatedDescription": "Libyan Arab Jamahiriya"
        },
        {
            "Id": 158,
            "IsoCode": "MA",
            "IsoCode3LetterDocumentReader": "MAR",
            "NonTranslatedDescription": "Marrocos",
            "TranslatedDescription": "Morocco"
        },
        {
            "Id": 167,
            "IsoCode": "MC",
            "IsoCode3LetterDocumentReader": "MCO",
            "NonTranslatedDescription": "Mónaco",
            "TranslatedDescription": "Monaco"
        },
        {
            "Id": 166,
            "IsoCode": "MD",
            "IsoCode3LetterDocumentReader": "MDA",
            "NonTranslatedDescription": "Moldova (República de)",
            "TranslatedDescription": "Moldova, Republic of"
        },
        {
            "Id": 245,
            "IsoCode": "ME",
            "IsoCode3LetterDocumentReader": "MNE",
            "NonTranslatedDescription": "Montenegro",
            "TranslatedDescription": "Montenegro"
        },
        {
            "Id": 251,
            "IsoCode": "MF",
            "IsoCode3LetterDocumentReader": "MAF",
            "NonTranslatedDescription": "São Martinho (parte francesa)",
            "TranslatedDescription": "Collectivity of Saint Martin"
        },
        {
            "Id": 152,
            "IsoCode": "MG",
            "IsoCode3LetterDocumentReader": "MDG",
            "NonTranslatedDescription": "Madagáscar",
            "TranslatedDescription": "Madagascar"
        },
        {
            "Id": 123,
            "IsoCode": "MH",
            "IsoCode3LetterDocumentReader": "MHL",
            "NonTranslatedDescription": "Ilhas Marshall",
            "TranslatedDescription": "Marshall Islands"
        },
        {
            "Id": 151,
            "IsoCode": "MK",
            "IsoCode3LetterDocumentReader": "MKD",
            "NonTranslatedDescription": "Macedónia (antiga rep. jugoslava da)",
            "TranslatedDescription": "Macedonia, the Former Yugoslav Republic of"
        },
        {
            "Id": 156,
            "IsoCode": "ML",
            "IsoCode3LetterDocumentReader": "MLI",
            "NonTranslatedDescription": "Mali",
            "TranslatedDescription": "Mali"
        },
        {
            "Id": 170,
            "IsoCode": "MM",
            "IsoCode3LetterDocumentReader": "MMR",
            "NonTranslatedDescription": "Myanmar",
            "TranslatedDescription": "Myanmar"
        },
        {
            "Id": 168,
            "IsoCode": "MN",
            "IsoCode3LetterDocumentReader": "MNG",
            "NonTranslatedDescription": "Mongólia",
            "TranslatedDescription": "Mongolia"
        },
        {
            "Id": 150,
            "IsoCode": "MO",
            "IsoCode3LetterDocumentReader": "MAC",
            "NonTranslatedDescription": "Macau",
            "TranslatedDescription": "Macao"
        },
        {
            "Id": 122,
            "IsoCode": "MP",
            "IsoCode3LetterDocumentReader": "MNP",
            "NonTranslatedDescription": "Ilhas Marianas do Norte",
            "TranslatedDescription": "Northern Mariana Islands"
        },
        {
            "Id": 159,
            "IsoCode": "MQ",
            "IsoCode3LetterDocumentReader": "MTQ",
            "NonTranslatedDescription": "Martinica",
            "TranslatedDescription": "Martinique"
        },
        {
            "Id": 161,
            "IsoCode": "MR",
            "IsoCode3LetterDocumentReader": "MRT",
            "NonTranslatedDescription": "Mauritânia",
            "TranslatedDescription": "Mauritania"
        },
        {
            "Id": 169,
            "IsoCode": "MS",
            "IsoCode3LetterDocumentReader": "MSR",
            "NonTranslatedDescription": "Monserrate",
            "TranslatedDescription": "Montserrat"
        },
        {
            "Id": 157,
            "IsoCode": "MT",
            "IsoCode3LetterDocumentReader": "MLT",
            "NonTranslatedDescription": "Malta",
            "TranslatedDescription": "Malta"
        },
        {
            "Id": 160,
            "IsoCode": "MU",
            "IsoCode3LetterDocumentReader": "MUS",
            "NonTranslatedDescription": "Maurícias",
            "TranslatedDescription": "Mauritius"
        },
        {
            "Id": 155,
            "IsoCode": "MV",
            "IsoCode3LetterDocumentReader": "MDV",
            "NonTranslatedDescription": "Maldivas",
            "TranslatedDescription": "Maldives"
        },
        {
            "Id": 154,
            "IsoCode": "MW",
            "IsoCode3LetterDocumentReader": "MWI",
            "NonTranslatedDescription": "Malawi",
            "TranslatedDescription": "Malawi"
        },
        {
            "Id": 163,
            "IsoCode": "MX",
            "IsoCode3LetterDocumentReader": "MEX",
            "NonTranslatedDescription": "México",
            "TranslatedDescription": "Mexico"
        },
        {
            "Id": 153,
            "IsoCode": "MY",
            "IsoCode3LetterDocumentReader": "MYS",
            "NonTranslatedDescription": "Malásia",
            "TranslatedDescription": "Malaysia"
        },
        {
            "Id": 165,
            "IsoCode": "MZ",
            "IsoCode3LetterDocumentReader": "MOZ",
            "NonTranslatedDescription": "Moçambique",
            "TranslatedDescription": "Mozambique"
        },
        {
            "Id": 171,
            "IsoCode": "NA",
            "IsoCode3LetterDocumentReader": "NAM",
            "NonTranslatedDescription": "Namíbia",
            "TranslatedDescription": "Namibia"
        },
        {
            "Id": 178,
            "IsoCode": "NC",
            "IsoCode3LetterDocumentReader": "NCL",
            "NonTranslatedDescription": "Nova Caledónia",
            "TranslatedDescription": "New Caledonia"
        },
        {
            "Id": 175,
            "IsoCode": "NE",
            "IsoCode3LetterDocumentReader": "NER",
            "NonTranslatedDescription": "Niger",
            "TranslatedDescription": "Niger"
        },
        {
            "Id": 125,
            "IsoCode": "NF",
            "IsoCode3LetterDocumentReader": "NFK",
            "NonTranslatedDescription": "Ilhas Norfolk",
            "TranslatedDescription": "Norfolk Island"
        },
        {
            "Id": 176,
            "IsoCode": "NG",
            "IsoCode3LetterDocumentReader": "NGA",
            "NonTranslatedDescription": "Nigéria",
            "TranslatedDescription": "Nigeria"
        },
        {
            "Id": 174,
            "IsoCode": "NI",
            "IsoCode3LetterDocumentReader": "NIC",
            "NonTranslatedDescription": "Nicarágua",
            "TranslatedDescription": "Nicaragua"
        },
        {
            "Id": 26,
            "IsoCode": "NL",
            "IsoCode3LetterDocumentReader": "NLD",
            "NonTranslatedDescription": "Países Baixos",
            "TranslatedDescription": "Netherlands"
        },
        {
            "Id": 21,
            "IsoCode": "NO",
            "IsoCode3LetterDocumentReader": "NOR",
            "NonTranslatedDescription": "Noruega",
            "TranslatedDescription": "Norway"
        },
        {
            "Id": 173,
            "IsoCode": "NP",
            "IsoCode3LetterDocumentReader": "NPL",
            "NonTranslatedDescription": "Nepal",
            "TranslatedDescription": "Nepal"
        },
        {
            "Id": 172,
            "IsoCode": "NR",
            "IsoCode3LetterDocumentReader": "NRU",
            "NonTranslatedDescription": "Nauru",
            "TranslatedDescription": "Nauru"
        },
        {
            "Id": 177,
            "IsoCode": "NU",
            "IsoCode3LetterDocumentReader": "NIU",
            "NonTranslatedDescription": "Niue",
            "TranslatedDescription": "Niue"
        },
        {
            "Id": 29,
            "IsoCode": "NZ",
            "IsoCode3LetterDocumentReader": "NZL",
            "NonTranslatedDescription": "Nova Zelândia",
            "TranslatedDescription": "New Zealand"
        },
        {
            "Id": 179,
            "IsoCode": "OM",
            "IsoCode3LetterDocumentReader": "OMN",
            "NonTranslatedDescription": "Omã",
            "TranslatedDescription": "Oman"
        },
        {
            "Id": 181,
            "IsoCode": "PA",
            "IsoCode3LetterDocumentReader": "PAN",
            "NonTranslatedDescription": "Panamá",
            "TranslatedDescription": "Panama"
        },
        {
            "Id": 185,
            "IsoCode": "PE",
            "IsoCode3LetterDocumentReader": "PER",
            "NonTranslatedDescription": "Peru",
            "TranslatedDescription": "Peru"
        },
        {
            "Id": 187,
            "IsoCode": "PF",
            "IsoCode3LetterDocumentReader": "PYF",
            "NonTranslatedDescription": "Polinésia Francesa",
            "TranslatedDescription": "French Polynesia"
        },
        {
            "Id": 182,
            "IsoCode": "PG",
            "IsoCode3LetterDocumentReader": "PNG",
            "NonTranslatedDescription": "Papuásia-Nova Guiné",
            "TranslatedDescription": "Papua New Guinea"
        },
        {
            "Id": 90,
            "IsoCode": "PH",
            "IsoCode3LetterDocumentReader": "PHL",
            "NonTranslatedDescription": "Filipinas",
            "TranslatedDescription": "Philippines"
        },
        {
            "Id": 183,
            "IsoCode": "PK",
            "IsoCode3LetterDocumentReader": "PAK",
            "NonTranslatedDescription": "Paquistão",
            "TranslatedDescription": "Pakistan"
        },
        {
            "Id": 188,
            "IsoCode": "PL",
            "IsoCode3LetterDocumentReader": "POL",
            "NonTranslatedDescription": "Polónia",
            "TranslatedDescription": "Poland"
        },
        {
            "Id": 203,
            "IsoCode": "PM",
            "IsoCode3LetterDocumentReader": "SPM",
            "NonTranslatedDescription": "São Pedro e Miquelon",
            "TranslatedDescription": "Saint Pierre and Miquelon"
        },
        {
            "Id": 186,
            "IsoCode": "PN",
            "IsoCode3LetterDocumentReader": "PCN",
            "NonTranslatedDescription": "Pitcairn",
            "TranslatedDescription": "Pitcairn"
        },
        {
            "Id": 189,
            "IsoCode": "PR",
            "IsoCode3LetterDocumentReader": "PRI",
            "NonTranslatedDescription": "Porto Rico",
            "TranslatedDescription": "Puerto Rico"
        },
        {
            "Id": 223,
            "IsoCode": "PS",
            "IsoCode3LetterDocumentReader": "PSE",
            "NonTranslatedDescription": "Palestina",
            "TranslatedDescription": "Palestine"
        },
        {
            "Id": 2,
            "IsoCode": "PT",
            "IsoCode3LetterDocumentReader": "PRT",
            "NonTranslatedDescription": "Portugal",
            "TranslatedDescription": "Portugal"
        },
        {
            "Id": 180,
            "IsoCode": "PW",
            "IsoCode3LetterDocumentReader": "PLW",
            "NonTranslatedDescription": "Palau",
            "TranslatedDescription": "Palau"
        },
        {
            "Id": 184,
            "IsoCode": "PY",
            "IsoCode3LetterDocumentReader": "PRY",
            "NonTranslatedDescription": "Paraguai",
            "TranslatedDescription": "Paraguay"
        },
        {
            "Id": 64,
            "IsoCode": "QA",
            "IsoCode3LetterDocumentReader": "QAT",
            "NonTranslatedDescription": "Catar",
            "TranslatedDescription": "Qatar"
        },
        {
            "Id": 193,
            "IsoCode": "RE",
            "IsoCode3LetterDocumentReader": "REU",
            "NonTranslatedDescription": "Reunião",
            "TranslatedDescription": "Reunion"
        },
        {
            "Id": 253,
            "IsoCode": "RKS",
            "IsoCode3LetterDocumentReader": "RKS",
            "NonTranslatedDescription": "Kosovo, República do",
            "TranslatedDescription": "Kosovo, República do"
        },
        {
            "Id": 194,
            "IsoCode": "RO",
            "IsoCode3LetterDocumentReader": "ROU",
            "NonTranslatedDescription": "Roménia",
            "TranslatedDescription": "Romania"
        },
        {
            "Id": 246,
            "IsoCode": "RS",
            "IsoCode3LetterDocumentReader": "SRB",
            "NonTranslatedDescription": "Sérvia",
            "TranslatedDescription": "Sérvia"
        },
        {
            "Id": 20,
            "IsoCode": "RU",
            "IsoCode3LetterDocumentReader": "RUS",
            "NonTranslatedDescription": "Rússia (Federação da)",
            "TranslatedDescription": "Russian Federation"
        },
        {
            "Id": 195,
            "IsoCode": "RW",
            "IsoCode3LetterDocumentReader": "RWA",
            "NonTranslatedDescription": "Ruanda",
            "TranslatedDescription": "Rwanda"
        },
        {
            "Id": 39,
            "IsoCode": "SA",
            "IsoCode3LetterDocumentReader": "SAU",
            "NonTranslatedDescription": "Arábia Saudita",
            "TranslatedDescription": "Saudi Arabia"
        },
        {
            "Id": 126,
            "IsoCode": "SB",
            "IsoCode3LetterDocumentReader": "SLB",
            "NonTranslatedDescription": "Ilhas Salomão",
            "TranslatedDescription": "Solomon Islands"
        },
        {
            "Id": 209,
            "IsoCode": "SC",
            "IsoCode3LetterDocumentReader": "SYC",
            "NonTranslatedDescription": "Seychelles",
            "TranslatedDescription": "Seychelles"
        },
        {
            "Id": 215,
            "IsoCode": "SD",
            "IsoCode3LetterDocumentReader": "SDN",
            "NonTranslatedDescription": "Sudão",
            "TranslatedDescription": "Sudan"
        },
        {
            "Id": 14,
            "IsoCode": "SE",
            "IsoCode3LetterDocumentReader": "SWE",
            "NonTranslatedDescription": "Suécia",
            "TranslatedDescription": "Sweden"
        },
        {
            "Id": 210,
            "IsoCode": "SG",
            "IsoCode3LetterDocumentReader": "SGP",
            "NonTranslatedDescription": "Singapura",
            "TranslatedDescription": "Singapore"
        },
        {
            "Id": 198,
            "IsoCode": "SH",
            "IsoCode3LetterDocumentReader": "SHN",
            "NonTranslatedDescription": "Santa Helena",
            "TranslatedDescription": "Saint Helena"
        },
        {
            "Id": 87,
            "IsoCode": "SI",
            "IsoCode3LetterDocumentReader": "SVN",
            "NonTranslatedDescription": "Eslovénia",
            "TranslatedDescription": "Slovenia"
        },
        {
            "Id": 217,
            "IsoCode": "SJ",
            "IsoCode3LetterDocumentReader": "SJM",
            "NonTranslatedDescription": "Svalbard e a Ilha de Jan Mayen",
            "TranslatedDescription": "Svalbard and Jan Mayen"
        },
        {
            "Id": 86,
            "IsoCode": "SK",
            "IsoCode3LetterDocumentReader": "SVK",
            "NonTranslatedDescription": "Eslovaca (República)",
            "TranslatedDescription": "Slovakia"
        },
        {
            "Id": 208,
            "IsoCode": "SL",
            "IsoCode3LetterDocumentReader": "SLE",
            "NonTranslatedDescription": "Serra Leoa",
            "TranslatedDescription": "Sierra Leone"
        },
        {
            "Id": 202,
            "IsoCode": "SM",
            "IsoCode3LetterDocumentReader": "SMR",
            "NonTranslatedDescription": "São Marino",
            "TranslatedDescription": "San Marino"
        },
        {
            "Id": 207,
            "IsoCode": "SN",
            "IsoCode3LetterDocumentReader": "SEN",
            "NonTranslatedDescription": "Senegal",
            "TranslatedDescription": "Senegal"
        },
        {
            "Id": 212,
            "IsoCode": "SO",
            "IsoCode3LetterDocumentReader": "SOM",
            "NonTranslatedDescription": "Somália",
            "TranslatedDescription": "Somalia"
        },
        {
            "Id": 216,
            "IsoCode": "SR",
            "IsoCode3LetterDocumentReader": "SUR",
            "NonTranslatedDescription": "Suriname",
            "TranslatedDescription": "Suriname"
        },
        {
            "Id": 256,
            "IsoCode": "SS",
            "IsoCode3LetterDocumentReader": "SSD",
            "NonTranslatedDescription": "Sudão do Sul",
            "TranslatedDescription": "South Sudan"
        },
        {
            "Id": 204,
            "IsoCode": "ST",
            "IsoCode3LetterDocumentReader": "STP",
            "NonTranslatedDescription": "São Tomé e Príncipe",
            "TranslatedDescription": "Sao Tome and Principe"
        },
        {
            "Id": 82,
            "IsoCode": "SV",
            "IsoCode3LetterDocumentReader": "SLV",
            "NonTranslatedDescription": "El Salvador",
            "TranslatedDescription": "El Salvador"
        },
        {
            "Id": 257,
            "IsoCode": "SX",
            "IsoCode3LetterDocumentReader": "SXM",
            "NonTranslatedDescription": "São Martinho (Países Baixos)",
            "TranslatedDescription": "Sint Maarten"
        },
        {
            "Id": 211,
            "IsoCode": "SY",
            "IsoCode3LetterDocumentReader": "SYR",
            "NonTranslatedDescription": "Síria (República Árabe da)",
            "TranslatedDescription": "Syrian Arab Republic"
        },
        {
            "Id": 214,
            "IsoCode": "SZ",
            "IsoCode3LetterDocumentReader": "SWZ",
            "NonTranslatedDescription": "Suazilândia",
            "TranslatedDescription": "Swaziland"
        },
        {
            "Id": 231,
            "IsoCode": "TC",
            "IsoCode3LetterDocumentReader": "TCA",
            "NonTranslatedDescription": "Turcos e Caicos (Ilhas)",
            "TranslatedDescription": "Turks and Caicos Islands"
        },
        {
            "Id": 67,
            "IsoCode": "TD",
            "IsoCode3LetterDocumentReader": "TCD",
            "NonTranslatedDescription": "Chade",
            "TranslatedDescription": "Chad"
        },
        {
            "Id": 224,
            "IsoCode": "TF",
            "IsoCode3LetterDocumentReader": "ATF",
            "NonTranslatedDescription": "Territórios Franceses do Sul",
            "TranslatedDescription": "French Southern Territories"
        },
        {
            "Id": 226,
            "IsoCode": "TG",
            "IsoCode3LetterDocumentReader": "TGO",
            "NonTranslatedDescription": "Togo",
            "TranslatedDescription": "Togo"
        },
        {
            "Id": 218,
            "IsoCode": "TH",
            "IsoCode3LetterDocumentReader": "THA",
            "NonTranslatedDescription": "Tailândia",
            "TranslatedDescription": "Thailand"
        },
        {
            "Id": 220,
            "IsoCode": "TJ",
            "IsoCode3LetterDocumentReader": "TJK",
            "NonTranslatedDescription": "Tajiquistão",
            "TranslatedDescription": "Tajikistan"
        },
        {
            "Id": 227,
            "IsoCode": "TK",
            "IsoCode3LetterDocumentReader": "TKL",
            "NonTranslatedDescription": "Tokelau",
            "TranslatedDescription": "Tokelau"
        },
        {
            "Id": 225,
            "IsoCode": "TL",
            "IsoCode3LetterDocumentReader": "TLS",
            "NonTranslatedDescription": "Timor Leste",
            "TranslatedDescription": "Timor-Leste"
        },
        {
            "Id": 232,
            "IsoCode": "TM",
            "IsoCode3LetterDocumentReader": "TKM",
            "NonTranslatedDescription": "Turquemenistão",
            "TranslatedDescription": "Turkmenistan"
        },
        {
            "Id": 230,
            "IsoCode": "TN",
            "IsoCode3LetterDocumentReader": "TUN",
            "NonTranslatedDescription": "Tunísia",
            "TranslatedDescription": "Tunisia"
        },
        {
            "Id": 228,
            "IsoCode": "TO",
            "IsoCode3LetterDocumentReader": "TON",
            "NonTranslatedDescription": "Tonga",
            "TranslatedDescription": "Tonga"
        },
        {
            "Id": 233,
            "IsoCode": "TR",
            "IsoCode3LetterDocumentReader": "TUR",
            "NonTranslatedDescription": "Turquia",
            "TranslatedDescription": "Turkey"
        },
        {
            "Id": 229,
            "IsoCode": "TT",
            "IsoCode3LetterDocumentReader": "TTO",
            "NonTranslatedDescription": "Trindade e Tobago",
            "TranslatedDescription": "Trinidad and Tobago"
        },
        {
            "Id": 234,
            "IsoCode": "TV",
            "IsoCode3LetterDocumentReader": "TUV",
            "NonTranslatedDescription": "Tuvalu",
            "TranslatedDescription": "Tuvalu"
        },
        {
            "Id": 219,
            "IsoCode": "TW",
            "IsoCode3LetterDocumentReader": "TWN",
            "NonTranslatedDescription": "Taiwan (Província da China)",
            "TranslatedDescription": "Taiwan, Province of China"
        },
        {
            "Id": 221,
            "IsoCode": "TZ",
            "IsoCode3LetterDocumentReader": "TZA",
            "NonTranslatedDescription": "Tanzânia, República Unida da",
            "TranslatedDescription": "Tanzania, United Republic of"
        },
        {
            "Id": 235,
            "IsoCode": "UA",
            "IsoCode3LetterDocumentReader": "UKR",
            "NonTranslatedDescription": "Ucrânia",
            "TranslatedDescription": "Ukraine"
        },
        {
            "Id": 236,
            "IsoCode": "UG",
            "IsoCode3LetterDocumentReader": "UGA",
            "NonTranslatedDescription": "Uganda",
            "TranslatedDescription": "Uganda"
        },
        {
            "Id": 124,
            "IsoCode": "UM",
            "IsoCode3LetterDocumentReader": "UMI",
            "NonTranslatedDescription": "Ilhas menores distantes dos Estados Unidos",
            "TranslatedDescription": "United States Minor Outlying Islands"
        },
        {
            "Id": 12,
            "IsoCode": "US",
            "IsoCode3LetterDocumentReader": "USA",
            "NonTranslatedDescription": "Estados Unidos",
            "TranslatedDescription": "United States"
        },
        {
            "Id": 237,
            "IsoCode": "UY",
            "IsoCode3LetterDocumentReader": "URY",
            "NonTranslatedDescription": "Uruguai",
            "TranslatedDescription": "Uruguay"
        },
        {
            "Id": 238,
            "IsoCode": "UZ",
            "IsoCode3LetterDocumentReader": "UZB",
            "NonTranslatedDescription": "Usbequistão",
            "TranslatedDescription": "Uzbekistan"
        },
        {
            "Id": 200,
            "IsoCode": "VA",
            "IsoCode3LetterDocumentReader": "VAT",
            "NonTranslatedDescription": "Santa Sé (Cidade Estado do Vaticano)",
            "TranslatedDescription": "Holy See (Vatican City State)"
        },
        {
            "Id": 205,
            "IsoCode": "VC",
            "IsoCode3LetterDocumentReader": "VCT",
            "NonTranslatedDescription": "São Vicente e Granadinas",
            "TranslatedDescription": "Saint Vincent and the Grenadines"
        },
        {
            "Id": 240,
            "IsoCode": "VE",
            "IsoCode3LetterDocumentReader": "VEN",
            "NonTranslatedDescription": "Venezuela",
            "TranslatedDescription": "Venezuela"
        },
        {
            "Id": 127,
            "IsoCode": "VG",
            "IsoCode3LetterDocumentReader": "VGB",
            "NonTranslatedDescription": "Ilhas Virgens (Britânicas)",
            "TranslatedDescription": "Virgin Islands, British"
        },
        {
            "Id": 128,
            "IsoCode": "VI",
            "IsoCode3LetterDocumentReader": "VIR",
            "NonTranslatedDescription": "Ilhas Virgens (Estados Unidos)",
            "TranslatedDescription": "Virgin Islands, U.s."
        },
        {
            "Id": 241,
            "IsoCode": "VN",
            "IsoCode3LetterDocumentReader": "VNM",
            "NonTranslatedDescription": "Vietname",
            "TranslatedDescription": "Viet Nam"
        },
        {
            "Id": 239,
            "IsoCode": "VU",
            "IsoCode3LetterDocumentReader": "VUT",
            "NonTranslatedDescription": "Vanuatu",
            "TranslatedDescription": "Vanuatu"
        },
        {
            "Id": 242,
            "IsoCode": "WF",
            "IsoCode3LetterDocumentReader": "WLF",
            "NonTranslatedDescription": "Wallis e Futuna (Ilha)",
            "TranslatedDescription": "Wallis and Futuna"
        },
        {
            "Id": 196,
            "IsoCode": "WS",
            "IsoCode3LetterDocumentReader": "WSM",
            "NonTranslatedDescription": "Samoa",
            "TranslatedDescription": "Samoa"
        },
        {
            "Id": 112,
            "IsoCode": "YE",
            "IsoCode3LetterDocumentReader": "YEM",
            "NonTranslatedDescription": "Iémen",
            "TranslatedDescription": "Yemen"
        },
        {
            "Id": 162,
            "IsoCode": "YT",
            "IsoCode3LetterDocumentReader": "MYT",
            "NonTranslatedDescription": "Mayotte",
            "TranslatedDescription": "Mayotte"
        },
        {
            "Id": 138,
            "IsoCode": "YU",
            "IsoCode3LetterDocumentReader": "XXX",
            "NonTranslatedDescription": "Jugoslávia",
            "TranslatedDescription": "Jugoslávia"
        },
        {
            "Id": 30,
            "IsoCode": "ZA",
            "IsoCode3LetterDocumentReader": "ZAF",
            "NonTranslatedDescription": "África do Sul",
            "TranslatedDescription": "South Africa"
        },
        {
            "Id": 243,
            "IsoCode": "ZM",
            "IsoCode3LetterDocumentReader": "ZMB",
            "NonTranslatedDescription": "Zâmbia",
            "TranslatedDescription": "Zambia"
        },
        {
            "Id": 244,
            "IsoCode": "ZW",
            "IsoCode3LetterDocumentReader": "ZWE",
            "NonTranslatedDescription": "Zimbabwe",
            "TranslatedDescription": "Zimbabwe"
        }
    ]