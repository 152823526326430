import { useState, useMemo, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Select } from "antd";
import { ChevronDown } from "lucide-react";
import { AlertTriangle } from "lucide-react";
import { AppContext } from "../../../../context/AppContext";
import { CheckFieldVisibility } from "../Utilities/CheckFieldVisibility";
import { previewHotelInitialData } from "../Utilities/previewModeData";

/**
 * @namespace Nationality
 * @memberof module:OnlineCheckIn
 * @description Component for managing guest nationality selection.
 * Provides a searchable dropdown with country flags and translations.
 *
 * @component
 * @param {Object} props Component properties
 * @param {Function} props.validateNifonBlur Function to validate NIF on nationality change
 * @param {Function} props.handleGuestChange Function to update guest data
 * @param {Object} props.guest Current guest data
 * @param {Object} props.nifValues Current NIF values for guests
 * @param {Object} props.initialData Initial configuration with country list
 * @param {Array<Object>} props.requiredFields List of required fields
 * @param {string|boolean} props.error Error state for the field
 *
 * @returns {JSX.Element} Nationality selection component
 *
 * @example
 * <Nationality
 *   validateNifonBlur={(id, nif, country) => validateNif(id, nif, country)}
 *   handleGuestChange={(id, field, value) => updateGuest(id, field, value)}
 *   guest={{ id: 1, data: { NationalityId: "PT" }}}
 *   nifValues={{ 1: "123456789" }}
 *   initialData={{ CountryList: [...] }}
 *   requiredFields={[{ Field: "NationalityId", IsMandatory: true }]}
 *   error={false}
 * />
 *
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @since 1.0.0
 * @version 1.0.0
 */
export const ShortTitle = ({ handleGuestChange, guest, error, guestFieldsGXP, isPreviewMode }) => {
  const [t] = useTranslation("global");
  const [isOpen, setIsOpen] = useState(false);
  const [state, dispatch] = useContext(AppContext);
  const [labelIsActive, setLabelIsActive] = useState(null);
  const [placeholderIsActive, setPlaceholderIsActive] = useState(null);
  const [titles, setTitles] = useState([]);
  const [militaryMode, setMilitaryMode] = useState(false);

  const field = guestFieldsGXP?.find(field => field.Code === "TITLESHORT_ID");

  const { isVisible, isMandatory } = CheckFieldVisibility(field,guest,state);

  useEffect(() => {
    if(isPreviewMode){
      setTitles(previewHotelInitialData.Titles);
      setMilitaryMode(previewHotelInitialData?.Parameters?.MilitaryMode);
      return
    }


    if(state?.hotelInitialData){
      setTitles(state.hotelInitialData.Titles)
      setMilitaryMode(state.hotelInitialData?.Parameters?.MilitaryMode);
    }
  }, [state?.hotelInitialData])

  /**
 * @function useEffectThemeStyles
 * @memberof module:OnlineCheckIn.Nationality
 * @description Effect that manages label and placeholder visibility based on theme settings.
 *
 * @effect Updates labelIsActive and placeholderIsActive states
 * @dependencies [state.activeTheme]
 *
 * @example
 * useEffect(() => {
 *   setLabelIsActive(state.activeTheme.find(style =>
 *     style.themeStyleCode === "OC-FormField-label_is_active" &&
 *     style.isActive
 *   ).value);
 * }, [state.activeTheme]);
 *
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @since 1.0.0
 * @version 1.0.0
 */
  useEffect(() => {
    setLabelIsActive( state.activeTheme.find( (style) => style.themeStyleCode === "OC-FormField-label_is_active" && style.isActive ).value );
    setPlaceholderIsActive( state.activeTheme.find( (style) => style.themeStyleCode === "OC-FormField-placeholder_is_active" && style.isActive ).value );
  }, [state.activeTheme]);

/**
 * @function isRequiredId
 * @memberof module:OnlineCheckIn.Nationality
 * @description Determines if the nationality field is required based on validation rules.
 *
 * @type {boolean}
 *
 * @example
 * const isRequiredId = requiredFields.some(
 *   field => field.Field === "NationalityId" && field.IsMandatory
 * );
 *
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @since 1.0.0
 * @version 1.0.0
 */
  const isRequiredId = isMandatory;

  /**
 * @function handleShortTitleChange
 * @memberof module:OnlineCheckIn.Title
 * @description Handles selection of short title and resets dependent selections.
 *
 * @param {Object} selected Selected short title option
 *
 * @example
 * handleShortTitleChange({ value: "1", label: "Area 1" })
 *
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @since 1.0.0
 * @version 1.0.0
 */
const handleShortTitleChange = (selected) => {
  const value = selected?.value;
  const label = selected?.label;
  handleGuestChange(guest.id, "TitleShort_Id", value);
  handleGuestChange(guest.id, "TitleLong_Id", null);
  handleGuestChange(guest.id, "Title_Id", null);
};

  return (
    (titles && (
    <div className="flex flex-col w-full h-full">
      <div className="w-full h-full justify-end flex flex-col dark:text-white relative">
        {isVisible && (labelIsActive === "true" || labelIsActive === null) && (
          <label className={`z-10 w-fit OC-FormField-label_location OC-FormField-label_color OC-FormField-label_size OC-FormField-label_font_family OC-FormField-label_variant OC-Form-bg_color pl-2  rounded-xl inline-block text-center justify-center dark:bg-gxp_dark_3 dark:text-white mb-1`} >
                     <span className="dark:text-white text-red-600">
            {isRequiredId ? ` * \u00A0` : ``}
          </span>{militaryMode ?`${t(`Title.area`)}`:`${t(`Title.short`)}`}
          </label>
        )}
        <Select
          suffixIcon={ <ChevronDown className={`OC-FormField-icon_color cursor-pointer dark:text-white transition-transform duration-300 ease-in-out ${ isOpen ? "rotate-180" : "" }`} /> }
          onDropdownVisibleChange={(open) => setIsOpen(open)}
          className={` OC-FormField-text_color OC-FormField-text_size OC-FormField-text_font_family OC-FormField-text_variant OC-FormField-bg_color OC-FormField-border_rounded OC-FormField-border_position OC-FormField-border_width dark:bg-gxp_dark_3 dark:text-white w-full px-2 py-2 h-[42px] ${error ? 'border-red-500' : 'OC-FormField-border_color dark:border-white'} focus:ring-2 focus:border-blue-500 transition duration-150 ease-in-out`}
          onChange={handleShortTitleChange}
          placeholder={ <span className="ant-select-selection-placeholder dark:bg-gxp_dark_3 dark:text-white w-full py-2 ${error ? 'border-red-500' : 'OC-FormField-border_color dark:border-white'} focus:ring-2 focus:border-blue-500 transition duration-150 ease-in-out"> {placeholderIsActive === "true" ? `${t(`Nationality.nationality`)} ${isRequiredId ? `( * )\u00A0` : ``}` : `${t(`Nationality.select`)}`} </span> }
          optionLabelProp="label"
          showSearch
          dropdownStyle={{ minWidth: "260px" }}
          popupClassName={`dark:dark`}
          labelInValue
          value={guest.data.TitleShort_Id ? { value: guest.data.TitleShort_Id, label: titles?.shortTitle?.find(title => title.ID === guest.data.TitleShort_Id)?.Description } : undefined}
          filterOption={(input, option) => option.children.props.children.props.children .toLowerCase() .includes(input.toLowerCase()) }
          virtual={false}
        >
          {titles?.shortTitle?.map((title) => (
                <Select.Option key={title.ID} value={title.ID} className={`dark:dark`} >
                  <div className="flex items-center">
                    <span className="text-l">{title.Description}</span>
                  </div>
                </Select.Option>
              ))}
        </Select>
        {error && (
          <div className="absolute left-0 bottom-0 transform translate-y-[calc(100%+4px)] flex items-center z-10 w-full">
            <AlertTriangle className="text-red-500 mr-1" size={16} />
            <span className="text-red-500 text-sm">{`${t(`HostForm.fieldRequired`)}`}</span>
          </div>
        )}        </div>
    </div>
    ))
  );
};
