import { useEffect, useState, useContext, useRef } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { ChevronDown, ChevronsDownUp, ChevronsUpDown, ChevronUp } from "lucide-react";
import { useLocation, useParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import { getDefaultPreviewInitialData, getHostCheckoutInfo, getReservationByDetailId } from "../../services";
import { toast } from "react-toastify";
import React from "react";
import { AppContext } from "../../context/AppContext";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { HeadersPageCK } from "./Components/HeadersPageCK";
import { Loader } from "../../components/Layout/components/Loader/Loader";
import { previewCheckoutData, previewHomeReservationsData, previewHotelInitialData } from "./Utils/previewModeData";

/**
* @module Checkout
* @description Core module for managing hotel checkout process.
* Handles displaying and processing of reservation lines, dates, and totals.
*/

/**
* @namespace Checkout
* @memberof module:Checkout
 * @description Main component responsible for rendering and managing the hotel checkout interface.
 * Handles reservation data, checkout lines processing, and interactive date-based grouping of transactions.
 * Supports preview mode for theme customization and inspection.
*
* @component
* @returns {JSX.Element} Checkout component
*
* @example
* <Checkout />
*
* @author Tiago Ferreira <tiago.ferreira@hhs.pt>
* @since 1.0.0
* @version 1.0.0
*/
export const Checkout = () => {
  const [state, dispatch] = useContext(AppContext);
  const [data, setData] = useState({ checkoutData: null, reservation: state.reservation, groupedLines: {}, });
  const [expandedDates, setExpandedDates] = useState([]);
  const {detailId} = useParams();
  const [cookie] = useCookies(["sUid", "currentHotel", "guest"]);
  const [t] = useTranslation("global");
  const [isLoading, setIsLoading] = useState(true);
  const [allExpanded, setAllExpanded] = useState(false);
  const [error, setError] = useState(null);


  //////////// previewmode ///////////////
  const { pathname } = useLocation();
  const isPreviewMode = pathname.includes("/previewmode/");
  const {defaultThemeId} = useParams()
  const [isHovered, setIsHovered] = useState();
  const [currentRef, setCurrentRef] = useState(null);
  const [inspectMode, setInspectMode] = useState(JSON.parse(localStorage.getItem("inspectMode"))==="true" ? true : false);
  const CKPageRef = useRef(null);
  const CKHeaderRef = useRef(null);
  const CKButton1Ref = useRef(null);
  const CKTableRef = useRef(null);

  const handleDoubleClick = (e, elementRef) => {
    e.stopPropagation();

    // Verifica se o inspectMode está ativo antes de enviar a mensagem
    if (isPreviewMode && inspectMode && elementRef.current) {
      const elementId = elementRef.current.id;

      window.parent.postMessage({
        action: "doubleClick",
        elementId: elementId,
        debugInfo: 'Double click on element to expand category/section'
      }, "*");
    }
  };

 /**
   * @function useEffectFetchDefaultPreviewInitialData
* @memberof module:Checkout.Checkout
   * @description Effect that loads the initial preview data for the default theme.
   * Fetches and sets the active theme when the component is in preview mode.
   *
   * @effect Updates the active theme in the global application state
   * @dependencies [defaultThemeId] - Executes when the default theme ID changes
   *
   * @example
   * useEffect(() => {
   *   if (isPreviewMode) {
   *     fetchDefaultPreviewInitialData()
   *   }
   * }, [defaultThemeId]);
   *
   * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @version 1.0.0
   */
 useEffect(() => {
  const fetchDefaultPreviewInitialData = async () => {
    try {
      const InitialData = await getDefaultPreviewInitialData(defaultThemeId);
      dispatch({ type: "SET_ACTIVE_THEME", payload: InitialData.activeTheme });
    } catch (error) {
      console.error("Error loading initialData-AllRoutes:", error);
    }
  }

  if (isPreviewMode) {
    fetchDefaultPreviewInitialData()
  }
}, [defaultThemeId]); // eslint-disable-line

  /**
 * @function handleMouseEnter
* @memberof module:Checkout.Checkout
 * @description Manages mouse enter events on elements during preview mode.
 * Sends messages to the parent component with information about the focused element.
 *
 * @param {Event} e - Mouse event
 * @param {React.RefObject} elementRef - React element reference
 *
 * @example
 * handleMouseEnter(event, myRef)
 *
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @since 1.0.0
 * @version 1.0.0
 */
const handleMouseEnter = (e, elementRef) => {
  e.stopPropagation();

  if (isPreviewMode && elementRef.current) {
    const current = elementRef.current;
    window.parent.postMessage({
      action: "hover",
      elementId: current.id,
      elementClass: current.className || '',
      elementTag: current.tagName || '',
      debugInfo: 'Hover on specific hoverable element'
    }, "*");
  }
};

/**
* @function handleMouseLeave
* @memberof module:Checkout.Checkout
* @description Handles mouse leave events for elements in preview mode.
* Sends a message to the parent window with element details when hover is removed.
*
* @param {Event} e - Mouse event object
* @param {React.RefObject} elementRef - Reference to the DOM element
*
* @example
* handleMouseLeave(event, myElementRef)
*
* @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @since 1.0.0
 * @version 1.0.0
 */
const handleMouseLeave = (e, elementRef) => {

  if (isPreviewMode && elementRef.current) {
    const current = elementRef.current;
    window.parent.postMessage({
      action: "hoverOut",
      elementId: current.id,
      elementClass: current.className || '',
      elementTag: current.tagName || '',
      debugInfo: 'HoverOut from specific hoverable element'
    }, "*");
  }
};

/**
* @function handleElementEnter
* @memberof module:Checkout.Checkout
* @description Manages element hover state entry in preview mode.
* Handles state transitions between different hovered elements and triggers
* appropriate mouse enter events.
*
* @param {Event} e - Mouse event object
* @param {React.RefObject} ref - Reference to the target DOM element
* @param {string} id - Unique identifier for the element
*
* @example
* handleElementEnter(event, elementRef, "element-id")
*
* @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @since 1.0.0
 * @version 1.0.0
 */
const handleElementEnter = (e, ref, id) => {
  e.preventDefault();
  e.stopPropagation();

  // Se houver um hover ativo em outro elemento
  if (isHovered && isHovered !== id && currentRef) {
      handleMouseLeave(e, currentRef);
  }

  // Atualiza o estado para o novo elemento
  if(inspectMode){
  setCurrentRef(ref);
  handleMouseEnter(e, ref);
  setIsHovered(id);
  }
};

/**
* @function handleElementLeave
* @memberof module:Checkout.Checkout
* @description Manages element hover state exit in preview mode.
* Cleans up hover states and references when leaving an element.
*
* @param {Event} e - Mouse event object
* @param {React.RefObject} ref - Reference to the target DOM element
* @param {string} id - Unique identifier for the element
*
* @example
* handleElementLeave(event, elementRef, "element-id")
*
* @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @since 1.0.0
 * @version 1.0.0
 */
const handleElementLeave = (e, ref, id) => {
  e.preventDefault();
  e.stopPropagation();

  // Apenas limpa o estado se for o elemento atual com hover
  if (isHovered === id) {
      handleMouseLeave(e, ref);
      setIsHovered(null);
      setCurrentRef(null);
  }
};

/**
* @function useEffectMessageHandler
* @memberof module:Checkout.Checkout
* @description Effect that sets up a message event listener for inspect mode toggling.
* Manages the inspect mode state and persists it to localStorage.
*
* @effect Sets up and cleans up message event listener
* @dependencies []
*
* @example
* useEffect(() => {
*   const handleMessage = (event) => { ... };
*   window.addEventListener("message", handleMessage);
*   return () => window.removeEventListener("message", handleMessage);
* }, []);
*
* @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @since 1.0.0
 * @version 1.0.0
 */
useEffect(() => {
const handleMessage = (event) => {
  // Log para debug
  // console.log("Mensagem recebida no iframe:", event.data);

  if (event.data.action === "toggleInspectMode") {
    const newMode = event.data.value;
    setInspectMode(newMode);
    localStorage.setItem("inspectMode", newMode.toString());
  }
};

window.addEventListener("message", handleMessage);
return () => window.removeEventListener("message", handleMessage);
}, []);

/**
* @function useEffectThemeUpdate
* @memberof module:Checkout.Checkout
* @description Effect that registers a global theme update function.
* Allows dynamic theme style updates through a globally accessible function.
* Used in preview mode to listen for theme changes and apply them to the global state.
* The global function is exposed to allow parent windows to trigger theme updates.
*
* @effect Sets up and cleans up global theme update function
* @dependencies [dispatch]
*
* @example
* useEffect(() => {
*   window.__THEME_UPDATE__ = (themeStyleCode, value) => { ... };
*   return () => { delete window.__THEME_UPDATE__; };
* }, [dispatch]);
*
* @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @since 1.0.0
 * @version 1.0.0
 */
 useEffect(() => {
  // Registrar a função de atualização globalmente
  window.__THEME_UPDATE__ = (themeStyleCode, value) => {
    // console.log('Theme update function called:', { themeStyleCode, value });
    dispatch({
      type: 'UPDATE_ACTIVE_THEME_STYLE',
      payload: { themeStyleCode, value }
    });
  };
  return () => {
    delete window.__THEME_UPDATE__;
  };
}, [dispatch]);

//////////////////////////////////////////////////////////////////////////

/**
  * @function processCheckoutLines
  * @memberof module:Checkout.Checkout
  * @description Processes and groups checkout lines by date.
  * Handles void items and ensures chronological ordering.
  *
  * @param {Array} lines - Raw checkout lines to process
  * @returns {Object} Grouped and processed checkout lines
  *
  * @example
  * const processedLines = processCheckoutLines(rawLines)
  *
  * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
  * @since 1.0.0
  * @version 1.0.0
  */
  const processCheckoutLines = (lines) => {
    // Remove os pares de itens anulados
    const nonVoidedLines = lines.filter((line) => {
      if (line.BookingId === 0 && line.VoidedBy === 0) return true;
      if (line.VoidedBy !== 0) {
        const voidingItem = lines.find(
          (voidLine) => voidLine.BookingId === line.VoidedBy
        );
        return !voidingItem;
      }
      if (line.VoidId !== 0) {
        const voidedItem = lines.find(
          (voidLine) => voidLine.BookingId === line.VoidId
        );
        return !voidedItem;
      }
      return true;
    });

    // Processa as linhas e agrupa por data
    const processedLines = nonVoidedLines.map((line) => ({ ...line, Description: line.RecordType === 2 ? `(Previsão) ${line.Description}` : line.Description, }));

    // Agrupa por data mantendo a data original para ordenação
    const groupedByDate = processedLines.reduce((acc, line) => {
      const formattedDate = formatDate(line.Date);
      if (!acc[formattedDate]) {
        acc[formattedDate] = {
          originalDate: line.Date,
          lines: []
        };
      }
      acc[formattedDate].lines.push(line);
      return acc;
    }, {});

    // Ordena as datas usando a data original
    const orderedDates = Object.entries(groupedByDate)
      .sort(([, a], [, b]) => new Date(a.originalDate) - new Date(b.originalDate))
      .reduce((acc, [formattedDate, { lines }]) => {
        acc[formattedDate] = lines;
        return acc;
      }, {});

    return orderedDates;
  };

/**
  * @function toggleDate
  * @memberof module:Checkout.Checkout
  * @description Toggles expansion state for specific date sections.
  *
  * @param {string} date - Date to toggle
  *
  * @example
  * toggleDate("2024-02-14")
  *
  * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
  * @since 1.0.0
  * @version 1.0.0
  */
  const toggleDate = (date) => { setExpandedDates((prev) => prev.includes(date) ? prev.filter((d) => d !== date) : [...prev, date] ); };

 /**
  * @function formatCurrency
  * @memberof module:Checkout.Checkout
  * @description Formats amounts as EUR currency strings.
  *
  * @param {number} amount - Amount to format
  * @returns {string} Formatted currency string
  *
  * @example
  * formatCurrency(100.50) // "€100.50"
  *
  * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
  * @since 1.0.0
  * @version 1.0.0
  */
  const formatCurrency = (amount) => {
    return new Intl.NumberFormat("pt-PT", {
      style: "currency",
      currency: "EUR",
    }).format(amount);
  };

/**
  * @function toggleAllDates
  * @memberof module:Checkout.Checkout
  * @description Toggles expansion state for all date sections.
  *
  * @example
  * toggleAllDates()
  *
  * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
  * @since 1.0.0
  * @version 1.0.0
  */
  const toggleAllDates = () => { setAllExpanded(!allExpanded); setExpandedDates(allExpanded ? [] : Object.keys(data.groupedLines)); };

  /**
  * @function formatDate
  * @memberof module:Checkout.Checkout
  * @description Formats dates according to localization settings.
  *
  * @param {string} dateString - Date string to format
  * @returns {string} Formatted date string
  *
  * @example
  * formatDate("2024-02-14")
  *
  * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
  * @since 1.0.0
  * @version 1.0.0
  */
  const formatDate = (dateString) => { return dayjs(dateString).format(t("dayjs_format_long")); };

 /**
  * @function fetchAllData
  * @memberof module:Checkout.Checkout
  * @description Fetches all necessary checkout data.
  * Handles reservation and checkout information retrieval.
  *
  * @async
  * @returns {Promise<void>}
  *
  * @example
  * await fetchAllData()
  *
  * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
  * @since 1.0.0
  * @version 1.0.0
  */
  const fetchAllData = async () => {
      if (!detailId || !cookie.sUid || !state.currentHotel?.subscriptionKey) {
        setIsLoading(false);
        return;
      }

      try {
        setIsLoading(true);

        // First, ensure we have reservation data
        let currentReservation = data.reservation;
        if (!currentReservation) {
          const results = await getReservationByDetailId( detailId, state.currentHotel, cookie.sUid );
          currentReservation = results[0];
          dispatch({ type: "SET_USER_RESERVATION", payload: currentReservation });
        }

        // Then, if we have a valid reservation, fetch checkout data
        if (currentReservation?.resStatus === 10) {
          const [checkoutInfo] = await Promise.all([
            getHostCheckoutInfo(cookie.sUid, detailId, state.currentHotel)
          ]);

          const groupedLines = checkoutInfo.data.Lines ? processCheckoutLines(checkoutInfo.data.Lines) : {};

          setData({ checkoutData: checkoutInfo.data, reservation: currentReservation, groupedLines, });
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setError(error);
        toast.error(error.message === "reservation"
          ? "Unable to get reservations!"
          : "Unable to get checkout information!");
      } finally {
        setIsLoading(false);
      }
    };

 /**
  * @function useEffectFetchData
  * @memberof module:Checkout.Checkout
  * @description Effect that triggers data fetching on mount and dependency changes.
  *
  * @effect Fetches checkout and reservation data
  * @dependencies [detailId, cookie.sUid, state.currentHotel]
  *
  * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
  * @since 1.0.0
  * @version 1.0.0
  */
 useEffect(() => {

  if(isPreviewMode){
    let currentReservation = previewHomeReservationsData[0].reservations[1]
    dispatch({ type: "SET_USER_RESERVATION", payload: previewHomeReservationsData[0].reservations[1] });
    const groupedLines = processCheckoutLines(previewCheckoutData.Lines)
    setData({ checkoutData: previewCheckoutData, reservation: currentReservation, groupedLines, });

    setIsLoading(false);
    return;
  }
    fetchAllData();
  }, [detailId, cookie.sUid, state.currentHotel]);


  const { reservation, checkoutData, groupedLines } = data;

  return (
    <>
      { reservation && (
        <div className={` antialiased h-full `}>
          <section id="CK-Page" ref={CKPageRef} onDoubleClick={(e) => handleDoubleClick(e, CKPageRef)} onMouseOver={(e) => handleElementEnter(e, CKPageRef, "CK-Page")} onMouseLeave={(e) => handleElementLeave(e, CKPageRef, "CK-Page")} className={`${isPreviewMode && isHovered === "CK-Page" ? "diagonal-stripes" : ""} dark:bg-gxp_dark_2 overflow-hidden flex-1 CK-bg_color flex flex-col h-full`}>
            <div id="CK-Header" ref={CKHeaderRef} onDoubleClick={(e) => handleDoubleClick(e, CKHeaderRef)} onMouseOver={(e) => handleElementEnter(e, CKHeaderRef, "CK-Header")} onMouseLeave={(e) => handleElementLeave(e, CKHeaderRef, "CK-Header")} className={`${isPreviewMode && isHovered === "CK-Header" ? "diagonal-stripes" : ""}`}>
              <HeadersPageCK title={`${t(`Checkout.checkout`)}`} room={reservation?.room} />
            </div>
            <div className="p-6 h-full flex-1">
              {isLoading ? (
                <div className={`flex flex-col items-center justify-center h-full w-full`} >
                  <Loader className={`w-10 h-10 dark:text-white`} />
                </div>
              ) : (
                <>
                  {reservation?.resStatus===10 ? reservation && checkoutData && !isLoading ? (
                    <>
                      <div className="mb-4 flex justify-between items-center">
                        <button id="CK-Button1" ref={CKButton1Ref} onDoubleClick={(e) => handleDoubleClick(e, CKButton1Ref)} onMouseOver={(e) => handleElementEnter(e, CKButton1Ref, "CK-Button1")} onMouseLeave={(e) => handleElementLeave(e, CKButton1Ref, "CK-Button1")} onClick={toggleAllDates} className={`${isPreviewMode && isHovered === "CK-Button1" ? "diagonal-stripes" : ""} flex items-center gap-2 px-4 py-2 CK-Button1-bg_color CK-Button1-border_color CK-Button1-icon_color border rounded transition-colors`} >
                          {allExpanded ? ( <ChevronsDownUp className="w-4 h-4" /> ) : ( <ChevronsUpDown className="w-4 h-4" /> )}
                          {allExpanded ? t("Checkout.collapseAll") : t("Checkout.expandAll")}
                        </button>
                        <span className="CK-title_color CK-title_size CK-title_font_family CK-title_variant dark:text-white"> {t("Checkout.balance")}{" "} {formatCurrency(checkoutData.Balance)} </span>
                      </div>
                      <table id="CK-Table" ref={CKTableRef} onDoubleClick={(e) => handleDoubleClick(e, CKTableRef)} onMouseOver={(e) => handleElementEnter(e, CKTableRef, "CK-Table")} onMouseLeave={(e) => handleElementLeave(e, CKTableRef, "CK-Table")} className={`${isPreviewMode && isHovered === "CK-Table" ? "diagonal-stripes" : ""} w-full CK-Table-border_color CK-Table-border_width CK-Table-border_rounded CK-Table-border_position`}>
                        <thead className="CK-Table-header_title_color CK-Table-header_title_size CK-Table-header_title_font_family CK-Table-header_title_variant CK-Table-header_bg_color CK-Table-header_border_color CK-Table-header_border_width CK-Table-header_border_rounded CK-Table-header_border_position">
                          <tr className="text-left">
                            <th className="py-2 px-4 w-full ">{t("Checkout.description")}</th>
                            <th className="py-2 px-4">{t("Checkout.quantity")}</th>
                            <th className="py-2 px-4">{t("Checkout.total")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {Object.entries(groupedLines).map(([date, lines]) => (
                            <React.Fragment key={date}>
                              <tr className="cursor-pointer transition-colors duration-150 CK-Table-body_rowheader_bg_color CK-Table-body_rowheader_hover_bg_color CK-Table-body_rowheader_title_color CK-Table-body_rowheader_title_size CK-Table-body_rowheader_title_font_family CK-Table-body_rowheader_title_variant CK-Table-body_rowheader_border_color CK-Table-body_rowheader_border_width CK-Table-body_rowheader_border_rounded CK-Table-body_rowheader_border_position" onClick={() => toggleDate(date)} >
                                <td colSpan="4" className="py-2 px-4">
                                  <div className="flex justify-between items-center font-semibold">
                                    <span>{date}</span>
                                    {expandedDates.includes(date) ? ( <ChevronUp className="w-5 h-5 CK-Table-body_rowheader_icon_color" /> ) : ( <ChevronDown className="w-5 h-5 CK-Table-body_rowheader_icon_color" /> )}
                                  </div>
                                </td>
                              </tr>
                              <AnimatePresence initial={false}>
                                {expandedDates.includes(date) && (
                                  <motion.tr initial="collapsed" animate="expanded" exit="collapsed" variants={{ expanded: { height: "auto", opacity: 1 }, collapsed: { height: 0, opacity: 0 }, }} transition={{ duration: 0.3, ease: "easeInOut" }} >
                                    <td colSpan="4" className="p-0">
                                      <motion.div initial="collapsed" animate="expanded" exit="collapsed" variants={{ expanded: { height: "auto", opacity: 1 }, collapsed: { height: 0, opacity: 0 }, }} transition={{ duration: 0.2, ease: "easeInOut" }} style={{ overflow: "hidden" }} >
                                        <table className="w-full">
                                          <tbody>
                                            {lines.map((line, index) => (
                                              <tr key={index} className={ `${index % 2 === 0 ? "CK-Table-body_row_bg_color1" : "CK-Table-body_row_bg_color2" } CK-Table-body_row_text_color CK-Table-body_row_text_size CK-Table-body_row_text_font_family CK-Table-body_row_text_variant CK-Table-body_row_border_color CK-Table-body_row_border_width CK-Table-body_row_border_rounded CK-Table-body_row_border_position`} >
                                                <td className="py-2 px-4 w-full"> {line.Description} </td>
                                                <td className="py-2 px-4">{line.Pieces}</td>
                                                <td className="py-2 px-4 text-right">
                                                  {formatCurrency(line.Price)}
                                                </td>
                                              </tr>
                                            ))}
                                          </tbody>
                                        </table>
                                      </motion.div>
                                    </td>
                                  </motion.tr>
                                )}
                              </AnimatePresence>
                            </React.Fragment>
                          ))}
                        </tbody>
                      </table>
                    </>
                  ) : ( <p className="dark:text-white">{t("Checkout.checkin")}</p> ):( <p className="dark:text-white">{t("Checkout.checkin")}</p> )}
                </>
              )}
            </div>
          </section>
        </div>
      )}
    </>
  );
};