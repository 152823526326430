import { useState, useMemo, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Select } from "antd";
import { ChevronDown } from "lucide-react";
import { AlertTriangle } from "lucide-react";
import { AppContext } from "../../../../context/AppContext";
import { CheckFieldVisibility } from "../Utilities/CheckFieldVisibility";

/**
 * @namespace Phone
 * @memberof module:OnlineCheckIn
 * @description Component for managing guest phone number input with country dial codes.
 * Provides country selection with flags and dial code integration.
 */
export const Phone = ({ initialData, guest, handleGuestChange, requiredFields, error, guestFieldsGXP }) => {
  const [selectedDialCode, setSelectedDialCode] = useState(""); // Estado para armazenar o código de discagem selecionado
  const [phoneNumber, setPhoneNumber] = useState(guest?.data?.Phone || ""); // Estado para armazenar o número de telefone local
  const [formattedPhone, setFormattedPhone] = useState(guest?.data?.Phone || ""); // Estado formatado para atualização global
  const [isDialCodeSelected, setIsDialCodeSelected] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [t] = useTranslation("global");
  const [state, dispatch] = useContext(AppContext);
  const [labelIsActive, setLabelIsActive] = useState(null);
  const [placeholderIsActive, setPlaceholderIsActive] = useState(null);

  const field = guestFieldsGXP?.find(field => field.Code === "TELEPHONE1");
  const { isVisible, isMandatory } = CheckFieldVisibility(field, guest, state);

  // Sincronizar os estados locais quando o valor do guest mudar externamente
  useEffect(() => {
    if (guest?.data?.Phone) {
      setFormattedPhone(guest.data.Phone);

      // Extrair código de área e número se possível
      const match = guest.data.Phone.match(/^(\+\d+)\s(.*)$/);
      if (match) {
        setSelectedDialCode(match[1]);
        setPhoneNumber(match[2]);
        setIsDialCodeSelected(true);
      } else {
        setPhoneNumber(guest.data.Phone);
      }
    }
  }, [guest?.data?.Phone]);

  useEffect(() => {
    setLabelIsActive(state.activeTheme.find(
      (style) => style.themeStyleCode === "OC-FormField-label_is_active" && style.isActive
    ).value);

    setPlaceholderIsActive(state.activeTheme.find(
      (style) => style.themeStyleCode === "OC-FormField-placeholder_is_active" && style.isActive
    ).value);
  }, [state.activeTheme]);

  const isRequired = isMandatory;

  const countries = useMemo(() => {
    return initialData.CountryList.map((country) => ({
      ...country,
      dialCode: initialData.DialCodes[country.IsoCode] || "",
    }));
  }, [initialData.CountryList, initialData.DialCodes]);

  // Manipulador para a alteração do código de discagem
  const handleDialCodeChange = (selected) => {
    const dialCode = selected.value;
    setSelectedDialCode(dialCode);
    setIsDialCodeSelected(true);

    // Atualizar o estado formatado
    const newFormattedPhone = `${dialCode} ${phoneNumber.replace(/^\+\d+\s/, "")}`;
    setFormattedPhone(newFormattedPhone);
  };

  // Manipulador para a alteração do número de telefone
  const handleLocalPhoneChange = (e) => {
    const value = e.target.value;
    setPhoneNumber(value);

    // Atualizar o estado formatado
    const newFormattedPhone = `${selectedDialCode} ${value}`;
    setFormattedPhone(newFormattedPhone);
  };

  // Função para atualizar o valor no estado global quando o campo perde o foco
  const handleBlur = () => {
    handleGuestChange(guest.id, "Phone", formattedPhone);
  };

  return (
    <div className="relative">
      {isVisible && (labelIsActive === "true" || labelIsActive === null) && (
        <label
          htmlFor="telefone"
          className={`OC-FormField-label_location_phone OC-FormField-label_color OC-FormField-label_size OC-FormField-label_font_family OC-FormField-label_variant OC-Form-bg_color pl-2 rounded-xl inline-block text-center justify-center dark:bg-gxp_dark_3 dark:text-white mb-1 z-10`}
        >
          <span className="dark:text-white text-red-600">
            {isRequired ? ` * \u00A0` : ``}
          </span>
          {`${t(`Phone.phone`)}`}
        </label>
      )}
      <div className="flex h-[43.62px] w-full">
        <div className="flex h-full w-full items-stretch dark:border-gray-600">
          <Select
            suffixIcon={
              <ChevronDown
                className={`OC-FormField-icon_color dark:text-white transition-transform duration-300 ease-in-out ${
                  isOpen ? "rotate-180" : ""
                }`}
              />
            }
            onDropdownVisibleChange={(open) => setIsOpen(open)}
            className={`OC-FormField-text_color OC-FormField-text_size OC-FormField-text_font_family OC-FormField-text_variant OC-FormField-bg_color OC-FormField-border_rounded_phone_select OC-FormField-border_position OC-FormField-border_width dark:bg-gxp_dark_3 dark:text-white w-full px-2 py-2 h-[42px] ${
              error
                ? "border-red-500"
                : "OC-FormField-border_color dark:border-white"
            } focus:ring-2 focus:border-blue-500 transition duration-150 ease-in-out`}
            style={{ width: "150px" }}
            onChange={handleDialCodeChange}
            onBlur={handleBlur}
            placeholder={
              <span className="dark:text-gray-400">
                {`${t(`Phone.select`)}`}
              </span>
            }
            optionLabelProp="label"
            popupClassName={`dark:dark`}
            showSearch
            dropdownStyle={{ minWidth: "260px" }}
            labelInValue
            value={selectedDialCode ? { value: selectedDialCode, label: selectedDialCode } : undefined}
            filterOption={(input, option) => {
              const country = countries.find(
                (country) => country.dialCode === option.value
              );
              return (
                country?.TranslatedDescription?.toLowerCase().includes(
                  input.toLowerCase()
                ) ||
                country?.dialCode?.toLowerCase().includes(input.toLowerCase())
              );
            }}
            virtual={false}
          >
            {countries.map((country) => (
              <Select.Option
                key={country.IsoCode}
                className={`dark:dark`}
                value={country.dialCode}
                label={
                  <div className="flex items-center">
                    {" "}
                    <img
                      src={`https://flagcdn.com/w20/${country.IsoCode.toLowerCase()}.png`}
                      alt={`${country.TranslatedDescription} flag`}
                      className="w-6 h-4 mr-2"
                    />{" "}
                    <span className="text-l">{country.dialCode}</span>{" "}
                  </div>
                }
              >
                <div className="flex items-center">
                  <img
                    src={`https://flagcdn.com/w20/${country.IsoCode.toLowerCase()}.png`}
                    alt={`${country.TranslatedDescription} flag`}
                    className="w-6 h-4 mr-2 "
                  />
                  <span className="text-l dark:text-white">
                    {country.TranslatedDescription}
                  </span>
                  <span className="ml-2 text-l text-gray-500 dark:text-white">
                    {country.dialCode}
                  </span>
                </div>
              </Select.Option>
            ))}
          </Select>

          <div className="justify-center w-full h-full">
            <input
              name="Phone"
              onChange={handleLocalPhoneChange}
              onBlur={handleBlur}
              value={phoneNumber}
              type="tel"
              id="telefone"
              className={`OC-FormField-text_color OC-FormField-text_size OC-FormField-text_font_family OC-FormField-text_variant OC-FormField-bg_color OC-FormField-border_rounded_phone OC-FormField-border_position OC-FormField-border_width dark:bg-gxp_dark_3 dark:text-white w-full px-4 py-2 h-[42px] ${
                error
                  ? "border-red-500"
                  : "OC-FormField-border_color dark:border-white"
              } focus:ring-2 focus:border-blue-500 transition duration-150 ease-in-out
              OC-FormField-placeholder_color OC-FormField-placeholder_font_family OC-FormField-placeholder_variant`}
              placeholder={placeholderIsActive === "true" || placeholderIsActive === null
                ? `${t(`Phone.phone`)} ${isRequired ? `( * )\u00A0` : ``}`
                : ""}
            />
          </div>
        </div>
      </div>
      {error && (
        <div className="absolute left-0 bottom-0 transform translate-y-[calc(100%+4px)] flex items-center z-10 w-full">
          <AlertTriangle className="text-red-500 mr-1" size={16} />
          <span className="text-red-500 text-sm">{`${t(`HostForm.fieldRequired`)}`}</span>
        </div>
      )}
    </div>
  );
};