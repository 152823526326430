import React from 'react';

export const applyTheme = (themeStyles) => {
  if (!themeStyles || !Array.isArray(themeStyles)) {
    return;
  }

  const root = document.documentElement;

  const applyStyleProperty = (styleCode) => {
    const style = themeStyles.find(s => s.themeStyleCode === styleCode);
    if (style) {
      const propertyName = `--${styleCode}`;
      let propertyValue;

      switch (true) {
        case style.themeStyleCode.includes('_width'):
          propertyValue = `${style.value}px`;
          break;

        case style.themeStyleCode.includes('_size'):
          propertyValue = `${style.value}px`;
          break;

        case style.themeStyleCode.includes('_color'):
          propertyValue = style.value;
          break;

        case style.themeStyleCode.includes('border_position'):
          // Mapeamento dos valores para as propriedades de borda
          const borderMap = {
            'Bottom': 'none none solid none',
            'X-Axis': 'none solid none solid',
            'Y-Axis': 'solid none solid none',
            'Top': 'solid none none none',
            'Left': 'none none none solid',
            'Right': 'none solid none none',
            'All': 'solid'
          };
          propertyValue = borderMap[style.value] || 'none';
          break;

          case style.themeStyleCode.includes('border_rounded'):
            // Mapeamento dos valores para as propriedades de borda
            const borderRoundedMap = {
              'None': '0px',
              'Small': '4px',
              'Medium': '8px',
              'Large': '12px',
              'Extra Large': '16px',
              'Round': '24px',
              'Full': '9999px'
            };
            propertyValue = borderRoundedMap[style.value] || 'none';
            break;

            case style.themeStyleCode.includes('FormField-label_location'):
              // Mapeamento dos valores para as propriedades de borda
              const locationMap = {
                'Normal': '0px',
                'On Top': '14px',
              };
              propertyValue = locationMap[style.value] || 'none';
              break;

          case style.themeStyleCode.includes('_variant'):
            const variantMap = {
              'None': { style: 'normal', weight: 'normal' },
              'Italic': { style: 'italic', weight: 'normal' },
              'Bold': { style: 'normal', weight: 'bold' },
              'Bold & Italic': { style: 'italic', weight: 'bold' }
            };

            // Definir as propriedades separadamente
            const variant = variantMap[style.value] || variantMap['None'];
            root.style.setProperty(`${propertyName}-style`, variant.style);
            root.style.setProperty(`${propertyName}-weight`, variant.weight);
            return; // Retorna aqui pois já definimos as propriedades

        default:
          propertyValue = style.value;
      }

      root.style.setProperty(propertyName, propertyValue);
    }
  };

  const styleCategories = {
    onlineCheckin: [
      'OC-bg_color',
      'OC-title_color',
      'OC-title_size',
      'OC-title_font_family',
      'OC-title_variant',

      'OC-Header-image',
      'OC-Header-logo_image',
      'OC-Header-title_color',
      'OC-Header-title_size',
      'OC-Header-title_font_family',
      'OC-Header-title_variant',
      'OC-Header-text_color',
      'OC-Header-text_size',
      'OC-Header-text_font_family',
      'OC-Header-text_variant',
      'OC-Header-bar_bg_color',
      'OC-Header-bar_border_color',
      'OC-Header-bar_border_width',
      'OC-Header-bar_border_position',
      'OC-Header-bar_border_rounded',

      'OC-Stepper-border_color',
      'OC-Stepper-active_color',
      'OC-Stepper-text_color',
      'OC-Stepper-text_font_family',
      'OC-Stepper-text_variant',
      'OC-Stepper-label_color',
      'OC-Stepper-label_font_family',
      'OC-Stepper-label_variant',
      'OC-Stepper-label_size',

      'OC-Form-title_color',
      'OC-Form-title_size',
      'OC-Form-title_font_family',
      'OC-Form-title_variant',
      'OC-Form-text_color',
      'OC-Form-text_size',
      'OC-Form-text_font_family',
      'OC-Form-text_variant',
      'OC-Form-bg_color',
      'OC-Form-border_color',
      'OC-Form-border_rounded',
      'OC-Form-border_position',
      'OC-Form-border_width',

      'OC-FormField-label_color',
      'OC-FormField-label_size',
      'OC-FormField-label_font_family',
      'OC-FormField-label_variant',
      'OC-FormField-label_location',
      'OC-FormField-label_is_active',
      'OC-FormField-text_color',
      'OC-FormField-text_size',
      'OC-FormField-text_font_family',
      'OC-FormField-text_variant',
      'OC-FormField-bg_color',
      'OC-FormField-icon_color',
      'OC-FormField-border_color',
      'OC-FormField-border_rounded',
      'OC-FormField-border_position',
      'OC-FormField-border_width',
      'OC-FormField-placeholder_color',
      'OC-FormField-placeholder_font_family',
      'OC-FormField-placeholder_variant',
      'OC-FormField-placeholder_is_active',


      'OC-Button1-bg_color',
      'OC-Button1-icon_color',
      'OC-Button1-border_color',

      'OC-Button2-bg_color',
      'OC-Button2-icon_color',
      'OC-Button2-border_color',

      'OC-Consent-bg_color',
      'OC-Consent-border_color',
      'OC-Consent-border_rounded',
      'OC-Consent-border_position',
      'OC-Consent-border_width',
      'OC-Consent-text_color',
      'OC-Consent-text_size',
      'OC-Consent-text_font_family',
      'OC-Consent-text_variant',
      'OC-Consent-title_color',
      'OC-Consent-title_size',
      'OC-Consent-title_font_family',
      'OC-Consent-title_variant',
      'OC-Consent-icon_color',

      'OC-GeneralTerm-bg_color',
      'OC-GeneralTerm-border_color',
      'OC-GeneralTerm-border_rounded',
      'OC-GeneralTerm-border_position',
      'OC-GeneralTerm-border_width',
      'OC-GeneralTerm-text_color',
      'OC-GeneralTerm-text_size',
      'OC-GeneralTerm-text_font_family',
      'OC-GeneralTerm-text_variant',
      'OC-GeneralTerm-title_color',
      'OC-GeneralTerm-title_size',
      'OC-GeneralTerm-title_font_family',
      'OC-GeneralTerm-title_variant',
      'OC-GeneralTerm-icon_color',

    ],
    tableReservation: [
      'TR-bg_color',

      'TR-Header-title_color',
      'TR-Header-title_size',
      'TR-Header-title_font_family',
      'TR-Header-title_variant',
      'TR-Header-text_color',
      'TR-Header-text_size',
      'TR-Header-text_font_family',
      'TR-Header-text_variant',
      'TR-Header-bar_bg_color',
      'TR-Header-bar_border_color',
      'TR-Header-bar_border_width',
      'TR-Header-bar_border_position',
      'TR-Header-bar_border_rounded',

      'TR-Stepper-border_color',
      'TR-Stepper-active_color',
      'TR-Stepper-text_color',
      'TR-Stepper-text_font_family',
      'TR-Stepper-text_variant',
      'TR-Stepper-icon_color',
      'TR-Stepper-hover_bg_color',
      'TR-Stepper-bg_color',

      'TR-Form-title_color',
      'TR-Form-title_size',
      'TR-Form-title_font_family',
      'TR-Form-title_variant',
      'TR-Form-text_color',
      'TR-Form-text_size',
      'TR-Form-text_font_family',
      'TR-Form-text_variant',
      'TR-Form-bg_color',
      'TR-Form-border_color',
      'TR-Form-border_rounded',
      'TR-Form-border_position',
      'TR-Form-border_width',


      'TR-Card-bg_color',
      'TR-Card-border_color',
      'TR-Card-border_rounded',
      'TR-Card-border_position',
      'TR-Card-border_width',
      'TR-Card-text_color',
      'TR-Card-text_size',
      'TR-Card-text_font_family',
      'TR-Card-text_variant',
      'TR-Card-hover_bg_color',
      'TR-Card-hover_border_color',
      'TR-Card-active_bg_color',
      'TR-Card-active_color',
      'TR-Card-disabled_bg_color',
      'TR-Card-disabled_text_color',
      'TR-Card-disabled_border_color',

      'TR-Button1-bg_color',
      'TR-Button1-icon_color',
      'TR-Button1-border_color',


      'TR-Button2-bg_color',
      'TR-Button2-icon_color',
      'TR-Button2-border_color',

      'TR-FormField-label_color',
      'TR-FormField-label_size',
      'TR-FormField-label_font_family',
      'TR-FormField-label_variant',
      'TR-FormField-label_location',
      'TR-FormField-label_is_active',
      'TR-FormField-text_color',
      'TR-FormField-text_size',
      'TR-FormField-text_font_family',
      'TR-FormField-text_variant',
      'TR-FormField-bg_color',
      'TR-FormField-icon_color',
      'TR-FormField-border_color',
      'TR-FormField-border_rounded',
      'TR-FormField-border_position',
      'TR-FormField-border_width',
      'TR-FormField-placeholder_color',
      'TR-FormField-placeholder_font_family',
      'TR-FormField-placeholder_variant',
      'TR-FormField-placeholder_is_active',

    ],

    menuHeader: [
      'MH-bg_color',
      'MH-title_color',
      'MH-title_size',
      'MH-title_font_family',
      'MH-title_variant',
      'MH-border_color',
      'MH-border_rounded',
      'MH-border_position',
      'MH-border_width',
      'MH-icon_color',
      'MH-text_color',
      'MH-text_size',
      'MH-text_font_family',
      'MH-text_variant',
    ],
    menuFooter: [
      'MF-bg_color',
      'MF-title_color',
      'MF-title_size',
      'MF-title_font_family',
      'MF-title_variant',
      'MF-border_color',
      'MF-border_rounded',
      'MF-border_position',
      'MF-border_width',
      'MF-icon_color',
    ],
    home: [
      'HM-bg_color',
      'HM-title_color',
      'HM-title_size',
      'HM-title_font_family',
      'HM-title_variant',
      'HM-text_color',
      'HM-text_size',
      'HM-text_font_family',
      'HM-text_variant',
      'HM-icon_color',

      'HM-Card-bg_color',
      'HM-Card-title_color',
      'HM-Card-title_size',
      'HM-Card-title_font_family',
      'HM-Card-title_variant',
      'HM-Card-text_color',
      'HM-Card-text_size',
      'HM-Card-text_font_family',
      'HM-Card-text_variant',
      'HM-Card-border_color',
      'HM-Card-border_rounded',
      'HM-Card-border_position',
      'HM-Card-border_width',
      'HM-Card-icon_color',
    ],
    reservation: [
      'RS-bg_color',
      'RS-title_color',
      'RS-title_size',
      'RS-title_font_family',
      'RS-title_variant',

      'RS-Card-bg_color',
      'RS-Card-title_color',
      'RS-Card-title_size',
      'RS-Card-title_font_family',
      'RS-Card-title_variant',
      'RS-Card-text_color',
      'RS-Card-text_size',
      'RS-Card-text_font_family',
      'RS-Card-text_variant',
      'RS-Card-border_color',
      'RS-Card-border_rounded',
      'RS-Card-border_position',
      'RS-Card-border_width',
      'RS-Card-icon_color',
    ],
    checkout: [
      'CK-bg_color',
      'CK-title_color',
      'CK-title_size',
      'CK-title_font_family',
      'CK-title_variant',

      'CK-Button1-bg_color',
      'CK-Button1-icon_color',
      'CK-Button1-border_color',

      'CK-Header-title_color',
      'CK-Header-title_size',
      'CK-Header-title_font_family',
      'CK-Header-title_variant',
      'CK-Header-text_color',
      'CK-Header-text_size',
      'CK-Header-text_font_family',
      'CK-Header-text_variant',
      'CK-Header-bar_bg_color',
      'CK-Header-bar_border_color',
      'CK-Header-bar_border_width',
      'CK-Header-bar_border_position',
      'CK-Header-bar_border_rounded',

      'CK-Table-border_color',
      'CK-Table-border_rounded',
      'CK-Table-border_position',
      'CK-Table-border_width',

      'CK-Table-header_bg_color',
      'CK-Table-header_title_color',
      'CK-Table-header_title_size',
      'CK-Table-header_title_font_family',
      'CK-Table-header_title_variant',
      'CK-Table-header_border_color',
      'CK-Table-header_border_rounded',
      'CK-Table-header_border_position',
      'CK-Table-header_border_width',

      'CK-Table-body_rowheader_bg_color',
      'CK-Table-body_rowheader_title_color',
      'CK-Table-body_rowheader_title_size',
      'CK-Table-body_rowheader_title_font_family',
      'CK-Table-body_rowheader_title_variant',
      'CK-Table-body_rowheader_border_color',
      'CK-Table-body_rowheader_border_rounded',
      'CK-Table-body_rowheader_border_position',
      'CK-Table-body_rowheader_border_width',
      'CK-Table-body_rowheader_hover_bg_color',
      'CK-Table-body_rowheader_icon_color',

      'CK-Table-body_row_bg_color1',
      'CK-Table-body_row_bg_color2',
      'CK-Table-body_row_text_color',
      'CK-Table-body_row_text_size',
      'CK-Table-body_row_text_font_family',
      'CK-Table-body_row_text_variant',
      'CK-Table-body_row_border_color',
      'CK-Table-body_row_border_rounded',
      'CK-Table-body_row_border_position',
      'CK-Table-body_row_border_width',
    ],
    maintenance: [
      'MN-bg_color',
      'MN-title_color',
      'MN-title_size',
      'MN-title_font_family',
      'MN-title_variant',

      'MN-Button1-bg_color',
      'MN-Button1-icon_color',
      'MN-Button1-border_color',

      'MN-Header-title_color',
      'MN-Header-title_size',
      'MN-Header-title_font_family',
      'MN-Header-title_variant',
      'MN-Header-text_color',
      'MN-Header-text_size',
      'MN-Header-text_font_family',
      'MN-Header-text_variant',
      'MN-Header-bar_bg_color',
      'MN-Header-bar_border_color',
      'MN-Header-bar_border_width',
      'MN-Header-bar_border_position',
      'MN-Header-bar_border_rounded',

      'MN-FormField-text_color',
      'MN-FormField-text_size',
      'MN-FormField-text_font_family',
      'MN-FormField-text_variant',
      'MN-FormField-border_color',
      'MN-FormField-border_rounded',
      'MN-FormField-border_position',
      'MN-FormField-border_width',
      'MN-FormField-placeholder_color',
      'MN-FormField-placeholder_font_family',
      'MN-FormField-placeholder_variant',
      'MN-FormField-bg_color',
    ],
    qrcode: [
      'QR-bg_color',

      'QR-Header-title_color',
      'QR-Header-title_size',
      'QR-Header-title_font_family',
      'QR-Header-title_variant',
      'QR-Header-text_color',
      'QR-Header-text_size',
      'QR-Header-text_font_family',
      'QR-Header-text_variant',
      'QR-Header-bar_bg_color',
      'QR-Header-bar_border_color',
      'QR-Header-bar_border_width',
      'QR-Header-bar_border_position',
      'QR-Header-bar_border_rounded',
    ],
    hotel: [
      'HT-bg_color',

      'HT-Card-bg_color',
      'HT-Card-title_color',
      'HT-Card-title_size',
      'HT-Card-title_font_family',
      'HT-Card-title_variant',
      'HT-Card-text_color',
      'HT-Card-text_size',
      'HT-Card-text_font_family',
      'HT-Card-text_variant',
      'HT-Card-border_color',
      'HT-Card-border_rounded',
      'HT-Card-border_position',
      'HT-Card-border_width',
      'HT-Card-icon_color',
    ],
    emenu: [
      'EM-bg_color',

      'EM-Card-bg_color',
      'EM-Card-title_color',
      'EM-Card-title_size',
      'EM-Card-title_font_family',
      'EM-Card-title_variant',
      'EM-Card-text_color',
      'EM-Card-text_size',
      'EM-Card-text_font_family',
      'EM-Card-text_variant',
      'EM-Card-border_color',
      'EM-Card-border_rounded',
      'EM-Card-border_position',
      'EM-Card-border_width',
      'EM-Card-promotion_color',
      'EM-Card-allergen_text_size',
      'EM-Card-allergen_text_color',
      'EM-Card-allergen_text_font_family',
      'EM-Card-allergen_text_variant',
      'EM-Card-allergen_text_bg_color',

      'EM-EMenuCard-with_image_title_color',
      'EM-EMenuCard-with_image_title_size',
      'EM-EMenuCard-with_image_title_font_family',
      'EM-EMenuCard-with_image_title_variant',
      'EM-EMenuCard-with_image_text_color',
      'EM-EMenuCard-with_image_text_size',
      'EM-EMenuCard-with_image_text_font_family',
      'EM-EMenuCard-with_image_text_variant',
      'EM-EMenuCard-with_image_border_color',
      'EM-EMenuCard-with_image_border_rounded',
      'EM-EMenuCard-with_image_border_position',
      'EM-EMenuCard-with_image_border_width',

      'EM-EMenuCard-no_image_title_color',
      'EM-EMenuCard-no_image_title_size',
      'EM-EMenuCard-no_image_title_font_family',
      'EM-EMenuCard-no_image_title_variant',
      'EM-EMenuCard-no_image_text_color',
      'EM-EMenuCard-no_image_text_size',
      'EM-EMenuCard-no_image_text_font_family',
      'EM-EMenuCard-no_image_text_variant',
      'EM-EMenuCard-no_image_border_color',
      'EM-EMenuCard-no_image_border_rounded',
      'EM-EMenuCard-no_image_border_position',
      'EM-EMenuCard-no_image_border_width',
      'EM-EMenuCard-no_image_bg_color',

      'EM-Searchbar-bg_color',
      'EM-Searchbar-border_color',
      'EM-Searchbar-border_rounded',
      'EM-Searchbar-border_position',
      'EM-Searchbar-border_width',
      'EM-Searchbar-icon_color',
    ]
  };

  // Aplicar todas as propriedades de estilo organizadas por categoria
  Object.values(styleCategories).flat().forEach(applyStyleProperty);
};