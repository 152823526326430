import { useTranslation } from "react-i18next";
import { useState, useEffect, useContext, useMemo } from "react";
import { AlertTriangle, ChevronDown } from "lucide-react";
import { AppContext } from "../../../../context/AppContext";
import { CheckFieldVisibility } from "../Utilities/CheckFieldVisibility";
import { Select } from "antd";

/**
 * @namespace Country
 * @memberof module:OnlineCheckIn
 * @description Component for managing guest address information.
 * This component includes input fields for country selection, address, city,
 * and postal code, along with validation based on required fields.
 *
 * @component
 * @param {Object} props - The properties passed to the component
 * @param {Object} props.guest - The guest object containing address data
 * @param {function} props.handleGuestChange - Function to handle changes in guest data
 * @param {Object} props.initialData - Initial data including the list of countries
 * @param {Array<Object>} props.requiredFields - List of required fields for validation
 * @param {Object} [props.error] - Validation error object
 * @param {boolean} [props.isSameCountry] - Indicates if the country is the same
 *
 * @returns {JSX.Element} The rendered address form fields
 *
 * @example
 * <Address
 *   guest={guestData}
 *   handleGuestChange={handleChange}
 *   initialData={countryData}
 *   requiredFields={[
 *     { Field: "Address1", IsMandatory: true },
 *     { Field: "CountryId", IsMandatory: true }
 *   ]}
 * />
 *
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @version 1.0.0
 * @since 1.0.0
 */
export const Country = ({ guest, handleGuestChange, error, guestFieldsGXP }) => {
  const [t] = useTranslation("global");
  const [state, dispatch] = useContext(AppContext);
  const [labelIsActive, setLabelIsActive] = useState(null);
  const [placeholderIsActive, setPlaceholderIsActive] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const field = guestFieldsGXP?.find(field => field.Code === "COUNTRY_ID");

  const { isVisible, isMandatory } = CheckFieldVisibility(field,guest,state);

  /**
 * @function isRequiredAddress1
 * @memberof module:OnlineCheckIn.Address
 * @type {boolean}
 *
 * @description Determines if the Address1 field is required based on validation rules.
 *
 * @example
 * const isRequiredAddress1 = requiredFields.some(
 *   field => field.Field === "Address1" && field.IsMandatory
 * );
 *
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @since 1.0.0
 * @version 1.0.0
 */
  const isRequiredCountry = isMandatory;

/**
 * @function useEffectThemeStyles
 * @memberof module:OnlineCheckIn.Address
 * @param {Array} state.activeTheme
 *
 * @description Effect that manages label and placeholder visibility based on theme settings.
 *
 * @effect Updates labelIsActive and placeholderIsActive states
 * @dependencies [state.activeTheme]
 *
 * @example
 * useEffect(() => {
 *   setLabelIsActive(state.activeTheme.find(style =>
 *     style.themeStyleCode === "OC-FormField-label_is_active" &&
 *     style.isActive
 *   ).value);
 *   setPlaceholderIsActive(state.activeTheme.find(style =>
 *     style.themeStyleCode === "OC-FormField-placeholder_is_active" &&
 *     style.isActive
 *   ).value);
 * }, [state.activeTheme]);
 *
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @since 1.0.0
 * @version 1.0.0
 */
  useEffect(() => {
    setLabelIsActive( state.activeTheme.find( (style) => style.themeStyleCode === "OC-FormField-label_is_active" && style.isActive ).value );
    setPlaceholderIsActive( state.activeTheme.find( (style) => style.themeStyleCode === "OC-FormField-placeholder_is_active" && style.isActive ).value );
  }, [state.activeTheme]);

    /**
 * @function useCountriesMemo
 * @memberof module:OnlineCheckIn.Address
 * @param {Array} initialData.CountryList - List of countries from initial data
 *
 * @description Memoizes the list of countries to optimize performance
 *
 * @returns {Array} Processed list of countries
 *
 * @dependencies [initialData.CountryList]
 *
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @version 1.0.0
 * @since 1.0.0
 */
    const countries = useMemo(() => {
      return state.hotelInitialData.CountryList.map((country) => ({
        ...country,
      }));
    }, [state.hotelInitialData.CountryList]);

    /**
 * @function getCountryLabel
 * @memberof module:OnlineCheckIn.Address
 * @param {number|string} countryIdOrIso - Country ID or ISO code
 *
 * @description Retrieves the country label based on ID or ISO code
 *
 * @returns {string} Translated country description
 *
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @version 1.0.0
 * @since 1.0.0
 */
  const getCountryLabel = (countryIdOrIso) => {
    if (state.hotelInitialData) {
      const country = state.hotelInitialData.CountryList.find(
        (country) =>
          country.Id === countryIdOrIso || country.IsoCode === countryIdOrIso
      );
      return country ? country.TranslatedDescription : "";
    }
  };

    /**
 * @function getCountryValue
 * @memberof module:OnlineCheckIn.Address
 *
 * @description Determines the country value for the select input
 *
 * @returns {number|undefined} Country ID or undefined
 *
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @version 1.0.0
 * @since 1.0.0
 */
    const getCountryValue = () => {
      if (guest.data.CountryId) {
        return guest.data.CountryId;
      } else if (guest.data.CountryIso) {
        const country = state.hotelInitialData.CountryList.find(
          (c) => c.IsoCode === guest.data.CountryIso
        );
        return country ? country.Id : undefined;
      }
      return undefined;
    };

  const countryValue = getCountryValue();

  return (
    <>
                <div className="flex flex-col w-full h-full justify-end">
        {(labelIsActive === "true" || labelIsActive === null) && (
          <label htmlFor="genero" className={`z-10 w-fit OC-FormField-label_location OC-FormField-label_color OC-FormField-label_size OC-FormField-label_font_family OC-FormField-label_variant OC-Form-bg_color pl-2  rounded-xl inline-block text-center justify-center dark:bg-gxp_dark_3 dark:text-white mb-1`} >
            <span className="dark:text-white text-red-600">
              {isRequiredCountry ? ` * \u00A0` : ``}
            </span>
            {`${t(`Address.country`)}`}{" "}
          </label>
        )}
          <Select
            suffixIcon={
              <ChevronDown
                className={`OC-FormField-icon_color dark:text-white transition-transform duration-300 ease-in-out ${
                  isOpen ? "rotate-180" : ""
                }`}
              />
            }
            onDropdownVisibleChange={(open) => setIsOpen(open)}
            className={`OC-FormField-text_color OC-FormField-text_size OC-FormField-text_font_family OC-FormField-text_variant OC-FormField-bg_color OC-FormField-border_rounded OC-FormField-border_position OC-FormField-border_width dark:bg-gxp_dark_3 dark:text-white w-full px-2 py-2 h-[42px] ${
              error
                ? "border-red-500"
                : "OC-FormField-border_color dark:border-white"
            } focus:ring-2 focus:border-blue-500 transition duration-150 ease-in-out`}
            onChange={(selected) => {
              handleGuestChange(guest.id, "CountryId", selected.value);
              const selectedCountry = state.hotelInitialData.CountryList.find(
                (c) => c.Id === selected.value
              );
              if (selectedCountry) {
                handleGuestChange(
                  guest.id,
                  "CountryIso",
                  selectedCountry.IsoCode
                );
              }
            }}
            placeholder={ <span className="dark:text-gray-400"> {placeholderIsActive === "true" ? `${t(`Address.country`)} ${isRequiredCountry ? `( * )\u00A0` : ``}` : `${t(`Address.select`)}`} </span> }
            optionLabelProp="label"
            popupClassName={`dark:dark`}
            showSearch
            dropdownStyle={{ minWidth: "260px" }}
            labelInValue
            value={
              countryValue
                ? { value: countryValue, label: getCountryLabel(countryValue) }
                : undefined
            }
            filterOption={(input, option) => { const country = countries.find( (country) => country.Id === option.value ); return country?.TranslatedDescription?.toLowerCase().includes( input.toLowerCase() ); }}
            virtual={false}
          >
            {countries.map((country) => (
              <Select.Option
                key={country.Id}
                className={`dark:dark`}
                value={country.Id}
                label={
                  <div className="flex items-center">
                    {" "}
                    <img
                      src={`https://flagcdn.com/w20/${country.IsoCode.toLowerCase()}.png`}
                      alt={`${country.TranslatedDescription} flag`}
                      className="w-6 h-4 mr-2"
                    />{" "}
                    <span className="text-l">{country.dialCode}</span>{" "}
                  </div>
                }
              >
                <div className="flex items-center">
                  <img
                    src={`https://flagcdn.com/w20/${country.IsoCode.toLowerCase()}.png`}
                    alt={`${country.TranslatedDescription} flag`}
                    className="w-6 h-4 mr-2"
                  />
                  <span className="text-l">
                    {country.TranslatedDescription}
                  </span>
                </div>
              </Select.Option>
            ))}
          </Select>
          {error && (
            <div className="flex mt-1 items-center">
              {" "}
              <AlertTriangle className="text-red-500 mr-1" size={16} />{" "}
              <span className="text-red-500 text-sm">{error}</span>{" "}
            </div>
          )}
        </div>
    </>
  );
};
