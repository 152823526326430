import { ArrowBigLeftDash, CloudDownloadIcon, Plus, Save, X } from "lucide-react";
import { motion, AnimatePresence } from "framer-motion";
import { useCallback, useContext, useEffect, useState } from "react";
import { AppContext } from "../../../context/AppContext";
import { Loader } from "../../../components/Layout/components/Loader/Loader";
import { GroupHotelModal } from "./GroupHotelModal";
import { getAdminInitialData, getHotelInfo, saveHotel } from "../../../services";
import { useTranslation } from "react-i18next";
import { CloseCircleOutlined, PlusCircleOutlined, SaveOutlined, } from "@ant-design/icons";
import { hostSelectCustomStyles, hostSelectCustomStylesModal, } from "../../../01_HotelPanel/components/utils/hostSelectStyles";
import Select from "react-select";
import dayjs from "dayjs";

export const HotelModal = ({ isGroupModalOpen, setIsGroupModalOpen, isHotelModalOpen, onCloseHotelModal, title, type, hotel, }) => {
  const [isSending, setIsSending] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [state, dispatch] = useContext(AppContext);
  const [availableGroups, setAvailableGroups] = useState([]);
  const [t] = useTranslation("global");
  const [saveStatus, setSaveStatus] = useState({
    status: null,
    message: "",
  });
  const [formData, setFormData] = useState({
    id: "",
    hotelId: "",
    name1: "",
    name2: "",
    groupId: "",
    rooms: "",
    posQuantity: "",
    hotelCountry: "",
    license: "",
    licenseExpireDate: "",
    subscriptionKey: "",
    isActive: true,
    user: state.adminLoggedIn.username,
  });

  const loadHotelInfo = async () => {
    try {
      setIsLoading(true); // Set sending state to true
      const data = await getHotelInfo(formData.subscriptionKey); // Send request
      return data;
    } catch (error) {
      console.error("Error loading hotel:", error);
      setSaveStatus({
        status: "error",
        message: error.message || `${t(`Admin.hotelList.errorLoading`)}`,
      });
      return false;
    } finally {
      setIsLoading(false); // Set sending state back to false
    }
  };

  const updateHotelInfo = async () => {
    const data = await loadHotelInfo();
    if(data){
      setFormData((prev) => ({
        ...prev,
        hotelId: data.PlatformUid,
        name1: data.HotelName,
        name2: data.HotelName2,
        rooms: data.License.Rooms,
        posQuantity: (data.License.PosWorkstationsInternal+data.License.PosWorkstationsExternal+data.License.PosWorkstationsMobile),
        hotelCountry: data.License.CountryCode,
        license: data.License.LicenseInterfaceModules,
        licenseExpireDate: dayjs(data.License.ValidUntil).format('DD/MM/YYYY'),
      }))
      setSaveStatus({
        status: "success",
        message: `${t(`Admin.hotelList.successLoading`)}`,
      });
    }
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (name === "hotelId" && type !== "checkbox") {
      // Verifica se já existe um groupId igual
      const hotelExists = state.adminInitialData?.hotels.some(
        (h) =>
          h.hotelId.toLowerCase() === value.toLowerCase().trim() &&
          (!hotel || h.id !== hotel.id) // Ignora o próprio registro em caso de edição
      );

      if (hotelExists) {
        setSaveStatus({
          status: "error",
          message: `${t(`Admin.hotelList.errorHotelID`)}`,
        });
      } else {
        setSaveStatus({
          status: null,
          message: "",
        });
      }
    }

    if (name === "subscriptionKey" && type !== "checkbox") {
      // Verifica se já existe um groupId igual
      const hotelExists = state.adminInitialData?.hotels.some(
        (h) =>
          h.subscriptionKey.toLowerCase() === value.toLowerCase().trim() &&
          (!hotel || h.id !== hotel.id) // Ignora o próprio registro em caso de edição
      );

      if (hotelExists) {
        setSaveStatus({
          status: "error",
          message: `${t(`Admin.hotelList.errorSubscriptionKey`)}`,
        });
      } else {
        setSaveStatus({
          status: null,
          message: "",
        });
      }
    }

    setFormData((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSelectChange = (selectedOption) => {
    // Extract the groupId value from the selected option
    const value = selectedOption ? selectedOption.groupId : "";

    // Create a synthetic event object similar to what a DOM event would provide
    const syntheticEvent = {
      target: {
        name: "groupId",
        value: value,
        type: "select-one",
      },
    };

    // Use the existing handleChange function
    handleChange(syntheticEvent);
  };

  const postHotel = async () => {
    try {
      setIsSending(true); // Set sending state to true
      await saveHotel(formData); // Send request
      setSaveStatus({
        status: "success",
        message: `${t(`Admin.hotelList.success`)}`,
      });
      return true;
    } catch (error) {
      console.error("Error saving hotel:", error);
      setSaveStatus({
        status: "error",
        message: error.message || `${t(`Admin.hotelList.error`)}`,
      });
      setIsSending(false); // Set sending state back to false
      return false;
    } finally {
      setIsSending(false); // Set sending state back to false
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSending(true);
    if (type === "add") {
      const success = await postHotel(formData);

      if (success) {
        // Aguarda 2 segundos para mostrar o feedback antes de fechar
        setTimeout(() => {
          dispatch({
            type: "SET_GLOBAL_REFRESH",
            payload: state.globalRefresh + 1,
          });
          onCloseHotelModal();
        }, 2000);
      }
    } else if (type === "edit") {
      const success = await saveHotel(formData);

      if (success) {
        setTimeout(() => {
          dispatch({
            type: "SET_GLOBAL_REFRESH",
            payload: state.globalRefresh + 1,
          });
          onCloseHotelModal();
        }, 2000);
      }
    }
  };

  const refreshData = useCallback(async () => {
    try {
      setIsSending(true);
      const newData = await getAdminInitialData(); // sua chamada API

      dispatch({ type: "SET_HOTEL_INITIALDATA", payload: newData });
      // Atualiza os grupos disponíveis localmente
      setAvailableGroups(newData.groupHotels);
    } catch (error) {
      console.error("Erro ao atualizar dados:", error);
    } finally {
      setIsSending(false);
    }
  }, [dispatch]);

  useEffect(() => {
    if (type === "add") {
      setFormData({
        id: 0,
        hotelId: "",
        name1: "",
        name2: "",
        groupId: "",
        rooms: "",
        posQuantity: "",
        hotelCountry: "",
        license: "",
        licenseExpireDate: "",
        subscriptionKey: "",
        isActive: true,
        user: state.adminLoggedIn.username,
      });
    } else if (type === "edit") {
      setFormData({
        id: hotel.id,
        hotelId: hotel.hotelId,
        name1: hotel.name1,
        name2: hotel.name2,
        groupId: hotel.groupId,
        rooms: hotel.rooms,
        posQuantity: hotel.posQuantity,
        hotelCountry: hotel.hotelCountry,
        license: hotel.license,
        licenseExpireDate: hotel.licenseExpireDate,
        subscriptionKey: hotel.subscriptionKey,
        isActive: hotel.isActive,
        user: state.adminLoggedIn.username,
      });
    }
    setSaveStatus({
      status: null,
      message: "",
    });
  }, [isHotelModalOpen]);

  useEffect(() => {
    setAvailableGroups(state.adminInitialData?.groupHotels);
  }, [state.adminInitialData?.groupHotels]);

  return (
    <AnimatePresence>
      {isHotelModalOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.2 }}
          className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
        >
          <motion.div
            initial={{ scale: 0.95, opacity: 0, y: 20 }}
            animate={{ scale: 1, opacity: 1, y: 0 }}
            exit={{ scale: 0.95, opacity: 0, y: 20 }}
            transition={{
              duration: 0.2,
              ease: "easeOut",
            }}
            className="bg-white rounded-lg shadow-xl w-full max-w-md max-h-[90vh] overflow-scroll"
            onClick={(e) => e.stopPropagation()}
          >
            <motion.div
              className="flex items-center justify-between p-4 border-b"
              initial={{ y: -10, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ delay: 0.1 }}
            >
              <h2 className="host-title-modal">{title}</h2>
              <motion.button
                onClick={onCloseHotelModal}
                className="p-1 hover:bg-gray-100 rounded-full"
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
              >
                <CloseCircleOutlined className="w-5 h-5 items-center justify-center" />
              </motion.button>
            </motion.div>

            <motion.div
              className="px-4 py-2"
              initial={{ y: 10, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ delay: 0.15 }}
            >
              <>
                <form onSubmit={handleSubmit} className="space-y-4">
                <div>
                  <div className="flex justify-between items-end">
                    <label className="block host-text-modal">
                      {`${t(`Admin.hotelList.subscriptionKey`)}`}
                    </label>
                    <button
                        type="button"
                        onClick={() => updateHotelInfo()}
                        className={`${formData.subscriptionKey==="" || formData.subscriptionKey===null || formData.subscriptionKey===undefined ? "host-grid-button-disabled !text-[var(--host-r24-border-color)]" :"host-grid-button" } mt-1 inline-flex items-center !border-[var(--host-r24-border-color)]`}
                        disabled={formData.subscriptionKey==="" || formData.subscriptionKey===null || formData.subscriptionKey===undefined || isLoading}
                      >
                        {isLoading ? <Loader /> : <CloudDownloadIcon className="w-5 h-5 items-center justify-center" />}
                      </button>
                  </div>
                    <input
                      type="text"
                      name="subscriptionKey"
                      value={formData.subscriptionKey || ""} 
                      onChange={handleChange}
                      className="mt-1 block w-full host-search-input"
                      required
                    />
                  </div>
                  <div>
                    <label className="block host-text-modal">
                      {`${t(`Admin.hotelList.groupId`)}`}
                    </label>
                    <div className="flex gap-2">
                      <Select
                        classNamePrefix="select"
                        className="w-full mt-1"
                        styles={hostSelectCustomStylesModal}
                        isSearchable={true}
                        name="groupId"
                        placeholder={`${t(`Admin.hotelModal.selectGroup`)}`}
                        value={ availableGroups?.find( (group) => group.groupId === formData.groupId ) || null }
                        options={availableGroups}
                        getOptionValue={(group) => group.groupId}
                        getOptionLabel={(group) =>
                          `${group.description} (${group.groupId})`
                        }
                        onChange={handleSelectChange}
                        inputProps={{
                          style: { textIndent: "0", marginLeft: "0" },
                        }}
                      />
                      <button
                        type="button"
                        onClick={() => setIsGroupModalOpen(true)}
                        className="mt-1 inline-flex items-center host-grid-button !border-[var(--host-r24-border-color)]"
                      >
                        <PlusCircleOutlined className="w-5 h-5 items-center justify-center" />
                      </button>
                    </div>
                  </div>
                  <div>
                    <label className="block host-text-modal">
                      {`${t(`Admin.hotelList.platformId`)}`}
                    </label>
                    <input
                      disabled={type === "edit"}
                      type="text"
                      name="hotelId"
                      value={formData.hotelId}
                      onChange={handleChange}
                      className={`mt-1 block w-full host-search-input ${
                        type === "edit" ? "bg-gray-100" : ""
                      }`}
                      required
                    />
                  </div>

                  <div>
                    <label className="block host-text-modal">
                      {`${t(`Admin.hotelList.mainName`)}`}
                    </label>
                    <input
                      type="text"
                      name="name1"
                      value={formData.name1}
                      onChange={handleChange}
                      className="mt-1 block w-full host-search-input"
                      required
                    />
                  </div>

                  <div>
                    <label className="block host-text-modal">
                      {`${t(`Admin.hotelList.secondaryName`)}`}
                    </label>
                    <input
                      type="text"
                      name="name2"
                      value={formData.name2}
                      onChange={handleChange}
                      className="mt-1 block w-full host-search-input"
                    />
                  </div>

                  <div className="grid grid-cols-2 gap-2">
                  <div>
                    <label className="block host-text-modal">
                      {`${t(`Admin.hotelList.rooms`)}`}
                    </label>
                    <input
                      type="text"
                      name="rooms"
                      value={formData.rooms}
                      onChange={handleChange}
                      className="mt-1 block w-full host-search-input"
                      required
                    />
                  </div>
                  <div>
                    <label className="block host-text-modal">
                      {`${t(`Admin.hotelList.pos`)}`}
                    </label>
                    <input
                      type="text"
                      name="posQuantity"
                      value={formData.posQuantity}
                      onChange={handleChange}
                      className="mt-1 block w-full host-search-input"
                      required
                    />
                  </div>
                  </div>

                  <div className="grid grid-cols-2 gap-2">
                  <div>
                    <label className="block host-text-modal">
                      {`${t(`Admin.hotelList.country`)}`}
                    </label>
                    <input
                      type="text"
                      name="hotelCountry"
                      value={formData.hotelCountry}
                      onChange={handleChange}
                      className="mt-1 block w-full host-search-input"
                      required
                    />
                  </div>
                  <div>
                    <label className="block host-text-modal">
                      {`${t(`Admin.hotelList.licenseExpireDate`)}`}
                    </label>
                    <input
                      type="text"
                      name="licenseExpireDate"
                      value={formData.licenseExpireDate}
                      onChange={handleChange}
                      className="mt-1 block w-full host-search-input"
                      required
                    />
                  </div>
                  </div>

                  <div>
                    <label className="block host-text-modal">
                      {`${t(`Admin.hotelList.license`)}`}
                    </label>
                    <input
                      type="text"
                      name="license"
                      value={formData.license}
                      onChange={handleChange}
                      className="mt-1 block w-full host-search-input"
                    />
                  </div>

                  {type === "edit" && (
                    <div className="flex items-center">
                      <input
                        type="checkbox"
                        id="isActive"
                        name="isActive"
                        checked={formData.isActive}
                        onChange={handleChange}
                        className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                      />
                      <label
                        htmlFor="isActive"
                        className="ml-2 block host-text-modal"
                      >
                        {`${t(`Admin.hotelList.active`)}`}
                      </label>
                    </div>
                  )}

                  {/* Status Message */}
                  {saveStatus.status && (
                    <div
                      className={`p-3 rounded-md ${
                        saveStatus.status === "success"
                          ? "bg-green-50 text-green-700"
                          : "bg-red-50 text-red-700"
                      }`}
                    >
                      {saveStatus.message}
                    </div>
                  )}

                  <div className="flex justify-end space-x-3">
                    {isSending ? (
                      <button
                        type="submit"
                        className="px-4 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                      >
                        <Loader />
                      </button>
                    ) : (
                      <button
                        type="submit"
                        disabled={saveStatus.status === "error" || isSending}
                        className="px-4 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                      >
                        <SaveOutlined className="w-5 h-5 items-center justify-center" />
                      </button>
                    )}
                  </div>
                </form>
                <GroupHotelModal
                  onSuccess={refreshData}
                  isGroupModalOpen={isGroupModalOpen}
                  onCloseGroupModal={() => setIsGroupModalOpen(false)}
                  title={`${t(`Admin.groupHotelList.newHotelGroup`)}`}
                  type={"add"}
                  groupHotel={null}
                />
              </>
            </motion.div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};
