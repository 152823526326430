import { useContext, useEffect, useState } from 'react';
import { Sidebar } from './Components/Layout/Sidebar';
import { Outlet } from 'react-router-dom';
import { Header } from './Components/Layout/Header';
import { getAdminInitialData } from '../services';
import { AppContext } from '../context/AppContext';
import { Loader } from '../components/Layout/components/Loader/Loader';
import { useTranslation } from 'react-i18next';
import { sidebarCategoriesList } from './Components/utils/sidebarCategoriesList';
import { AdminFrame } from './AdminFrame';

// Main Admin Panel Component
export const AdminPanel = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [loading, setLoading] = useState(false);
  const [activeSection, setActiveSection] = useState('dashboard');
  const [expandedCategories, setExpandedCategories] = useState(['']);
  const [state, dispatch] = useContext(AppContext);
  const [t] = useTranslation("global");
  const sidebarCategories = sidebarCategoriesList(t);

  const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);

  const toggleCategory = (category) => {
    setActiveSection(category)
    setExpandedCategories(prev =>
      prev.includes(category)
        ? prev.filter(c => c !== category)
        : [...prev, category]
    );
  };

  const fetchAdminData = async () => {
    try {
      setLoading(true);
      const response = await getAdminInitialData()
      dispatch({ type: "SET_ADMIN_INITIALDATA", payload: response});

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();

    } catch (err) {

    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAdminData();
  }, [state.globalRefresh, state.adminInitialData===null]);



  return (
    <div className="flex h-screen bg-[var(--host-r24-border-color-2)]">
      <Sidebar isOpen={isSidebarOpen} categories={sidebarCategories} activeSection={activeSection} expandedCategories={expandedCategories} onToggle={toggleSidebar} onCategoryClick={setActiveSection} onToggleCategory={toggleCategory} />
      <div className="flex flex-col flex-1 overflow-hidden">
        <Header title={activeSection.includes('.') ? sidebarCategories.find(c => c.name === activeSection.split('.')[0])?.label : sidebarCategories.find(c => c.name === activeSection)?.label } />
        {loading ?
        (<div className="flex justify-center items-center h-screen"><Loader className={`w-20 h-20`}/></div>)
        :
        (<main className="h-full mx-3 mb-3">
          <AdminFrame activeSection={activeSection}>
            <Outlet />
          </AdminFrame>
        </main>)
}
      </div>
    </div>
  );
};

