import { useTranslation } from "react-i18next";
import { Phone } from "./FormFields/Phone";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../context/AppContext";

/**
 * @module SubmissionForm
 * @description Renders a submission form for table reservations, allowing
 * users to input their reservation details, including guest information,
 * and contact details. This form validates inputs and uses the provided
 * handler function to submit the data.
 *
 * @param {Object} props - The component properties.
 * @param {Array} props.mainDialCodes - Array of main dialing codes for phone
 * number input.
 * @param {function} props.handleSubmit - Function to handle the form submission.
 * @param {Object} props.fadeVariants - Animation variants for fade effects.
 * @param {number} props.people - Number of guests for the reservation.
 * @param {Date} props.date - Selected reservation date.
 * @param {string} props.time - Selected reservation time.
 * @param {Object} props.formData - Object containing form data fields.
 * @param {function} props.setFormData - Function to update the form data state.
 * @param {function} props.getEndTime - Function to calculate the end time
 * based on the start time.
 * @param {Object} props.service - Object containing details of the service.
 * @param {string} props.title - Title to represent the reservation.
 * @param {function} props.setTitle - Function to update the title state.
 *
 * @returns {JSX.Element} The submission form component for reservations.
 *
 * @example
 * <SubmissionForm
 *   mainDialCodes={dialCodes}
 *   handleSubmit={submitHandler}
 *   fadeVariants={variants}
 *   people={4}
 *   date={new Date()}
 *   time="18:00"
 *   formData={formData}
 *   setFormData={setFormData}
 *   getEndTime={getEndTime}
 *   service={serviceDetails}
 *   title={reservationTitle}
 *   setTitle={setReservationTitle}
 * />
 *
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @version 1.0.0
 */
export const SubmissionForm = ({ handleDoubleClick, mainDialCodes, people, date, time, formData, setFormData, getEndTime, service, title, setTitle, initialData, TRFormFieldRef, handleElementEnter, handleElementLeave, isPreviewMode, isHovered }) => {
  const [t] = useTranslation("global");
  const [labelIsActive, setLabelIsActive] = useState(null);
  const [placeholderIsActive, setPlaceholderIsActive] = useState(null);
  const [state, dispatch] = useContext(AppContext);

  useEffect(() => {
    setLabelIsActive(
      state.activeTheme.find(
        (style) =>
          style.themeStyleCode === "TR-FormField-label_is_active" &&
          style.isActive
      ).value
    );
    setPlaceholderIsActive(
      state.activeTheme.find(
        (style) =>
          style.themeStyleCode === "TR-FormField-placeholder_is_active" &&
          style.isActive
      ).value
    );
  }, [state.activeTheme]);

  return (
    <>
      {" "}
      {service && mainDialCodes && state.activeTheme && (
        <>
          <h2 className="TR-Form-title_color TR-Form-title_size TR-Form-title_font_family TR-Form-title_variant mb-1 mt-4 dark:text-white">{`${t(
            `SubmissionForm.reservationdetails`
          )}`}</h2>
          <div className="TR-Form-text_color TR-Form-text_size TR-Form-text_font_family TR-Form-text_variant dark:text-white">
            <p>{`${people} ${t( `SubmissionForm.guests` )} | ${date.toLocaleDateString("pt-PT", { weekday: "long", day: "2-digit", month: "2-digit", })} | ${time}`}</p>
          </div>
          <div className={`${isPreviewMode && isHovered === "TR-FormField" ? "diagonal-stripes" : ""} max-w-full`} id={`TR-FormField`} ref={TRFormFieldRef} onDoubleClick={(e) => handleDoubleClick(e, TRFormFieldRef)} onMouseOver={(e) => handleElementEnter(e, TRFormFieldRef, "TR-FormField")} onMouseLeave={(e) => handleElementLeave(e, TRFormFieldRef, "TR-FormField")}>
            <div className="sm:flex sm:flex-wrap sm:space-x-2 max-w-full">
              <div className={`sm:w-[calc(50%-0.25rem)]`}>
                {(labelIsActive === "true" || labelIsActive === null) && (

                  <label
                    required={true}
                    className={`TR-FormField-label_location TR-FormField-label_color TR-FormField-label_size TR-FormField-label_font_family TR-FormField-label_variant TR-Form-bg_color pl-2  rounded-xl inline-block text-center justify-center dark:bg-gxp_dark_3 dark:text-white mb-1`}
                  >
                    {`${t(`FirstName.firstname`)}`}
                    <span className="dark:text-white ml-2">
                      {`( * )\u00A0`}
                    </span>
                  </label>
                )}
                <input
                  name="firstName"
                  onChange={(e) =>
                    setFormData({ ...formData, FirstName: e.target.value })
                  }
                  value={formData.FirstName}
                  type="text"
                  id="nome"
                  required={true}
                  className={`TR-FormField-text_color TR-FormField-text_size TR-FormField-text_font_family TR-FormField-text_variant TR-FormField-bg_color TR-FormField-border_rounded TR-FormField-border_color TR-FormField-border_position TR-FormField-border_width dark:bg-gxp_dark_3 dark:text-white w-full px-4 py-2 h-[42px] focus:ring-2 focus:border-blue-500 transition duration-150 ease-in-out TR-FormField-placeholder_color TR-FormField-placeholder_font_family TR-FormField-placeholder_variant`}
                  placeholder={
                    placeholderIsActive === "true" ||
                    placeholderIsActive === null
                      ? `${t(`FirstName.firstname`)} ${`( * )\u00A0`}`
                      : ""
                  }
                />
              </div>
              <div className="sm:w-[calc(50%-0.25rem)]">

              {(labelIsActive === "true" || labelIsActive === null) && (
                  <label
                    required={true}
                    className={`TR-FormField-label_location TR-FormField-label_color TR-FormField-label_size TR-FormField-label_font_family TR-FormField-label_variant TR-Form-bg_color pl-2  rounded-xl inline-block text-center justify-center dark:bg-gxp_dark_3 dark:text-white mb-1`}
                  >
                    {`${t(`LastName.lastname`)}`}
                    <span className="dark:text-white ml-2">
                      {`( * )\u00A0`}
                    </span>

                  </label>
                )}
                <input
                  name="lastName"
                  onChange={(e) =>
                    setFormData({ ...formData, LastName: e.target.value })
                  }
                  value={formData.LastName}
                  type="text"
                  id="lastName"
                  required={true}


                  className={`TR-FormField-text_color TR-FormField-text_size TR-FormField-text_font_family TR-FormField-text_variant TR-FormField-border_color TR-FormField-bg_color TR-FormField-border_rounded TR-FormField-border_position TR-FormField-border_width TR-FormField-placeholder_color TR-FormField-placeholder_font_family TR-FormField-placeholder_variant dark:bg-gxp_dark_3 dark:text-white w-full px-4 py-2 h-[42px] focus:ring-2 focus:border-blue-500 transition duration-150 ease-in-out `}
                  placeholder={
                    placeholderIsActive === "true" ||
                    placeholderIsActive === null
                      ? `${t(`LastName.lastname`)} ${`( * )\u00A0`}`
                      : ""


                  }
                />
              </div>
            </div>
            <div>

            {(labelIsActive === "true" || labelIsActive === null) && (
                  <label
                    required={true}
                    className={`TR-FormField-label_location TR-FormField-label_color TR-FormField-label_size TR-FormField-label_font_family TR-FormField-label_variant TR-Form-bg_color pl-2  rounded-xl inline-block text-center justify-center dark:bg-gxp_dark_3 dark:text-white mb-1`}
                  >
                    {`${t(`Email.email`)}`}
                    <span className="dark:text-white ml-2">
                      {`( * )\u00A0`}
                    </span>

                  </label>
                )}
                <input
                  name="email"
                  onChange={(e) =>
                    setFormData({ ...formData, GuestEmail1: e.target.value })
                  }
                  value={formData.GuestEmail1}
                  type="email"
                  id="email"
                  required={true}

                  className={`TR-FormField-text_color TR-FormField-text_size TR-FormField-text_font_family TR-FormField-text_variant TR-FormField-bg_color TR-FormField-border_color TR-FormField-border_rounded TR-FormField-border_position TR-FormField-border_width dark:bg-gxp_dark_3 dark:text-white w-full px-4 py-2 h-[42px] focus:ring-2 focus:border-blue-500 transition duration-150 ease-in-out TR-FormField-placeholder_color TR-FormField-placeholder_font_family TR-FormField-placeholder_variant`}
                  placeholder={
                    placeholderIsActive === "true" ||
                    placeholderIsActive === null
                      ? `${t(`Email.email`)} ${`( * )\u00A0`}`
                      : ""
                  }
                />
            </div>
            <Phone
              mainDialCodes={mainDialCodes}
              formData={formData}
              setFormData={setFormData}
              initialData={initialData}
              required
            />
            <div>
              {(labelIsActive === "true" || labelIsActive === null) && (
              <label
                htmlFor="email"
                className="TR-FormField-label_location TR-FormField-label_color TR-FormField-label_size TR-FormField-label_font_family TR-FormField-label_variant TR-Form-bg_color pl-2 pr-2 rounded-xl inline-block translate-y-3.5 text-center justify-center translate-x-3 dark:bg-gxp_dark_3 dark:text-white mb-1"
              >
                {`${t(`FormFields.notes`)}`}
              </label>
              )}
              <textarea
                value={formData.Notes}

                onChange={(e) =>
                  setFormData({ ...formData, Notes: e.target.value })
                }
                className="TR-FormField-text_color TR-FormField-text_size TR-FormField-text_font_family TR-FormField-text_variant TR-FormField-border_color TR-FormField-bg_color TR-FormField-border_rounded TR-FormField-border_position TR-FormField-border_width TR-FormField-placeholder_color TR-FormField-placeholder_font_family TR-FormField-placeholder_variant w-full p-2 border h-24 resize-none max-sm:w-full dark:border-white dark:bg-gxp_dark_3 dark:text-white focus:ring-2 focus:border-blue-500 transition duration-150 ease-in-out"
              />
            </div>
          </div>
          <div className=" TR-FormField-text_color TR-FormField-text_size TR-FormField-text_font_family TR-FormField-text_variant mt-4 dark:text-white ">
            <p>
              <strong>{`${t(`ReservationComponent.duration`)}`}</strong>
              {` ${t(`ReservationComponent.yourtable`)} ${time} ${t(
                `ReservationComponent.to`
              )} ${getEndTime(time)}.`}
            </p>
          </div>
        </>
      )}
    </>
  );
};
