import { useTranslation } from "react-i18next";
import { AlertTriangle } from "lucide-react";
import { Select } from "antd";
import { ChevronDown } from "lucide-react";
import { useContext, useState } from "react";
import { AppContext } from "../../../../context/AppContext";
import { useEffect } from "react";
import { CheckFieldVisibility } from "../Utilities/CheckFieldVisibility";

/**
 * @namespace DocumentType
 * @memberof module:OnlineCheckIn
 * @description Component for managing document type selection.
 * Provides a searchable dropdown with available document types and validation.
 *
 * @component
 * @param {Object} props Component properties
 * @param {Function} props.handleGuestChange Function to update guest data
 * @param {Object} props.guest Current guest data
 * @param {Object} props.initialData Initial configuration with document types
 * @param {Array<Object>} props.initialData.GuestDocumentTypes List of available document types
 * @param {string} props.initialData.GuestDocumentTypes[].Id Document type identifier
 * @param {string} props.initialData.GuestDocumentTypes[].Description Document type description
 * @param {Array<Object>} props.requiredFields List of required fields
 * @param {string} props.requiredFields[].Field Field identifier
 * @param {boolean} props.requiredFields[].IsMandatory Indicates if field is mandatory
 * @param {string|boolean} props.error Error state for the field
 * @param {string} props.cS Custom styling parameter
 *
 * @returns {JSX.Element} Document type selection dropdown
 *
 * @example
 * <DocumentType
 *   handleGuestChange={(id, field, value) => updateGuest(id, field, value)}
 *   guest={{ id: 1, data: { DocType: "PASSPORT" }}}
 *   initialData={{
 *     GuestDocumentTypes: [
 *       { Id: "PASSPORT", Description: "Passport" },
 *       { Id: "IDCARD", Description: "ID Card" }
 *     ]
 *   }}
 *   requiredFields={[{ Field: "DocType", IsMandatory: true }]}
 *   error={false}
 *   cS="1"
 * />
 *
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @since 1.0.0
 * @version 1.0.0
 */
export const DocumentType = ({ handleGuestChange, guest, initialData, requiredFields, error, guestFieldsGXP }) => {
  const [t] = useTranslation("global");
  const [isOpen, setIsOpen] = useState(false);
  const [state, dispatch] = useContext(AppContext);
  const [labelIsActive, setLabelIsActive] = useState(null);
  const [placeholderIsActive, setPlaceholderIsActive] = useState(null);

  const field = guestFieldsGXP?.find(field => field.Code === "PASSTYPE");

  const { isVisible, isMandatory } = CheckFieldVisibility(field,guest,state);

  /**
 * @function useEffectThemeStyles
 * @memberof module:OnlineCheckIn.DocumentType
 * @description Effect that manages label and placeholder visibility based on theme settings.
 *
 * @effect Updates labelIsActive and placeholderIsActive states
 * @dependencies [state.activeTheme]
 *
 * @example
 * useEffect(() => {
 *   setLabelIsActive(state.activeTheme.find(style =>
 *     style.themeStyleCode === "OC-FormField-label_is_active" &&
 *     style.isActive
 *   ).value);
 * }, [state.activeTheme]);
 *
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @since 1.0.0
 * @version 1.0.0
 */
  useEffect(() => {
    setLabelIsActive( state.activeTheme.find( (style) => style.themeStyleCode === "OC-FormField-label_is_active" && style.isActive ).value );
    setPlaceholderIsActive( state.activeTheme.find( (style) => style.themeStyleCode === "OC-FormField-placeholder_is_active" && style.isActive ).value );
  }, [state.activeTheme]);

/**
 * @function isRequired
 * @memberof module:OnlineCheckIn.DocumentType
 * @description Determines if the document type field is required based on validation rules.
 *
 * @type {boolean}
 *
 * @example
 * const isRequired = requiredFields.some(
 *   field => field.Field === "DocType" && field.IsMandatory
 * );
 *
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @since 1.0.0
 * @version 1.0.0
 */
  const isRequired = isMandatory;

  return (
    <div className="flex flex-col w-full h-full ">
      <div className="w-full h-full justify-end flex flex-col dark:text-white relative">
      {isVisible && (labelIsActive === "true" || labelIsActive === null) && (
          <label className={`z-10 w-fit OC-FormField-label_location OC-FormField-label_color OC-FormField-label_size OC-FormField-label_font_family OC-FormField-label_variant OC-Form-bg_color pl-2  rounded-xl inline-block text-center justify-center dark:bg-gxp_dark_3 dark:text-white mb-1`} >
            <span className="dark:text-white text-red-600">
              {isRequired ? ` * \u00A0` : ``}
            </span>
            {`${t(`DocumentType.documenttype`)}`}
          </label>
        )}
        <Select
          suffixIcon={ <ChevronDown className={`OC-FormField-icon_color dark:text-white cursor-pointer transition-transform duration-300 ease-in-out ${ isOpen ? "rotate-180" : "" }`} /> }
          onDropdownVisibleChange={(open) => setIsOpen(open)}
          className={`OC-FormField-text_color OC-FormField-text_size OC-FormField-text_font_family OC-FormField-text_variant OC-FormField-bg_color OC-FormField-border_rounded OC-FormField-border_position OC-FormField-border_width dark:bg-gxp_dark_3 dark:text-white w-full px-2 py-2 h-[42px] ${error ? 'border-red-500' : 'OC-FormField-border_color dark:border-white'} focus:ring-2 focus:border-blue-500 transition duration-150 ease-in-out`}
          onChange={(selected) => handleGuestChange(guest.id, "DocType", selected.value) }
          placeholder={ <span className="dark:text-gray-400"> {placeholderIsActive === "true" ? `${t(`DocumentType.documenttype`)} ${isRequired ? `( * )\u00A0` : ``}` : `${t(`DocumentType.select`)}`} </span> }
          optionLabelProp="label"
          popupClassName={`dark:dark`}
          showSearch
          dropdownStyle={{ minWidth: "260px" }}
          labelInValue
          value={ guest.data.DocType ? { value: guest.data.DocType, label: initialData.GuestDocumentTypes.find( (doc) => doc.Id === guest.data.DocType )?.Description, } : undefined }
          filterOption={(input, option) => { return option.children.toLowerCase().includes(input.toLowerCase()); }}
          virtual={false}
        >
          {initialData.GuestDocumentTypes.map((document) => (
            <Select.Option key={document.Id} value={document.Id} label={document.Description} className={`dark:dark`} >
              {document.Description}
            </Select.Option>
          ))}
        </Select>
        {error && (
          <div className="absolute left-0 bottom-0 transform translate-y-[calc(100%+4px)] flex items-center z-10 w-full">
            <AlertTriangle className="text-red-500 mr-1" size={16} />
            <span className="text-red-500 text-sm">{`${t(`HostForm.fieldRequired`)}`}</span>
          </div>
        )}        </div>
    </div>
  );
};
