import { useTranslation } from "react-i18next";
import { AlertTriangle, CalendarIcon } from "lucide-react";
import { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../../../../context/AppContext";
import { CheckFieldVisibility } from "../Utilities/CheckFieldVisibility";

export const BirthDate = ({ handleGuestChange, guest, requiredFields, error, guestFieldsGXP }) => {
  const [t] = useTranslation("global");
  const inputRef = useRef(null);
  const [state, dispatch] = useContext(AppContext);
  const [labelIsActive, setLabelIsActive] = useState(null);
  const [placeholderIsActive, setPlaceholderIsActive] = useState(null);
  const [displayFormat, setDisplayFormat] = useState("text"); // Track whether we're displaying in text or date format

  const field = guestFieldsGXP?.find(field => field.Code === "BIRTHDATE");
  const { isVisible, isMandatory } = CheckFieldVisibility(field, guest, state);

  // Format date for display (when in text mode)
  const formatDate = (dateString) => {
    if (!dateString) return "";

    try {
      const date = new Date(dateString);

      // Check if date is valid before formatting
      if (isNaN(date.getTime())) return "";

      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();

      return `${day}/${month}/${year}`;
    } catch (e) {
      console.error("Error formatting date:", e);
      return "";
    }
  };

  // Convert DD/MM/YYYY to YYYY-MM-DD (for date input type)
  const formatDateForInput = (dateString) => {
    if (!dateString) return "";

    // If already in YYYY-MM-DD format
    if (/^\d{4}-\d{2}-\d{2}$/.test(dateString)) {
      return dateString;
    }

    // If in DD/MM/YYYY format
    const parts = dateString.split('/');
    if (parts.length === 3) {
      return `${parts[2]}-${parts[1]}-${parts[0]}`;
    }

    return dateString;
  };

  const handleIconClick = () => {
    setDisplayFormat("date");
    if (inputRef.current) {
      setTimeout(() => {
        inputRef.current.showPicker();
      }, 10);
    }
  };

  // Handle input change
  const handleDateChange = (e) => {
    handleGuestChange(guest.id, e.target.name, e.target.value);
  };

  // Handle focus - switch to date type
  const handleFocus = () => {
    setDisplayFormat("date");
  };

  // Handle blur - switch back to text format if empty
  const handleBlur = () => {
    if (!guest.data.BirthDate) {
      setDisplayFormat("text");
    }
  };

  useEffect(() => {
    setLabelIsActive(state.activeTheme.find(
      (style) => style.themeStyleCode === "OC-FormField-label_is_active" && style.isActive
    ).value);

    setPlaceholderIsActive(state.activeTheme.find(
      (style) => style.themeStyleCode === "OC-FormField-placeholder_is_active" && style.isActive
    ).value);
  }, [state.activeTheme]);

  const isRequired = isMandatory;

  // Determine the value to display based on format
  const displayValue = displayFormat === "text"
    ? formatDate(guest.data.BirthDate)
    : formatDateForInput(guest.data.BirthDate);

  return (
    <div className="flex flex-col relative">
      {isVisible && (labelIsActive === "true" || labelIsActive === null) && (
        <div className="flex justify-start mb-1">
          <label htmlFor="dataNascimento" className={`OC-FormField-label_location OC-FormField-label_color OC-FormField-label_size OC-FormField-label_font_family OC-FormField-label_variant OC-Form-bg_color pl-2 pr-2 rounded-xl inline-flex items-center dark:bg-gxp_dark_3 dark:text-white z-10`} >
          <span className="dark:text-white text-red-600">
              {isRequired ? ` * \u00A0` : ``}
            </span>
            {`${t(`Birthdate.birthdate`)}`}
          </label>
        </div>
      )}
      <div className="relative w-full">
        <input
          name="BirthDate"
          required={isRequired}
          onChange={handleDateChange}
          value={displayValue}
          type={displayFormat}
          id="dataNascimento"
          className={`OC-FormField-text_color OC-FormField-text_size OC-FormField-text_font_family OC-FormField-text_variant OC-FormField-bg_color OC-FormField-border_rounded OC-FormField-border_position OC-FormField-border_width dark:bg-gxp_dark_3 dark:text-white w-full px-4 py-2 h-[42px] ${
            error
              ? "border-red-500"
              : "OC-FormField-border_color dark:border-white"
          } focus:ring-2 focus:border-blue-500 transition duration-150 ease-in-out [&::-webkit-calendar-picker-indicator]:hidden  OC-FormField-placeholder_color OC-FormField-placeholder_font_family OC-FormField-placeholder_variant custom-placeholder`}
          placeholder={placeholderIsActive === "true" || placeholderIsActive === null
            ? `${t(`Birthdate.birthdate`)} ${isRequired ? `( * )\u00A0` : ``}`
            : ""}
          onFocus={handleFocus}
          onBlur={handleBlur}
          ref={inputRef}
        />
        <CalendarIcon
          className="absolute right-3 top-1/2 -translate-y-1/2 cursor-pointer OC-FormField-icon_color dark:text-white"
          onClick={handleIconClick}
        />
      </div>
      {error && (
        <div className="absolute left-0 bottom-0 transform translate-y-[calc(100%+4px)] flex items-center z-10 w-full">
          <AlertTriangle className="text-red-500 mr-1" size={16} />
          <span className="text-red-500 text-sm">{`${t(`HostForm.fieldRequired`)}`}</span>
        </div>
      )}
    </div>
  );
};