import React from "react";
import { sidebarCategoriesList } from "./Components/utils/sidebarCategoriesList";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

export const AdminFrame = ({ children, activeSection }) => {
  const [t] = useTranslation("global");
  const sidebarCategories = sidebarCategoriesList(t);
  const pathname = useLocation().pathname;

  function getActiveLabel(menuItems, activePath) {
    // Verifica se o activePath contém um ponto (indicando subcategoria)
    const parts = activePath.split(".");

    if (parts.length === 1) {
      // Caso seja apenas uma categoria principal
      const item = menuItems.find((item) => item.name === parts[0]);
      return item ? item.label : "";
    } else if (parts.length === 2) {
      // Caso seja uma subcategoria (formato: "categoria.subcategoria")
      const mainCategory = menuItems.find((item) => item.name === parts[0] );

      if (mainCategory && mainCategory.subCategories) {
        const subCategory = mainCategory.subCategories.find(
          (subItem) => subItem.name === parts[1]
        );
        return subCategory ? subCategory.label : "";
      }
    }

    return ""; // Retorna string vazia se não encontrar
  }

  const title = getActiveLabel(sidebarCategories, activeSection);

  return (
    <div className="flex flex-col h-full bg-white rounded-xl max-h-[calc(100vh-75px)]">
      <div className="flex flex-col justify-center pl-[12px] border-b w-full h-[54px] overflow-hidden min-h-[54px] max-h-[54px]">
        <h2 className="host-frame-title">{title}</h2>
      </div>
      {children}
    </div>
  );
};
