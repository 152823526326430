import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../context/AppContext";
import { Check, Edit, Plus, Search, X } from "lucide-react";
import { ParameterDescriptionModal } from "../Components/Modal/ParameterDescriptionModal";
import { useTranslation } from "react-i18next";
import { EditOutlined, PlusCircleOutlined } from "@ant-design/icons";

//  COMPONENTE UTILIZADO TANTO PARA O ADMIN PANEL COMO PARA O HOTELPANEL
export const Parameters = () => {
  const [isParameterDescriptionModalOpen, setIsParameterDescriptionModalOpen] =
    useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [state, dispatch] = useContext(AppContext);
  const [parameterDescriptions, setParameterDescriptions] = useState([]);
  const [type, setType] = useState("");
  const [parameterDescription, setParameterDescription] = useState(null);
  const [t] = useTranslation("global");
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    setParameterDescriptions(state.adminInitialData?.parameterDescriptions);
  }, [state.adminInitialData]);

  const filteredParameterDescriptions = parameterDescriptions?.filter(
    (parameter) =>
      parameter.paramCode.toLowerCase().includes(searchTerm.toLowerCase()) ||
      parameter.paramDescription
        .toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
      parameter.id.toString().includes(searchTerm)
  );

  return (
    <div className="px-6 py-4">
      <div className="flex justify-end items-center mb-4">
        {/* <h2 className="host-title !text-xl">{`${t( `Admin.AdminPanel.parameters` )}`}</h2> */}
        <div className="flex space-x-4 w-full justify-end bg-[var(--host-r24-sec-bg-color)] p-2 rounded-md">
          <div className="relative">
            <input
              type="text"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder={`${t(`freesearch`)}`}
              className="!pl-8 pr-4 py-2 host-search-input"
            />
            <Search className="w-4 h-4 text-gray-400 absolute left-3 top-1.5" />
          </div>
          <button
            className="flex items-center host-button-primary"
            onClick={() => {
              setModalTitle(`${t(`Admin.parameters.newParameter`)}`);
              setType("add");
              setIsParameterDescriptionModalOpen(true);
            }}
          >
            <PlusCircleOutlined className="w-5 h-5 items-center justify-center" />
          </button>
        </div>
      </div>

      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200 host-grid border">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left tracking-wider">
                {`${t(`Admin.parameters.id`)}`}
              </th>
              <th className="px-6 py-3 text-left tracking-wider">
                {`${t(`Admin.parameters.code`)}`}
              </th>
              <th className="px-6 py-3 text-left tracking-wider">
                {`${t(`Admin.parameters.description`)}`}
              </th>
              <th className="px-6 py-3 text-left tracking-wider">
                {`${t(`Admin.parameters.defaultValue`)}`}
              </th>
              <th className="px-6 py-3 text-left tracking-wider">
                {`${t(`Admin.parameters.type`)}`}
              </th>
              <th className="px-6 py-3 text-left tracking-wider">
                {`${t(`Admin.parameters.listValues`)}`}
              </th>
              <th className="px-6 py-3 text-center tracking-wider">
                {`${t(`Admin.parameters.active`)}`}
              </th>
              {state?.adminLoggedIn?.role === "Admin" && (
                <th className="px-6 py-3 text-center tracking-wider">
                  {`${t(`Admin.parameters.actions`)}`}
                </th>
              )}
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {filteredParameterDescriptions?.length > 0 ? (
              filteredParameterDescriptions.map((parameter, index) => (
                <tr
                  key={index}
                  className="hover:bg-gray-50 cursor-pointer"
                  onDoubleClick={() => {
                    setModalTitle(`${t(`Admin.parameters.editParameter`)}`);
                    setIsParameterDescriptionModalOpen(true);
                    setType("edit");
                    setParameterDescription(parameter);
                  }}
                >
                  <td className="px-6 py-4 whitespace-nowrap ">
                    {parameter.id}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap ">
                    {parameter.paramCode}
                  </td>
                  <td className="px-6 py-4 whitespace-normal ">
                    {parameter.paramDescription}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap ">
                    {parameter.defaultValue}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap ">
                    {parameter.valueType}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap ">
                    {parameter.valueList?.length > 0
                      ? parameter.valueList.join("; ")
                      : ""}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {
                      <div className="w-8 h-8 rounded-md flex items-center mx-auto justify-center bg-white border-2 border-gray-200">
                        {parameter.isActive ? (
                          <Check className="w-5 h-5 text-green-500" />
                        ) : (
                          <X className="w-5 h-5 text-red-500" />
                        )}
                      </div>
                    }
                  </td>
                  {state?.adminLoggedIn?.role === "Admin" && (
                    <td className="align-middle h-full">
                      <div className="flex items-center justify-center w-full h-full">
                        <button
                          type="button"
                          onClick={() => {
                            setModalTitle(
                              `${t(`Admin.parameters.editParameter`)}`
                            );
                            setIsParameterDescriptionModalOpen(true);
                            setType("edit");
                            setParameterDescription(parameter);
                          }}
                          className="host-grid-button transition-all duration-300 ease-in-out"
                        >
                          <EditOutlined style={{ fontSize: "15px" }} />
                        </button>
                      </div>
                    </td>
                  )}
                </tr>
              ))
            ) : (
              <tr>
                <td
                  colSpan={4}
                  className="px-6 py-4 whitespace-nowrap text-sm text-gray-900"
                >{`${t(`Admin.parameters.notfound`)}`}</td>
                <td
                  colSpan={4}
                  className="px-6 py-4 whitespace-nowrap text-sm text-gray-900"
                ></td>
                <td
                  colSpan={4}
                  className="px-6 py-4 whitespace-nowrap text-sm text-gray-900"
                ></td>
                <td
                  colSpan={4}
                  className="px-6 py-4 whitespace-nowrap text-sm text-gray-900"
                ></td>
                <td
                  colSpan={4}
                  className="px-6 py-4 whitespace-nowrap text-sm text-gray-900"
                ></td>
                <td
                  colSpan={4}
                  className="px-6 py-4 whitespace-nowrap text-sm text-gray-900"
                ></td>
                <td
                  colSpan={4}
                  className="px-6 py-4 whitespace-nowrap text-sm text-gray-900"
                ></td>
                {state?.adminLoggedIn?.role === "Admin" && (
                  <td
                    colSpan={4}
                    className="px-6 py-4 whitespace-nowrap text-sm text-gray-900"
                  ></td>
                )}
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {type && (
        <ParameterDescriptionModal
          isParameterDescriptionModalOpen={isParameterDescriptionModalOpen}
          onCloseParameterDescriptionModal={() =>
            setIsParameterDescriptionModalOpen(false)
          }
          title={modalTitle}
          type={type}
          parameterDescription={type === "add" ? null : parameterDescription}
        />
      )}
    </div>
  );
};
