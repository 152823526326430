/**
 * Organiza os campos do formulário por seção e ordem
 * @param {Array} guestFieldsGXP - Array de campos do GXP
 * @returns {Object} - Objeto com campos organizados por seção
 */
export const organizeFieldsBySection = (guestFieldsGXP) => {
    if (!guestFieldsGXP || !Array.isArray(guestFieldsGXP)) {
      return {};
    }

    // Inicializar objeto para armazenar campos por seção
    const sections = {};

    // Agrupar campos por seção
    guestFieldsGXP.forEach(field => {
      if (!sections[field.Section]) {
        sections[field.Section] = [];
      }
      sections[field.Section].push(field);
    });

    // Ordenar campos dentro de cada seção por SortOrder
    Object.keys(sections).forEach(section => {
      sections[section].sort((a, b) => a.SortOrder - b.SortOrder);
    });

    return sections;
  };

  /**
   * Retorna a ordem das seções para renderização
   * @returns {Array} - Array com a ordem das seções
   */
  export const getSectionOrder = () => [
    "Title",
    "Data",
    "Identification",
    "Contact",
    "Address",
    "Additional",
  ];

  export const getBillingCompanySection = () => [
    "Company Billing Data",
  ];

  export const getBillingIndividualSection = () => [
    "Individual Billing Data",
  ];

  /**
   * Mapeia o código do campo para o componente correto
   * @param {string} code - Código do campo
   * @returns {string} - Nome do componente
   */
  export const mapFieldToComponent = (code) => {
    const mapping = {
      "NAME2": "FirstName",
      "NAME1": "LastName",
      "BIRTHDATE": "BirthDate",
      "VATNUMBER": "Nif",
      "NATIONALITY": "Nationality",
      "SEX": "Gender",
      "PASSTYPE": "DocumentType",
      "DOCNUMBER": "DocumentNumber",
      "DocExpiryDate": "DocumentExpireDate",
      "ISSUEAUTHOR": "DocumentAuthor",
      "EMAIL1": "Email",
      "TELEPHONE1": "Phone",
      "MOBILEPHONE1": "Mobile",
      "TITLESHORT_ID": "ShortTitle",
      "TITLELONG_ID": "LongTitle",
      "TITLE_ID": "Title",
      "ADDRESS1": "Address1",
      "ZIPCODE": "ZipCode",
      "TOWN": "Town",
      "COUNTRY_ID": "Country",
      "ADDRESS2": "Address2",
      "ADDRESS3": "Address3",
      "ADDRESSSTATE": "AddressState",
      "REGION_ID": "Region",
      "VEHICLENUMBER": "VehicleNumber",
      "COMPANYVATNUMBER": "CompanyVatNumber",
      "COMPANYDESIGNATION": "CompanyDesignation",
      "COMPANYADDRESS": "CompanyAddress",
      "COMPANYZIPCODE": "CompanyZipCode",
      "COMPANYTOWN": "CompanyTown",
      "COMPANYCOUNTRY_ID": "CompanyCountry",
      "INDIVIDUALVATNUMBER": "IndividualVatNumber",
      "INDIVIDUALNAME1": "IndividualLastName",
      "INDIVIDUALNAME2": "IndividualFirstName",
      "INDIVIDUALADDRESS": "IndividualAddress",
      "INDIVIDUALZIPCODE": "IndividualZipCode",
      "INDIVIDUALTOWN": "IndividualTown",
      "INDIVIDUALCOUNTRY_ID": "IndividualCountry",
    };

    return mapping[code] || null;
  };