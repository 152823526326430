import { Info, ArrowBigLeftDash, ArrowBigRightIcon } from "lucide-react";
import { useState, useEffect, useCallback, useRef } from "react";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";

/**
 * @namespace GeneralTerms
 * @memberof module:OnlineCheckIn
 * @description Component for managing general terms and conditions agreements.
 * This component displays mandatory and optional agreements for guests,
 * allowing them to review and accept terms.
 *
 * @component
 * @param {Object} props - The properties passed to the component.
 * @param {function} props.setCanSubmit - Function to set if the form can be submitted.
 * @param {number} props.activeTab - The currently active tab index.
 * @param {boolean} props.showConsent - Flag to determine if the consent view is shown.
 * @param {boolean} props.showGeneralTerms - Flag to determine if general terms view is shown.
 * @param {function} props.previous - Function to navigate to the previous step.
 * @param {function} props.next - Function to navigate to the next step.
 * @param {Array<Object>} props.guests - An array of guest objects.
 * @param {function} props.setGuests - Function to update the guests' data.
 * @param {Object} props.initialData - The initial data for consent agreements.
 *
 * @returns {JSX.Element} The rendered general terms component.
 *
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @version 1.0.0
 * @since 1.0.0
 *
 * @example
 * // Example usage of the GeneralTerms component
 * <GeneralTerms
 *   setCanSubmit={setCanSubmitFunction}
 *   activeTab={0}
 *   showConsent={true}
 *   showGeneralTerms={false}
 *   previous={previousFunction}
 *   next={nextFunction}
 *   guests={guestsArray}
 *   setGuests={setGuestsFunction}
 *   initialData={initialDataObject}
 * />
 */
export const GeneralTerms = ({handleDoubleClick, OCButton2Ref, isHovered, handleElementEnter, handleElementLeave, isPreviewMode, setCanSubmit, activeTab, showConsent, showGeneralTerms, previous, next, guests, setGuests, initialData, }) => {
  const [allConsent, setAllConsent] = useState(false);
  const [visibleConsentIds, setVisibleConsentIds] = useState([]);
  const [generalTerms, setGeneralTerms] = useState([]);
  const [cookies, setCookie] = useCookies(["cultureCode"]); // eslint-disable-line
  const [t] = useTranslation("global");
  const OCGeneralTermRef = useRef(null);

  /**
   * @function toggleVisibility
   * @memberof module:OnlineCheckIn.GeneralTerms
   * @param {string} consentId - The unique identifier of the consent to toggle.
   *
   * @description Toggles the visibility of a specific consent's detailed description.
   * This function adds or removes the consentId from the list of visible consents.
   *
   * @returns {void}
   *
   * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @version 1.0.0
   * @since 1.0.0
   *
   * @example
   * toggleVisibility('consent123');
   */
  const toggleVisibility = (consentId) => {
    setVisibleConsentIds((prev) => {
      if (prev.includes(consentId)) {
        return prev.filter((id) => id !== consentId); // Remove the consentId if it is already visible
      } else {
        return [...prev, consentId]; // Adds the consentId to make it visible
      }
    });
  };

  /**
   * @function handleAllConsent
   * @memberof module:OnlineCheckIn.GeneralTerms
   *
   * @description Handles the agreement to all consents at once.
   * This function toggles the agreement status of all general terms for the guest
   * based on whether they have already agreed to them or not.
   *
   * @returns {void}
   *
   * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @version 1.0.0
   * @since 1.0.0
   *
   * @example
   * handleAllConsent();
   */
  const handleAllConsent = useCallback(() => {
    const allAgreed = generalTerms.every((consent) => consent.state); // Checks if all consents are accepted

    const updatedConsents = generalTerms.map((consent) => ({
      ...consent,
      state: !allAgreed, // Set all to true or false
    }));

    setGeneralTerms(updatedConsents); // Atualiza o estado geral dos consentimentos
  }, [generalTerms, setGeneralTerms]);

  /**
   * @function handleGuestChange
   * @memberof module:OnlineCheckIn.GeneralTerms
   * @param {string} consentId - The unique identifier of the consent being changed.
   * @param {boolean} isChecked - The new agreement status of the consent.
   *
   * @description Handles changes to individual guest consent agreements.
   * This function updates the specific consent for a guest based on their agreement.
   *
   * @returns {void}
   *
   * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @version 1.0.0
   * @since 1.0.0
   *
   * @example
   * handleGuestChange('consent123', true);
   */
  const handleGuestChange = useCallback(
    (consentId, isChecked) => {
      setGeneralTerms((prevTerms) =>
        prevTerms.map((term) => {
          if (term.consent.Id === consentId) {
            // Updates the specific consent state
            return { ...term, state: isChecked }; // Updates the 'state'
          }
          return term; // Returns the unmodified term
        })
      );
    },
    [setGeneralTerms]
  );

  /**
   * @function handlePrevious
   * @memberof module:OnlineCheckIn.GeneralTerms
   * @param {Event} e - The event object.
   *
   * @description Handles the previous button click event.
   * If in preview mode, it navigates to the previous step.
   * Otherwise, it navigates to the previous step without any additional checks.
   *
   * @returns {void}
   *
   * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @version 1.0.0
   * @since 1.0.0
   */
  const handlePrevious = (e) => {

    if (isPreviewMode) {
      handleElementLeave(e, OCButton2Ref, "OC-Button2");
      previous(e, 0)

      window.parent.postMessage({
        action: "OC-ConsentPage",
        debugInfo: 'Change to Consent Page'
      }, "*");

      return;
    }

    previous(e, 0)  };

 /**
   * @function useEffectAllConsent
   * @memberof module:OnlineCheckIn.GeneralTerms
   * @param {Array} generalTerms - Array of general terms agreements.
   *
   * @description Checks if all consents are accepted and updates the state for allConsent.
   * This effect runs whenever the generalTerms state changes and determines
   * if all consents have been agreed to by the guest.
   *
   * @effect Updates the allConsent state based on the agreement status of all general terms.
   * @dependencies [generalTerms]
   *
   * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @version 1.0.0
   * @since 1.0.0
   */
  useEffect(() => {
    // Checks if all consents are accepted
    const allAgreed = generalTerms.every((consent) => consent.state);
    setAllConsent(allAgreed);
  }, [generalTerms]); // Dependency on generalTerms

  /**
   * @function useEffectGeneralTerms
   * @memberof module:OnlineCheckIn.GeneralTerms
   * @param {Object} initialData - Initial data for consent agreements.
   *
   * @description Initializes general terms based on initial data for consents.
   * This effect runs when the component is mounted or when initialData changes.
   * It sets up the generalTerms state with the data received from the initialData prop.
   *
   * @effect Initializes the generalTerms state based on the initial data.
   * @dependencies [initialData]
   *
   * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @version 1.0.0
   * @since 1.0.0
   */
  useEffect(() => {
    if (initialData.DataConsents) {
      const termsArray = initialData.DataConsents.map((consent, index) => ({
        id: index,
        state: false,
        consent: consent,
      }));
      setGeneralTerms(termsArray);
    }
  }, [initialData]);

  /**
   * @function useEffectConsents
   * @memberof module:OnlineCheckIn.GeneralTerms
   * @param {Array} generalTerms - Array of general terms agreements.
   * @param {function} setGuests - Function to update the guests' data.
   *
   * @description Checks if all mandatory and general consents are accepted.
   * This effect runs whenever the generalTerms state changes.
   * If all mandatory consents are accepted, it updates the guests' state
   * and allows form submission.
   *
   * @effect Updates the guests' state and form submission status based on the agreement of mandatory consents.
   * @dependencies [generalTerms, setGuests]
   *
   * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @version 1.0.0
   * @since 1.0.0
   */
  useEffect(() => {
    // Checks if all mandatory and general consents are accepted
    const allMandatoryAgreed = generalTerms.every(
      (consent) =>
        consent.consent.IsGeneralTerms &&
        consent.consent.IsMandatory &&
        consent.state
    );

    // If all mandatory consents are accepted
    if (allMandatoryAgreed) {
      // Updates the guests' state with the new information
      setGuests((prevGuests) =>
        prevGuests.map((g) =>
          g.id === 0
            ? { ...g, data: { ...g.data, GeneralTermsAccepted: true } }
            : g
        )
      );
      setCanSubmit(true); // Allows the form to be submitted
    } else {
      setCanSubmit(false); // Disallows form submission
    }
  }, [generalTerms, setGuests]); // Dependências para o useEffect

  return (
    <div  className={` p-2 mx-auto w-full`}>
      <h2 className="OC-title_color OC-title_size OC-title_font_family OC-title_variant text-center mb-3 dark:text-white">{`${t(`Stepper.terms`)}`}</h2>
        <div id="OC-GeneralTerm" ref={OCGeneralTermRef} onDoubleClick={(e) => handleDoubleClick(e, OCGeneralTermRef)} onMouseOver={(e) => handleElementEnter(e, OCGeneralTermRef, "OC-GeneralTerm")} onMouseLeave={(e) => handleElementLeave(e, OCGeneralTermRef, "OC-GeneralTerm")} className={`${isPreviewMode && isHovered === "OC-GeneralTerm" ? "diagonal-stripes" : ""} dark:bg-gxp_dark_3 OC-GeneralTerm-bg_color OC-GeneralTerm-border_color OC-GeneralTerm-border_rounded OC-GeneralTerm-border_position OC-GeneralTerm-border_width max-w-2xl mx-auto px-6 py-4 w-full`}>
          <p className="OC-GeneralTerm-title_color OC-GeneralTerm-title_size OC-GeneralTerm-title_font_family OC-GeneralTerm-title_variant mb-6 dark:text-white">{`${t(`Consent.accept`)}`}</p>
          <div className="mb-6 w-full">
            <div className="flex items-center mb-4">
              <input type="checkbox" checked={allConsent} onChange={handleAllConsent} className="cursor-pointer mr-2" />
              <span className="OC-GeneralTerm-text_color OC-GeneralTerm-text_size OC-GeneralTerm-text_font_family OC-GeneralTerm-text_variant dark:text-white">{`${t(`Consent.agreeall`)}`}</span>
            </div>

            <div className="mb-4 w-full">
              <p className="OC-GeneralTerm-title_color OC-GeneralTerm-title_size OC-GeneralTerm-title_font_family OC-GeneralTerm-title_variant dark:text-white mb-2">{`${t(`Consent.mandatory`)}`}</p>
              <div className="flex items-center w-full">
                {generalTerms.length > 0 &&
                  generalTerms
                    .filter((consent) => consent.consent.IsGeneralTerms)
                    .filter((consent) => consent.consent.IsMandatory)
                    .map((consent, index) => (
                      <div key={index} className=" w-full">
                        <div className="flex items-center w-full">
                          <input
                            type="checkbox"
                            checked={consent.state}
                            onChange={(e) => handleGuestChange(consent.consent.Id, e.target.checked)}
                            className="cursor-pointer mr-2"
                          />
                          <div className="flex items-center justify-between w-full">
                            <span className="w-full OC-GeneralTerm-text_color OC-GeneralTerm-text_size OC-GeneralTerm-text_font_family OC-GeneralTerm-text_variant dark:text-white">
                              {consent.consent.TextTitle.find(
                                (title) => title.Culture === localStorage.getItem("userLanguage")
                              )?.TextPlain}
                            </span>
                            <Info
                              onClick={() => toggleVisibility(consent.Id)}
                              className="OC-GeneralTerm-icon_color dark:text-white ml-2 w-4 h-4 cursor-pointer"
                            />
                          </div>
                        </div>
                        {visibleConsentIds.includes(consent.Id) &&
                          consent.consent.TextLong.filter(
                            (text) => text.Culture === localStorage.getItem("userLanguage")
                          ).map((text, index) => (
                            <p key={index} className="OC-GeneralTerm-text_color OC-GeneralTerm-text_size OC-GeneralTerm-text_font_family OC-GeneralTerm-text_variant mt-2 dark:text-white"> {text.TextPlain} </p>
                          ))}
                      </div>
                    ))}
              </div>
            </div>

            {!generalTerms
              .filter((consent) => consent.consent.IsGeneralTerms)
              .filter((consent) => !consent.consent.IsMandatory) && (
              <div>
                <p className="OC-GeneralTerm-title_color OC-GeneralTerm-title_size OC-GeneralTerm-title_font_family OC-GeneralTerm-title_variant dark:text-white mb-2">{`${t(`Consent.optional`)}`}</p>
                <div className="flex items-center w-full">
                  {generalTerms && generalTerms
                      .filter((consent) => consent.consent.IsGeneralTerms)
                      .filter((consent) => !consent.consent.IsMandatory)
                      .map((consent) => (
                        <div key={consent.Id}>
                          <div className="flex items-center w-full">
                            <input type="checkbox" checked={consent.state} onChange={(e) => handleGuestChange( consent.consent.Id, e.target.checked ) } className="cursor-pointer mr-2" />
                              <div className="flex justify-between w-full">
                              {consent.consent.TextTitle.filter(
                                (title) => title.Culture === cookies.cultureCode
                              ).map((title, index) => (
                                <span key={index} className="OC-GeneralTerm-text_color OC-GeneralTerm-text_size OC-GeneralTerm-text_font_family OC-GeneralTerm-text_variant dark:text-white">{title.TextPlain}</span>
                              ))}
                              <Info onClick={() => toggleVisibility(consent.Id)} className="cursor-pointer OC-GeneralTerm-icon_color dark:text-white ml-2 w-4 h-4" />
                            </div>
                            </div>
                          {visibleConsentIds.includes(consent.Id) &&
                            consent.consent.TextLong.filter(
                              (text) => text.Culture === cookies.cultureCode
                            ).map((text, index) => (
                              <p key={index} className="OC-GeneralTerm-text_color OC-GeneralTerm-text_size OC-GeneralTerm-text_font_family OC-GeneralTerm-text_variant mt-2 dark:text-white"> {text.TextPlain} </p>
                            ))}
                        </div>
                      ))}
                </div>
              </div>
            )}
          </div>

          <div className="flex justify-between">
            <button id="OC-Button2" ref={OCButton2Ref} onDoubleClick={(e) => handleDoubleClick(e, OCButton2Ref)} onMouseOver={(e) => handleElementEnter(e, OCButton2Ref, "OC-Button2")} onMouseLeave={(e) => handleElementLeave(e, OCButton2Ref, "OC-Button2")} type="button" onClick={handlePrevious} className={`${isPreviewMode && isHovered === "OC-Button2" ? "diagonal-stripes" : ""} py-2 px-4 border dark:text-gxp_violet dark:border-gxp_violet dark:bg-white OC-Button2-bg_color OC-Button2-icon_color OC-Button2-border_color rounded`} >
               <ArrowBigLeftDash />
            </button>
          </div>
        </div>
    </div>
  );
};
