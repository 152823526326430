import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, FreeMode } from "swiper/modules";
import { SliderCardHotel } from "./SliderCardHotel";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../context/AppContext";
import { useMenuDataProcessor } from "../../../hooks/useMenuDataProcessor";
import "../../../index.css";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";

/**
 * @namespace SliderSection
 * @memberof module:HotelHome
 * @description Component responsible for displaying a sliding carousel of hotel services.
 * Manages responsive carousel display and menu data processing.
 *
 * @component
 * @param {Object} props - Component props
 * @param {string} props.posSection - Section position identifier for menu data processing
 *
 * @returns {JSX.Element} SliderSection component
 *
 * @example
 * <SliderSection posSection="SLIDER" />
 *
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @since 1.0.0
 * @version 1.0.0
 */
export const SliderSection = ({ posSection, isPreviewMode }) => {
  const [menuData, setMenuData] = useState([]);
  const [state] = useContext(AppContext);

  const processMenuData = useMenuDataProcessor(
    posSection,
    setMenuData,
    isPreviewMode
  );

  /**
   * @function useEffectProcessMenu
   * @memberof module:HotelHome.SliderSection
   * @description Effect that processes menu data when hotel menu or reservation changes.
   * Triggers menu data processing to populate the slider content.
   *
   * @effect Updates processed menu data
   * @dependencies [state.hotelMenu, state.reservation]
   *
   * @example
   * useEffect(() => {
   *   if (state.hotelMenu && state.reservation) {
   *     processMenuData();
   *   }
   * }, [state.hotelMenu, state.reservation]);
   *
   * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @since 1.0.0
   * @version 1.0.0
   */
  useEffect(() => {
    if (state.hotelMenu && state.reservation) {
      processMenuData();
    }
  }, [state.hotelMenu, state.reservation]);

  return (
    menuData && (
      <section className="flex flex-wrap justify-between pl-3 z-10">
        <div className="max-w-full">
          <Swiper
            spaceBetween={10}
            breakpoints={{
              320: { slidesPerView: 1.5, spaceBetween: 10 },
              640: { slidesPerView: 2.5, spaceBetween: 15 },
              768: { slidesPerView: 3.5, spaceBetween: 15 },
            }}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
              pauseOnMouseEnter: true,
            }}
            loop={true}
            modules={[FreeMode, Autoplay]}
            className="mySwiper"
          >
            {menuData.map((subItem, index) => (
              <SwiperSlide className="overflow-clip px-1 py-4" key={index}>
                <SliderCardHotel subItem={subItem} index={index} className />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </section>
    )
  );
};
