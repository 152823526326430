import { CheckFieldVisibility } from "./CheckFieldVisibility";

/**
 * Verifica se uma seção tem algum campo visível para o hóspede atual
 * @param {Array} sectionFields - Array de campos da seção
 * @param {Object} guest - Dados do hóspede
 * @returns {boolean} - True se a seção tiver pelo menos um campo visível
 */
export const sectionHasVisibleFields = (sectionFields, guest, state) => {
    if (!sectionFields || !Array.isArray(sectionFields) || sectionFields.length === 0) {
      return false;
    }

    return sectionFields.some(field => {
      const { isVisible } = CheckFieldVisibility(field,guest,state);
      return isVisible;
    });
  };