import { useContext, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { HostFormComplete } from "./components/HostFormComplete";
import { toast } from "react-toastify";
import { getHostProfile, getHotelPmsIdPrefix, searchReservation } from "../../services";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import { motion, AnimatePresence } from "framer-motion";
import { AppContext } from "../../context/AppContext";
import { updateI18nLanguage } from "../Settings/Language/ConfigureI18n";
import { Loader } from "../../components/Layout/components/Loader/Loader";
import { previewCurrentHotel, previewGroupHotels, previewReservation } from "./components/Utilities/previewModeData";

/**
* @module OnlineCheckIn
* @description Core module for managing online check-in process.
* Handles data loading, preview mode functionality, and form display.
*/

/**
* @namespace OnlineCheckIn
* @memberof module:OnlineCheckIn
* @description Component responsible for orchestrating the online check-in flow.
* Manages hotel data, guest profiles, and reservation information.
*
* @component
* @returns {JSX.Element} OnlineCheckIn component
*
* @example
* <OnlineCheckIn />
*
* @author Tiago Ferreira <tiago.ferreira@hhs.pt>
* @since 1.0.0
* @version 1.0.0
*/
export const OnlineCheckIn = () => {
  const { platformUid, detailId, resNo, sUid } = useParams();
  const [state,dispatch] = useContext(AppContext);
  const [cookies, setCookie] = useCookies([ "cultureCode", "GUid", "currentHotel", "sUid", "profileKey", "guest", "isProfileLoaded", ]);
  const [data, setData] = useState({ groupHotels: null, isLoading: true, isInitialized: false, profileLoaded: false, reservationLoaded: false });
  const [reservation, setReservation] = useState(null)
  const [t] = useTranslation("global");
  const { pathname } = useLocation();
  const showMenus = !pathname.includes("/checkindirect");
  const isPreviewMode = pathname.includes("/previewmode/");
  const [guestLanguage, setGuestLanguage]= useState(localStorage.getItem(`userLanguage`) || "pt-PT")

  /////////////////// UTILIZADO PARA O PREVIEW MODE ///////////////////

  const handleDoubleClick = (e, elementRef) => {
    e.stopPropagation();

    if (isPreviewMode && elementRef.current) {
      const current = elementRef.current;
      window.parent.postMessage({
        action: "doubleClick",
        elementId: current.id,
        debugInfo: 'Double click on element to expand category/section'
      }, "*");
    }
  };

  /**
 * @function handleMouseEnter
 * @memberof module:OnlineCheckIn.OnlineCheckIn
 * @description Manages mouse enter events in preview mode.
 * Sends element information to parent window.
 *
 * @param {Event} e - Mouse event object
 * @param {React.RefObject} elementRef - Reference to target element
 *
 * @example
 * handleMouseEnter(event, myRef)
 *
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @since 1.0.0
 * @version 1.0.0
 */
  const handleMouseEnter = (e, elementRef) => {
    e.stopPropagation();

    if (isPreviewMode && elementRef.current) {
      const current = elementRef.current;
      window.parent.postMessage({
        action: "hover",
        elementId: current.id,
        elementClass: current.className || '',
        elementTag: current.tagName || '',
        debugInfo: 'Hover on specific hoverable element'
      }, "*");
    }
  };

  /**
 * @function handleMouseLeave
 * @memberof module:OnlineCheckIn.OnlineCheckIn
 * @description Manages mouse leave events in preview mode.
 * Sends element exit information to parent window.
 *
 * @param {Event} e - Mouse event object
 * @param {React.RefObject} elementRef - Reference to target element
 *
 * @example
 * handleMouseLeave(event, myRef)
 *
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @since 1.0.0
 * @version 1.0.0
 */
  const handleMouseLeave = (e, elementRef) => {

    if (isPreviewMode && elementRef.current) {
      const current = elementRef.current;
      window.parent.postMessage({
        action: "hoverOut",
        elementId: current.id,
        elementClass: current.className || '',
        elementTag: current.tagName || '',
        debugInfo: 'HoverOut from specific hoverable element'
      }, "*");
    }
  };

/**
 * @function useEffectThemeUpdate
 * @memberof module:OnlineCheckIn.OnlineCheckIn
 * @description Effect that registers theme update handler for preview mode.
 * Sets up global theme update function.
 *
 * @effect Sets up and cleans up theme update handler
 * @dependencies [dispatch]
 *
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @since 1.0.0
 * @version 1.0.0
 */
useEffect(() => {
    // Registrar a função de atualização globalmente
    window.__THEME_UPDATE__ = (themeStyleCode, value) => {
      console.log('Theme update function called:', { themeStyleCode, value });
      dispatch({
        type: 'UPDATE_ACTIVE_THEME_STYLE',
        payload: { themeStyleCode, value }
      });
    };

    return () => {
      delete window.__THEME_UPDATE__;
    };
  }, [dispatch]);

  //////////////////////////////////////////////////////////////////////////////////////

 /**
  * @function loadHotels
  * @memberof module:OnlineCheckIn.OnlineCheckIn
  * @description Fetches and sets hotel group data.
  * Identifies principal hotel and updates cookies.
  *
  * @async
  * @returns {Promise<void>}
  *
  * @example
  * await loadHotels()
  *
  * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
  * @since 1.0.0
  * @version 1.0.0
  */
  const loadHotels = async () => {
    if (!platformUid || !pathname.includes("checkindirect")) return;

    try {
      const hotelsData = await getHotelPmsIdPrefix(platformUid);
      setData(prev => ({ ...prev, groupHotels: hotelsData }));

      // Encontra e define o hotel principal
      for (const hotel of hotelsData) {
        const pmsIdPrefix = hotel.hotelId.slice(0, 8);
        if (pmsIdPrefix === platformUid) {
          setCookie("currentHotel", hotel, { path: "/" });
          dispatch({ type: "SET_CURRENT_HOTEL", payload: hotel });
          break;
        }
      }
      // Define sUid nos cookies se existir
      if (sUid) {
        setCookie("sUid", sUid, { path: "/" });
      }
    } catch (error) {
      console.error("Error loading hotels:", error);
      toast.error("Unable to get hotels!");
    }
  };

  /**
  * @function loadProfile
  * @memberof module:OnlineCheckIn.OnlineCheckIn
  * @description Fetches guest profile information.
  * Updates cookies with profile data.
  *
  * @async
  * @returns {Promise<void>}
  *
  * @example
  * await loadProfile()
  *
  * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
  * @since 1.0.0
  * @version 1.0.0
  */
  const loadProfile = async () => {
    if (!state.currentHotel?.subscriptionKey || !sUid || data.profileLoaded) return;

    try {
      const profileResult = await getHostProfile( sUid, state.currentHotel.subscriptionKey );

      setCookie("profileKey", profileResult.ProfileKey, { path: "/" });
      setCookie("GUid", profileResult.Uid, { path: "/" });
      setCookie("guest", JSON.stringify(profileResult), { path: "/" });
      setCookie("isProfileLoaded", true, { path: "/" });

      setData(prev => ({ ...prev, profileLoaded: true }));
    } catch (error) {
      console.error("Error loading profile:", error);
      toast.error(t("OnlineCheckIn.errorhost"));
    }
  };

  /**
  * @function loadReservation
  * @memberof module:OnlineCheckIn.OnlineCheckIn
  * @description Fetches reservation details.
  * Updates local state with reservation data.
  *
  * @async
  * @returns {Promise<void>}
  *
  * @example
  * await loadReservation()
  *
  * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
  * @since 1.0.0
  * @version 1.0.0
  */
  const loadReservation = async () => {

    if (!state.currentHotel || !cookies.GUid || !detailId || !resNo || data.reservationLoaded) return;

    try {
        const reservationResponse = await searchReservation( resNo, detailId, state.currentHotel, cookies.GUid, guestLanguage );
        setReservation(reservationResponse.Data?.Details[0])
        setData(prev => ({
          ...prev,
          reservationLoaded: true,
          isLoading: false
        }));
    } catch (error) {
      console.error("Error loading reservation:", error);
      toast.error(t("OnlineCheckIn.errorreservation"));
      setData(prev => ({ ...prev, isLoading: false }));
    } finally {
      setData(prev => ({
      ...prev,
      reservationLoaded: true,
      isLoading: false
    }));

    }
  };

/**
  * @function useEffectLoadHotels
  * @memberof module:OnlineCheckIn.OnlineCheckIn
  * @description Effect that manages initial hotel data loading.
  *
  * @effect Loads hotel data or sets preview data
  * @dependencies [platformUid]
  *
  * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
  * @since 1.0.0
  * @version 1.0.0
  */
useEffect(() => {
    if(isPreviewMode){
      setData(prev => ({ ...prev, groupHotels: previewGroupHotels }));
      setCookie("currentHotel", previewCurrentHotel, { path: "/" });
      dispatch({ type: "SET_CURRENT_HOTEL", payload: previewCurrentHotel });
      return
    }

    if(state.currentHotel?.hotelId?.substring(0,8)!==platformUid){
      loadHotels();
    }
  }, [platformUid]); // eslint-disable-line

/**
  * @function useEffectLoadProfile
  * @memberof module:OnlineCheckIn.OnlineCheckIn
  * @description Effect that manages guest profile loading.
  *
  * @effect Loads profile data or sets preview data
  * @dependencies [state.currentHotel?.subscriptionKey, sUid]
  *
  * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
  * @since 1.0.0
  * @version 1.0.0
  */
  useEffect(() => {
    if(isPreviewMode){
      setData(prev => ({ ...prev, profileLoaded: true }));
      return
    }
    if (state.currentHotel?.subscriptionKey && sUid && !data.profileLoaded) {
      loadProfile();
    }
  }, [state.currentHotel?.subscriptionKey, sUid]); // eslint-disable-line

 /**
  * @function useEffectLoadReservation
  * @memberof module:OnlineCheckIn.OnlineCheckIn
  * @description Effect that manages reservation data loading.
  *
  * @effect Loads reservation data or sets preview data
  * @dependencies [data.profileLoaded, state.currentHotel, guestLanguage, detailId, resNo]
  *
  * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
  * @since 1.0.0
  * @version 1.0.0
  */
  useEffect(() => {
    if(isPreviewMode){
      if(!reservation){
        setReservation(previewReservation)
      }
      setData(prev => ({
        ...prev,
        reservationLoaded: true,
        isLoading: false
      }));
      return
    }

    if (state.currentHotel && data.profileLoaded && !data.reservationLoaded && !reservation) {
      loadReservation();
    }
  }, [data.profileLoaded, state.currentHotel, guestLanguage, detailId, resNo]); // eslint-disable-line

  /**
 * @function useEffectI18n
 * @memberof module:OnlineCheckIn.OnlineCheckIn
 * @description Effect that initializes i18n language settings.
 *
 * @effect Updates i18n language configuration
 * @dependencies []
 *
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @since 1.0.0
 * @version 1.0.0
 */
  useEffect(() => {
    updateI18nLanguage(guestLanguage);
  }, [])

  /**
 * @function useEffectLanguage
 * @memberof module:OnlineCheckIn.OnlineCheckIn
 * @description Effect that manages language settings and storage.
 * Handles both preview mode and normal mode language configuration.
 *
 * @effect Updates language settings and storage
 * @dependencies [state.userLanguage]
 *
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @since 1.0.0
 * @version 1.0.0
 */
  useEffect(() => {
    if(isPreviewMode) {
      const language = localStorage.getItem('userLanguage');
      if(!language) {
        localStorage.setItem("userLanguage", "en-US");
      }
    }

    if(localStorage.getItem('userLanguage')) {
      setGuestLanguage(localStorage.getItem('userLanguage'));
    } else {
      localStorage.setItem("userLanguage", state?.hotelInitialData?.Hotel?.DefaultLanguage);
      setGuestLanguage(state?.hotelInitialData?.Hotel?.DefaultLanguage);
    }

  }, [state.userLanguage,state?.hotelInitialData]);

  return (
    (data.isLoading && !data.groupHotels && !data.reservationLoaded ? (
      <div className="flex justify-center items-center h-full w-full">
        <Loader className="h-20 w-20" />
      </div>) : (
      <AnimatePresence mode="wait">
      {state.currentHotel && guestLanguage && (
        <motion.div
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 10 }}
          transition={{
            type: "tween",
            ease: "easeOut",
            duration: 0.3
          }}
          className={`antialiased dark:gxp_dark_2`}
        >
          <section className={`flex-grow h-full w-full`}>
            <HostFormComplete
              data={data}
              showMenus={showMenus}
              hotel={state.currentHotel}
              reservation={reservation}
              setReservation={setReservation}
              cultureCode={guestLanguage}
              isPreviewMode={isPreviewMode}
              handleMouseEnter={handleMouseEnter}
              handleMouseLeave={handleMouseLeave}
              handleDoubleClick={handleDoubleClick}
            />
          </section>
        </motion.div>
      )}
    </AnimatePresence>
    )
    )
  );
};