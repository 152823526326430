import { useEffect, useState } from "react";
import { ReservationCard } from "./ReservationCard";
import { ChevronDown, MapPin } from "lucide-react";
import { useTranslation } from "react-i18next";

/**
* @namespace HotelGroup
* @memberof module:Home
* @description Component responsible for grouping and displaying hotel reservations.
* Manages expandable/collapsible hotel sections and their reservations.
* Supports preview mode with inspection capabilities.
*
* @component
* @param {Object} props - Component props
* @param {Array} props.reservations - Array of reservations grouped by hotel
* @param {boolean} props.isLoading - Loading state flag
* @param {Function} props.setIsLoading - Function to update loading state
* @param {boolean} props.isPreviewMode - Flag indicating if component is in preview mode
* @param {React.RefObject} props.HMCardRef - Reference for hotel card element
* @param {Function} props.handleElementEnter - Handler for mouse enter events
* @param {Function} props.handleElementLeave - Handler for mouse leave events
* @param {string|null} props.isHovered - ID of currently hovered element
*
* @returns {JSX.Element} HotelGroup component
*
* @example
* <HotelGroup
*   reservations={reservations}
*   isLoading={isLoading}
*   setIsLoading={setIsLoading}
*   isPreviewMode={isPreviewMode}
*   HMCardRef={HMCardRef}
*   handleElementEnter={handleElementEnter}
*   handleElementLeave={handleElementLeave}
*   isHovered={isHovered}
* />
*
* @author Tiago Ferreira <tiago.ferreira@hhs.pt>
* @since 1.0.0
* @version 1.0.0
*/
export const HotelGroup = ({ handleDoubleClick, reservations, isLoading, setIsLoading, isPreviewMode, HMCardRef, handleElementEnter, handleElementLeave, isHovered }) => {
  const [expandedState, setExpandedState] = useState({});
  const [t] = useTranslation("global");

  /**
  * @function useEffectInitializeExpandedState
  * @memberof module:Home.HotelGroup
  * @description Effect that initializes the expanded state for each hotel.
  * Sets all hotels to be initially expanded.
  *
  * @effect Updates expandedState for all hotels
  * @dependencies [reservations]
  *
  * @example
  * useEffect(() => {
  *   const initialState = reservations.reduce((acc, reservation) => {
  *     acc[reservation.hotel.id] = true;
  *     return acc;
  *   }, {});
  *   setExpandedState(initialState);
  * }, [reservations]);
  *
  * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
  * @since 1.0.0
  * @version 1.0.0
  */
  useEffect(() => {
    const initialState = reservations.reduce((acc, reservation) => {
      acc[reservation.hotel.id] = true; // Inicialmente, expandimos todos os hotéis
      return acc;
    }, {});
    setExpandedState(initialState);
  }, [reservations]);

  /**
  * @function toggleExpansion
  * @memberof module:Home.HotelGroup
  * @description Toggles the expansion state of a specific hotel section.
  * Updates the expandedState object for the given hotel ID.
  *
  * @param {string} hotelId - ID of the hotel to toggle
  *
  * @example
  * toggleExpansion("hotel123")
  *
  * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
  * @since 1.0.0
  * @version 1.0.0
  */
  const toggleExpansion = (hotelId) => {
    setExpandedState(prevState => ({
      ...prevState,
      [hotelId]: !prevState[hotelId]
    }))};

   return (
    <>
    { reservations.length===0 ?
      (<div className="flex items-center">
        <h3 className="HM-text_color HM-text_size HM-text_font_family HM-text_variant py-4 dark:text-white">{`${t("HotelGroup.requirement")}`}</h3>
      </div>)
      :
      (expandedState &&
        <div className="">
      {reservations.filter((reservation)=>reservation.reservations.length>0).map((reservation) => (
        <div key={reservation.hotel.id} className="">
          <div className="flex items-center justify-between rounded-t-lg cursor-pointer" onClick={() => toggleExpansion(reservation.hotel.id)} >
            <div className="flex items-center">
              <MapPin className="w-5 h-5 mr-2 HM-icon_color dark:text-white" />
              <h3 className="HM-text_color HM-text_size HM-text_font_family HM-text_variant py-2 dark:text-white">{reservation.hotel.name2}</h3>
            </div>
            <div className="flex items-center">
              <span className="mr-2 HM-text_color HM-text_size HM-text_font_family HM-text_variant dark:text-white"> {reservation.reservations.length} {`${t("HotelGroup.reservation")}`}{reservation.reservations.length !== 1 ? 's' : ''} </span>
              <ChevronDown className={`HM-icon_color transition-transform duration-200 dark:text-white ${ expandedState[reservation.hotel.id] ? "rotate-180" : "rotate-0" }`} />
            </div>
          </div>
          <div className={`rounded-b-lg transition-all duration-300 ease-in-out overflow-hidden ${expandedState[reservation.hotel.id] ? "opacity-100" : "max-h-0 opacity-0" }`} >
            <div className="">
              <ReservationCard handleDoubleClick={handleDoubleClick} HMCardRef={HMCardRef} handleElementEnter={handleElementEnter} handleElementLeave={handleElementLeave} isPreviewMode={isPreviewMode} isHovered={isHovered} reservations={reservation} isLoading={isLoading} setIsLoading={setIsLoading} />
            </div>
          </div>
        </div>
      ))}
    </div>
  )}
  </>
  );
};
