import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';

/**
 * @module GuestStep
 * @description A React component that allows users to select the number of guests for a reservation.
 * It displays buttons for each guest number, allowing the user to click and set the number of guests.
 * The component uses animations for transitions and integrates with the internationalization library.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {Object} props.fadeVariants - The animation variants used for fading transitions.
 * @param {function} props.setPeople - Function to update the number of guests selected.
 * @param {function} props.setStep - Function to change the current step in the booking process.
 * @param {number} props.people - The current number of guests selected.
 * @param {Object} props.formData - An object holding form data for the reservation.
 * @param {function} props.setFormData - Function to update the form data.
 *
 * @returns {JSX.Element} The rendered component with buttons for guest selection.
 *
 * @example
 * <GuestStep
 *   fadeVariants={fadeVariants}
 *   setPeople={setPeople}
 *   setStep={setStep}
 *   people={people}
 *   formData={formData}
 *   setFormData={setFormData}
 * />
 *
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @version 1.0.0
 */
export const GuestStep = ({handleDoubleClick, fadeVariants, setPeople, setStep, people, formData, setFormData, TRCardRef, handleElementEnter, handleElementLeave, isPreviewMode, isHovered }) => {
  const [t] = useTranslation("global");

  const next = (e, num) => {
    setPeople(num);
    setStep(2);
    setFormData({ ...formData, Pax: num });

    if(isPreviewMode){
      handleElementLeave(e, TRCardRef, "TR-Card");

      window.parent.postMessage({
        action: "TR-DatePage",
        debugInfo: 'Change to Date Page'
      }, "*");

      return;
    }

  };

  return (
    <motion.div variants={fadeVariants} initial="hidden" animate="visible" exit="exit" >
      <h2 className="TR-Form-title_color TR-Form-title_size TR-Form-title_font_family TR-Form-title_variant mb-4 mt-4 dark:text-white">{`${t(`GuestStep.howmanyguests`)}`}</h2>
      <div className="grid grid-cols-4 gap-4">
        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13].map((num) => (
          <button type='button' key={num} id={`TR-Card`} ref={TRCardRef} onDoubleClick={(e) => handleDoubleClick(e, TRCardRef)} onMouseOver={(e) => handleElementEnter(e, TRCardRef, "TR-Card")} onMouseLeave={(e) => handleElementLeave(e, TRCardRef, "TR-Card")} onClick={(e) => next(e, num)}
          className={` ${isPreviewMode && isHovered === "TR-Card" ? "diagonal-stripes" : ""} p-2 transition-transform duration-300 ease-in-out transform hover:scale-105  dark:hover:text-white dark:hover:bg-gxp_violet_dark dark:hover:border-gxp_violet TR-Card-bg_color TR-Card-border_rounded TR-Card-border_position TR-Card-border_width TR-Card-border_color TR-Card-hover_bg_color TR-Card-hover_border_color TR-Card-text_color TR-Card-text_size TR-Card-text_font_family TR-Card-text_variant ${ people === num ? 'TR-Card-active_color TR-Card-active_bg_color dark:text-white dark:border-gxp_violet dark:bg-gxp_violet_dark' : 'dark:text-gxp_dark_3' }`} >
            {num}
          </button>
        ))}
      </div>
    </motion.div>
  );
};
