import { useState, useEffect, useContext, useRef } from "react";
import { ReservationsDisplay } from "./Components/ReservationsDisplay";
import { useLocation, useParams } from "react-router-dom";
import { getDefaultPreviewInitialData, getHotelPmsIdPrefix } from "../../services";
import { useCookies } from "react-cookie";
import { AppContext } from "../../context/AppContext";

/**
 * @module Home
 * @description Core module for rendering the reservations Home page.
 * Manages the state of reservations and group hotels.
 * Includes functionality for a preview mode with inspection mode and interface element interactivity.
 */

/**
 * @namespace Home
 * @memberof module:Home
 * @description Main component responsible for rendering the reservations page.
 * Manages the state of reservations and group hotels.
 * Includes functionality for a preview mode with inspection mode and interface element interactivity.
 *
 * @component
 * @returns {JSX.Element} Home page component
 *
 * @example
 * <Home />
 *
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @since 1.0.0
 * @version 1.0.0
 */
export const Home = () => {

  const { platformUid } = useParams(); //primeiros 8 digitos correspondentes ao Guid do hotel
  const [principalHotel, setPrincipalHotel] = useState(); // eslint-disable-line
  const [groupHotels, setGroupHotels] = useState([]);
  const [reservations, setReservations] = useState([]);
  const [cookie, setCookie] = useCookies([ "platformUid", "sUid", "profileKey", "cultureCode" ]); // eslint-disable-line
  const [state,dispatch] = useContext(AppContext); // eslint-disable-line

  //////////// previewmode  ///////////////
  const { pathname } = useLocation();
  const isPreviewMode = pathname.includes("/previewmode/");
  const {defaultThemeId} = useParams()
  const [isHovered, setIsHovered] = useState();
  const [currentRef, setCurrentRef] = useState(null);
  const [inspectMode, setInspectMode] = useState(JSON.parse(localStorage.getItem("inspectMode"))==="true" ? true : false);
  const HMPageRef = useRef(null);
  const HMCardRef = useRef(null);

  const handleDoubleClick = (e, elementRef) => {
    e.stopPropagation();

    // Verifica se o inspectMode está ativo antes de enviar a mensagem
    if (isPreviewMode && inspectMode && elementRef.current) {
      const elementId = elementRef.current.id;

      console.log("elementId", elementRef.current.id);

      window.parent.postMessage({
        action: "doubleClick",
        elementId: elementId,
        debugInfo: 'Double click on element to expand category/section'
      }, "*");
    }
  };

    /**
   * @function useEffectFetchDefaultPreviewInitialData
   * @memberof module:Home.Home
   * @description Effect that loads the initial preview data for the default theme.
   * Fetches and sets the active theme when the component is in preview mode.
   *
   * @effect Updates the active theme in the global application state
   * @dependencies [defaultThemeId] - Executes when the default theme ID changes
   *
   * @example
   * useEffect(() => {
   *   if (isPreviewMode) {
   *     fetchDefaultPreviewInitialData()
   *   }
   * }, [defaultThemeId]);
   *
   * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @version 1.0.0
   */
  useEffect(() => {
    const fetchDefaultPreviewInitialData = async () => {
      try {
        const InitialData = await getDefaultPreviewInitialData(defaultThemeId);
        dispatch({ type: "SET_ACTIVE_THEME", payload: InitialData.activeTheme });
      } catch (error) {
        console.error("Error loading initialData-AllRoutes:", error);
      }
    }

    if (isPreviewMode) {
      fetchDefaultPreviewInitialData()
    }
  }, [defaultThemeId]); // eslint-disable-line

    /**
   * @function handleMouseEnter
   * @memberof module:Home.Home
   * @description Manages mouse enter events on elements during preview mode.
   * Sends messages to the parent component with information about the focused element.
   *
   * @param {Event} e - Mouse event
   * @param {React.RefObject} elementRef - React element reference
   *
   * @example
   * handleMouseEnter(event, myRef)
   *
   * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @since 1.0.0
   * @version 1.0.0
   */
  const handleMouseEnter = (e, elementRef) => {
    e.stopPropagation();

    if (isPreviewMode && elementRef.current) {
      const current = elementRef.current;
      window.parent.postMessage({
        action: "hover",
        elementId: current.id,
        elementClass: current.className || '',
        elementTag: current.tagName || '',
        debugInfo: 'Hover on specific hoverable element'
      }, "*");
    }
  };

  /**
 * @function handleMouseLeave
 * @memberof module:Home.Home
 * @description Handles mouse leave events for elements in preview mode.
 * Sends a message to the parent window with element details when hover is removed.
 *
 * @param {Event} e - Mouse event object
 * @param {React.RefObject} elementRef - Reference to the DOM element
 *
 * @example
 * handleMouseLeave(event, myElementRef)
 *
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @since 1.0.0
   * @version 1.0.0
   */
  const handleMouseLeave = (e, elementRef) => {

    if (isPreviewMode && elementRef.current) {
      const current = elementRef.current;
      window.parent.postMessage({
        action: "hoverOut",
        elementId: current.id,
        elementClass: current.className || '',
        elementTag: current.tagName || '',
        debugInfo: 'HoverOut from specific hoverable element'
      }, "*");
    }
  };

  /**
 * @function handleElementEnter
 * @memberof module:Home.Home
 * @description Manages element hover state entry in preview mode.
 * Handles state transitions between different hovered elements and triggers
 * appropriate mouse enter events.
 *
 * @param {Event} e - Mouse event object
 * @param {React.RefObject} ref - Reference to the target DOM element
 * @param {string} id - Unique identifier for the element
 *
 * @example
 * handleElementEnter(event, elementRef, "element-id")
 *
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @since 1.0.0
   * @version 1.0.0
   */
  const handleElementEnter = (e, ref, id) => {
    e.preventDefault();
    e.stopPropagation();

    // Se houver um hover ativo em outro elemento
    if (isHovered && isHovered !== id && currentRef) {
        handleMouseLeave(e, currentRef);
    }

    // Atualiza o estado para o novo elemento
    if(inspectMode){
      console.log("handleElementEnter", id);
    setCurrentRef(ref);
    handleMouseEnter(e, ref);
    setIsHovered(id);
    }
};

/**
 * @function handleElementLeave
 * @memberof module:Home.Home
 * @description Manages element hover state exit in preview mode.
 * Cleans up hover states and references when leaving an element.
 *
 * @param {Event} e - Mouse event object
 * @param {React.RefObject} ref - Reference to the target DOM element
 * @param {string} id - Unique identifier for the element
 *
 * @example
 * handleElementLeave(event, elementRef, "element-id")
 *
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @since 1.0.0
   * @version 1.0.0
   */
const handleElementLeave = (e, ref, id) => {
    e.preventDefault();
    e.stopPropagation();

    // Apenas limpa o estado se for o elemento atual com hover
    if (isHovered === id) {
        handleMouseLeave(e, ref);
        setIsHovered(null);
        setCurrentRef(null);
    }
};

/**
 * @function useEffectMessageHandler
 * @memberof module:Home.Home
 * @description Effect that sets up a message event listener for inspect mode toggling.
 * Manages the inspect mode state and persists it to localStorage.
 *
 * @effect Sets up and cleans up message event listener
 * @dependencies []
 *
 * @example
 * useEffect(() => {
 *   const handleMessage = (event) => { ... };
 *   window.addEventListener("message", handleMessage);
 *   return () => window.removeEventListener("message", handleMessage);
 * }, []);
 *
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @since 1.0.0
   * @version 1.0.0
   */
useEffect(() => {
  const handleMessage = (event) => {
    // Log para debug
    // console.log("Mensagem recebida no iframe:", event.data);

    if (event.data.action === "toggleInspectMode") {
      const newMode = event.data.value;
      setInspectMode(newMode);
      localStorage.setItem("inspectMode", newMode.toString());
    }
  };

  window.addEventListener("message", handleMessage);
  return () => window.removeEventListener("message", handleMessage);
}, []);

/**
* @function useEffectThemeUpdate
* @memberof module:Home.Home
* @description Effect that registers a global theme update function.
* Allows dynamic theme style updates through a globally accessible function.
* Used in preview mode to listen for theme changes and apply them to the global state.
* The global function is exposed to allow parent windows to trigger theme updates.
*
* @effect Sets up and cleans up global theme update function
* @dependencies [dispatch]
*
* @example
* useEffect(() => {
*   window.__THEME_UPDATE__ = (themeStyleCode, value) => { ... };
*   return () => { delete window.__THEME_UPDATE__; };
* }, [dispatch]);
*
* @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @since 1.0.0
   * @version 1.0.0
   */
   useEffect(() => {
    // Registrar a função de atualização globalmente
    window.__THEME_UPDATE__ = (themeStyleCode, value) => {
      console.log('Theme update function called:', { themeStyleCode, value });
      dispatch({
        type: 'UPDATE_ACTIVE_THEME_STYLE',
        payload: { themeStyleCode, value }
      });
    };
    return () => {
      delete window.__THEME_UPDATE__;
    };
  }, [dispatch]);

//////////////////////////////////////////////////////////////////////////

  /**
   * @function findPrincipalHotel
   * @memberof module:Home.Home
   * @description Locates the principal hotel based on the provided platformUid.
   * Searches the first 8 digits of the pmsId for each hotel in the group.
   *
   * @param {Array} groupHotels - Array of group hotels
   *
   * @example
   * findPrincipalHotel(groupHotelsArray)
   *
   * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @since 1.0.0
   * @version 1.0.0
   */
  const findPrincipalHotel = (groupHotels) => {
    // Loop através da lista de hotéis
    for (const hotel of groupHotels) {
      // Extrai os primeiros 8 dígitos do pmsId
      const pmsIdPrefix = hotel.pmsId?.slice(0, 8);
      // Verifica se o prefixo do pmsId corresponde ao platformId
      if (pmsIdPrefix === platformUid) {
        setPrincipalHotel(hotel);
        break; // Encerra o loop após encontrar o hotel correspondente
      }
    }
  };

    /**
   * @function fetchHotels
   * @memberof module:Home.Home
   * @description Fetches and sorts the list of group hotels.
   * Prioritizes the hotel corresponding to platformUid in the sorting.
   *
   * @async
   * @returns {Promise<void>}
   *
   * @example
   * await fetchHotels()
   *
   * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @since 1.0.0
   * @version 1.0.0
   */
  const fetchHotels = async () => {
    try {
      const data = await getHotelPmsIdPrefix(platformUid.toLowerCase());
      const sortHotels = (hotels) => {
        return hotels.sort((a, b) => {
          // Critério 1: Hotel correspondente ao platformUid vem primeiro
          const isAMatch = a.hotelId.startsWith(platformUid.toLowerCase());
          const isBMatch = b.hotelId.startsWith(platformUid.toLowerCase());

          if (isAMatch !== isBMatch) {
            return isAMatch ? -1 : 1;
          }
          return 0;
        });
      };
      const sortedHotels = sortHotels(data);
      setGroupHotels(sortedHotels);
      findPrincipalHotel(sortedHotels);
    } catch (error) {
      console.error("Error fetching hotels:", error);
    }
  };

  /**
   * @function useEffectFetchHotels
   * @memberof module:Home.Home
   * @description Effect that fetches hotels when the component mounts or when
   * there are changes to platformUid or cookie.
   *
   * @effect Updates the group hotels state
   * @dependencies [cookie.platformUid, platformUid]
   *
   * @example
   * useEffect(() => {
   *   if(!isPreviewMode) {
   *     fetchHotels();
   *   }
   * }, [cookie.platformUid, platformUid]);
   *
   * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @since 1.0.0
   * @version 1.0.0
   */
  useEffect(() => {
    if(isPreviewMode){
      return;
    }
    fetchHotels();
  }, [cookie.platformUid, platformUid]); // eslint-disable-line

  return (
        <main id="HM-Page" ref={HMPageRef} onDoubleClick={(e) => handleDoubleClick(e, HMPageRef)} onMouseOver={(e) => handleElementEnter(e, HMPageRef, "HM-Page")} onMouseLeave={(e) => handleElementLeave(e, HMPageRef, "HM-Page")} className={`${isPreviewMode && isHovered === "HM-Page" ? "diagonal-stripes" : ""} dark:bg-gxp_dark_2 flex flex-col w-full h-full HM-bg_color flex-1`}>
          <div className="h-full container mx-auto mt-5">
            <ReservationsDisplay handleDoubleClick={handleDoubleClick} HMCardRef={HMCardRef} handleElementEnter={handleElementEnter} handleElementLeave={handleElementLeave} isPreviewMode={isPreviewMode} isHovered={isHovered} reservations={reservations} groupHotels={groupHotels} setReservations={setReservations} />
          </div>
        </main>
  );
};
