import { useState, useContext, useEffect, useRef } from "react";
import { HostForm } from "./HostForm";
import { AppContext } from "../../../context/AppContext";
import { getDefaultPreviewInitialData, saveReservationGuests } from "../../../services";
import { Consent } from "./Consent";
import { GeneralTerms } from "./GeneralTerms";
import { SquarePen } from "lucide-react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Stepper from "./ProgressBar/Stepper";
import { Loader } from "../../../components/Layout/components/Loader/Loader";
import { HeadersPageOC } from "./Utilities/HeadersPageOC";
import { previewHotelInitialData } from "./Utilities/previewModeData";
import { HamburgerMenuIcon } from "@radix-ui/react-icons";
import { HeaderUserMenu } from "../../../components/Layout/components/HeaderUserMenu";
import { ArrivalTime } from "./FormFields/ArrivalTime";

/**
 * @namespace HostFormComplete
 * @memberof module:OnlineCheckIn
 * @description Component responsible for rendering the complete host form interface.
 * Manages multiple guest entries, form navigation, consent forms, and submission flow.
 *
 * @component
 * @param {Object} props Component properties
 * @param {Object} props.data Current form data state
 * @param {Object} props.hotel Hotel information
 * @param {Object} props.reservation Reservation details
 * @param {Function} props.setReservation Function to update reservation state
 * @param {boolean} props.showMenus Flag to control menu visibility
 * @param {string} props.cultureCode Current culture/language code
 * @param {boolean} props.isPreviewMode Flag indicating preview mode
 * @param {Function} props.handleMouseEnter Mouse enter event handler for preview mode
 * @param {Function} props.handleMouseLeave Mouse leave event handler for preview mode
 *
 * @returns {JSX.Element} Host form component
 *
 * @example
 * <HostFormComplete
 *   data={data}
 *   hotel={hotel}
 *   reservation={reservation}
 *   setReservation={setReservation}
 *   showMenus={true}
 *   cultureCode="en-US"
 *   isPreviewMode={false}
 * />
 *
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @since 1.0.0
 * @version 1.0.0
 */
export const HostFormComplete = ({handleDoubleClick, data, hotel, reservation, setReservation, showMenus, cultureCode, isPreviewMode, handleMouseEnter, handleMouseLeave}) => {
  const { detailId } = useParams()
  const [guests, setGuests] = useState([]);
  const [activeTab, setActiveTab] = useState(() => {
    if (isPreviewMode) {
      return 0;
    }
    try {
      const storedTab = localStorage.getItem("localTab");
      if (!storedTab) return 0;

      const parsedData = JSON.parse(storedTab);
      if (parsedData.id === detailId) {
        return parsedData.tab;
      }
      return 0;
    } catch (error) {
      console.error("Error parsing localTab:", error);
      return 0;
    }
  });
  const [state,dispatch] = useContext(AppContext);
  const { pathname } = useLocation();
  const [initialData, setInitialData] = useState(isPreviewMode ? previewHotelInitialData : state.hotelInitialData);
  const [showConsent, setShowConsent] = useState(() => {
    try {
      if(isPreviewMode){
        return false
      }
        const storedConsent = localStorage.getItem("showConsent");
        if (!storedConsent) return false;

        const parsedData = JSON.parse(storedConsent);
        if (parsedData.id === detailId) {
            return parsedData.showConsent;
        }
        return false;
    } catch (error) {
        console.error("Error parsing showConsent:", error);
        return false;
    }
  });
  const [showGeneralTerms, setShowGeneralTerms] = useState(() => {
    if(isPreviewMode){
      return false
    }
    try {
        const storedGeneralTerms = localStorage.getItem("showGeneralTerms");
        if (!storedGeneralTerms) return false;

        const parsedData = JSON.parse(storedGeneralTerms);
        if (parsedData.id === detailId) {
            return parsedData.showGeneralTerms;
        }
        return false;
    } catch (error) {
        console.error("Error parsing showGeneralTerms:", error);
        return false;
    }
});
  const [canSubmit, setCanSubmit] = useState(false);
  const [successSubmit, setSuccessSubmit] = useState(false);
  const [t] = useTranslation("global");
  const [step, setStep] = useState(() => {
    if(isPreviewMode){
      return 1
    }
    try {
        const storedStep = localStorage.getItem("localStep");
        if (storedStep === null) return 1;

        const parsedStep = JSON.parse(storedStep);
        return typeof parsedStep === 'number' ? parsedStep : 1;
    } catch (error) {
        console.error("Error parsing localStep:", error);
        return 1;
    }
});
  const [totalSteps, setTotalSteps] = useState(3);
  const [militaryMode, setMilitaryMode]= useState(false)
  const [titles, setTitles]= useState({})
  const [titlesLoading, setTitlesLoading] = useState(true);
  const [post, setPost] = useState("")
  const navigate = useNavigate()
  const [isSending, setIsSending]=useState(false)
  const [localReservation, setLocalReservation] = useState(null)
  const {defaultThemeId} = useParams()
  const [isHovered, setIsHovered] = useState();
  const [currentRef, setCurrentRef] = useState(null);
  const [inspectMode, setInspectMode] = useState(JSON.parse(localStorage.getItem("inspectMode"))==="true" ? true : false);
  const OCStepperRef = useRef(null);
  const OCHeaderRef = useRef(null);
  const OCPageRef = useRef(null);
  const OCFormFieldRef = useRef(null);
  const OCButton1Ref = useRef(null);
  const OCButton2Ref = useRef(null);
  const OCArrivalTimeRef = useRef(null);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const isDirect = pathname.includes("checkindirect");
  const menuRef = useRef(null);

/**
 * @function useEffectInspectMode
 * @memberof module:OnlineCheckIn.HostFormComplete
 * @description Effect that handles inspect mode toggle messages from parent window.
 *
 * @effect Updates inspect mode state based on parent window messages
 * @dependencies []
 *
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @since 1.0.0
 * @version 1.0.0
 */
  useEffect(() => {
    const handleMessage = (event) => {
      // Log para debug
      // console.log("Mensagem recebida no iframe:", event.data);

      if (event.data.action === "toggleInspectMode") {
        const newMode = event.data.value;
        setInspectMode(newMode);
        localStorage.setItem("inspectMode", newMode.toString());
      }
    };

    window.addEventListener("message", handleMessage);
    return () => window.removeEventListener("message", handleMessage);
  }, []);

  /**
 * @function useEffectClickOutside
 * @memberof module:OnlineCheckIn.HostFormComplete
 * @description Effect that handles clicking outside the mobile menu to close it.
 *
 * @effect Closes mobile menu when clicking outside
 * @dependencies [isMobileMenuOpen]
 *
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @since 1.0.0
 * @version 1.0.0
 */
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target) &&
          !event.target.closest('button')) {
        setIsMobileMenuOpen(false);
      }
    };

    if (isMobileMenuOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isMobileMenuOpen]);

/**
 * @function useEffectInitialReservation
 * @memberof module:OnlineCheckIn.HostFormComplete
 * @description Effect that handles the initial loading of reservation data from localStorage.
 * If a matching reservation exists in localStorage, it is loaded.
 * Otherwise, a new reservation is created and saved to localStorage.
 *
 * @effect Initializes or loads reservation data in localStorage
 * @dependencies [detailId, reservation]
 *
 * @example
 * useEffect(() => {
 *   // Loads or initializes reservation in localStorage
 *   if (!reservation || !detailId) return;
 *   const localStReservation = JSON.parse(localStorage.getItem("localReservation"));
 *   // ... rest of the effect
 * }, [detailId, reservation]);
 *
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @since 1.0.0
 * @version 1.0.0
 */
useEffect(() => {
  if (!reservation || !detailId) return;

    const localStReservation = JSON.parse(localStorage.getItem("localReservation"));

    if (localStReservation && localStReservation.detailId === detailId) {
      setLocalReservation(localStReservation);
    } else {

      const newLocalReservation = {
        detailId: detailId,
        billingType: 'sameAsGuest',
        reservation: reservation
      };
      localStorage.setItem("localReservation", JSON.stringify(newLocalReservation));
      setLocalReservation(newLocalReservation);
    }

}, [detailId, reservation]);

/**
 * @function useEffectLocalStorage
 * @memberof module:OnlineCheckIn.HostFormComplete
 * @description Effect that synchronizes local reservation data with localStorage.
 *
 * @effect Updates localStorage with current reservation data
 * @dependencies [localReservation]
 *
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @since 1.0.0
 * @version 1.0.0
 */
  useEffect(() => {
    if(localReservation){
      localStorage.setItem("localReservation", JSON.stringify(localReservation))
    }
  }, [localReservation])

  /**
 * @function useEffectMilitaryTitle
 * @memberof module:OnlineCheckIn.HostFormComplete
 * @description Effect that updates military post title based on guest title ID.
 *
 * @effect Updates post title for military mode
 * @dependencies [guests, titles, militaryMode]
 *
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @since 1.0.0
 * @version 1.0.0
 */
  useEffect(()=>{
    const guest = guests[0]
    if(militaryMode && titles && guest){
      const newTitle = titles?.result?.title.find((element) => element.ID === guest.data.Title_Id)
      setPost(newTitle)
    }
  },[guests, titles, militaryMode])

/**
 * @function addNewGuest
 * @memberof module:OnlineCheckIn.HostFormComplete
 * @description Adds a new guest to the guests list with default values.
 * Updates the active tab to focus on the newly added guest.
 *
 * @example
 * addNewGuest()
 *
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @since 1.0.0
 * @version 1.0.0
 */
  const addNewGuest = () => {
    const newGuest = {
      id: guests.length,
      title: `${t(`HostFormComplete.guest`)} ${guests.length + 1}`,
      data: {},
    };
    setGuests([...guests, newGuest]);
    setActiveTab(newGuest.id);
  };

/**
 * @function removeGuest
 * @memberof module:OnlineCheckIn.HostFormComplete
 * @description Removes a guest from the guests list except for the main guest.
 * Updates the active tab to the last remaining guest.
 *
 * @param {number} id - ID of the guest to remove
 *
 * @example
 * removeGuest(2)
 *
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @since 1.0.0
 * @version 1.0.0
 */
  const removeGuest = (id) => {
    if (id === 0) return; // Do not allow removal of the main guest
    const newGuests = guests.filter((guest) => guest.id !== id);
    setGuests(newGuests);
    setActiveTab(newGuests[newGuests.length - 1].id);
  };

/**
 * @function useEffectLocalStorage
 * @memberof module:OnlineCheckIn.HostFormComplete
 * @description Effect that persists form state to localStorage.
 * Saves active tab, step, consent, and general terms states.
 *
 * @effect Updates multiple localStorage items with current form state
 * @dependencies [step, showConsent, showGeneralTerms, detailId, activeTab]
 *
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @since 1.0.0
 * @version 1.0.0
 */
  useEffect(()=>{

    const tabData = {
      id: detailId,
      tab: activeTab
  };
    localStorage.setItem("localTab", JSON.stringify(tabData));

    const stepData = {
      id: detailId,
      step: step
  };
    localStorage.setItem("localStep", JSON.stringify(stepData));

    const showConsentData = {
      id: detailId,
      showConsent: showConsent
    }
    localStorage.setItem("showConsent", JSON.stringify(showConsentData))

    const showGeneralTermsData = {
      id: detailId,
      showGeneralTerms: showGeneralTerms
    }
    localStorage.setItem("showGeneralTerms", JSON.stringify(showGeneralTermsData))
  },[step, showConsent, showGeneralTerms, detailId, activeTab])

/**
 * @function extractData
 * @memberof module:OnlineCheckIn.HostFormComplete
 * @description Extracts data from the guests array, mapping each guest to their data object.
 *
 * @param {Array} guests - Array of guest objects
 * @returns {Array} Array of guest data objects
 *
 * @example
 * const guestData = extractData(guests)
 *
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @since 1.0.0
 * @version 1.0.0
 */
  function extractData(guests) {
    return guests.map((guest) => guest.data);
  }

/**
 * @function handleSubmit
 * @memberof module:OnlineCheckIn.HostFormComplete
 * @description Processes form submission, validates required fields,
 * and saves guest information to the reservation system.
 *
 * @param {Event} e - Form submission event
 *
 * @example
 * handleSubmit(event)
 *
 * @throws {Error} When form submission fails
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @since 1.0.0
 * @version 1.0.0
 */
  const handleSubmit = async (e) => {
    e.preventDefault();

    if(isPreviewMode){
      setSuccessSubmit(true);
      handleElementLeave(e, OCButton1Ref, "OC-Button1");

      window.parent.postMessage({
        action: "OC-SuccessPage",
        debugInfo: 'Change to Success Page'
      }, "*");
      return;
    }

    if (!guests[0].data.GeneralTermsAccepted) {
      alert(`${t(`HostFormComplete.acceptgeneral`)}`);
      return;
    }
    if (!guests[0].data.ArrivalTime) {
      alert(`${t(`HostFormComplete.arrivaltime`)}`);
      return;
    }

    if (guests.length > 0 && reservation) {
      setReservation((prevReservation) => ({
        ...prevReservation,
        Guests: guests.map((guest) => guest.data),
      }));
    }


    if (canSubmit) {
      try {
        setIsSending(true);

        const data = await saveReservationGuests(hotel, extractData(guests));
        if(data){
          setSuccessSubmit(true);
          localStorage.removeItem("localReservation")
          localStorage.removeItem("localStep")
          localStorage.removeItem("localTab")
          localStorage.removeItem("showConsent")
          localStorage.removeItem("showGeneralTerms")

        }
      } catch (error) {
        alert(`${t('HostFormComplete.formfail')}`, error);
      } finally {
        setIsSending(false);
      }
    } else {
      alert(`${t(`HostFormComplete.arrivaltime`)}`);
    }
  };

  /**
 * @function handleBack
 * @memberof module:OnlineCheckIn.HostFormComplete
 * @description Handles the back navigation in the form, returning to the previous state.
 * In preview mode, sends a message to parent window for navigation.
 *
 * @example
 * handleBack()
 *
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @since 1.0.0
 * @version 1.0.0
 */
  const handleBack = () => {
    if(isPreviewMode){
      setSuccessSubmit(false)

      window.parent.postMessage({
        action: "OC-GeneralTermPage",
        debugInfo: 'Change to General Terms Page'
      }, "*");

      return;
    }
    setSuccessSubmit(false)
  }

/**
 * @function next
 * @memberof module:OnlineCheckIn.HostFormComplete
 * @description Handles navigation to the next step in the form process.
 * Manages transitions between guest forms, consent pages, and general terms,
 * with special handling for guests marked with FillLater=true.
 * The function implements the following navigation logic:
 * - Skips guests marked as FillLater (except for the main guest)
 * - Ensures the main guest (id=0) always provides consent
 * - Transitions to general terms after all valid guests complete consent
 * - Automatically scrolls to top after each transition
 *
 * @param {Event} e - Click event object to be prevented from default behavior
 * @param {number} id - Current guest ID (0 = main guest)
 *
 * @throws {Error} Implicitly may throw if guests array or its data is undefined
 *
 * @example
 * // Navigate from current guest to next
 * next(event, currentGuestId)
 *
 * @example
 * // Navigate from last guest to consent page
 * next(event, guests.length - 1)
 *
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @since 1.0.0
 * @version 1.0.0
 */
const next = (e, id) => {
  e.preventDefault();

  // Helper function to find next non-FillLater guest
  const findNextValidGuest = (currentId) => {
    for (let i = currentId + 1; i < guests.length; i++) {
      if (!guests[i].data.FillLater || i === 0) { // Main guest (id=0) always needs consent
        return i;
      }
    }
    return -1; // No more valid guests found
  };

  if (!showGeneralTerms && showConsent) {
    // If current guest has FillLater=true and is not the main guest, skip to next valid guest
    if (guests[id].data.FillLater && id !== 0) {
      const nextValidId = findNextValidGuest(id);

      if (nextValidId === -1) {
        // No more valid guests, move to general terms
        setShowGeneralTerms(true);
        setShowConsent(false);
        setActiveTab(0);
        setStep(step + 1);
        window.scrollTo(0, 0);
        return;
      } else {
        // Move to next valid guest
        setActiveTab(nextValidId);
        window.scrollTo(0, 0);
        return;
      }
    }

    if (id === guests.length - 1) {
      setShowGeneralTerms(true);
      setShowConsent(false);
      setStep(step + 1);
      window.scrollTo(0, 0);
      return;
    }

    // If next guest has FillLater=true, find the next valid guest
    const nextId = id + 1;
    if (guests[nextId].data.FillLater && nextId !== 0) {
      const nextValidId = findNextValidGuest(id);
      if (nextValidId === -1) {
        setShowGeneralTerms(true);
        setShowConsent(false);
        setStep(step + 1);
      } else {
        setActiveTab(nextValidId);
      }
    } else {
      setActiveTab(nextId);
    }
    window.scrollTo(0, 0);
    return;
  }

  if (!showGeneralTerms && !showConsent && id === guests.length - 1) {
    // Before moving to consent, find first non-FillLater guest or main guest
    let firstValidGuest = 0; // Default to main guest
    for (let i = 0; i < guests.length; i++) {
      if (!guests[i].data.FillLater || i === 0) {
        firstValidGuest = i;
        break;
      }
    }

    setShowConsent(true);
    setActiveTab(firstValidGuest);
    setStep(step + 1);
    window.scrollTo(0, 0);
    return;
  }

  setActiveTab(id + 1);
  window.scrollTo(0, 0);
};

/**
 * @function previous
 * @memberof module:OnlineCheckIn.HostFormComplete
 * @description Handles navigation to the previous step in the form process.
 * Manages backward transitions between guest forms, consent pages, and general terms,
 * with special handling for guests marked with FillLater=true.
 * The function implements the following navigation logic:
 * - When on general terms: returns to consent page of last valid guest
 * - When on consent: navigates to previous valid guest, or form if on first guest
 * - Skips guests marked as FillLater (except main guest)
 * - Automatically scrolls to top after each transition
 *
 * @param {Event} e - Click event object to be prevented from default behavior
 * @param {number} id - Current guest ID (0 = main guest)
 *
 * @throws {Error} Implicitly may throw if guests array or its data is undefined
 *
 * @example
 * // Navigate from current guest to previous
 * previous(event, currentGuestId)
 *
 * @example
 * // Navigate from general terms back to consent
 * previous(event, 0)
 *
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @since 1.0.0
 * @version 1.0.0
 */
const previous = (e, id) => {
  e.preventDefault();

  // Helper function to find previous non-FillLater guest
  const findPreviousValidGuest = (currentId) => {
    for (let i = currentId - 1; i >= 0; i--) {
      if (!guests[i].data.FillLater || i === 0) {
        return i;
      }
    }
    return -1;
  };

  // Quando voltar dos termos gerais
  if (showGeneralTerms && !showConsent && id === 0) {
    // Encontra o último guest válido
    let lastValidGuest = 0;
    for (let i = guests.length - 1; i >= 0; i--) {
      if (!guests[i].data.FillLater || i === 0) {
        lastValidGuest = i;
        break;
      }
    }

    setShowConsent(true);
    setShowGeneralTerms(false);
    setActiveTab(lastValidGuest);
    setStep(step - 1);
    window.scrollTo(0, 0);
    return;
  }

  // Quando estiver na seção de consent
  if (!showGeneralTerms && showConsent) {
    // Se estiver no primeiro guest, volta para o formulário
    if (id === 0) {
      setShowConsent(false);
      setActiveTab(guests.length - 1);
      setStep(step - 1);
      window.scrollTo(0, 0);
      return;
    }

    // Se não, encontra o guest válido anterior
    const previousValidId = findPreviousValidGuest(id);
    if (previousValidId !== -1) {
      setActiveTab(previousValidId);
      window.scrollTo(0, 0);
      return;
    }
  }

  setActiveTab(id - 1);
  window.scrollTo(0, 0);
};

  /**
 * @function fetchHostTitles
 * @memberof module:OnlineCheckIn.HostFormComplete
 * @description Fetches host titles from the server based on culture code and hotel subscription.
 * Used primarily for military mode functionality.
 *
 * @async
 * @throws {Error} When titles cannot be fetched
 *
 * @example
 * await fetchHostTitles()
 *
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @since 1.0.0
 * @version 1.0.0
 */
  const fetchHostTitles = async () =>{
      if(state?.hotelInitialData){
        setTitles(state.hotelInitialData.Titles)
      }
  }

  /**
 * @function handleElementEnter
 * @memberof module:OnlineCheckIn.HostFormComplete
 * @description Manages element hover state entry in preview mode.
 * Controls hover states and triggers appropriate mouse enter events.
 *
 * @param {Event} e - Mouse event
 * @param {React.RefObject} ref - Reference to target element
 * @param {string} id - Element identifier
 *
 * @example
 * handleElementEnter(event, elementRef, "form-field-1")
 *
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @since 1.0.0
 * @version 1.0.0
 */
  const handleElementEnter = (e, ref, id) => {
    e.preventDefault();
    e.stopPropagation();

    // Se houver um hover ativo em outro elemento
    if (isHovered && isHovered !== id && currentRef) {
        handleMouseLeave(e, currentRef);
    }

    // Atualiza o estado para o novo elemento
    if(inspectMode){
    setCurrentRef(ref);
    handleMouseEnter(e, ref);
    setIsHovered(id);
    }
};

/**
 * @function handleElementLeave
 * @memberof module:OnlineCheckIn.HostFormComplete
 * @description Manages element hover state exit in preview mode.
 * Cleans up hover states and references when leaving an element.
 *
 * @param {Event} e - Mouse event
 * @param {React.RefObject} ref - Reference to target element
 * @param {string} id - Element identifier
 *
 * @example
 * handleElementLeave(event, elementRef, "form-field-1")
 *
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @since 1.0.0
 * @version 1.0.0
 */
const handleElementLeave = (e, ref, id) => {
    e.preventDefault();
    e.stopPropagation();

    // Apenas limpa o estado se for o elemento atual com hover
    if (isHovered === id) {
        handleMouseLeave(e, ref);
        setIsHovered(null);
        setCurrentRef(null);
    }
};

/**
 * @function clearAllHoverStates
 * @memberof module:OnlineCheckIn.HostFormComplete
 * @description Clears all hover states and references in preview mode.
 * Used for cleaning up hover effects when unmounting or resetting UI state.
 *
 * @example
 * clearAllHoverStates()
 *
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @since 1.0.0
 * @version 1.0.0
 */
const clearAllHoverStates = () => {
    if (currentRef) {
        handleMouseLeave(null, currentRef);
    }
    setIsHovered(null);
    setCurrentRef(null);
};

 /**
 * @function useEffectSetGuests
 * @memberof module:OnlineCheckIn.HostFormComplete
 * @description Effect that initializes and updates guest list based on reservation data.
 * Handles guest data persistence and synchronization with local storage.
 *
 * @effect Updates guests state and local storage
 * @dependencies [reservation, detailId]
 *
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @since 1.0.0
 * @version 1.0.0
 */
 useEffect(() => {
  if (!isPreviewMode) {
    if (!reservation || !detailId) {
      // console.log("Missing reservation or detailId, exiting useEffect");
      return;
    }
  }

  const isMatchingReservation = reservation?.DetailId === parseInt(detailId);
  const sourceReservation = isMatchingReservation ?
    JSON.parse(localStorage.getItem("localReservation") || '{"reservation":{"Guests":[]}}') :
    { detailId, billingType: 'sameAsGuest', reservation };

  const numberOfAdults = (sourceReservation?.reservation || reservation).Adults || 0;
  const numberOfChildren = ((sourceReservation?.reservation || reservation).Children1 || 0) +
                          ((sourceReservation?.reservation || reservation).Children2 || 0) +
                          ((sourceReservation?.reservation || reservation).Children3 || 0);

  // console.log("numberOfAdults", numberOfAdults);
  // console.log("numberOfChildren", numberOfChildren);
  // console.log("total expected guests", numberOfAdults + numberOfChildren);

  const createGuests = (sourceGuests, useLocalData) => {
    // Ensure we're only working with valid guest data
    if (!Array.isArray(sourceGuests)) {
      console.error("sourceGuests is not an array:", sourceGuests);
      return [];
    }

    // Filter out any null values before mapping
    return sourceGuests
      .filter(guestData => guestData !== null)
      .map((guestData, index) => ({
        id: index,
        title: guestData.IsMainGuest ? t(`HostFormComplete.mainguest`) :
              guestData.IsChildren ? `${t(`HostFormComplete.child`)} ${index + 1}` :
              `${t(`HostFormComplete.guest`)} ${index + 1}`,
        data: {
          ...guestData,
          invoiceEntityVATNumber: useLocalData ? sourceReservation.reservation.Guests[index]?.invoiceEntityVATNumber || guestData.FiscalNumber : guestData.FiscalNumber,
          invoiceEntityFirstName: useLocalData ? sourceReservation.reservation.Guests[index]?.invoiceEntityFirstName || guestData.FirstName : guestData.FirstName,
          invoiceEntityLastName: useLocalData ? sourceReservation.reservation.Guests[index]?.invoiceEntityLastName || guestData.LastName : guestData.LastName,
          invoiceEntityAddress1: useLocalData ? sourceReservation.reservation.Guests[index]?.invoiceEntityAddress1 || guestData.Address1 : guestData.Address1,
          invoiceEntityTown: useLocalData ? sourceReservation.reservation.Guests[index]?.invoiceEntityTown || guestData.Town : guestData.Town,
          invoiceEntityZipCode: useLocalData ? sourceReservation.reservation.Guests[index]?.invoiceEntityZipCode || guestData.ZipCode : guestData.ZipCode,
          invoiceEntityCountryIso: useLocalData ? sourceReservation.reservation.Guests[index]?.invoiceEntityCountryIso || guestData.CountryIso : guestData.CountryIso,
          invoiceEntityCountryId: useLocalData ? sourceReservation.reservation.Guests[index]?.invoiceEntityCountryId || guestData.CountryId : guestData.CountryId,
          billingType: useLocalData ? sourceReservation.reservation.Guests[index]?.billingType || "sameAsGuest" : "sameAsGuest"
        }
      }));
  };

  // Get source guests and ensure it's a valid array
  const sourceGuests = isMatchingReservation ?
    (sourceReservation.reservation?.Guests || []) :
    (reservation?.Guests || []);

  // console.log("sourceGuests original", sourceGuests);

  // Initial guests creation
  const newGuests = createGuests(sourceGuests, isMatchingReservation);

  // Find the main guest from the existing guests
  const mainGuest = newGuests.find(guest => guest && guest.data && guest.data.IsMainGuest);
  // console.log("mainGuest found:", mainGuest ? "Yes" : "No");

  // Count existing valid guests by type
  const existingAdults = newGuests.filter(g => g && g.data && !g.data.IsChildren).length;
  const existingChildren = newGuests.filter(g => g && g.data && g.data.IsChildren).length;

  // console.log("Existing adults:", existingAdults);
  // console.log("Existing children:", existingChildren);
  // console.log("Existing total:", newGuests.length);

  // STEP 1: Add adult guests if we have fewer than required
  if (existingAdults < numberOfAdults && mainGuest) {
    // console.log(`Adding ${numberOfAdults - existingAdults} adult guests`);

    // Fields that should be empty or different for additional guests
    const fieldsToReset = {
      GuestId: -1,
      GuestUid: "",
      IsMainGuest: false,
      GuestSignatureBase64: "",
      Email: "",
      Phone: "",
      Mobile: "",
      DocType: "",
      FillLater: false,
      Gender: "",
      IsChildren: false,
      NationalityId: null,
      TitleLong_Id: null,
      TitleShort_Id: null,
      Title_Id: null,
      NationalityIso: "",
      DocNumber: "",
      BirthDate: "",
      FirstName: "",
      LastName: "",
      SocialName: "",
      FiscalNumber: "",
      DocVal: "",
      DocIssueAuthor: "",
      Address1: "",
      Address2: "",
      Address3: "",
      AddressState:"",
      Town: "",
      ZipCode: "",
      CountryIso: "",
      CountryId: null,
      invoiceEntityVATNumber: "",
      invoiceEntityFirstName: "",
      invoiceEntityLastName: "",
      invoiceEntityAddress1: "",
      invoiceEntityTown: "",
      invoiceEntityZipCode:"",
      invoiceEntityCountryIso:"",
      invoiceEntityCountryId: null,
      billingType: "sameAsGuest"
    };

    // Create new adult guests to reach the required number
    for (let i = existingAdults; i < numberOfAdults; i++) {
      // Copy and modify Consents from main guest
      const modifiedConsents = mainGuest.data.Consents?.map(consent => ({
        ...consent,
        GuestHasConsent: false
      })) || [];

      newGuests.push({
        id: i,
        title: `${t(`HostFormComplete.guest`)} ${i + 1}`,
        data: {
          ...mainGuest.data,  // Copy all data from main guest
          ...fieldsToReset,   // Reset specific fields
          ResDetailId: reservation.DetailId,
          Consents: modifiedConsents  // Add modified consents
        }
      });
    }
  } else if (existingAdults > numberOfAdults) {
    // console.log(`Warning: More existing adult guests (${existingAdults}) than required (${numberOfAdults})`);
  } else {
    // console.log("No need to add more adult guests");
  }

  // STEP 2: Check total expected guests (adults + children) and add children if needed
  const totalExpectedGuests = numberOfAdults + numberOfChildren;
  const currentTotalGuests = newGuests.length;

  // Only add children if we need more guests to reach the total and we have a main guest
  if (currentTotalGuests < totalExpectedGuests && mainGuest) {
    const childrenToAdd = totalExpectedGuests - currentTotalGuests;
    // console.log(`Adding ${childrenToAdd} child guests`);

    // Fields to reset for children guests (similar to adult guests but with IsChildren=true)
    const childFieldsToReset = {
      GuestId: -1,
      GuestUid: "",
      IsMainGuest: false,
      IsChildren: true,  // Mark as child
      GuestSignatureBase64: "",
      Email: "",
      Phone: "",
      Mobile: "",
      DocType: "",
      FillLater: false,
      Gender: "",
      NationalityId: null,
      TitleLong_Id: null,
      TitleShort_Id: null,
      Title_Id: null,
      NationalityIso: "",
      DocNumber: "",
      BirthDate: "",
      FirstName: "",
      LastName: "",
      SocialName: "",
      FiscalNumber: "",
      DocVal: "",
      DocIssueAuthor: "",
      Address1: "",
      Address2: "",
      Address3: "",
      AddressState:"",
      Town: "",
      ZipCode: "",
      CountryIso: "",
      CountryId: null,
      invoiceEntityVATNumber: "",
      invoiceEntityFirstName: "",
      invoiceEntityLastName: "",
      invoiceEntityAddress1: "",
      invoiceEntityTown: "",
      invoiceEntityZipCode:"",
      invoiceEntityCountryIso:"",
      invoiceEntityCountryId: null,
      billingType: "sameAsGuest"
    };

    // Add the required number of children
    for (let i = 0; i < childrenToAdd; i++) {
      const childIndex = currentTotalGuests + i;

      // Copy and modify Consents from main guest
      const modifiedConsents = mainGuest.data.Consents?.map(consent => ({
        ...consent,
        GuestHasConsent: false
      })) || [];

      newGuests.push({
        id: childIndex,
        title: `${t(`HostFormComplete.child`)} ${existingChildren + i + 1}`,  // Use appropriate translation for "child"
        data: {
          ...mainGuest.data,  // Copy base data from main guest
          ...childFieldsToReset,  // Apply child-specific fields
          ResDetailId: reservation.DetailId,
          Consents: modifiedConsents
        }
      });
    }
  } else if (currentTotalGuests > totalExpectedGuests) {
    // console.log(`Warning: More existing guests (${currentTotalGuests}) than required (${totalExpectedGuests})`);
  } else {
    // console.log("No need to add child guests");
  }

  // Make sure we're working with a clean array (no null entries)
  const finalGuests = newGuests.filter(guest => guest !== null);

  // Debug output of final structure
  // console.log("Final structure - Total adults expected:", numberOfAdults);
  // console.log("Final structure - Total children expected:", numberOfChildren);
  // console.log("Final structure - Total guests created:", finalGuests.length);
  // console.log("Final structure - Children guests created:", finalGuests.filter(g => g.data.IsChildren).length);
  // console.log("Final structure - Adult guests created:", finalGuests.filter(g => !g.data.IsChildren).length);

  setGuests(finalGuests);
  setLocalReservation(sourceReservation);
}, [reservation, detailId, t]);

/**
 * @function useEffectSyncGuests
 * @memberof module:OnlineCheckIn.HostFormComplete
 * @description Effect that synchronizes the guest list with the local reservation data.
 * When the guests array changes, updates the local reservation in localStorage
 * to maintain data consistency between state and storage.
 *
 * @effect Updates localStorage with current guest data
 * @dependencies [guests]
 *
 * @example
 * useEffect(() => {
 *   if (localReservation && guests?.length > 0) {
 *     const updatedLocalReservation = {
 *       ...localReservation,
 *       reservation: {
 *         ...localReservation.reservation,
 *         Guests: guests.map(guest => guest.data)
 *       }
 *     };
 *     localStorage.setItem("localReservation", JSON.stringify(updatedLocalReservation));
 *   }
 * }, [guests]);
 *
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @since 1.0.0
 * @version 1.0.0
 */
  useEffect(() => {
    if (localReservation && guests?.length > 0) {
      const updatedLocalReservation = {
        ...localReservation,
        reservation: {
          ...localReservation.reservation,
          Guests: guests.map(guest => guest.data)
        }
      };
      localStorage.setItem("localReservation", JSON.stringify(updatedLocalReservation));
    }
  }, [guests]);

  /**
 * @function useEffectUpdateReservation
 * @memberof module:OnlineCheckIn.HostFormComplete
 * @description Effect that synchronizes guest data with the reservation state.
 * Updates local storage when guest information changes.
 *
 * @effect Updates reservation state and local storage
 * @dependencies [guests]
 *
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @since 1.0.0
 * @version 1.0.0
 */
  useEffect(() => {
    if (guests && guests.length>0 && guests.length!==localReservation?.reservation?.Guests?.length) {
      setLocalReservation((prevReservation) => ({
        ...prevReservation,
        reservation: {
          ...prevReservation.reservation,
          Guests: guests.map((guest) => guest.data),
        },
      }));
    }
  }, [reservation]); // Agora depende de guests

/**
 * @function useEffectFetchHotel
 * @memberof module:OnlineCheckIn.HostFormComplete
 * @description Effect that manages hotel data initialization and updates.
 * Handles both preview mode and normal mode hotel data loading.
 *
 * @effect Updates hotel initial data and military mode settings
 * @dependencies [state.hotelInitialData]
 *
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @since 1.0.0
 * @version 1.0.0
 */
  useEffect(() => {
    if(isPreviewMode){
      setInitialData(previewHotelInitialData);
      setMilitaryMode(previewHotelInitialData?.Parameters?.MilitaryMode);
      return
    }

    setInitialData(state.hotelInitialData);
    setMilitaryMode(state.hotelInitialData?.Parameters?.MilitaryMode);
  }, [state.hotelInitialData]); // eslint-disable-line

  /**
 * @function useEffectMilitaryMode
 * @memberof module:OnlineCheckIn.HostFormComplete
 * @description Effect that fetches hotel titles when military mode is enabled.
 *
 * @effect Fetches host titles when military mode is active
 * @dependencies [initialData]
 *
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @since 1.0.0
 * @version 1.0.0
 */
  useEffect(() => {
      fetchHostTitles();
  }, [initialData]);

/**
 * @function useEffectTotalSteps
 * @memberof module:OnlineCheckIn.HostFormComplete
 * @description React effect hook that calculates and updates the total number of steps
 * in the form process. The calculation considers:
 * - Only non-FillLater guests (except main guest which is always included)
 * - Two steps per valid guest (form + consent)
 * - One additional step for general terms
 *
 * @effect Sets totalSteps state using the formula: (validGuestsCount * 2 + 1)
 * where validGuestsCount includes:
 * - Main guest (id=0) regardless of FillLater status
 * - All other guests where FillLater=false
 *
 * @dependencies {Array} guests - Array of guest objects containing id and data.FillLater properties
 *
 * @example
 * // For 3 guests where guest[1] has FillLater=true:
 * // - Main guest (always counted): 1
 * // - Guest 2 (FillLater=true): 0
 * // - Guest 3 (FillLater=false): 1
 * // Total valid guests: 2
 * // Total steps: (2 * 2 + 1) = 5 steps
 *
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @since 1.0.0
 * @version 1.0.0
 */
  // useEffect(() => {
  //   // Count only non-FillLater guests for steps (except main guest which is always counted)
  //   const validGuestsCount = guests.reduce((count, guest) => {
  //     if (!guest.data.FillLater || guest.id === 0) {
  //       return count + 1;
  //     }
  //     return count;
  //   }, 0);

  //   setTotalSteps(validGuestsCount * 2 + 1);
  // }, [guests]);

  /**
 * @function useEffectPreviewData
 * @memberof module:OnlineCheckIn.HostFormComplete
 * @description Effect that fetches default preview data in preview mode.
 *
 * @effect Updates active theme with preview data
 * @dependencies [defaultThemeId]
 *
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @since 1.0.0
 * @version 1.0.0
 */
  useEffect(() => {
    const fetchDefaultPreviewInitialData = async () => {
      try {
        const InitialData = await getDefaultPreviewInitialData(defaultThemeId);
        dispatch({ type: "SET_ACTIVE_THEME", payload: InitialData.activeTheme });
      } catch (error) {
        console.error("Error loading initialData-AllRoutes:", error);
      }
    }

    if (isPreviewMode) {
      fetchDefaultPreviewInitialData()
    }
  }, [defaultThemeId]); // eslint-disable-line

  if (successSubmit) {
    // Success component
    return (

      <div id="OC-Page" ref={OCPageRef} onDoubleClick={(e) => handleDoubleClick(e, OCPageRef)} onMouseOver={(e) => handleElementEnter(e, OCPageRef, "OC-Page")} onMouseLeave={(e) => handleElementLeave(e, OCPageRef, "OC-Page")} className={`${isPreviewMode && isHovered === "OC-Page" ? "diagonal-stripes" : ""} relative h-full w-full flex flex-col`}> {/* Container relativo para posicionamento absoluto */}
          {/* Language Selector posicionado absolutamente */}
          {/* Language Selector posicionado absolutamente */}
          {isDirect && (
            <div className="bg-white absolute top-6 right-6 z-50 rounded-md">
              <button
                onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
                className="p-2 rounded-md hover:bg-gray-200">
                <HamburgerMenuIcon className="w-6 h-6" />
              </button>
            </div>
          )}
          {isMobileMenuOpen && (
            <div ref={menuRef} className="absolute top-16 right-6">
              <HeaderUserMenu isOpen={isMobileMenuOpen} setIsOpen={setIsMobileMenuOpen} />
            </div>
          )}
        <div id="onlineCheckinForm" onSubmit={handleSubmit} className={`mx-auto OC-bg_color dark:gxp_dark_2 h-screen w-full flex flex-col `} >
        <div id="OC-Header" ref={OCHeaderRef} onDoubleClick={(e) => handleDoubleClick(e, OCHeaderRef)} onMouseOver={(e) => handleElementEnter(e, OCHeaderRef, "OC-Header")} onMouseLeave={(e) => handleElementLeave(e, OCHeaderRef, "OC-Header")} className={`${isPreviewMode && isHovered === "OC-Header" ? "diagonal-stripes" : ""}`}>
          <HeadersPageOC title={`${t(`HostFormComplete.checkin`)}`} room={reservation?.room} militaryMode={militaryMode} guest={guests[0]?.data} post={post} />
        </div>
          <div className="text-center">
            <div className={` px-8 py-6 rounded-xl mx-auto h-full w-full`} >
              <div className="flex justify-between items-center">
                {" "}
                <h2 className="OC-title_color OC-title_size OC-title_variant OC-title_font_family mb-4 dark:text-white">{`${t( `HostFormComplete.success` )}`}</h2>{" "}
                <SquarePen onClick={handleBack} className="OC-Form-title_color cursor-pointer dark:text-white" />{" "}
              </div>

              <div className="bg-green-100 text-green-700 p-4 rounded-lg mb-4 flex justify-between">
                { militaryMode && post?
                  <p> {`${t(`HostFormComplete.thankyou`)}`} {post.Description} {guests[0].data?.LastName || `${t(`HostFormComplete.guest`)}`} {`${t(`HostFormComplete.formsuccess`)}`} </p>
                :
                  <p> {`${t(`HostFormComplete.thankyou`)}`} {guests[0].data?.FirstName || `${t(`HostFormComplete.guest`)}`} {`${t(`HostFormComplete.formsuccess`)}`} </p>
                }
                { pathname.includes(`checkindirect`)?
                  <button disabled={isPreviewMode} onClick={() => navigate(-1)} className="">
                  <b>{`${t(`HostFormComplete.backpage`)}`}</b>{" "}
                  </button>
                :
                  <Link to={`/hotel/${hotel?.hotelId?.substring(0, 8)}/${reservation.DetailId}`}>
                  <b>{`${t(`HostFormComplete.backhotel`)}`}</b>{" "}
                  </Link>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
        <div id="OC-Page" ref={OCPageRef} onDoubleClick={(e) => handleDoubleClick(e, OCPageRef)} onMouseOver={(e) => handleElementEnter(e, OCPageRef, "OC-Page")} onMouseLeave={(e) => handleElementLeave(e, OCPageRef, "OC-Page")} className={`${isPreviewMode && isHovered === "OC-Page" ? "diagonal-stripes" : ""} relative flex flex-col min-h-screen h-full w-full`}> {/* Container relativo para posicionamento absoluto */}
          {/* Language Selector posicionado absolutamente */}
          {isDirect && (
            <div className="bg-white absolute top-6 right-6 z-50 rounded-md">
              <button
                onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
                className="p-2 rounded-md hover:bg-gray-200">
                <HamburgerMenuIcon className="w-6 h-6" />
              </button>
            </div>
          )}
          {isMobileMenuOpen && (
            <div ref={menuRef} className="absolute top-16 right-6">
              <HeaderUserMenu isOpen={isMobileMenuOpen} setIsOpen={setIsMobileMenuOpen} />
            </div>
          )}
          <form id="onlineCheckinForm" onSubmit={handleSubmit} className={`mx-auto OC-bg_color dark:gxp_dark_2 flex flex-col flex-grow w-full h-full`} >

            <div id="OC-Header" ref={OCHeaderRef} onDoubleClick={(e) => handleDoubleClick(e, OCHeaderRef)} onMouseOver={(e) => handleElementEnter(e, OCHeaderRef, "OC-Header")} onMouseLeave={(e) => handleElementLeave(e, OCHeaderRef, "OC-Header")} className={`${isPreviewMode && isHovered === "OC-Header" ? "diagonal-stripes" : ""}`}>
              <HeadersPageOC title={`${t(`HostFormComplete.checkin`)}`} room={reservation?.room} militaryMode={militaryMode} guest={guests[0]?.data} post={post} />
            </div>
            <div id="OC-Stepper" ref={OCStepperRef} onDoubleClick={(e) => handleDoubleClick(e, OCStepperRef)} onMouseOver={(e) => handleElementEnter(e, OCStepperRef, "OC-Stepper")} onMouseLeave={(e) => handleElementLeave(e, OCStepperRef, "OC-Stepper")} className={`${isPreviewMode && isHovered === "OC-Stepper" ? "diagonal-stripes" : ""} flex flex-col justify-center`}>
              <Stepper step={step} totalSteps={totalSteps} />
            </div>
            {reservation && hotel && guests.length > 0 && state.activeTheme && step ? (
              <>
                {
                guests.map((guest, index) => (
                  <div key={guest.id} style={{ display: activeTab === guest.id ? "block" : "none" }} className={`md:px-10 w-full h-full`} >
                    {!showConsent && !showGeneralTerms && (
                      <HostForm handleDoubleClick={handleDoubleClick} localReservation={localReservation} setLocalReservation={setLocalReservation} OCButton1Ref={OCButton1Ref} OCFormFieldRef={OCFormFieldRef} isHovered={isHovered} handleElementEnter={handleElementEnter} handleElementLeave={handleElementLeave} isPreviewMode={isPreviewMode} titlesLoading={titlesLoading} setTitlesLoading={setTitlesLoading} activeTab={activeTab} setActiveTab={setActiveTab} showGeneralTerms={showGeneralTerms} showConsent={showConsent} previous={previous} next={next} isMain={index === 0} mainGuest={guests.find((g) => g.data.IsMainGuest) === index} guest={guest} guests={guests} setGuests={setGuests} initialData={initialData} titles={titles.result} militaryMode={militaryMode} />
                    )}
                    {showConsent && !showGeneralTerms && (
                      <Consent handleDoubleClick={handleDoubleClick} OCButton1Ref={OCButton1Ref} OCButton2Ref={OCButton2Ref} isHovered={isHovered} isPreviewMode={isPreviewMode} handleElementEnter={handleElementEnter} handleElementLeave={handleElementLeave} activeTab={activeTab} showGeneralTerms={showGeneralTerms} showConsent={showConsent} previous={previous} next={next} guest={guest} guests={guests} setGuests={setGuests} titles={titles} militaryMode={militaryMode} />
                    )}
                    {showGeneralTerms && !showConsent && (
                      <GeneralTerms handleDoubleClick={handleDoubleClick} OCButton2Ref={OCButton2Ref} isHovered={isHovered} handleElementEnter={handleElementEnter} handleElementLeave={handleElementLeave} isPreviewMode={isPreviewMode} setCanSubmit={setCanSubmit} activeTab={activeTab} showGeneralTerms={showGeneralTerms} showConsent={showConsent} previous={previous} next={next} guests={guests} setGuests={setGuests} initialData={initialData} />
                    )}
                  </div>
                ))}
              </>
          )
          :
          (<div className="w-full h-full flex flex-col items-center"> <Loader className={`mt-20 mb-20 h-20 w-20 dark:text-white`} /> </div> )}

          <div className="flex justify-center w-full">
            <ArrivalTime handleDoubleClick={handleDoubleClick} isHovered={isHovered} isPreviewMode={isPreviewMode} OCArrivalTimeRef={OCArrivalTimeRef} handleElementEnter={handleElementEnter} handleElementLeave={handleElementLeave} guests={guests} setGuests={setGuests} />
          </div>

            {guests.length > 0 && reservation && showGeneralTerms && (
              <div className="flex justify-center">
                {isSending ? (
                  <button type="button" className={`w-auto px-6 py-3 mt-5 mb-6  ${ canSubmit ? "OC-Button1-bg_color" : "bg-gray-400 cursor-not-allowed" } OC-Button1-icon_color OC-Button1-border_color font-medium rounded-lg shadow-md transition duration-150 ease-in-out`} >
                    <Loader />
                  </button>
                ) : (
                <button id="OC-Button1" ref={OCButton1Ref} onDoubleClick={(e) => handleDoubleClick(e, OCButton1Ref)} onMouseOver={(e) => handleElementEnter(e, OCButton1Ref, "OC-Button1")} onMouseLeave={(e) => handleElementLeave(e, OCButton1Ref, "OC-Button1")} onSubmit={(e) => handleSubmit(e)} className={`${isPreviewMode && isHovered === "OC-Button1" ? "diagonal-stripes" : ""} w-auto px-6 py-3 mt-5 mb-6  ${ canSubmit || isPreviewMode ? "OC-Button1-bg_color dark:bg-gxp_violet" : "bg-gray-400 cursor-not-allowed" } dark:text-white dark:border-white border OC-Button1-icon_color OC-Button1-border_color font-medium rounded-lg shadow-md transition duration-150 ease-in-out`} >
                  {`${t(`HostFormComplete.submit`)}`}
                </button>)}
              </div>
            )}
          </form>
        </div>
    </>
  );
};
