import { Link, useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";

import host_logo from "../../../assets/logos/host-logo-long.png";
import loginGxp from "../../../assets/images/LoginHostGXP.png";

import Logo from "../../../assets/logos/Host_GXP-Dashboard-Black.png";
import Logo_dark from "../../../assets/logos/Host_GXP-Dashboard.png";
import { AppContext } from "../../../context/AppContext";
import { loginUser } from "../../../services";
import { Loader } from "../../../components/Layout/components/Loader/Loader";
import packageJson from "../../../../package.json";
import "./CSS/Login.css"; // Importante importar a folha de estilos CSS
import { useTranslation } from "react-i18next";

export const LoginHostOne = () => {
  const [state] = useContext(AppContext);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [t] = useTranslation("global");

  const [formData, setFormData] = useState({
    email: "",
    password: "",
    rememberMe: false,
  });

  const [rememberMe, setRememberMe] = useState(false);

  // Na função handleChange, adicione:
  const handleRememberMe = (e) => {
    setRememberMe(e.target.checked);
  };

  const handleChange = (e) => {
    e.preventDefault();

    const { name, value, type, checked } = e.target;

    setFormData((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const Login = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    const authDetail = {
      email: formData.email,
      password: formData.password,
      rememberMe: rememberMe,
    };

    try {
      await loginUser(authDetail);

      // Se remember me estiver marcado, salva o email
      if (rememberMe) {
        localStorage.setItem("rememberedEmail", formData.email);
      } else {
        localStorage.removeItem("rememberedEmail");
      }

      navigate("/admin/dashboard");
    } catch (error) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const rememberedEmail = localStorage.getItem("rememberedEmail");
    if (rememberedEmail) {
      setFormData((prev) => ({
        ...prev,
        email: rememberedEmail,
      }));
      setRememberMe(true);
    }
  }, []);

  return (
    <main className="flex h-screen w-full">
      <div className="bg-[var(--host-r24-bg-color)] w-[calc(100%-440px)] h-full justify-end items-end">
        <img className="h-[100%] object-cover" src={loginGxp} alt="LoginGxp" />
      </div>
      <div className="w-[440px] h-screen login-layout bg-white dark:bg-gray-800 dark:border-gray-700 flex flex-col p-6">
        <div className="flex items-center justify-center py-10">
          <img
            className="w-40"
            src={state.darkMode ? Logo_dark : Logo}
            alt="GXP Logo"
          />
        </div>

        <div className="space-y-4 flex-1 w-full">
          <div className="flex items-center justify-center gap-2 pb-4 dark:border-gray-700">
            <h1 className="!text-2xl inputlabel font-bold leading-tight tracking-tight text-gray-900 dark:text-white">
              Administrator Login
            </h1>
          </div>

          <form onSubmit={Login} className="space-y-4 md:space-y-6">
            <div>
              <label htmlFor="email" className="block inputlabel">
                Email
              </label>
              <input
                type="email"
                value={formData.email}
                onChange={handleChange}
                name="email"
                id="email"
                placeholder="admin@company.com"
                required=""
                className="inputlogin"
              />
            </div>
            <div>
              <label className="block inputlabel">Password</label>
              <input
                type="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                id="password"
                placeholder="Introduza a sua password"
                className="inputlogin"
                required=""
              />
            </div>
            {error && (
              <div className="p-2 bg-red-100 border border-red-400 text-red-700 text-sm rounded">
                {error}
              </div>
            )}
            <div className="flex items-center justify-between">
              <div className="flex items-start">
                <div className="flex items-center h-5">
                  <input
                    id="remember"
                    checked={rememberMe}
                    onChange={handleRememberMe}
                    aria-describedby="remember"
                    type="checkbox"
                    className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800"
                  />
                </div>
                <div className="ml-3 text-sm">
                  <label htmlFor="remember" className="inputlabel">
                    Remember me
                  </label>
                </div>
              </div>
              <Link
                to=""
                className="inputlabel !text-blue-600 hover:underline dark:text-blue-400"
              >
                Forgot password?
              </Link>
            </div>
            <button type="submit" className="w-full input-submit h-[50px]">
              {isLoading ? <Loader /> : "Iniciar Sessão"}
            </button>
            <p className="inputlabel dark:text-gray-400">
              Need an admin account?{" "}
              <Link
                to="/admin/register"
                className="inputlabel !text-blue-600 hover:underline dark:text-blue-400"
              >
                Request access
              </Link>
            </p>
          </form>
          <div className="flex flex-col items-center justify-center pt-20">
            <img className="w-40" src={host_logo} alt="Host Logo" />
            {/* Add App Version Information */}
            <div className="px-4 py-1 inputlabel text-center mt-5">
              {t("appVersion")} {packageJson.version}
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};
