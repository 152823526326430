import { useEffect, useRef, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import { toast } from "react-toastify";
import React from "react";
import { useContext } from "react";
import { AppContext } from "../../context/AppContext";
import { useTranslation } from "react-i18next";
import { QRCode } from "antd";
import { getDefaultPreviewInitialData, getKeypassKey, getReservationByDetailId } from "../../services";
import { Loader } from "../../components/Layout/components/Loader/Loader";
import { previewGuestKey, previewHomeReservationsData } from "./Utils/previewModeData";
import { HeadersPageQR } from "./Components/HeadersPageQR";

/**
* @module QrCode
* @description Core module for generating and displaying QR codes for hotel reservations.
* Manages QR code generation, dark mode support, and data fetching.
*/

/**
* @namespace QrCode
* @memberof module:QrCode
* @description Component responsible for displaying reservation QR codes.
* Handles keypass key fetching and QR code rendering with loading states.
* Supports preview mode for theme customization and inspection.
*
* @component
* @returns {JSX.Element} QrCode component
*
* @example
* <QrCode />
*
* @author Tiago Ferreira <tiago.ferreira@hhs.pt>
* @since 1.0.0
* @version 1.0.0
*/
export const QrCode = () => {
  const [keypassKeyString, setKeypassKeyString] = useState("");
  const { detailId } = useParams();
  const [cookie] = useCookies(["sUid", "currentHotel"]);
  const [state, dispatch] = useContext(AppContext); // eslint-disable-line
  const [t] = useTranslation("global");
  const [reservation, setReservation] = useState(state.reservation);
  const [isLoading, setIsLoading] = useState(true);
  const [darkMode, setDarkMode] = useState( JSON.parse(localStorage.getItem(`darkMode`)) || false ); // eslint-disable-line

//////////// previewmode ///////////////
const { pathname } = useLocation();
const isPreviewMode = pathname.includes("/previewmode/");
const {defaultThemeId} = useParams()
const [isHovered, setIsHovered] = useState();
const [currentRef, setCurrentRef] = useState(null);
const [inspectMode, setInspectMode] = useState(JSON.parse(localStorage.getItem("inspectMode"))==="true" ? true : false);
const QRPageRef = useRef(null);
const QRHeaderRef = useRef(null);

const handleDoubleClick = (e, elementRef) => {
  e.stopPropagation();

  // Verifica se o inspectMode está ativo antes de enviar a mensagem
  if (isPreviewMode && inspectMode && elementRef.current) {
    const elementId = elementRef.current.id;

    window.parent.postMessage({
      action: "doubleClick",
      elementId: elementId,
      debugInfo: 'Double click on element to expand category/section'
    }, "*");
  }
};

/**
 * @function useEffectFetchDefaultPreviewInitialData
  * @memberof module:QrCode.QrCode
 * @description Effect that loads the initial preview data for the default theme.
 * Fetches and sets the active theme when the component is in preview mode.
 *
 * @effect Updates the active theme in the global application state
 * @dependencies [defaultThemeId] - Executes when the default theme ID changes
 *
 * @example
 * useEffect(() => {
 *   if (isPreviewMode) {
 *     fetchDefaultPreviewInitialData()
 *   }
 * }, [defaultThemeId]);
 *
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @version 1.0.0
 */
useEffect(() => {
  const fetchDefaultPreviewInitialData = async () => {
    try {
      const InitialData = await getDefaultPreviewInitialData(defaultThemeId);
      dispatch({ type: "SET_ACTIVE_THEME", payload: InitialData.activeTheme });
    } catch (error) {
      console.error("Error loading initialData-AllRoutes:", error);
    }
  }

  if (isPreviewMode) {
    fetchDefaultPreviewInitialData()
  }
  }, [defaultThemeId]); // eslint-disable-line

  /**
  * @function handleMouseEnter
  * @memberof module:QrCode.QrCode
  * @description Manages mouse enter events on elements during preview mode.
  * Sends messages to the parent component with information about the focused element.
  *
  * @param {Event} e - Mouse event
  * @param {React.RefObject} elementRef - React element reference
  *
  * @example
  * handleMouseEnter(event, myRef)
  *
  * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
  * @since 1.0.0
  * @version 1.0.0
  */
  const handleMouseEnter = (e, elementRef) => {
  e.stopPropagation();

  if (isPreviewMode && elementRef.current) {
    const current = elementRef.current;
    window.parent.postMessage({
      action: "hover",
      elementId: current.id,
      elementClass: current.className || '',
      elementTag: current.tagName || '',
      debugInfo: 'Hover on specific hoverable element'
    }, "*");
  }
  };

  /**
  * @function handleMouseLeave
  * @memberof module:QrCode.QrCode
  * @description Handles mouse leave events for elements in preview mode.
  * Sends a message to the parent window with element details when hover is removed.
  *
  * @param {Event} e - Mouse event object
  * @param {React.RefObject} elementRef - Reference to the DOM element
  *
  * @example
  * handleMouseLeave(event, myElementRef)
  *
  * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
  * @since 1.0.0
  * @version 1.0.0
  */
  const handleMouseLeave = (e, elementRef) => {

  if (isPreviewMode && elementRef.current) {
    const current = elementRef.current;
    window.parent.postMessage({
      action: "hoverOut",
      elementId: current.id,
      elementClass: current.className || '',
      elementTag: current.tagName || '',
      debugInfo: 'HoverOut from specific hoverable element'
    }, "*");
  }
  };

  /**
  * @function handleElementEnter
  * @memberof module:QrCode.QrCode
  * @description Manages element hover state entry in preview mode.
  * Handles state transitions between different hovered elements and triggers
  * appropriate mouse enter events.
  *
  * @param {Event} e - Mouse event object
  * @param {React.RefObject} ref - Reference to the target DOM element
  * @param {string} id - Unique identifier for the element
  *
  * @example
  * handleElementEnter(event, elementRef, "element-id")
  *
  * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
  * @since 1.0.0
  * @version 1.0.0
  */
  const handleElementEnter = (e, ref, id) => {
  e.preventDefault();
  e.stopPropagation();

  // Se houver um hover ativo em outro elemento
  if (isHovered && isHovered !== id && currentRef) {
      handleMouseLeave(e, currentRef);
  }

  // Atualiza o estado para o novo elemento
  if(inspectMode){
  setCurrentRef(ref);
  handleMouseEnter(e, ref);
  setIsHovered(id);
  }
  };

  /**
  * @function handleElementLeave
  * @memberof module:QrCode.QrCode
  * @description Manages element hover state exit in preview mode.
  * Cleans up hover states and references when leaving an element.
  *
  * @param {Event} e - Mouse event object
  * @param {React.RefObject} ref - Reference to the target DOM element
  * @param {string} id - Unique identifier for the element
  *
  * @example
  * handleElementLeave(event, elementRef, "element-id")
  *
  * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
  * @since 1.0.0
  * @version 1.0.0
  */
  const handleElementLeave = (e, ref, id) => {
  e.preventDefault();
  e.stopPropagation();

  // Apenas limpa o estado se for o elemento atual com hover
  if (isHovered === id) {
      handleMouseLeave(e, ref);
      setIsHovered(null);
      setCurrentRef(null);
  }
  };

  /**
  * @function useEffectMessageHandler
  * @memberof module:QrCode.QrCode
  * @description Effect that sets up a message event listener for inspect mode toggling.
  * Manages the inspect mode state and persists it to localStorage.
  *
  * @effect Sets up and cleans up message event listener
  * @dependencies []
  *
  * @example
  * useEffect(() => {
  *   const handleMessage = (event) => { ... };
  *   window.addEventListener("message", handleMessage);
  *   return () => window.removeEventListener("message", handleMessage);
  * }, []);
  *
  * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
  * @since 1.0.0
  * @version 1.0.0
  */
  useEffect(() => {
  const handleMessage = (event) => {
  // Log para debug
  // console.log("Mensagem recebida no iframe:", event.data);

  if (event.data.action === "toggleInspectMode") {
    const newMode = event.data.value;
    setInspectMode(newMode);
    localStorage.setItem("inspectMode", newMode.toString());
  }
  };

  window.addEventListener("message", handleMessage);
  return () => window.removeEventListener("message", handleMessage);
  }, []);

  /**
  * @function useEffectThemeUpdate
  * @memberof module:QrCode.QrCode
  * @description Effect that registers a global theme update function.
  * Allows dynamic theme style updates through a globally accessible function.
  * Used in preview mode to listen for theme changes and apply them to the global state.
  * The global function is exposed to allow parent windows to trigger theme updates.
  *
  * @effect Sets up and cleans up global theme update function
  * @dependencies [dispatch]
  *
  * @example
  * useEffect(() => {
  *   window.__THEME_UPDATE__ = (themeStyleCode, value) => { ... };
  *   return () => { delete window.__THEME_UPDATE__; };
  * }, [dispatch]);
  *
  * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
  * @since 1.0.0
  * @version 1.0.0
  */
  useEffect(() => {
  // Registrar a função de atualização globalmente
  window.__THEME_UPDATE__ = (themeStyleCode, value) => {
    // console.log('Theme update function called:', { themeStyleCode, value });
    dispatch({
      type: 'UPDATE_ACTIVE_THEME_STYLE',
      payload: { themeStyleCode, value }
    });
  };
  return () => {
    delete window.__THEME_UPDATE__;
  };
  }, [dispatch]);

  //////////////////////////////////////////////////////////////////////////

 /**
  * @function getKeypassKeyString
  * @memberof module:QrCode.QrCode
  * @description Fetches keypass key for QR code generation.
  * Manages loading state and error handling.
  *
  * @async
  * @returns {Promise<void>}
  *
  * @example
  * await getKeypassKeyString()
  *
  * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
  * @since 1.0.0
  * @version 1.0.0
  */
  const getKeypassKeyString = async () => {
    try {
      setIsLoading(true);
      const result = await getKeypassKey(detailId, state.currentHotel, cookie.sUid);
      setKeypassKeyString(result);
    } catch (error) {
      console.log(error);
      toast.error("Unable to get KeyPass Key!");
    } finally {
      setIsLoading(false);
    }
  };

 /**
  * @function fetchReservationDetails
  * @memberof module:QrCode.QrCode
  * @description Fetches and updates reservation details.
  * Updates both local and global state with fetched data.
  *
  * @async
  * @returns {Promise<void>}
  *
  * @example
  * await fetchReservationDetails()
  *
  * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
  * @since 1.0.0
  * @version 1.0.0
  */
  const fetchReservationDetails = async () => {
    try {
      const data = await getReservationByDetailId(detailId, state.currentHotel, cookie.sUid);
      setReservation(data[0]);
      dispatch({ type: "SET_USER_RESERVATION", payload:data[0] });
    } catch (error) {
      console.error("App: Error fetching reservation info:", error);
      toast.error("App: Unable to get reservation info!");
    }
  };

 /**
  * @function useEffectFetchKeypass
  * @memberof module:QrCode.QrCode
  * @description Effect that triggers keypass key fetching on mount.
  *
  * @effect Fetches keypass key data
  * @dependencies []
  *
  * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
  * @since 1.0.0
  * @version 1.0.0
  */
 useEffect(() => {
  if(isPreviewMode){
      setKeypassKeyString(previewGuestKey);
      setIsLoading(false);
    return;
  }

    if (detailId && cookie.sUid && state.currentHotel) {
      getKeypassKeyString();
    }
  }, []); // eslint-disable-line

/**
  * @function useEffectFetchReservation
  * @memberof module:QrCode.QrCode
  * @description Effect that triggers reservation fetching when needed.
  *
  * @effect Fetches reservation data if not present
  * @dependencies [detailId]
  *
  * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
  * @since 1.0.0
  * @version 1.0.0
  */
 useEffect(() => {
  if(isPreviewMode){
    dispatch({ type: "SET_USER_RESERVATION", payload: previewHomeReservationsData[0].reservations[1] });
    setReservation(previewHomeReservationsData[0].reservations[1])
    return;
  }

    if(!reservation){
      fetchReservationDetails();
    }
  }, [detailId]); // eslint-disable-line

  return (
    <>
      {reservation && (
        <div id="QR-Page" ref={QRPageRef} onDoubleClick={(e) => handleDoubleClick(e, QRPageRef)} onMouseOver={(e) => handleElementEnter(e, QRPageRef, "QR-Page")} onMouseLeave={(e) => handleElementLeave(e, QRPageRef, "QR-Page")} className={`${isPreviewMode && isHovered === "QR-Page" ? "diagonal-stripes" : ""} dark:bg-gxp_dark_2 antialiased flex flex-col flex-1 w-full QR-bg_color ${isPreviewMode ? "h-screen" : "h-full"}`}>
          <section className="h-full w-full">
            <div id="QR-Header" ref={QRHeaderRef} onDoubleClick={(e) => handleDoubleClick(e, QRHeaderRef)} onMouseOver={(e) => handleElementEnter(e, QRHeaderRef, "QR-Header")} onMouseLeave={(e) => handleElementLeave(e, QRHeaderRef, "QR-Header")} className={`${isPreviewMode && isHovered === "QR-Header" ? "diagonal-stripes" : ""}`}>
               <HeadersPageQR title={`${t(`QrCode.qrcode`)}`} room={reservation?.room} />
            </div>
            <div className="flex flex-col justify-center items-center mt-5">
              {isLoading ? (
                <Loader />
              ) : (
                <QRCode key={darkMode} value={keypassKeyString || ""} status={isLoading ? "loading" : "active"} size={256} errorLevel="H" color={"#000000"} bgColor="#FFFFFF" bordered />
              )}
            </div>
          </section>
        </div>
      )}
    </>
  );
};