import React from "react";
import { MenuItem } from "./MenuItem";

/**
 * @namespace MenuDetail
 * @memberof module:EMenu
 * @description Component for rendering detailed view of a menu category.
 * Displays category header with optional image and list of menu items.
 * Supports both image-based and plain headers with dark mode compatibility.
 *
 * @component
 * @param {Object} props Component properties
 * @param {Object} props.category Category data object
 * @param {string} props.category.Id Unique identifier for the category
 * @param {string} props.category.Description Category title/description
 * @param {string} props.category.Notes Optional additional information
 * @param {string} props.category.ImageUrl Optional URL for category image
 * @param {Array<Object>} props.category.Children Array of menu items within this category
 * @param {Object} props.service Service configuration object containing menu settings
 *
 * @returns {JSX.Element|null} Detailed category view with items or null if data is missing
 *
 * @example
 * const category = {
 *   Id: '1',
 *   Description: 'Main Courses',
 *   Notes: 'Traditional dishes',
 *   ImageUrl: '/images/main.jpg',
 *   Children: [
 *     { Id: '101', Name: 'Pasta', Price: '12.99' }
 *   ]
 * };
 *
 * <MenuDetail
 *   category={category}
 *   service={serviceConfig}
 * />
 *
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @since 1.0.0
 * @version 1.0.0
 */
export const MenuDetail = ({ handleDoubleClick, category, service, isPreviewMode, isHovered, handleElementEnter, handleElementLeave, EMEMenuCardRef, EMCardRef, }) => {
  if (!category) return null;
  if (!service) return null;

  return (
    <div className="max-w-4xl mx-auto mt-2">
      <div id="EM-EMenuCard" ref={EMEMenuCardRef} onDoubleClick={(e) => handleDoubleClick(e, EMEMenuCardRef)} onMouseOver={(e) => handleElementEnter(e, EMEMenuCardRef, "EM-EMenuCard") } onMouseLeave={(e) => handleElementLeave(e, EMEMenuCardRef, "EM-EMenuCard") } style={ category.ImageUrl !== "" ? { backgroundImage: `url(${category.ImageUrl})`, backgroundSize: "cover", backgroundPosition: "center", } : {} } className={`${ isPreviewMode && isHovered === "EM-EMenuCard" ? "diagonal-stripes" : "" } ${ category.ImageUrl !== "" ? "EM-EMenuCard-with_image_border_color EM-EMenuCard-with_image_border_width EM-EMenuCard-with_image_border_position EM-EMenuCard-with_image_border_rounded" : "EM-EMenuCard-no_image_bg_color EM-EMenuCard-no_image_border_color EM-EMenuCard-no_image_border_width EM-EMenuCard-no_image_border_position EM-EMenuCard-no_image_border_rounded" } dark:border-gxp_dark_3 relative h-48 w-full overflow-hidden mb-2`} >
        <div className={`${ category.ImageUrl !== "" ? "bg-gradient-to-t from-black/60 to-transparent" : "" } absolute inset-0 h-full w-full`} />
        <div className="absolute bottom-0 left-0 right-0 p-4">
          <h2 className={`${ category.ImageUrl !== "" ? "EM-EMenuCard-with_image_title_color EM-EMenuCard-with_image_title_size EM-EMenuCard-with_image_title_variant EM-EMenuCard-with_image_title_font_family" : "EM-EMenuCard-no_image_title_color EM-EMenuCard-no_image_title_size EM-EMenuCard-no_image_title_variant EM-EMenuCard-no_image_title_font_family" }`} >
            {category.Description}
          </h2>
          {category.Notes && (
            <p className={`${ category.ImageUrl !== "" ? "EM-EMenuCard-with_image_text_color EM-EMenuCard-with_image_text_size EM-EMenuCard-with_image_text_variant EM-EMenuCard-with_image_text_font_family" : "EM-EMenuCard-no_image_text_color EM-EMenuCard-no_image_text_size EM-EMenuCard-no_image_text_variant EM-EMenuCard-no_image_text_font_family" } mt-1`} >
              {category.Notes}
            </p>
          )}
        </div>
      </div>

      <div className="space-y-3 p-2">
        {category.Children?.map((item) => (
          <MenuItem
            isPreviewMode={isPreviewMode}
            isHovered={isHovered}
            handleElementEnter={handleElementEnter}
            handleElementLeave={handleElementLeave}
            handleDoubleClick={handleDoubleClick}
            EMCardRef={EMCardRef}
            key={item.Id}
            item={item}
            service={service}
          />
        ))}
      </div>
    </div>
  );
};
