import { BoxSelect, Brush, Building2, GroupIcon, Home, Hotel, Layers, LayoutDashboard, LogOut, PaintBucket, Palette, Settings, Sliders, Users } from 'lucide-react';
import React from 'react'

export const sidebarCategoriesList = (t) => [
    {
      name: 'dashboard',
      label: `${t(`Admin.AdminPanel.dashboard`)}`,
      icon: <Home className="w-5 h-5" />,
      linkTo: '/admin/dashboard',
      roles: ['Admin', 'Developer', 'Comercial']
    },
    {
      name: 'hotels',
      label: `${t(`Admin.AdminPanel.hotels`)}`,
      icon: <Hotel className="w-5 h-5" />,
      roles: ['Admin', 'Developer', 'Comercial'],
      subCategories: [
        {
          name: 'grouphotels',
          label: `${t(`Admin.AdminPanel.hotelsGroup`)}`,
          icon: <GroupIcon className="w-5 h-5" />,
          roles: ['Admin', 'Developer', 'Comercial'],
          linkTo: '/admin/grouphotels'
        },
        {
          name: 'hotels',
          label: `${t(`Admin.AdminPanel.hotelsList`)}`,
          icon: <Building2 className="w-5 h-5" />,
          roles: ['Admin', 'Developer', 'Comercial'],
          linkTo: '/admin/hotels'
        },
      ]
    },
    {
      name: 'themesMenu',
      label: `${t(`Admin.AdminPanel.themes`)}`,
      icon: <Palette className="w-5 h-5" />,
      roles: ['Admin', 'Developer', 'Comercial'],
      subCategories: [
        {
          name: 'stylecategories',
          label: `${t(`Admin.AdminPanel.categories`)}`,
          icon: <Layers className="w-5 h-5" />,
          roles: ['Admin', 'Developer'],
          linkTo: '/admin/themes/stylecategories'
        },
        {
          name: 'stylesubcategories',
          label: `${t(`Admin.AdminPanel.subcategories`)}`,
          icon: <BoxSelect className="w-5 h-5" />,
          roles: ['Admin', 'Developer'],
          linkTo: '/admin/themes/stylesubcategories'
        },
        {
          name: 'themestyles',
          label: `${t(`Admin.AdminPanel.categoriesRelations`)}`,
          icon: <Brush className="w-5 h-5" />,
          roles: ['Admin', 'Developer'],
          linkTo: '/admin/themes/themestyles'
        },
        {
          name: 'themesdefault',
          label: `${t(`Admin.AdminPanel.defaultThemes`)}`,
          icon: <PaintBucket className="w-5 h-5" />,
          roles: ['Admin', 'Developer', 'Comercial'],
          linkTo: '/admin/themes/themesdefault'
        }
      ]
    },
    {
      name: 'settings',
      label: `${t(`Admin.AdminPanel.settings`)}`,
      icon: <Settings className="w-5 h-5" />,
      roles: ['Admin', 'Developer'],
      subCategories: [
        {
          name: 'parameters',
          label: `${t(`Admin.AdminPanel.parameters`)}`,
          icon: <Sliders className="w-5 h-5" />,
          roles: ['Admin', 'Developer'],
          linkTo: '/admin/parameters/parameters'
        }
      ]
    },
    {
      name: 'users',
      label: `${t(`Admin.AdminPanel.users`)}`,
      icon: <Users className="w-5 h-5" />,
      roles: ['Admin', 'Developer', 'Comercial'],
      linkTo: '/admin/users',
    },
  ];
