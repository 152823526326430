import { ArrowBigLeftDash, Eye, EyeOff, Plus, Save, X } from "lucide-react";
import { motion, AnimatePresence } from "framer-motion";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../context/AppContext";
import { Loader } from "../../../components/Layout/components/Loader/Loader";
import { registerUser } from "../../../services";
import { useTranslation } from "react-i18next";
import { usersRoles, usersStatus } from "../utils/usersRoles";
import { PasswordValidation } from "../../Pages/Auth/components/PasswordValidation";
import { CloseCircleOutlined, SaveOutlined } from "@ant-design/icons";
import Select from "react-select";
import { hostSelectCustomStylesModal } from "../../../01_HotelPanel/components/utils/hostSelectStyles";

export const UserModal = ({ isUserModalOpen, onCloseUserModal, title, type, user, }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordValidation, setShowPasswordValidation] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [userRoles, setUserRoles] = useState([]);
  const [userStatus, setUserStatus] = useState([]);
  const [state, dispatch] = useContext(AppContext);
  const [t] = useTranslation("global");
  const [saveStatus, setSaveStatus] = useState({
    status: null,
    message: "",
  });
  const [formData, setFormData] = useState({
    id: "",
    username: "",
    email: "",
    password: "",
    firstName: "",
    lastName: "",
    phone: "",
    role: "",
    status: "",
    isEmailVerified: "",
    user: "",
  });

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (name === "username" && type !== "checkbox") {
      // Verifica se já existe um username igual
      const userExists = state.adminInitialData?.users.some(
        (u) =>
          u.username.toLowerCase() === value.toLowerCase().trim() &&
          (!user || u.id !== user.id) // Ignora o próprio registro em caso de edição
      );

      if (userExists) {
        setSaveStatus({
          status: "error",
          message: `${t(`Admin.users.errorUsername`)}`,
        });
      } else {
        setSaveStatus({
          status: null,
          message: "",
        });
      }
    }

    if (name === "email" && type !== "checkbox") {
      // Verifica se já existe um email igual
      const emailExists = state.adminInitialData?.users.some(
        (u) =>
          u.email.toLowerCase() === value.toLowerCase().trim() &&
          (!user || u.id !== user.id) // Ignora o próprio registro em caso de edição
      );

      if (emailExists) {
        setSaveStatus({
          status: "error",
          message: `${t(`Admin.users.errorEmail`)}`,
        });
      } else {
        setSaveStatus({
          status: null,
          message: "",
        });
      }
    }

    setFormData((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const postUser = async () => {
    try {
      setIsSending(true); // Set sending state to true
      await registerUser(formData); // Send request
      setSaveStatus({
        status: "success",
        message: `${t(`Admin.users.success`)}`,
      });
      return true;
    } catch (error) {
      console.error("Error saving hotel:", error);
      setSaveStatus({
        status: "error",
        message: error.message || `${t(`Admin.users.error`)}`,
      });
      return false;
    } finally {
      setIsSending(false); // Set sending state back to false
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSending(true);
    if (type === "add") {
      const success = await postUser(formData);

      if (success) {
        // Aguarda 2 segundos para mostrar o feedback antes de fechar
        setTimeout(() => {
          dispatch({
            type: "SET_GLOBAL_REFRESH",
            payload: state.globalRefresh + 1,
          });
          onCloseUserModal();
        }, 2000);
      }
    } else if (type === "edit") {
      const success = await postUser(formData);

      if (success) {
        setTimeout(() => {
          dispatch({
            type: "SET_GLOBAL_REFRESH",
            payload: state.globalRefresh + 1,
          });
          onCloseUserModal();
        }, 2000);
      }
    }
  };

  useEffect(() => {
    if (type === "add") {
      setFormData({
        id: user.id,
        username: "",
        email: "",
        password: "",
        firstName: "",
        lastName: "",
        phone: "",
        role: "Pending",
        status: "Pending",
        isEmailVerified: false,
        user: state.adminLoggedIn.username,
      });
    } else if (type === "edit") {
      setFormData({
        id: user.id,
        username: user.username,
        email: user.email,
        password: user.password,
        firstName: user.firstName,
        lastName: user.lastName,
        phone: user.phone,
        role: user.role,
        status: user.status,
        isEmailVerified: user.isEmailVerified,
        user: state.adminLoggedIn.username,
      });
    }
  }, [isUserModalOpen]);

  useEffect(() => {
    setUserRoles(usersRoles);
    setUserStatus(usersStatus);
  }, []);

  return (
    <AnimatePresence>
      {isUserModalOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.2 }}
          className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
        >
          <motion.div
            initial={{ scale: 0.95, opacity: 0, y: 20 }}
            animate={{ scale: 1, opacity: 1, y: 0 }}
            exit={{ scale: 0.95, opacity: 0, y: 20 }}
            transition={{
              duration: 0.2,
              ease: "easeOut",
            }}
            className="bg-white rounded-lg shadow-xl w-full max-w-md"
            onClick={(e) => e.stopPropagation()}
          >
            <motion.div
              className="flex items-center justify-between p-4 border-b"
              initial={{ y: -10, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ delay: 0.1 }}
            >
              <h2 className="host-title-modal">{title}</h2>
              <motion.button
                onClick={onCloseUserModal}
                className="p-1 hover:bg-gray-100 rounded-full"
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
              >
                <CloseCircleOutlined className="w-5 h-5 items-center justify-center" />
              </motion.button>
            </motion.div>

            <motion.div
              className="p-4"
              initial={{ y: 10, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ delay: 0.15 }}
            >
              <>
                <form onSubmit={handleSubmit} className="space-y-4">
                  <div className="gap-2 grid grid-cols-2">
                    <div>
                      <label className="block host-text-modal">
                        {`${t(`Admin.users.id`)}`}
                      </label>
                      <input
                        disabled={type === "edit"}
                        type="text"
                        name="id"
                        value={formData.id}
                        onChange={handleChange}
                        className={`mt-1 block w-full host-search-input ${
                          type === "edit" ? "!bg-gray-100" : ""
                        }`}
                        required
                      />
                    </div>
                    <div>
                      <label className="block host-text-modal">
                        {`${t(`Admin.users.username`)}`}
                      </label>
                      <input
                        disabled={type === "edit"}
                        type="text"
                        name="username"
                        value={formData.username}
                        onChange={handleChange}
                        className={`mt-1 block w-full host-search-input ${
                          type === "edit" ? "!bg-gray-100" : ""
                        }`}
                        required
                      />
                    </div>
                  </div>

                  <div className="grid grid-cols-2 gap-2">
                    <div>
                      <label className="block host-text-modal">
                        {`${t(`Admin.users.firstname`)}`}
                      </label>
                      <input
                        type="text"
                        name="firstName"
                        value={formData.firstName}
                        onChange={handleChange}
                        className={`mt-1 block w-full host-search-input`}
                        required
                      />
                    </div>
                    <div>
                      <label className="block host-text-modal">
                        {`${t(`Admin.users.lastname`)}`}
                      </label>
                      <input
                        type="text"
                        name="lastName"
                        value={formData.lastName}
                        onChange={handleChange}
                        className={`mt-1 block w-full host-search-input`}
                        required
                      />
                    </div>
                  </div>

                  <div>
                    <label className="block host-text-modal">
                      {`${t(`Admin.users.email`)}`}
                    </label>
                    <input
                      type="text"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      className={`mt-1 block w-full host-search-input ${
                        type === "edit" ? "!bg-gray-100" : ""
                      }`}
                    />
                  </div>

                  <div className="grid grid-cols-2 gap-2">
                    <div className="relative">
                      <label className="block host-text-modal">
                        {`${t(`Admin.users.password`)}`}
                      </label>
                      <input
                        type={showPassword ? "text" : "password"}
                        name="password"
                        value={formData.password}
                        onChange={handleChange}
                        onBlur={() => setShowPasswordValidation(true)}
                        className={`mt-1 block w-full host-search-input ${!isPasswordValid && formData.password !== '' ? '!border-red-300' : '!border-gray-300'} px-3 py-2`}
                        required
                      />
                      <button
                        type="button"
                        onClick={togglePasswordVisibility}
                        className="absolute right-3 top-0 translate-y-8 text-gray-500 hover:text-gray-700 focus:outline-none"
                      >
                        {showPassword ? (
                          <EyeOff size={20} />
                        ) : (
                          <Eye size={20} />
                        )}
                      </button>
                      <PasswordValidation
                                        password={formData.password}
                                        onValidationChange={setIsPasswordValid}
                                        showValidation={showPasswordValidation}
                                    />
                    </div>
                    <div>
                      <label className="block host-text-modal">
                        {`${t(`Admin.users.phone`)}`}
                      </label>
                      <input
                        type="text"
                        name="phone"
                        value={formData.phone}
                        onChange={handleChange}
                        className={`mt-1 block w-full host-search-input`}
                        required
                      />
                    </div>
                  </div>

                  <div className="grid grid-cols-2 gap-2">
                    <div className="">
                      <label className="block host-text-modal">
                        {`${t(`Admin.users.status`)}`}
                      </label>
                      <Select
                          classNamePrefix="select"
                          className="w-full mt-1"
                          styles={hostSelectCustomStylesModal}
                          isSearchable={false}
                          name="status"
                          placeholder="Select status"
                          value={
                            userStatus?.find(status => status.name === formData.status)
                              ? { value: formData.status, label: formData.status }
                              : null
                          }
                          options={userStatus?.map(status => ({
                            value: status.name,
                            label: status.name
                          }))}
                          onChange={(selectedOption) => {
                            setFormData(prev => ({
                              ...prev,
                              status: selectedOption ? selectedOption.value : ""
                            }));
                          }}
                          inputProps={{
                            style: { textIndent: "0", marginLeft: "0" },
                          }}
                        />
                    </div>
                    <div className="">
                      <label className="block host-text-modal">
                        {`${t(`Admin.users.role`)}`}
                      </label>
                      <Select
                          classNamePrefix="select"
                          className="w-full mt-1"
                          styles={hostSelectCustomStylesModal}
                          isSearchable={false}
                          name="role"
                          placeholder="Select role"
                          value={
                            userRoles?.find(role => role.name === formData.role)
                              ? { value: formData.role, label: formData.role }
                              : null
                          }
                          options={userRoles?.map(role => ({
                            value: role.name,
                            label: role.name
                          }))}
                          onChange={(selectedOption) => {
                            setFormData(prev => ({
                              ...prev,
                              role: selectedOption ? selectedOption.value : ""
                            }));
                          }}
                          inputProps={{
                            style: { textIndent: "0", marginLeft: "0" },
                          }}
                        />
                    </div>
                  </div>

                  {type === "edit" && (
                    <div className="flex items-center">
                      <input
                        type="checkbox"
                        id="isEmailVerified"
                        name="isEmailVerified"
                        checked={formData.isEmailVerified}
                        onChange={handleChange}
                        className={`h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded bg-gray-100`}
                        disabled={true}
                      />
                      <label
                        htmlFor="isEmailVerified"
                        className="ml-2 block text-sm text-gray-900"
                      >
                        {`${t(`Admin.users.emailverified`)}`}
                      </label>
                    </div>
                  )}

                  {/* Status Message */}
                  {saveStatus.status && (
                    <div
                      className={`p-3 rounded-md ${
                        saveStatus.status === "success"
                          ? "bg-green-50 text-green-700"
                          : "bg-red-50 text-red-700"
                      }`}
                    >
                      {saveStatus.message}
                    </div>
                  )}

                  <div className="flex justify-end space-x-3 pt-2">
                    {isSending ? (
                      <button
                        type="submit"
                        className="px-4 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                      >
                        <Loader />
                      </button>
                    ) : (
                      <button
                        type="submit"
                        disabled={saveStatus.status === "error" || isSending}
                        className="px-4 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                      >
                        <SaveOutlined className="w-5 h-5 items-center justify-center" />
                      </button>
                    )}
                  </div>
                </form>
              </>
            </motion.div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};
