import { useState, useRef, useEffect, useContext } from "react";
import { ArrowBigLeftDash, ArrowBigRightIcon, CopyIcon } from "lucide-react";
import {
  FirstName,
  LastName,
  BirthDate,
  Nif,
  Nationality,
  Gender,
  DocumentType,
  DocumentNumber,
  DocumentExpireDate,
  DocumentAuthor,
  Email,
  Phone,
  Mobile,
  ShortTitle,
  LongTitle,
  Title,
  Address1,
  ZipCode,
  Town,
  Country,
  Address2,
  Address3,
  AddressState,
  Region,
  VehicleNumber,
  CompanyVatNumber,
  CompanyDesignation,
  CompanyAddress,
  CompanyZipCode,
  CompanyTown,
  CompanyCountry,
  IndividualVatNumber,
  IndividualLastName,
  IndividualFirstName,
  IndividualAddress,
  IndividualZipCode,
  IndividualTown,
  IndividualCountry,
} from "./FormFieldsGXP/index";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";
import ScanDocYouverse from "./Utilities/ScanDocYouverse";
import { AppContext } from "../../../context/AppContext";
import { organizeFieldsBySection, getSectionOrder, mapFieldToComponent, getBillingCompanySection, getBillingIndividualSection, } from "./Utilities/organizeFieldsBySection";
import { CheckFieldVisibility } from "./Utilities/CheckFieldVisibility";
import { sectionHasVisibleFields } from "./Utilities/sectionHasVisibleFields";

/**
 * @namespace HostForm
 * @memberof module:OnlineCheckIn
 * @description Component responsible for rendering and managing the guest information form.
 * Handles data input, validation, and form navigation for each guest's details.
 *
 * @component
 * @param {Object} props Component properties
 * @param {Object} props.localReservation Current reservation data in local storage
 * @param {Function} props.setLocalReservation Function to update local reservation
 * @param {React.RefObject} props.OCButton1Ref Reference to continue button
 * @param {React.RefObject} props.OCFormFieldRef Reference to form fields
 * @param {string} props.isHovered Currently hovered element ID
 * @param {Function} props.handleElementEnter Function to handle element hover enter
 * @param {Function} props.handleElementLeave Function to handle element hover leave
 * @param {number} props.activeTab Currently active guest tab
 * @param {Function} props.setActiveTab Function to change active tab
 * @param {boolean} props.showConsent Flag for showing consent section
 * @param {boolean} props.showGeneralTerms Flag for showing general terms
 * @param {Function} props.previous Function to go to previous guest
 * @param {Function} props.next Function to go to next guest
 * @param {boolean} props.isMain Flag indicating if this is the main guest
 * @param {boolean} props.mainGuest Flag indicating if this guest is the main guest
 * @param {Object} props.guest Current guest data
 * @param {Array} props.guests List of all guests
 * @param {Function} props.setGuests Function to update guests list
 * @param {Object} props.initialData Initial form configuration data
 * @param {Object} props.titles Available titles for military mode
 * @param {boolean} props.militaryMode Flag indicating if military mode is active
 * @param {boolean} props.titlesLoading Flag indicating if titles are loading
 * @param {Function} props.setTitlesLoading Function to update titles loading state
 * @param {boolean} props.isPreviewMode Flag indicating if form is in preview mode
 *
 * @returns {JSX.Element} Host form component
 *
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @since 1.0.0
 * @version 1.0.0
 */
export const HostForm = ({ handleDoubleClick, localReservation, setLocalReservation, OCButton1Ref, OCFormFieldRef, isHovered, handleElementEnter, handleElementLeave, activeTab, setActiveTab, showConsent, showGeneralTerms, previous, next, isMain, guest, guests, setGuests, initialData, titles, militaryMode, titlesLoading, setTitlesLoading, isPreviewMode, }) => {
  const [nifValues, setNifValues] = useState({}); // State to store NIFs for each guest
  const [requiredFields, setRequiredFields] = useState([]);
  const [fieldErrors, setFieldErrors] = useState({});
  const [emailError, setEmailError] = useState(""); // State to track the error message
  const [t] = useTranslation("global");
  const [isSameCountry, setIsSameCountry] = useState(false);
  const { pathname } = useLocation();
  const OCFormRef = useRef(null);
  const [billingType, setBillingType] = useState(null);
  const { detailId } = useParams();
  const [state, dispatch] = useContext(AppContext);
  const [docScannerActive, setDocScannerActive] = useState("");
  const [guestFieldsGXP, setGuestFieldsGXP] = useState([]);
  const [organizedFields, setOrganizedFields] = useState({});
  const [ocInvoiceDataIsActive, setOcInvoiceDataIsActive] = useState(false);

  const redirectUrl =
    process.env.NODE_ENV === "development"
      ? "http://localhost:3001" + pathname + "/scancheck"
      : "https://secure.staynow.app" + pathname + "/scancheck";

  useEffect(() => {
    if (guestFieldsGXP.length > 0) {
      setOrganizedFields(organizeFieldsBySection(guestFieldsGXP));
    }
  }, [guestFieldsGXP]);

  useEffect(() => {
    const fetchGuestFieldsGXP = async () => {
      setGuestFieldsGXP(state.hotelInitialData.GuestFieldsOCGXP);
    };

    if (state.hotelInitialData) {
      fetchGuestFieldsGXP();
    }
  }, [state.hotelInitialData]);

  /**
   * @function useEffectBillingType
   * @memberof module:OnlineCheckIn.HostForm
   * @description Effect that manages billing type initialization and updates.
   * Sets billing type based on local reservation and detail ID comparison.
   *
   * @effect Updates billing type state
   * @dependencies [detailId]
   *
   * @example
   * useEffect(() => {
   *   if(localReservation && detailId) {
   *     // billing type logic
   *   }
   * }, [detailId]);
   *
   * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @since 1.0.0
   * @version 1.0.0
   */
  useEffect(() => {
    if (localReservation && detailId) {
      if (detailId && detailId === localReservation.detailId) {
        setBillingType(localReservation.billingType);
        return;
      }
      if (detailId && detailId !== localReservation.detailId) {
        setBillingType("sameAsGuest");
        return;
      }
    }
  }, [detailId]);

  /**
   * @function useEffectDocScanner
   * @memberof module:OnlineCheckIn.HostForm
   * @description Effect that initializes document scanner based on GXP parameters.
   * Sets document scanner partner based on GXP configuration.
   *
   * @effect Updates document scanner active state
   * @dependencies [state.gxpParameters]
   *
   * @example
   * useEffect(() => {
   *   if(state.gxpParameters) {
   *     // document scanner initialization
   *   }
   * }, [state.gxpParameters]);
   *
   * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @since 1.0.0
   * @version 1.0.0
   */
  useEffect(() => {
    if (state.gxpParameters) {
      const partner = state.gxpParameters.find(
        (parameter) => parameter.paramCode === "oc_docreader_service"
      ).value;

      setDocScannerActive(partner);
    }
  }, [state.gxpParameters]);

  /**
   * @function handleGuestChange
   * @memberof module:OnlineCheckIn.HostForm
   * @description Updates a specific field in the guest's data and syncs with local storage.
   *
   * @param {number} id Guest identifier
   * @param {string} fieldName Name of the field to update
   * @param {any} value New value for the field
   *
   * @example
   * handleGuestChange(1, "FirstName", "John")
   *
   * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @since 1.0.0
   * @version 1.0.0
   */
  const handleGuestChange = (id, fieldName, value) => {
    setGuests((prevGuests) =>
      prevGuests.map((guest) =>
        guest.id === id
          ? { ...guest, data: { ...guest.data, [fieldName]: value } }
          : guest
      )
    );

    setLocalReservation((prevReservation) => {
      // Acessar o estado mais recente dos guests através de uma ref ou estado global
      const updatedGuests = guests.map((guest) => {
        if (guest.id === id) {
          return {
            ...guest,
            data: { ...guest.data, [fieldName]: value },
          };
        }
        return guest;
      });

      return {
        ...prevReservation,
        reservation: {
          ...prevReservation.reservation,
          Guests: updatedGuests.map((guest) => guest.data),
        },
      };
    });

    /**
     * @function clearFieldError
     * @memberof module:OnlineCheckIn.HostForm
     * @description Removes the error message for a specific field from the errors state.
     * Creates a new error state object without the specified field's error.
     *
     * @param {string} fieldName - The name of the field to clear errors for
     *
     * @example
     * // To clear errors for the 'FirstName' field
     * setFieldErrors((prevErrors) => {
     *   const newErrors = { ...prevErrors };
     *   delete newErrors['FirstName'];
     *   return newErrors;
     * });
     *
     * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
     * @since 1.0.0
     * @version 1.0.0
     */
    setFieldErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      delete newErrors[fieldName];
      return newErrors;
    });
  };

  /**
   * @function copyAddressData
   * @memberof module:OnlineCheckIn.HostForm
   * @description Copies address data from the main guest to another guest.
   *
   * @param {number} guestIndex Index of the guest to copy data to
   * @param {Event} e Event object
   *
   * @example
   * copyAddressData(2, event)
   *
   * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @since 1.0.0
   * @version 1.0.0
   */
  const copyAddressData = (guestIndex, e) => {
    e.preventDefault();

    setGuests((prevGuests) => {
      const mainGuestIndex = prevGuests.findIndex((g) => g.data.IsMainGuest);
      const mainGuest = prevGuests[mainGuestIndex];

      const updatedGuests = [...prevGuests]; // Create a copy of the previous state

      // Check if the guest index is valid
      if (updatedGuests[guestIndex]) {
        updatedGuests[guestIndex] = {
          ...updatedGuests[guestIndex], // Keep current guest data
          data: {
            ...updatedGuests[guestIndex].data, // Keep current guest data
            CountryId: mainGuest?.data?.CountryId,
            CountryIso: mainGuest?.data?.CountryIso,
            Address1: mainGuest?.data?.Address1,
            Address2: mainGuest?.data?.Address2,
            Address3: mainGuest?.data?.Address3,
            Town: mainGuest?.data?.Town,
            ZipCode: mainGuest?.data?.ZipCode,
            AddressState: mainGuest?.data?.AddressState,
            Region: mainGuest?.data?.Region,
          },
        };
      }

      return updatedGuests; // Return the updated state
    });
  };

  /**
   * @function validateNIF
   * @memberof module:OnlineCheckIn.HostForm
   * @description Validates a tax identification number (NIF/VAT) based on country rules.
   *
   * @param {string} nif NIF/VAT number to validate
   * @param {string} country Country code for validation rules
   * @returns {boolean} True if NIF is valid, false otherwise
   *
   * @example
   * validateNIF("123456789", "PT")
   *
   * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @since 1.0.0
   * @version 1.0.0
   */
  const validateNIF = (nif, country) => {
    if (!nif || typeof nif !== "string") {
      return false; // Return false if the NIF is invalid
    }

    // Remove all non-numeric characters from the NIF
    nif = nif.replace(/[^\d]+/g, "");

    if (country === "PT") {
      // NIF validation for Portugal (9 digits)
      if (nif.length !== 9) return false;

      const initialDigits = ["1", "2", "3", "5", "6", "7", "8", "9"];
      const firstDigit = nif.charAt(0);

      if (!initialDigits.includes(firstDigit)) return false;

      // Calculate the check digit
      let checkDigit = parseInt(firstDigit) * 9;
      for (let i = 2; i <= 8; i++) {
        checkDigit += parseInt(nif.charAt(i - 1)) * (10 - i);
      }

      checkDigit = 11 - (checkDigit % 11);
      if (checkDigit >= 10) checkDigit = 0;

      return checkDigit === parseInt(nif.charAt(8));
    }

    // Return true for NIFs from other countries, if you don't want to validate
    return true; // Change to false if you want it to return false for NIFs from other countries
  };

  /**
   * @function handleNifChange
   * @memberof module:OnlineCheckIn.HostForm
   * @description Handles changes to the NIF field and clears related errors.
   *
   * @param {number} guestId Guest identifier
   * @param {string} value New NIF value
   *
   * @example
   * handleNifChange(1, "123456789")
   *
   * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @since 1.0.0
   * @version 1.0.0
   */
  const handleNifChange = (guestId, value) => {
    handleGuestChange(guestId, "FiscalNumber", value);
    // Clear any existing NIF error
    setFieldErrors((prev) => ({ ...prev, FiscalNumber: "" }));
  };

  /**
   * @function handleInvoiceNifChange
   * @memberof module:OnlineCheckIn.HostForm
   * @description Handles changes to the invoice VAT/NIF field.
   * Updates the guest's invoice VAT number and clears any existing validation errors.
   *
   * @param {number} guestId - The ID of the guest whose invoice NIF is being updated
   * @param {string} value - The new VAT/NIF value
   *
   * @example
   * // Update invoice VAT number for guest with ID 1
   * handleInvoiceNifChange(1, "123456789")
   *
   * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @since 1.0.0
   * @version 1.0.0
   */
  const handleInvoiceNifChange = (guestId, value) => {
    handleGuestChange(guestId, "invoiceEntityVATNumber", value);
    // Clear any existing NIF error
    setFieldErrors((prev) => ({ ...prev, invoiceEntityVATNumber: "" }));
  };

  /**
   * @function validateNifOnBlur
   * @memberof module:OnlineCheckIn.HostForm
   * @description Validates NIF when input loses focus, considering country context.
   *
   * @param {number} guestId Guest identifier
   * @param {string} nif NIF to validate
   * @param {string} country Country code for validation
   *
   * @example
   * validateNifOnBlur(1, "123456789", "PT")
   *
   * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @since 1.0.0
   * @version 1.0.0
   */
  const validateNifOnBlur = (guestId, nif, country) => {
    if (!nif || nif.trim() === "") {
      // Clear any error if the field is empty
      if (fieldErrors.FiscalNumber) {
        setFieldErrors((prev) => ({ ...prev, FiscalNumber: "" }));
      }
      return;
    }
    if (isSameCountry) {
      if (!validateNIF(nif, country)) {
        setFieldErrors((prev) => ({
          ...prev,
          FiscalNumber: t(`HostForm.invalidvat`),
        }));
      } else {
        setFieldErrors((prev) => ({ ...prev, FiscalNumber: "" }));
      }
    }
  };

  /**
   * @function validateInvoiceNifOnBlur
   * @memberof module:OnlineCheckIn.HostForm
   * @description Validates the invoice VAT/NIF number when the input loses focus.
   * Clears errors if the field is empty or updates error state based on validation result.
   * Only performs validation if the guest's country matches the hotel's country.
   *
   * @param {number} guestId - The ID of the guest whose NIF is being validated
   * @param {string} nif - The VAT/NIF number to validate
   * @param {string} country - The country code used for validation rules
   *
   * @example
   * // For a Portuguese VAT number
   * validateInvoiceNifOnBlur(1, "123456789", "PT")
   *
   * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @since 1.0.0
   * @version 1.0.0
   */
  const validateInvoiceNifOnBlur = (guestId, nif, country) => {
    if (!nif || nif.trim() === "") {
      // Clear any error if the field is empty
      setFieldErrors((prev) => ({ ...prev, invoiceEntityVATNumber: "" }));
      return;
    }
    if (isSameCountry) {
      if (!validateNIF(nif, country)) {
        setFieldErrors((prev) => ({
          ...prev,
          invoiceEntityVATNumber: t(`HostForm.invalidvat`),
        }));
      } else {
        setFieldErrors((prev) => ({ ...prev, invoiceEntityVATNumber: "" }));
      }
    }
  };

  /**
   * @function validateRequiredFields
   * @memberof module:OnlineCheckIn.HostForm
   * @description Validates all required fields for the current guest.
   *
   * @returns {boolean} True if all required fields are valid
   *
   * @example
   * if (validateRequiredFields()) {
   *   // proceed with form submission
   * }
   *
   * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @since 1.0.0
   * @version 1.0.0
   */

  const validateRequiredFields = () => {
    const errors = { ...fieldErrors }; // Mantém os erros existentes (incluindo erros de NIF)
    let allFieldsFilled = true;

    let fieldMapping;

    // Mapeamento de Codes para nomes de campos em guest.data
    if (billingType === "sameAsGuest") {
    fieldMapping = {
      NAME1: "LastName",
      NAME2: "FirstName",
      BIRTHDATE: "BirthDate",
      VATNUMBER: "FiscalNumber",
      NATIONALITY: "NationalityId",
      SEX: "Gender",
      PASSTYPE: "DocType",
      DOCNUMBER: "DocNumber",
      DocExpiryDate: "DocVal",
      ISSUEAUTHOR: "DocIssueAuthor",
      EMAIL1: "Email",
      TELEPHONE1: "Phone",
      MOBILEPHONE1: "Mobile",
      ADDRESS1: "Address1",
      ADDRESS2: "Address2",
      ADDRESS3: "Address3",
      ADDRESSSTATE: "AddressState",
      TOWN: "Town",
      ZIPCODE: "ZipCode",
      COUNTRY_ID: "CountryId",
      REGION_ID: "RegionId",
      TITLE_ID: "Title_Id",
      TITLESHORT_ID: "TitleShort_Id",
      TITLELONG_ID: "TitleLong_Id",
      TITLEPREFIX_ID: "TitlePrefix_Id",
      ADDRESS1: "Address1",
      ZIPCODE: "ZipCode",
      TOWN: "Town",
      COUNTRY_ID: "Country",
      ADDRESS2: "Address2",
      ADDRESS3: "Address3",
      ADDRESSSTATE: "AddressState",
      REGION_ID: "Region",
      VEHICLENUMBER: "VehicleNumber",
    };} else {
      fieldMapping = {
        NAME1: "LastName",
        NAME2: "FirstName",
        BIRTHDATE: "BirthDate",
        VATNUMBER: "FiscalNumber",
        NATIONALITY: "NationalityId",
        SEX: "Gender",
        PASSTYPE: "DocType",
        DOCNUMBER: "DocNumber",
        DocExpiryDate: "DocVal",
        ISSUEAUTHOR: "DocIssueAuthor",
        EMAIL1: "Email",
        TELEPHONE1: "Phone",
        MOBILEPHONE1: "Mobile",
        ADDRESS1: "Address1",
        ADDRESS2: "Address2",
        ADDRESS3: "Address3",
        ADDRESSSTATE: "AddressState",
        TOWN: "Town",
        ZIPCODE: "ZipCode",
        COUNTRY_ID: "CountryId",
        REGION_ID: "RegionId",
        TITLE_ID: "Title_Id",
        TITLESHORT_ID: "TitleShort_Id",
        TITLELONG_ID: "TitleLong_Id",
        TITLEPREFIX_ID: "TitlePrefix_Id",
        ADDRESS1: "Address1",
        ZIPCODE: "ZipCode",
        TOWN: "Town",
        COUNTRY_ID: "Country",
        ADDRESS2: "Address2",
        ADDRESS3: "Address3",
        ADDRESSSTATE: "AddressState",
        REGION_ID: "Region",
        VEHICLENUMBER: "VehicleNumber",
        COMPANYVATNUMBER: "invoiceEntityVATNumber",
        COMPANYDESIGNATION: "invoiceEntityLastName",
        COMPANYADDRESS: "invoiceEntityAddress1",
        COMPANYZIPCODE: "invoiceEntityZipCode",
        COMPANYTOWN: "invoiceEntityTown",
        COMPANYCOUNTRY_ID: "invoiceEntityCountryId",
        INDIVIDUALVATNUMBER: "invoiceEntityVATNumber",
        INDIVIDUALNAME1: "invoiceEntityLastName",
        INDIVIDUALNAME2: "invoiceEntityFirstName",
        INDIVIDUALADDRESS: "invoiceEntityAddress1",
        INDIVIDUALZIPCODE: "invoiceEntityZipCode",
        INDIVIDUALTOWN: "invoiceEntityTown",
        INDIVIDUALCOUNTRY_ID: "invoiceEntityCountryId",
      };
    }

    // Verificar campos obrigatórios
    requiredFields.forEach((field) => {
      const { isVisible, isMandatory } = CheckFieldVisibility(field, guest, state);

      // Apenas valida se o campo for visível e obrigatório
      if (isVisible && isMandatory) {
        // Usa o mapeamento para encontrar o nome correto do campo no guest.data
        const dataFieldName = fieldMapping[field.Code];

        if (!dataFieldName) {
          console.warn(`Campo não mapeado: ${field.Code}`);
          return; // Pula este campo se não estiver mapeado
        }

        const fieldValue = guest.data[dataFieldName];

        // Validação de campo vazio
        if (
          fieldValue === undefined ||
          fieldValue === null ||
          fieldValue === "" ||
          fieldValue === 0
        ) {
          errors[dataFieldName] = `${t("HostForm.fieldRequired")}`;
          allFieldsFilled = false;
        }
      }
    });

    // Adicionar erro de email se existir
    if (emailError !== "") {
      errors["Email"] = `${t(`Email.error`)}`;
      allFieldsFilled = false;
    }

    // Verificar erros de NIF existentes
    if (fieldErrors.invoiceEntityVATNumber || fieldErrors.FiscalNumber) {
      allFieldsFilled = false;
    }

    setFieldErrors(errors);
    return allFieldsFilled || guest.data.FillLater;
  };

  /**
   * @function handleContinue
   * @memberof module:OnlineCheckIn.HostForm
   * @description Handles form continuation after validating required fields.
   *
   * @param {Event} e Event object
   *
   * @example
   * handleContinue(event)
   *
   * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @since 1.0.0
   * @version 1.0.0
   */
  const handleContinue = (e) => {
    e.preventDefault(); // Prevents form submission

    if (isPreviewMode) {
      handleElementLeave(e, OCButton1Ref, "OC-Button1");
      next(e, guest.id); // If validation passes, calls the next function

      window.parent.postMessage(
        {
          action: "OC-ConsentPage",
          debugInfo: "Change to Consent Page",
        },
        "*"
      );

      return;
    }

    if (validateRequiredFields(e)) {
      next(e, guest.id); // If validation passes, calls the next function
    }
  };

  /**
   * @function handleBillingTypeChange
   * @memberof module:OnlineCheckIn.InvoiceData
   * @param {string} type - The new billing type to set
   *
   * @description Handles changes to the billing type, updating guest data, local reservation,
   * and clearing any existing invoice-related errors
   *
   * @example
   * handleBillingTypeChange('company')
   *
   * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @version 1.0.0
   * @since 1.0.0
   */
  const handleBillingTypeChange = (type) => {
    setBillingType(type);

    // Construir os novos dados do guest
    const updatedGuestData = {
      ...guest.data,
      invoiceEntityVATNumber: "",
      invoiceEntityFirstName: "",
      invoiceEntityLastName: "",
      invoiceEntityAddress1: "",
      invoiceEntityTown: "",
      invoiceEntityZipCode: "",
      invoiceEntityCountryId: null,
      invoiceEntityCountryIso: "",
      billingType: type,
    };

    // Se for sameAsGuest, copiar os dados do guest
    if (type === "sameAsGuest" && guest.data.IsMainGuest) {
      updatedGuestData.invoiceEntityVATNumber = guest.data.FiscalNumber ;
      updatedGuestData.invoiceEntityFirstName = guest.data.FirstName ;
      updatedGuestData.invoiceEntityLastName = guest.data.LastName ;
      updatedGuestData.invoiceEntityAddress1 = guest.data.Address1 ;
      updatedGuestData.invoiceEntityTown = guest.data.Town ;
      updatedGuestData.invoiceEntityZipCode = guest.data.ZipCode ;
      updatedGuestData.invoiceEntityCountryId = guest.data.CountryId ;
      updatedGuestData.invoiceEntityCountryIso = guest.data.CountryIso ;
      updatedGuestData.billingType = "sameAsGuest";
    }

    if (fieldErrors) {
      const newError = { ...fieldErrors };
      delete newError.invoiceEntityVATNumber;
      delete newError.invoiceEntityFirstName;
      delete newError.invoiceEntityLastName;
      delete newError.invoiceEntityAddress1;
      delete newError.invoiceEntityTown;
      delete newError.invoiceEntityZipCode;
      delete newError.invoiceEntityCountryId;
      // Nota: invoiceEntityCountryIso e IsInvoiceSameAsGuest não são campos de erro tipicamente

      // Atualizar o estado de erro (assumindo que existe uma função para isso)
      setFieldErrors(newError); // Você precisará receber setFieldErrors como prop
    }

    // Atualizar guests e localReservation em uma única operação
    setGuests((prevGuests) =>
      prevGuests.map((g) =>
        g.id === guest.id ? { ...g, data: updatedGuestData } : g
      )
    );

    // Atualizar localReservation com o novo billingType e guests atualizados
    setLocalReservation((prev) => ({
      ...prev,
      billingType: type,
      reservation: {
        ...prev.reservation,
        Guests: prev.reservation.Guests.map((g, index) =>
          index === guest.id ? updatedGuestData : g
        ),
      },
    }));
  };

  /**
   * @function useEffectRequiredFields
   * @memberof module:OnlineCheckIn.HostForm
   * @description Effect that initializes required fields based on initial data and military mode.
   * Combines standard guest fields with military-specific fields when applicable.
   *
   * @effect Updates required fields state
   * @dependencies [initialData]
   *
   * @example
   * useEffect(() => {
   *   if (initialData?.GuestFields) {
   *     // required fields setup
   *   }
   * }, [initialData]);
   *
   * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @since 1.0.0
   * @version 1.0.0
   */
  useEffect(() => {
    if (state?.hotelInitialData?.GuestFieldsOCGXP) {
      setRequiredFields(
        Array.isArray(state?.hotelInitialData?.GuestFieldsOCGXP)
          ? state.hotelInitialData.GuestFieldsOCGXP
          : []
      );
    }
  }, [state?.hotelInitialData]);

  /**
   * @function useEffectCountryCheck
   * @memberof module:OnlineCheckIn.HostForm
   * @description Effect that checks if guest's country matches hotel's country.
   * Updates isSameCountry state based on comparison.
   *
   * @effect Updates isSameCountry state
   * @dependencies [initialData?.Hotel?.CountryCodeLic, guest?.data?.CountryIso, isSameCountry]
   *
   * @example
   * useEffect(() => {
   *   if (!initialData?.CountryList || !initialData?.Hotel?.CountryCodeLic) return;
   *   if (!guest?.data?.CountryIso) return;
   *   // country comparison logic
   * }, [initialData?.Hotel?.CountryCodeLic, guest?.data?.CountryIso, isSameCountry]);
   *
   * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @since 1.0.0
   * @version 1.0.0
   */
  useEffect(() => {
    if (
      !state?.hotelInitialData?.CountryList ||
      !state?.currentHotel?.hotelCountry
    )
      return;
    if (!guest?.data?.NationalityIso) return;

    const hotelCountry = state?.hotelInitialData?.CountryList.find(
      (element) => element?.IsoCode === state?.currentHotel?.hotelCountry
    );

    const newIsSameCountry =
      hotelCountry?.IsoCode === guest.data.NationalityIso;
    if (isSameCountry !== newIsSameCountry) {
      setIsSameCountry(newIsSameCountry);
    }
  }, [
    state?.currentHotel?.hotelCountry,
    guest?.data?.NationalityIso,
    isSameCountry,
  ]);

  /**
   * @function useEffectInvoiceDataActive
   * @memberof module:OnlineCheckIn.InvoiceData
   * @param {Array} state.gxpParameters
   *
   * @description Effect that manages invoice data form visibility based on GXP parameters.
   *
   * @effect Updates ocInvoiceDataIsActive state
   * @dependencies [state.gxpParameters]
   *
   * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @since 1.0.0
   * @version 1.0.0
   */
  useEffect(() => {
    setOcInvoiceDataIsActive(
      state?.gxpParameters?.find(
        (style) =>
          style.paramCode === "oc_show_invoice_data_form" && style.isActive
      )?.value || "false"
    );
  }, [state?.gxpParameters]);

  return (
    <>
      {((billingType &&
        guest &&
        initialData &&
        titles &&
        militaryMode === true &&
        !titlesLoading) ||
        (guest && initialData && militaryMode === false)) && (
        <div
          id="OC-Form"
          ref={OCFormRef}
          onDoubleClick={(e) => handleDoubleClick(e, OCFormRef)}
          onMouseOver={(e) => handleElementEnter(e, OCFormRef, "OC-Form")}
          onMouseLeave={(e) => handleElementLeave(e, OCFormRef, "OC-Form")}
          className={`${
            isPreviewMode && isHovered === "OC-Form" ? "diagonal-stripes" : ""
          } OC-Form-bg_color OC-Form-border_color OC-Form-border_rounded OC-Form-border_position OC-Form-border_width px-4 py-2 mx-auto dark:bg-gxp_dark_3 shadow mt-4`}
        >
          <div className={`flex justify-between items-center`}>
            <div className="flex items-center justify-center">
              <h2
                className={`OC-Form-title_color OC-Form-title_size OC-Form-title_font_family OC-Form-title_variant font-bold dark:text-white mr-3`}
              >
                {" "}
                {guest.title}
                {""}{" "}
              </h2>
              {activeTab === guest.id && docScannerActive === "Youverse" && (
                <ScanDocYouverse
                  guest={guest}
                  guests={guests}
                  detailId={detailId}
                  redirectUrl={redirectUrl}
                  platformId={state?.currentHotel?.hotelId}
                  setActiveTab={setActiveTab}
                  setGuests={setGuests}
                />
              )}
            </div>
            <div
              className={` ${guest.id === 0 ? "hidden" : ""} flex items-center`}
            >
              <input
                name={`FillLater`}
                type="checkbox"
                checked={guest.data.FillLater}
                onChange={(e) =>
                  handleGuestChange(guest.id, e.target.name, e.target.checked)
                }
                className={` mr-2`}
              />
              <span className="dark:text-white OC-Form-text_color OC-Form-text_size OC-Form-text_font_family OC-Form-text_variant">{`${t(
                `HostForm.filllater`
              )}`}</span>
            </div>
          </div>
          <div className={``}>
            {getSectionOrder().map((sectionName) => {
              if (
                organizedFields[sectionName] &&
                organizedFields[sectionName].length > 0
              ) {
                // Check if the section has any visible fields for this guest
                const hasVisibleFields = sectionHasVisibleFields(
                  organizedFields[sectionName],
                  guest,
                  state
                );

                // Only render the section if it has visible fields
                if (hasVisibleFields) {
                  return (
                    <div key={sectionName}>
                      <div className="flex items-center justify-between">
                        <h3 className="OC-Form-text_color OC-Form-text_size OC-Form-text_font_family OC-Form-text_variant dark:text-white pt-4">
                          {`${t(`HostForm.${sectionName.toLowerCase()}`)}`}
                        </h3>
                        {sectionName === "Address" &&
                          !guest.data.IsMainGuest && (
                            <button
                              onClick={(e) => copyAddressData(guest.id, e)}
                              className="OC-Form-text_color OC-Form-text_size OC-Form-text_font_family OC-Form-text_variant flex items-center dark:text-white hover:text-blue-700 focus:outline-none"
                            >
                              <CopyIcon size={16} className="mr-2" />{" "}
                              {`${t(`HostForm.copy`)}`}
                            </button>
                          )}
                      </div>
                      <div
                        id="OC-FormField"
                        ref={OCFormFieldRef}
                        className={`${
                          isPreviewMode && isHovered === "OC-FormField"
                            ? "diagonal-stripes"
                            : ""
                        } grid grid-cols-1 md:grid-cols-3 max-sm:gap-y-0 gap-3`}
                      >
                        {/* Render fields for this section */}
                        {organizedFields[sectionName].map((field) => {
                          const componentName = mapFieldToComponent(field.Code);
                          // Check visibility for each individual field
                          const { isVisible } = CheckFieldVisibility(
                            field,
                            guest,
                            state
                          );

                          if (!componentName || !isVisible) return null;

                          // Example of dynamic component rendering - you'll need to adjust based on your needs
                          switch (componentName) {
                            case "FirstName":
                              return (
                                <FirstName
                                  key={field.Code}
                                  guestFieldsGXP={guestFieldsGXP}
                                  handleGuestChange={handleGuestChange}
                                  guest={guest}
                                  requiredFields={requiredFields}
                                  error={fieldErrors.FirstName}
                                />
                              );
                            case "LastName":
                              return (
                                <LastName
                                  key={field.Code}
                                  guestFieldsGXP={guestFieldsGXP}
                                  handleGuestChange={handleGuestChange}
                                  guest={guest}
                                  requiredFields={requiredFields}
                                  error={fieldErrors.LastName}
                                />
                              );
                            case "BirthDate":
                              return (
                                <BirthDate
                                  key={field.Code}
                                  handleGuestChange={handleGuestChange}
                                  guest={guest}
                                  requiredFields={requiredFields}
                                  error={fieldErrors.BirthDate}
                                  guestFieldsGXP={guestFieldsGXP}
                                />
                              );
                            case "Nif":
                              return (
                                <Nif
                                  key={field.Code}
                                  guest={guest}
                                  handleNifChange={handleNifChange}
                                  validateNifOnBlur={validateNifOnBlur}
                                  requiredFields={requiredFields}
                                  error={fieldErrors.FiscalNumber}
                                  initialData={initialData}
                                  guestFieldsGXP={guestFieldsGXP}
                                />
                              );
                            case "Nationality":
                              return (
                                <Nationality
                                  key={field.Code}
                                  validateNifOnBlur={validateNifOnBlur}
                                  handleGuestChange={handleGuestChange}
                                  guest={guest}
                                  nifValues={nifValues}
                                  initialData={initialData}
                                  requiredFields={requiredFields}
                                  error={fieldErrors.NationalityId}
                                  guestFieldsGXP={guestFieldsGXP}
                                />
                              );
                            case "Gender":
                              return (
                                <Gender
                                  key={field.Code}
                                  handleGuestChange={handleGuestChange}
                                  guest={guest}
                                  requiredFields={requiredFields}
                                  error={fieldErrors.Gender}
                                  guestFieldsGXP={guestFieldsGXP}
                                />
                              );
                            case "DocumentType":
                              return (
                                <DocumentType
                                  key={field.Code}
                                  handleGuestChange={handleGuestChange}
                                  guest={guest}
                                  initialData={initialData}
                                  requiredFields={requiredFields}
                                  error={fieldErrors.DocType}
                                  guestFieldsGXP={guestFieldsGXP}
                                />
                              );
                            case "DocumentNumber":
                              return (
                                <DocumentNumber
                                  key={field.Code}
                                  handleGuestChange={handleGuestChange}
                                  guest={guest}
                                  requiredFields={requiredFields}
                                  error={fieldErrors.DocNumber}
                                  guestFieldsGXP={guestFieldsGXP}
                                />
                              );
                            case "DocumentExpireDate":
                              return (
                                <DocumentExpireDate
                                  key={field.Code}
                                  handleGuestChange={handleGuestChange}
                                  guest={guest}
                                  requiredFields={requiredFields}
                                  error={fieldErrors.DocValidityDate}
                                  guestFieldsGXP={guestFieldsGXP}
                                />
                              );
                            case "DocumentAuthor":
                              return (
                                <DocumentAuthor
                                  key={field.Code}
                                  handleGuestChange={handleGuestChange}
                                  guest={guest}
                                  requiredFields={requiredFields}
                                  error={fieldErrors.DocIssueAuthor}
                                  guestFieldsGXP={guestFieldsGXP}
                                />
                              );
                            case "Email":
                              return (
                                <Email
                                  key={field.Code}
                                  emailError={emailError}
                                  setEmailError={setEmailError}
                                  handleGuestChange={handleGuestChange}
                                  guest={guest}
                                  requiredFields={requiredFields}
                                  error={fieldErrors.Email}
                                  guestFieldsGXP={guestFieldsGXP}
                                />
                              );
                            case "Phone":
                              return (
                                <Phone
                                  key={field.Code}
                                  initialData={initialData}
                                  guest={guest}
                                  handleGuestChange={handleGuestChange}
                                  requiredFields={requiredFields}
                                  error={fieldErrors.Phone}
                                  guestFieldsGXP={guestFieldsGXP}
                                />
                              );
                            case "Mobile":
                              return (
                                <Mobile
                                  key={field.Code}
                                  initialData={initialData}
                                  guest={guest}
                                  handleGuestChange={handleGuestChange}
                                  requiredFields={requiredFields}
                                  error={fieldErrors.Mobile}
                                  guestFieldsGXP={guestFieldsGXP}
                                />
                              );
                            case "ShortTitle":
                              return (
                                <ShortTitle
                                  key={field.Code}
                                  handleGuestChange={handleGuestChange}
                                  guest={guest}
                                  requiredFields={requiredFields}
                                  error={fieldErrors.TitleShort_Id}
                                  guestFieldsGXP={guestFieldsGXP}
                                  isPreviewMode={isPreviewMode}
                                />
                              );
                            case "LongTitle":
                              return (
                                <LongTitle
                                  key={field.Code}
                                  handleGuestChange={handleGuestChange}
                                  guest={guest}
                                  requiredFields={requiredFields}
                                  error={fieldErrors.TitleLong_Id}
                                  guestFieldsGXP={guestFieldsGXP}
                                  isPreviewMode={isPreviewMode}
                                />
                              );
                            case "Title":
                              return (
                                <Title
                                  key={field.Code}
                                  handleGuestChange={handleGuestChange}
                                  guest={guest}
                                  requiredFields={requiredFields}
                                  error={fieldErrors.Title_Id}
                                  guestFieldsGXP={guestFieldsGXP}
                                  isPreviewMode={isPreviewMode}
                                />
                              );
                            case "Address1":
                              return (
                                <Address1
                                  key={field.Code}
                                  handleGuestChange={handleGuestChange}
                                  guest={guest}
                                  requiredFields={requiredFields}
                                  error={fieldErrors.Address1}
                                  guestFieldsGXP={guestFieldsGXP}
                                />
                              );
                            case "ZipCode":
                              return (
                                <ZipCode
                                  key={field.Code}
                                  handleGuestChange={handleGuestChange}
                                  guest={guest}
                                  requiredFields={requiredFields}
                                  error={fieldErrors.ZipCode}
                                  guestFieldsGXP={guestFieldsGXP}
                                />
                              );
                            case "Town":
                              return (
                                <Town
                                  key={field.Code}
                                  handleGuestChange={handleGuestChange}
                                  guest={guest}
                                  requiredFields={requiredFields}
                                  error={fieldErrors.Town}
                                  guestFieldsGXP={guestFieldsGXP}
                                />
                              );
                            case "Country":
                              return (
                                <Country
                                  key={field.Code}
                                  handleGuestChange={handleGuestChange}
                                  guest={guest}
                                  requiredFields={requiredFields}
                                  error={fieldErrors.CountryId}
                                  guestFieldsGXP={guestFieldsGXP}
                                />
                              );
                            case "Address2":
                              return (
                                <Address2
                                  key={field.Code}
                                  handleGuestChange={handleGuestChange}
                                  guest={guest}
                                  requiredFields={requiredFields}
                                  error={fieldErrors.Address2}
                                  guestFieldsGXP={guestFieldsGXP}
                                />
                              );
                            case "Address3":
                              return (
                                <Address3
                                  key={field.Code}
                                  handleGuestChange={handleGuestChange}
                                  guest={guest}
                                  requiredFields={requiredFields}
                                  error={fieldErrors.Address3}
                                  guestFieldsGXP={guestFieldsGXP}
                                />
                              );
                            case "AddressState":
                              return (
                                <AddressState
                                  key={field.Code}
                                  handleGuestChange={handleGuestChange}
                                  guest={guest}
                                  requiredFields={requiredFields}
                                  error={fieldErrors.AddressState}
                                  guestFieldsGXP={guestFieldsGXP}
                                />
                              );
                            case "Region":
                              return (
                                <Region
                                  key={field.Code}
                                  handleGuestChange={handleGuestChange}
                                  guest={guest}
                                  requiredFields={requiredFields}
                                  error={fieldErrors.Region}
                                  guestFieldsGXP={guestFieldsGXP}
                                />
                              );
                            case "VehicleNumber":
                              return (
                                <VehicleNumber
                                  key={field.Code}
                                  handleGuestChange={handleGuestChange}
                                  guest={guest}
                                  requiredFields={requiredFields}
                                  error={fieldErrors.Region}
                                  guestFieldsGXP={guestFieldsGXP}
                                />
                              );
                            // Add cases for all your field components
                            default:
                              return null;
                          }
                        })}
                      </div>
                    </div>
                  );
                }
              }
              return null;
            })}

            {guest.data.IsMainGuest && ocInvoiceDataIsActive === "true" && (
              <div className="mt-4">
                <div className="flex items-center justify-between cursor-pointer OC-Form-text_color OC-Form-text_size OC-Form-text_font_family OC-Form-text_variant dark:text-white">
                  <h3 className="font-bold">{`${t(
                    `InvoiceData.billingdata`
                  )}`}</h3>
                </div>

                <div
                  className={` p-1 overflow-hidden transition-all duration-200`}
                >
                  <div className="flex gap-6 mb-4">
                    <label className="ml-1 flex items-center cursor-pointer">
                      <input
                        type="checkbox"
                        checked={billingType === "sameAsGuest"}
                        onChange={() => handleBillingTypeChange("sameAsGuest")}
                        className="mr-2"
                      />
                      <span className="dark:text-white OC-Form-text_color OC-Form-text_size max-sm:OC-Form-text_size_sm OC-Form-text_font_family OC-Form-text_variant">
                        {`${t(`InvoiceData.sameasguest`)}`}
                      </span>
                    </label>

                    <label className="flex items-center cursor-pointer">
                      <input
                        type="checkbox"
                        checked={billingType === "company"}
                        onChange={() => handleBillingTypeChange("company")}
                        className="mr-2"
                      />
                      <span className="dark:text-white OC-Form-text_color OC-Form-text_size OC-Form-text_font_family OC-Form-text_variant">
                        {`${t(`InvoiceData.company`)}`}
                      </span>
                    </label>

                    <label className="flex items-center cursor-pointer">
                      <input
                        type="checkbox"
                        checked={billingType === "individual"}
                        onChange={() => handleBillingTypeChange("individual")}
                        className="mr-2 ml-2"
                      />
                      <span className="dark:text-white OC-Form-text_color OC-Form-text_size OC-Form-text_font_family OC-Form-text_variant">
                        {`${t(`InvoiceData.individual`)}`}
                      </span>
                    </label>
                  </div>
                </div>
              </div>
            )}

            {billingType === "company" &&
              getBillingCompanySection().map((sectionName) => {
                if (
                  organizedFields[sectionName] &&
                  organizedFields[sectionName].length > 0
                ) {
                  // Check if the section has any visible fields for this guest
                  const hasVisibleFields = sectionHasVisibleFields(
                    organizedFields[sectionName],
                    guest,
                    state
                  );

                  // Only render the section if it has visible fields
                  if (hasVisibleFields) {
                    return (
                      <div key={sectionName}>
                        <div
                          id="OC-FormField"
                          ref={OCFormFieldRef}
                          className={`${
                            isPreviewMode && isHovered === "OC-FormField"
                              ? "diagonal-stripes"
                              : ""
                          } grid grid-cols-1 md:grid-cols-3 max-sm:gap-y-0 gap-3`}
                        >
                          {/* Render fields for this section */}
                          {organizedFields[sectionName].map((field) => {
                            const componentName = mapFieldToComponent(
                              field.Code
                            );
                            // Check visibility for each individual field
                            const { isVisible } = CheckFieldVisibility(
                              field,
                              guest,
                              state
                            );

                            if (!componentName || !isVisible) return null;

                            // Example of dynamic component rendering - you'll need to adjust based on your needs
                            switch (componentName) {
                              case "CompanyVatNumber":
                                return (
                                  <CompanyVatNumber
                                    key={field.Code}
                                    guest={guest}
                                    handleInvoiceNifChange={
                                      handleInvoiceNifChange
                                    }
                                    validateInvoiceNifOnBlur={
                                      validateInvoiceNifOnBlur
                                    }
                                    error={fieldErrors.invoiceEntityVATNumber}
                                    guestFieldsGXP={guestFieldsGXP}
                                  />
                                );
                              case "CompanyDesignation":
                                return (
                                  <CompanyDesignation
                                    key={field.Code}
                                    guestFieldsGXP={guestFieldsGXP}
                                    handleGuestChange={handleGuestChange}
                                    guest={guest}
                                    requiredFields={requiredFields}
                                    error={fieldErrors.invoiceEntityLastName}
                                  />
                                );
                              case "CompanyAddress":
                                return (
                                  <CompanyAddress
                                    key={field.Code}
                                    handleGuestChange={handleGuestChange}
                                    guest={guest}
                                    requiredFields={requiredFields}
                                    error={fieldErrors.invoiceEntityAddress1}
                                    guestFieldsGXP={guestFieldsGXP}
                                  />
                                );
                              case "CompanyZipCode":
                                return (
                                  <CompanyZipCode
                                    key={field.Code}
                                    handleGuestChange={handleGuestChange}
                                    guest={guest}
                                    requiredFields={requiredFields}
                                    error={fieldErrors.invoiceEntityZipCode}
                                    guestFieldsGXP={guestFieldsGXP}
                                  />
                                );
                              case "CompanyTown":
                                return (
                                  <CompanyTown
                                    key={field.Code}
                                    handleGuestChange={handleGuestChange}
                                    guest={guest}
                                    requiredFields={requiredFields}
                                    error={fieldErrors.invoiceEntityTown}
                                    guestFieldsGXP={guestFieldsGXP}
                                  />
                                );
                              case "CompanyCountry":
                                return (
                                  <CompanyCountry
                                    key={field.Code}
                                    handleGuestChange={handleGuestChange}
                                    guest={guest}
                                    requiredFields={requiredFields}
                                    error={fieldErrors.invoiceEntityCountryId}
                                    guestFieldsGXP={guestFieldsGXP}
                                  />
                                );
                              // Add cases for all your field components
                              default:
                                return null;
                            }
                          })}
                        </div>
                      </div>
                    );
                  }
                }
                return null;
              })}

            {billingType === "individual" &&
              getBillingIndividualSection().map((sectionName) => {
                if (
                  organizedFields[sectionName] &&
                  organizedFields[sectionName].length > 0
                ) {
                  // Check if the section has any visible fields for this guest
                  const hasVisibleFields = sectionHasVisibleFields(
                    organizedFields[sectionName],
                    guest,
                    state
                  );

                  // Only render the section if it has visible fields
                  if (hasVisibleFields) {
                    return (
                      <div key={sectionName}>
                        <div
                          id="OC-FormField"
                          ref={OCFormFieldRef}
                          className={`${
                            isPreviewMode && isHovered === "OC-FormField"
                              ? "diagonal-stripes"
                              : ""
                          } grid grid-cols-1 md:grid-cols-3 max-sm:gap-y-0 gap-3`}
                        >
                          {/* Render fields for this section */}
                          {organizedFields[sectionName].map((field) => {
                            const componentName = mapFieldToComponent(
                              field.Code
                            );
                            // Check visibility for each individual field
                            const { isVisible } = CheckFieldVisibility(field,guest,state);

                            if (!componentName || !isVisible) return null;

                            // Example of dynamic component rendering - you'll need to adjust based on your needs
                            switch (componentName) {
                              case "IndividualVatNumber":
                                return (
                                  <IndividualVatNumber
                                    key={field.Code}
                                    guest={guest}
                                    handleInvoiceNifChange={
                                      handleInvoiceNifChange
                                    }
                                    validateInvoiceNifOnBlur={
                                      validateInvoiceNifOnBlur
                                    }
                                    error={fieldErrors.invoiceEntityVATNumber}
                                    guestFieldsGXP={guestFieldsGXP}
                                  />
                                );
                              case "IndividualLastName":
                                return (
                                  <IndividualLastName
                                    key={field.Code}
                                    guestFieldsGXP={guestFieldsGXP}
                                    handleGuestChange={handleGuestChange}
                                    guest={guest}
                                    error={fieldErrors.invoiceEntityLastName}
                                  />
                                );
                              case "IndividualFirstName":
                                return (
                                  <IndividualFirstName
                                    key={field.Code}
                                    guestFieldsGXP={guestFieldsGXP}
                                    handleGuestChange={handleGuestChange}
                                    guest={guest}
                                    error={fieldErrors.invoiceEntityLastName}
                                  />
                                );
                              case "IndividualAddress":
                                return (
                                  <IndividualAddress
                                    key={field.Code}
                                    handleGuestChange={handleGuestChange}
                                    guest={guest}
                                    error={fieldErrors.invoiceEntityAddress1}
                                    guestFieldsGXP={guestFieldsGXP}
                                  />
                                );
                              case "IndividualZipCode":
                                return (
                                  <IndividualZipCode
                                    key={field.Code}
                                    handleGuestChange={handleGuestChange}
                                    guest={guest}
                                    error={fieldErrors.invoiceEntityZipCode}
                                    guestFieldsGXP={guestFieldsGXP}
                                  />
                                );
                              case "IndividualTown":
                                return (
                                  <IndividualTown
                                    key={field.Code}
                                    handleGuestChange={handleGuestChange}
                                    guest={guest}
                                    error={fieldErrors.invoiceEntityTown}
                                    guestFieldsGXP={guestFieldsGXP}
                                  />
                                );
                              case "IndividualCountry":
                                return (
                                  <IndividualCountry
                                    key={field.Code}
                                    handleGuestChange={handleGuestChange}
                                    guest={guest}
                                    requiredFields={requiredFields}
                                    error={fieldErrors.invoiceEntityCountryId}
                                    guestFieldsGXP={guestFieldsGXP}
                                  />
                                );
                              // Add cases for all your field components
                              default:
                                return null;
                            }
                          })}
                        </div>
                      </div>
                    );
                  }
                }
                return null;
              })}

            <div>
              <div className="flex justify-around mt-6">
                {!showConsent && !showGeneralTerms && activeTab !== 0 && (
                  <button
                    type="button"
                    onClick={(e) => previous(e, guest.id)}
                    className={`py-2 px-4 dark:bg-white border ${
                      !showGeneralTerms && !showConsent && activeTab === 0
                        ? "bg-gray-400 cursor-not-allowed"
                        : "OC-Button2-bg_color dark:bg-white"
                    } dark:text-gxp_violet dark:border-gxp_violet OC-Button2-icon_color OC-Button2-border_color rounded`}
                  >
                    <ArrowBigLeftDash />
                  </button>
                )}
                <button
                  id="OC-Button1"
                  ref={OCButton1Ref}
                  onDoubleClick={(e) => handleDoubleClick(e, OCButton1Ref)}
                  onMouseOver={(e) =>
                    handleElementEnter(e, OCButton1Ref, "OC-Button1")
                  }
                  onMouseLeave={(e) =>
                    handleElementLeave(e, OCButton1Ref, "OC-Button1")
                  }
                  type="button"
                  onClick={handleContinue}
                  className={`${
                    isPreviewMode && isHovered === "OC-Button1"
                      ? "diagonal-stripes"
                      : ""
                  } py-2 px-4 ${
                    showGeneralTerms &&
                    !showConsent &&
                    activeTab === guests.length - 1
                      ? "bg-gray-400 cursor-not-allowed"
                      : "OC-Button1-bg_color dark:bg-gxp_violet"
                  } OC-Button1-icon_color dark:text-white dark:border-white border OC-Button1-border_color rounded`}
                >
                  <ArrowBigRightIcon />
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
