import { useTranslation } from "react-i18next";
import { useState, useEffect, useContext } from "react";
import { AlertTriangle, Search } from "lucide-react";
import { AppContext } from "../../../../context/AppContext";
import { CheckFieldVisibility } from "../Utilities/CheckFieldVisibility";
import { PostalCodeModal } from "./PostalCodeModal";

/**
 * @namespace ZipCode
 * @memberof module:OnlineCheckIn
 * @description Component for managing guest zip/postal code information.
 */
export const ZipCode = ({ guest, handleGuestChange, error, guestFieldsGXP }) => {
  const [t] = useTranslation("global");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [state, dispatch] = useContext(AppContext);
  const [labelIsActive, setLabelIsActive] = useState(null);
  const [placeholderIsActive, setPlaceholderIsActive] = useState(null);
  // Estado local para o código postal
  const [localZipCode, setLocalZipCode] = useState(guest.data.ZipCode || "");

  const field = guestFieldsGXP?.find((field) => field.Code === "ZIPCODE");
  const { isVisible, isMandatory } = CheckFieldVisibility(field, guest, state);

  // Sincronizar o estado local quando o valor do guest mudar externamente
  useEffect(() => {
    setLocalZipCode(guest.data.ZipCode || "");
  }, [guest.data.ZipCode]);

  useEffect(() => {
    setLabelIsActive(
      state.activeTheme.find(
        (style) =>
          style.themeStyleCode === "OC-FormField-label_is_active" &&
          style.isActive
      ).value
    );
    setPlaceholderIsActive(
      state.activeTheme.find(
        (style) =>
          style.themeStyleCode === "OC-FormField-placeholder_is_active" &&
          style.isActive
      ).value
    );
  }, [state.activeTheme]);

  const isRequiredZipCode = isMandatory;

  // Função para lidar com a digitação do usuário (apenas atualiza o estado local)
  const handleLocalChange = (e) => {
    setLocalZipCode(e.target.value);
  };

  // Função para atualizar o valor no estado global quando o campo perde o foco
  const handleBlur = () => {
    handleGuestChange(guest.id, "ZipCode", localZipCode);
  };

  const handleConfirm = () => {
    // Lógica para confirmar o endereço
    setIsModalOpen(false);
  };

  return (
    <>
      <div className="relative">
        {isVisible && (labelIsActive === "true" || labelIsActive === null) && (
          <label
            className={`OC-FormField-label_location OC-FormField-label_color OC-FormField-label_size OC-FormField-label_font_family OC-FormField-label_variant OC-Form-bg_color pl-2 rounded-xl inline-block text-center justify-center dark:bg-gxp_dark_3 dark:text-white mb-1`}
          >
            <span className="dark:text-white text-red-600">
              {isRequiredZipCode ? ` * \u00A0` : ``}
            </span>
            {`${t(`Address.zipcode`)}`}
          </label>
        )}
        <input
          name="ZipCode"
          required={isRequiredZipCode}
          onChange={handleLocalChange}
          onBlur={handleBlur}
          value={localZipCode}
          type="text"
          id="ZipCode"
          className={`OC-FormField-text_color OC-FormField-text_size OC-FormField-text_font_family OC-FormField-text_variant OC-FormField-bg_color OC-FormField-border_rounded OC-FormField-border_position OC-FormField-border_width dark:bg-gxp_dark_3 dark:text-white w-full px-4 py-2 h-[42px] ${
            error
              ? "border-red-500"
              : "OC-FormField-border_color dark:border-white"
          } focus:ring-2 focus:border-blue-500 transition duration-150 ease-in-out
            OC-FormField-placeholder_color OC-FormField-placeholder_font_family OC-FormField-placeholder_variant`}
          placeholder={
            placeholderIsActive === "true" || placeholderIsActive === null
              ? `${t(`Address.zipcode`)} ${
                  isRequiredZipCode ? `( * )\u00A0` : ``
                }`
              : ""
          }
        />
        {state.currentHotel.hotelCountry === "BR" &&
          guest.data.CountryIso === "BR" && (
            <span className="absolute top-1/2 right-3 transform flex items-center cursor-pointer dark:text-white dark:hover:text-gxp_dark_2">
              <Search onClick={() => setIsModalOpen(true)} />
            </span>
          )}
        {error && (
          <div className="absolute left-0 bottom-0 transform translate-y-[calc(100%+4px)] flex items-center z-10 w-full">
            <AlertTriangle className="text-red-500 mr-1" size={16} />
            <span className="text-red-500 text-sm">{`${t(
              `HostForm.fieldRequired`
            )}`}</span>
          </div>
        )}
      </div>
      <PostalCodeModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onConfirm={handleConfirm}
        initialData={state.hotelInitialData}
        guest={guest}
        handleGuestChange={handleGuestChange}
      />
    </>
  );
};