import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ChevronLeft, Search} from "lucide-react";

/**
 * @namespace MenuLayout
 * @memberof module:EMenu
 * @description Component for rendering a responsive menu layout with search functionality.
 * Includes a header with back navigation, search capabilities, and optional footer.
 * Manages scroll behavior and search visibility states.
 *
 * @component
 * @param {Object} props Component properties
 * @param {React.ReactNode} props.children Content to be rendered within the layout
 * @param {string} props.searchText Current search text value
 * @param {Function} props.onSearchChange Callback function for search text changes
 * @param {Object} props.service Service configuration object
 * @param {boolean} props.service.HasFooterText Whether footer text should be displayed
 * @param {string} props.service.FooterText HTML content for the footer
 *
 * @returns {JSX.Element} Menu layout component with header, search, and content area
 *
 * @example
 * <MenuLayout
 *   searchText=""
 *   onSearchChange={(value) => handleSearch(value)}
 *   service={{ HasFooterText: true, FooterText: "<p>Footer content</p>" }}
 * >
 *   <div>Main content</div>
 * </MenuLayout>
 *
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @since 1.0.0
 * @version 1.0.0
 */
export const MenuLayout = ({handleDoubleClick, children,inspectMode, searchText, onSearchChange, service, isPreviewMode, isHovered, handleElementEnter, handleElementLeave, EMSearchbarRef }) => {
  const navigate = useNavigate();
  const [showSearch, setShowSearch] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

    /**
   * @function useEffectScrollBehavior
   * @memberof module:EMenu.MenuLayout
   * @description Effect that manages scroll behavior and touch events.
   * Handles header visibility on scroll and keyboard dismissal on touch.
   *
   * @effect
   * - Updates isScrolled state based on window scroll position
   * - Manages keyboard focus on touch events
   * @dependencies []
   *
   * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @since 1.0.0
   * @version 1.0.0
   */
  useEffect(() => {
    const handleScroll = () => {
      // Comportamento dos ícones
      setIsScrolled(window.scrollY > 50);
    };

    const handleTouch = () => {
      // Fecha o teclado imediatamente ao detectar toque/movimento
      if (document.activeElement instanceof HTMLInputElement) {
        document.activeElement.blur();
      }
    };

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("touchmove", handleTouch, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("touchmove", handleTouch);
    };
  }, []);

  return (
    <div className={`flex flex-col EM-bg_color dark:bg-gxp_dark_2 ${isPreviewMode ? "min-h-screen" : "h-full"}`}>
      {/* Container dos ícones e barra de pesquisa */}
      <div id="EM-Searchbar" ref={EMSearchbarRef} onDoubleClick={(e) => handleDoubleClick(e, EMSearchbarRef)} onMouseOver={(e) => handleElementEnter(e, EMSearchbarRef, "EM-Searchbar")} onMouseLeave={(e) => handleElementLeave(e, EMSearchbarRef, "EM-Searchbar")} className={`${isPreviewMode && isHovered === "EM-Searchbar" ? "diagonal-stripes w-full" : ""} ${isScrolled || (isPreviewMode)? "fixed top-0 shadow-md" : "absolute top-16" } ${ isScrolled ? "EM-Searchbar-bg_color dark:bg-gxp_dark_2/90" : "bg-transparent" } border EM-Searchbar-border_color EM-Searchbar-border_position EM-Searchbar-border_rounded EM-Searchbar-border_width max-w-4xl mx-auto justify-center left-0 right-0 z-10 transition-[background,box-shadow] duration-300 ease-in-out`} >
        <div className="flex items-center gap-2 p-4">
          <button type="button" onClick={() => navigate(-1)} className="drop-shadow-lg hover:text-gray-400 transition-colors z-10" >
            <ChevronLeft className={`w-6 h-6 ${ isScrolled ?"EM-Searchbar-icon_color dark:text-white":"text-white dark:text-white"}`} />
          </button>

          {/* Barra de pesquisa com efeito fade */}
          <div className={` transition-opacity duration-300 ease-in-out ${showSearch ? "opacity-100 w-full" : "opacity-0 w-0"} overflow-hidden `} >
            <div className="px-2">
              <div className="relative">
                <input
                  type="text"
                  value={searchText}
                  onChange={(e) => onSearchChange(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      e.target.blur();
                      document.activeElement?.blur();
                      window.document.activeElement?.blur();
                    }
                  }}
                  onFocus={() => {
                    setTimeout(() => {
                      window.scrollTo({
                        top: 0,
                        behavior: "smooth",
                      });
                    }, 300);
                  }}
                  placeholder="Pesquisar..."
                  className="w-full px-4 py-2 pl-10 pr-4 text-gray-700 bg-white/90 dark:bg-gray-800 dark:text-white rounded-full focus:outline-none focus:ring-2 focus:ring-blue-500 shadow-md"
                />
                <Search className="absolute left-3 top-2.5 w-5 h-5 text-gray-400" />
              </div>
            </div>
          </div>

          <button
            onClick={() => { setShowSearch(!showSearch); if (showSearch) { onSearchChange(""); } }}
            className="drop-shadow-lg hover:text-gray-400 transition-colors ml-auto z-10"
          >
            <Search className={`w-6 h-6 ${ isScrolled ?"EM-Searchbar-icon_color dark:text-white":"text-white dark:text-white"}`} />
          </button>
        </div>
      </div>

      {/* Conteúdo principal */}
      <main className={`overflow-auto h-full transition-all duration-300 dark:bg-gxp_dark_2 EM-bg_color w-full ${!isScrolled && searchText ? "pt-16" : ""} `} >
        {children}
      </main>
      {service.HasFooterText &&
      <div className="w-full relative overflow-hidden shadow-lg hover:shadow-xl transition-shadow border-violet-100 dark:bg-gxp_dark_3 dark:border-gray-800 text-gray-600 dark:text-white border-t-2 duration-300">
        <div className="">
          <div dangerouslySetInnerHTML={{ __html: service.FooterText }} className="text-gray-600 dark:text-white p-4" />
        </div>
      </div>
      }
    </div>
  );
};
