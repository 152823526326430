import { useCookies } from "react-cookie";
import { HotelGroup } from "./HotelGroup";
import { useContext, useEffect, useState } from "react";
import { getHostReservation } from "../../../services";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../../context/AppContext.js";
import { previewHomeReservationsData } from "../Utilities/previewModeData.js";

/**
 * @namespace ReservationsDisplay
 * @memberof module:Home
* @description Component responsible for displaying hotel reservations.
* Handles fetching, filtering and displaying reservation data.
* Supports preview mode with inspection capabilities.
*
* @component
* @param {Object} props - Component props
* @param {Array} props.reservations - Array of all reservations
* @param {Array} props.groupHotels - Array of hotels in the group
* @param {Function} props.setReservations - Function to update reservations state
* @param {boolean} props.isPreviewMode - Flag indicating if component is in preview mode
* @param {React.RefObject} props.HMCardRef - Reference for hotel card element
* @param {Function} props.handleElementEnter - Handler for mouse enter events
* @param {Function} props.handleElementLeave - Handler for mouse leave events
* @param {string|null} props.isHovered - ID of currently hovered element
*
* @returns {JSX.Element} ReservationsDisplay component
*
* @example
* <ReservationsDisplay
*   reservations={reservations}
*   groupHotels={groupHotels}
*   setReservations={setReservations}
*   isPreviewMode={isPreviewMode}
*   HMCardRef={HMCardRef}
*   handleElementEnter={handleElementEnter}
*   handleElementLeave={handleElementLeave}
*   isHovered={isHovered}
* />
*
* @author Tiago Ferreira <tiago.ferreira@hhs.pt>
* @since 1.0.0
* @version 1.0.0
*/
export const ReservationsDisplay = ({handleDoubleClick, reservations, groupHotels, setReservations, isPreviewMode, HMCardRef, handleElementEnter, handleElementLeave, isHovered }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [cookies] = useCookies(["platformUid", "sUid", "profileKey"]);
  const [t] = useTranslation("global");
  const [state, dispatch] = useContext(AppContext);
  const [filteredReservations, setFilteredReservations] = useState([]); // dados filtrados


    /**
   * @function retryRequest
   * @memberof module:Home.ReservationsDisplay
   * @description Implements retry mechanism for failed API requests.
   * Attempts to execute a failed request multiple times before giving up.
   *
   * @param {Function} fn - Async function to retry
   * @param {number} [retries=3] - Maximum number of retry attempts
   * @returns {Promise} Result of the successful request
   *
   * @example
   * await retryRequest(() => apiCall(), 3)
   *
   * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @since 1.0.0
   * @version 1.0.0
   */
  const retryRequest = async (fn, retries = 3) => {
    for (let i = 0; i < retries; i++) {
      try {
        return await fn();
      } catch (error) {
        if (i === retries - 1) throw error;
      }
    }
  };

    /**
   * @function fetchReservations
   * @memberof module:Home.ReservationsDisplay
   * @description Fetches reservations for all hotels in the group.
   * Makes parallel requests and handles failed attempts with retry mechanism.
   *
   * @async
   * @returns {Promise<void>}
   *
   * @example
   * await fetchReservations()
   *
   * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @since 1.0.0
   * @version 1.0.0
   */
  const fetchReservations = async () => {
    if (groupHotels && groupHotels.length > 0 && cookies.profileKey) {
      try {
        const reservationPromises = groupHotels.map((hotel) => {
          return retryRequest(() => getHostReservation(cookies.sUid, cookies.profileKey, hotel) );
        });
        const results = await Promise.allSettled(reservationPromises);

        const successfulResults = results
          .filter((result) => result.status === "fulfilled")
          .map((result) => result.value);

        const combinedReservations = successfulResults.flat();

        setReservations(combinedReservations);

        const failedResults = results.filter(
          (result) => result.status === "rejected"
        );
        if (failedResults.length > 0) {
          toast.warn(`${failedResults.length} reservations failed to fetch.`);
        }
      } catch (error) {
        toast.error("Unable to get reservations!");
      } finally {
        setIsLoading(false);
      }
    }
  };

    /**
   * @function useEffectFilterReservations
   * @memberof module:Home.ReservationsDisplay
   * @description Effect that filters active reservations from the complete list.
   * Removes reservations with status other than 0 and empty reservation lists.
   *
   * @effect Updates filteredReservations state
   * @dependencies [reservations]
   *
   * @example
   * useEffect(() => {
   *   const filteredData = reservations.filter(...)
   *   setFilteredReservations(filteredData);
   * }, [reservations]);
   *
   * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @since 1.0.0
   * @version 1.0.0
   */
  useEffect(() => {
    const filteredData = reservations
      .map(item => ({ ...item, reservations: item.reservations.filter(res => res.resStatus === 0 || res.resStatus === 10 )
      }))
      .filter(item => item.reservations.length > 0); // Remove itens sem reservations

    setFilteredReservations(filteredData);

  }, [reservations]);

  /**
   * @function useEffectFetchReservations
   * @memberof module:Home.ReservationsDisplay
   * @description Effect that handles initial reservations loading.
   * Loads mock data in preview mode or fetches real data otherwise.
   *
   * @effect Fetches or loads reservations data
   * @dependencies [groupHotels, cookies.profileKey]
   *
   * @example
   * useEffect(() => {
   *   if(isPreviewMode) {
   *     setReservations(previewData)
   *   } else {
   *     fetchReservations();
   *   }
   * }, [groupHotels, cookies.profileKey]);
   *
   * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @since 1.0.0
   * @version 1.0.0
   */
  useEffect(() => {
    if(isPreviewMode){
      setReservations(previewHomeReservationsData)
      setIsLoading(false);
      return;
    }
    fetchReservations();
  }, [groupHotels, cookies.profileKey]);

  return (
    <>
      {!isLoading &&
        <div className="max-w-4xl mx-auto px-4">
          <h2 className="HM-title_color HM-title_size HM-title_font_family HM-title_variant dark:text-white">
            {`${t("ReservationsDisplay.yourreservations")}`}
          </h2>
          <div className="space-y-6">
            <HotelGroup handleDoubleClick={handleDoubleClick} HMCardRef={HMCardRef} handleElementEnter={handleElementEnter} handleElementLeave={handleElementLeave} isPreviewMode={isPreviewMode} isHovered={isHovered} reservations={filteredReservations} isLoading={isLoading} setIsLoading={setIsLoading} />
          </div>
       </div>
      }
    </>
    );
};
