import { useTranslation } from "react-i18next";
import React, { useContext, useEffect, useState } from "react";
import { AlertTriangle } from "lucide-react";
import { AppContext } from "../../../../context/AppContext";
import { CheckFieldVisibility } from "../Utilities/CheckFieldVisibility";

/**
 * @namespace VehicleNumber
 * @memberof module:OnlineCheckIn
 * @description Component for managing guest vehicle number input field.
 * Includes theme-based styling, validation, and error handling with local state management.
 *
 * @component
 * @param {Object} props Component properties
 * @param {Function} props.handleGuestChange Function to update guest data
 * @param {Object} props.guest Current guest data
 * @param {Array<Object>} props.requiredFields List of required fields for validation
 * @param {boolean|string} props.error Error state for the field
 * @param {Array<Object>} props.guestFieldsGXP Guest field configuration
 *
 * @returns {JSX.Element} Vehicle number input field component
 */
export const VehicleNumber = ({ handleGuestChange, guest, error, guestFieldsGXP }) => {
  const [t] = useTranslation("global");
  const [state, dispatch] = useContext(AppContext);
  const [labelIsActive, setLabelIsActive] = useState(null);
  const [placeholderIsActive, setPlaceholderIsActive] = useState(null);

  // Local state for vehicle number input
  const [localVehicleNumber, setLocalVehicleNumber] = useState(guest.data.VehicleNumber || "");

  const field = guestFieldsGXP?.find(field => field.Code === "VEHICLENUMBER");
  const { isVisible, isMandatory } = CheckFieldVisibility(field, guest, state);

  // Synchronize local state when guest data changes externally
  useEffect(() => {
    setLocalVehicleNumber(guest.data.VehicleNumber || "");
  }, [guest.data.VehicleNumber]);

  // Theme-based styling effects
  useEffect(() => {
    setLabelIsActive(
      state.activeTheme.find(
        (style) => style.themeStyleCode === "OC-FormField-label_is_active" && style.isActive
      ).value
    );
    setPlaceholderIsActive(
      state.activeTheme.find(
        (style) => style.themeStyleCode === "OC-FormField-placeholder_is_active" && style.isActive
      ).value
    );
  }, [state.activeTheme]);

  const isRequired = isMandatory;

  // Handle local input changes
  const handleLocalChange = (e) => {
    setLocalVehicleNumber(e.target.value);
  };

  // Update global state when input loses focus
  const handleBlur = () => {
    handleGuestChange(guest.id, "VehicleNumber", localVehicleNumber);
  };

  return (
    <div className="relative">
      {isVisible && (labelIsActive === "true" || labelIsActive === null) && (
        <label required={isRequired} className={`OC-FormField-label_location OC-FormField-label_color OC-FormField-label_size OC-FormField-label_font_family OC-FormField-label_variant OC-Form-bg_color pl-2 rounded-xl inline-block text-center justify-center dark:bg-gxp_dark_3 dark:text-white mb-1`} >
          <span className="dark:text-white text-red-600">
            {isRequired ? ` * \u00A0` : ``}
          </span>
          {`${t(`VehicleNumber.VehicleNumber`)}`}
        </label>
      )}
      <input
        name="VehicleNumber"
        onChange={handleLocalChange}
        onBlur={handleBlur}
        value={localVehicleNumber}
        type="text"
        id="VehicleNumber"
        className={`OC-FormField-text_color OC-FormField-text_size OC-FormField-text_font_family OC-FormField-text_variant OC-FormField-bg_color OC-FormField-border_rounded OC-FormField-border_position OC-FormField-border_width dark:bg-gxp_dark_3 dark:text-white w-full px-4 py-2 h-[42px] ${
          error
            ? "border-red-500"
            : "OC-FormField-border_color dark:border-white"
        } focus:ring-2 focus:border-blue-500 transition duration-150 ease-in-out
        OC-FormField-placeholder_color OC-FormField-placeholder_font_family OC-FormField-placeholder_variant`}
        placeholder={
          placeholderIsActive === "true" || placeholderIsActive === null
            ? `${t(`VehicleNumber.VehicleNumber`)} ${isRequired ? `( * )\u00A0` : ``}`
            : ""
        }
      />
      {error && (
        <div className="absolute left-0 bottom-0 transform translate-y-[calc(100%+4px)] flex items-center z-10 w-full">
          <AlertTriangle className="text-red-500 mr-1" size={16} />
          <span className="text-red-500 text-sm">
            {`${t(`HostForm.fieldRequired`)}`}
          </span>
        </div>
      )}
    </div>
  );
};