import { useTranslation } from "react-i18next";
import { useState, useEffect, useContext } from "react";
import { AlertTriangle } from "lucide-react";
import { AppContext } from "../../../../context/AppContext";
import { CheckFieldVisibility } from "../Utilities/CheckFieldVisibility";

/**
 * @namespace Address1
 * @memberof module:OnlineCheckIn
 * @description Component for managing guest address information.
 */
export const Address1 = ({ guest, handleGuestChange, requiredFields, error, guestFieldsGXP }) => {
  const [t] = useTranslation("global");
  const [state, dispatch] = useContext(AppContext);
  const [labelIsActive, setLabelIsActive] = useState(null);
  const [placeholderIsActive, setPlaceholderIsActive] = useState(null);
  // Estado local para o endereço
  const [localAddress, setLocalAddress] = useState(guest.data.Address1 || "");

  const field = guestFieldsGXP?.find(field => field.Code === "ADDRESS1");
  const { isVisible, isMandatory } = CheckFieldVisibility(field, guest, state);

  // Sincronizar o estado local quando o valor do guest mudar externamente
  useEffect(() => {
    setLocalAddress(guest.data.Address1 || "");
  }, [guest.data.Address1]);

  useEffect(() => {
    setLabelIsActive(state.activeTheme.find(
      (style) => style.themeStyleCode === "OC-FormField-label_is_active" && style.isActive
    ).value);

    setPlaceholderIsActive(state.activeTheme.find(
      (style) => style.themeStyleCode === "OC-FormField-placeholder_is_active" && style.isActive
    ).value);
  }, [state.activeTheme]);

  const isRequiredAddress1 = isMandatory;

  // Função para lidar com a digitação do usuário (apenas atualiza o estado local)
  const handleLocalChange = (e) => {
    setLocalAddress(e.target.value);
  };

  // Função para atualizar o valor no estado global quando o campo perde o foco
  const handleBlur = () => {
    handleGuestChange(guest.id, "Address1", localAddress);
  };

  return (
    <>
      <div className="relative">
        {isVisible && (labelIsActive === "true" || labelIsActive === null) && (
          <label
            className={`OC-FormField-label_location OC-FormField-label_color OC-FormField-label_size OC-FormField-label_font_family OC-FormField-label_variant OC-Form-bg_color pl-2 rounded-xl inline-block text-center justify-center dark:bg-gxp_dark_3 dark:text-white mb-1`}
          >
            <span className="dark:text-white text-red-600">
              {isRequiredAddress1 ? ` * \u00A0` : ``}
            </span>
            {`${t(`Address.address1`)}`}
          </label>
        )}
        <input
          name="Address1"
          required={isRequiredAddress1}
          onChange={handleLocalChange}
          onBlur={handleBlur}
          value={localAddress}
          type="text"
          id="Address1"
          className={`OC-FormField-text_color OC-FormField-text_size OC-FormField-text_font_family OC-FormField-text_variant OC-FormField-bg_color OC-FormField-border_rounded OC-FormField-border_position OC-FormField-border_width dark:bg-gxp_dark_3 dark:text-white w-full px-4 py-2 h-[42px] ${
            error
              ? "border-red-500"
              : "OC-FormField-border_color dark:border-white"
          } focus:ring-2 focus:border-blue-500 transition duration-150 ease-in-out
          OC-FormField-placeholder_color OC-FormField-placeholder_font_family OC-FormField-placeholder_variant`}
          placeholder={placeholderIsActive === "true" || placeholderIsActive === null
            ? `${t(`Address.address1`)} ${isRequiredAddress1 ? `( * )\u00A0` : ``}`
            : ""}
        />
        {error && (
          <div className="absolute left-0 bottom-0 transform translate-y-[calc(100%+4px)] flex items-center z-10 w-full">
            <AlertTriangle className="text-red-500 mr-1" size={16} />
            <span className="text-red-500 text-sm">{`${t(`HostForm.fieldRequired`)}`}</span>
          </div>
        )}
      </div>
    </>
  );
};