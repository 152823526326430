import { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../../../context/AppContext";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { updateI18nLanguage } from "../../../pages/Settings/Language/ConfigureI18n";
import { ChevronDown, Moon, Sun, UserRoundCog } from "lucide-react";
import packageJson from '../../../../package.json';
import { HeaderMenu } from "../Components/HeaderMenu";

// Main Content Header
export const Header = ({ title, subtitle }) => {
  const [darkMode, setDarkMode] = useState( JSON.parse(localStorage.getItem(`darkMode`)) || false );
  const [selectedLanguage, setSelectedLanguage] = useState(); // Portuguese by default
  const [isLanguageOpen, setIsLanguageOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [avaliableLanguages, setAvaliableLanguages] = useState();

  const [state, dispatch] = useContext(AppContext);
  const dropdownRef = useRef(null);
  const languageSelectRef = useRef(null);
  const { pathname } = useLocation();
  const [t] = useTranslation("global");

  const gxpLanguages = [
    { code: "pt", name: "Português", cultureCode:"pt-PT" },
    { code: "gb", name: "English", cultureCode:"en-GB" },
    { code: "us", name: "English", cultureCode:"en-US" },
  ];

  const handleLanguageChange = (langCode) => {
    const language = gxpLanguages.find((l) => l.cultureCode === langCode);
    if (language && language.cultureCode !== selectedLanguage?.cultureCode) {
      setSelectedLanguage(language);
      localStorage.setItem("userLanguage", langCode);
      dispatch({ type: "SET_SYSTEM_LANGUAGE", payload: language });
      updateI18nLanguage(language.cultureCode); // Use a nova função em vez de i18n.changeLanguage
    }
  };

  const handleDarkModeToggle = () => {
    const newDarkMode = !darkMode;
    setDarkMode(newDarkMode);
    localStorage.setItem("darkMode", JSON.stringify(newDarkMode));
    // Aqui você pode adicionar lógica adicional para aplicar o tema escuro
  };


  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target) && languageSelectRef.current && !languageSelectRef.current.contains(event.target) ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  useEffect(() => {
    localStorage.setItem(`darkMode`, JSON.stringify(darkMode));
    if (darkMode) {
      document.documentElement.classList.add(`dark`);
    } else {
      document.documentElement.classList.remove(`dark`);
    }
  }, [darkMode]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target)
      ) {
        setIsLanguageOpen(false);
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  useEffect(() => {
    // Efeito imediato para definir o idioma do localStorage
    const storedLanguage = localStorage.getItem("userLanguage");
    if (storedLanguage) {
      const initialLanguage = gxpLanguages.find(l => l.cultureCode === storedLanguage);
      if (initialLanguage) {
        setSelectedLanguage(initialLanguage);
        dispatch({ type: "SET_SYSTEM_LANGUAGE", payload: initialLanguage });
        updateI18nLanguage(storedLanguage);
      }
    }
  }, []); // Executa apenas na montagem

    return (
      <header className="bg-[var(--host-r24-border-color-2)] text-gray-800 dark:text-white">
        <div className="px-6 py-4 flex justify-between">
          <h1 className="text-2xl font-semibold text-gray-800">{title}</h1>
          {subtitle && <p className="text-gray-600 mt-1">{subtitle}</p>}
          <HeaderMenu />
        </div>
      </header>
    );
  };