// DefaultValueInput.jsx
import React, { useEffect, useState } from "react";
import { PasswordInput } from "./PasswordInput";
import Select from "react-select";
import { hostSelectCustomStylesModal } from "../../../01_HotelPanel/components/utils/hostSelectStyles";

export const DefaultValueInput = React.memo(({ formData, setFormData }) => {
  const [localValue, setLocalValue] = useState(formData.defaultValue || "");

  useEffect(() => {
    setLocalValue(formData.defaultValue || "");
  }, [formData.defaultValue, formData.valueType]);

  const handleLocalChange = (e) => {
    e.stopPropagation();
    const value = e.target.value;
    setLocalValue(value);
    setFormData((prev) => ({
      ...prev,
      defaultValue: value,
    }));
  };

  switch (formData.valueType) {
    case "string":
      return (
        <input
          type="text"
          name="defaultValue"
          value={localValue}
          onChange={handleLocalChange}
          className="mt-1 block w-full host-search-input"
          required
        />
      );

      case "boolean":
        // Define options for boolean select
        const booleanOptions = [
          { value: "true", label: "true" },
          { value: "false", label: "false" }
        ];

        // Handle change specifically for React Select - fixed version
        const handleBooleanSelectChange = (selectedOption) => {
          // Update the local value directly
          const value = selectedOption ? selectedOption.value : "";
          setLocalValue(value);

          // Update the form data
          setFormData((prev) => ({
            ...prev,
            defaultValue: value,
          }));
        };

        return (
          <Select
            classNamePrefix="select"
            className="w-full"
            styles={hostSelectCustomStylesModal}
            isSearchable={false}
            name="defaultValue"
            placeholder="Selecione uma opção"
            value={booleanOptions.find(option => option.value === localValue) || null}
            options={booleanOptions}
            getOptionValue={(option) => option.value}
            getOptionLabel={(option) => option.label}
            onChange={handleBooleanSelectChange}
            inputProps={{
              style: { textIndent: "0", marginLeft: "0" },
            }}
          />
        );

    case "password":
      return (
        <PasswordInput
          value={formData.defaultValue || ""}
          onChange={handleLocalChange}
        />
      );

      case "integer":
        return (
          <input
            type="text"  // Mantemos como text para melhor controle
            name="defaultValue"
            value={localValue}
            onChange={(e) => {
              e.stopPropagation();
              const value = e.target.value;
              // Permite apenas números e vazio (para poder apagar)
              if (value === '' || /^[0-9]+$/.test(value)) {
                handleLocalChange(e);
              }
            }}
            onKeyDown={(e) => {
              // Previne caracteres não numéricos
              if (!/[0-9]/.test(e.key) &&
                  e.key !== 'Backspace' &&
                  e.key !== 'Delete' &&
                  e.key !== 'ArrowLeft' &&
                  e.key !== 'ArrowRight' &&
                  e.key !== 'Tab') {
                e.preventDefault();
              }
            }}
            className="mt-1 block w-full host-search-input"
            required
          />
        );

        case "list":
          if (!formData.valueList?.length) {
            return (
              <div className="flex items-center text-sm text-amber-600 bg-amber-50 px-3 py-2 rounded-md border border-amber-200">
                <p>Por favor, preencha primeiro a lista de valores abaixo</p>
              </div>
            );
          }

          // Convert the value list to React Select options format
          const listOptions = formData.valueList.map(option => ({
            value: option,
            label: option
          }));

          // Handle change specifically for React Select
          const handleListSelectChange = (selectedOption) => {
            // Update the local value directly
            const value = selectedOption ? selectedOption.value : "";
            setLocalValue(value);

            // Update the form data
            setFormData((prev) => ({
              ...prev,
              defaultValue: value,
            }));
          };

          return (
            <Select
              classNamePrefix="select"
              className="w-full"
              styles={hostSelectCustomStylesModal}
              isSearchable={true}
              name="defaultValue"
              placeholder="Selecione uma opção"
              value={listOptions.find(option => option.value === localValue) || null}
              options={listOptions}
              getOptionValue={(option) => option.value}
              getOptionLabel={(option) => option.label}
              onChange={handleListSelectChange}
              inputProps={{
                style: { textIndent: "0", marginLeft: "0" },
              }}
            />
          );


    default:
      return (
        <input
          type="text"
          name="defaultValue"
          value={localValue}
          onChange={handleLocalChange}
          className="mt-1 block w-full host-search-input"
          required
        />
      );
  }
});
