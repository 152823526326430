/**
 * @memberof module:EMenu.Utils
 * @description Recursive search function that finds menu items matching search text.
 * Searches through description and notes fields in the entire menu structure.
 *
 * @function searchInItems
 * @param {Array<Object>} items Array of menu items to search through
 * @param {string} searchText Text to search for in items
 * @returns {Array<Object>} Array of matching menu items
 *
 * @example
 * const menuItems = [
 *   {
 *     ItemId: 1,
 *     Description: "Pizza Margherita",
 *     Notes: "Traditional Italian pizza"
 *   }
 * ];
 * const results = searchInItems(menuItems, "pizza");
 * // Returns: [{ ItemId: 1, Description: "Pizza Margherita", ... }]
 *
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @since 1.0.0
 * @version 1.0.0
 */
export const searchInItems = (items, searchText) => {
    if (!searchText || !items) return [];

    const searchLower = searchText.toLowerCase();

    // Função recursiva para coletar todos os itens finais
    const getAllItems = (item) => {
        let results = [];

        // Se é um item final (tem ItemId > 0), verifica se corresponde à busca
        if (item.ItemId > 0) {
            const descriptionMatch = item.Description?.toLowerCase().includes(searchLower);
            const notesMatch = item.Notes?.toLowerCase().includes(searchLower);

            if (descriptionMatch || notesMatch) {
                results.push(item);
            }
        }

        // Se tem children, busca recursivamente neles
        if (item.Children) {
            item.Children.forEach(child => {
                results = results.concat(getAllItems(child));
            });
        }

        return results;
    };

    // Aplica a busca em todos os itens do menu
    let results = [];
    items.forEach(item => {
        results = results.concat(getAllItems(item));
    });

    return results;
};