import { useTranslation } from "react-i18next";
import { useState, useEffect, useContext, useMemo } from "react";
import { AlertTriangle, ChevronDown } from "lucide-react";
import { AppContext } from "../../../../context/AppContext";
import { CheckFieldVisibility } from "../Utilities/CheckFieldVisibility";
import { Select } from "antd";

/**
 * @namespace Region
 * @memberof module:OnlineCheckIn
 * @description Component for managing guest address information.
 * This component includes input fields for country selection, address, city,
 * and postal code, along with validation based on required fields.
 *
 * @component
 * @param {Object} props - The properties passed to the component
 * @param {Object} props.guest - The guest object containing address data
 * @param {function} props.handleGuestChange - Function to handle changes in guest data
 * @param {Object} props.initialData - Initial data including the list of countries
 * @param {Array<Object>} props.requiredFields - List of required fields for validation
 * @param {Object} [props.error] - Validation error object
 * @param {boolean} [props.isSameCountry] - Indicates if the country is the same
 *
 * @returns {JSX.Element} The rendered address form fields
 *
 * @example
 * <Address
 *   guest={guestData}
 *   handleGuestChange={handleChange}
 *   initialData={countryData}
 *   requiredFields={[
 *     { Field: "Address1", IsMandatory: true },
 *     { Field: "CountryId", IsMandatory: true }
 *   ]}
 * />
 *
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @version 1.0.0
 * @since 1.0.0
 */
export const Region = ({
  guest,
  handleGuestChange,
  requiredFields,
  error,
  guestFieldsGXP,
}) => {
  const [t] = useTranslation("global");
  const [state, dispatch] = useContext(AppContext);
  const [labelIsActive, setLabelIsActive] = useState(null);
  const [placeholderIsActive, setPlaceholderIsActive] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const field = guestFieldsGXP?.find((field) => field.Code === "REGION_ID");

  const { isVisible, isMandatory } = CheckFieldVisibility(field,guest,state);

  /**
   * @function isRequiredAddress1
   * @memberof module:OnlineCheckIn.Address
   * @type {boolean}
   *
   * @description Determines if the Address1 field is required based on validation rules.
   *
   * @example
   * const isRequiredAddress1 = requiredFields.some(
   *   field => field.Field === "Address1" && field.IsMandatory
   * );
   *
   * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @since 1.0.0
   * @version 1.0.0
   */
  const isRequiredRegion = isMandatory;

  /**
   * @function useEffectThemeStyles
   * @memberof module:OnlineCheckIn.Address
   * @param {Array} state.activeTheme
   *
   * @description Effect that manages label and placeholder visibility based on theme settings.
   *
   * @effect Updates labelIsActive and placeholderIsActive states
   * @dependencies [state.activeTheme]
   *
   * @example
   * useEffect(() => {
   *   setLabelIsActive(state.activeTheme.find(style =>
   *     style.themeStyleCode === "OC-FormField-label_is_active" &&
   *     style.isActive
   *   ).value);
   *   setPlaceholderIsActive(state.activeTheme.find(style =>
   *     style.themeStyleCode === "OC-FormField-placeholder_is_active" &&
   *     style.isActive
   *   ).value);
   * }, [state.activeTheme]);
   *
   * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @since 1.0.0
   * @version 1.0.0
   */
  useEffect(() => {
    setLabelIsActive(
      state.activeTheme.find(
        (style) =>
          style.themeStyleCode === "OC-FormField-label_is_active" &&
          style.isActive
      ).value
    );
    setPlaceholderIsActive(
      state.activeTheme.find(
        (style) =>
          style.themeStyleCode === "OC-FormField-placeholder_is_active" &&
          style.isActive
      ).value
    );
  }, [state.activeTheme]);

  /**
   * @function useCountriesMemo
   * @memberof module:OnlineCheckIn.Address
   * @param {Array} initialData.CountryList - List of countries from initial data
   *
   * @description Memoizes the list of countries to optimize performance
   *
   * @returns {Array} Processed list of countries
   *
   * @dependencies [initialData.CountryList]
   *
   * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @version 1.0.0
   * @since 1.0.0
   */
  const regions = useMemo(() => {
    return state.hotelInitialData.RegionList.map((region) => ({
      ...region,
    }));
  }, [state.hotelInitialData.RegionList]);

  const getRegionLabel = (regionIdOrIso) => {
    if (state.hotelInitialData) {
      const region = state.hotelInitialData.RegionList.find(
        (region) => region.Id === regionIdOrIso || region.Code === regionIdOrIso
      );
      return region ? region.Description : "";
    }
  };

  /**
   * @function getRegionValue
   * @memberof module:OnlineCheckIn.Address
   *
   * @description Determines the region value for the select input
   *
   * @returns {number|undefined} Region ID or undefined
   *
   * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @version 1.0.0
   * @since 1.0.0
   */
  const getRegionValue = () => {
    if (guest.data.RegionId) {
      return guest.data.RegionId;
    } else if (guest.data.RegionCode) {
      const region = state.hotelInitialData.RegionList.find(
        (c) => c.Code === guest.data.RegionCode
      );
      return region ? region.Id : undefined;
    }
    return undefined;
  };

  const regionValue = getRegionValue();

  return (
    <>
      <div className="flex flex-col w-full h-full justify-end relative">
        {isVisible && (labelIsActive === "true" || labelIsActive === null) && (
          <label
            className={`z-10 w-fit OC-FormField-label_location OC-FormField-label_color OC-FormField-label_size OC-FormField-label_font_family OC-FormField-label_variant OC-Form-bg_color pl-2  rounded-xl inline-block text-center justify-center dark:bg-gxp_dark_3 dark:text-white mb-1`}
          >
            <span className="dark:text-white text-red-600">
              {isRequiredRegion ? ` * \u00A0` : ``}
            </span>
            {`${t(`Address.region`)}`}
          </label>
        )}
        <Select
          suffixIcon={
            <ChevronDown
              className={`OC-FormField-icon_color dark:text-white transition-transform duration-300 ease-in-out ${
                isOpen ? "rotate-180" : ""
              }`}
            />
          }
          onDropdownVisibleChange={(open) => setIsOpen(open)}
          className={`OC-FormField-text_color OC-FormField-text_size OC-FormField-text_font_family OC-FormField-text_variant OC-FormField-bg_color OC-FormField-border_rounded OC-FormField-border_position OC-FormField-border_width dark:bg-gxp_dark_3 dark:text-white w-full px-2 py-2 h-[42px] ${
            error
              ? "border-red-500"
              : "OC-FormField-border_color dark:border-white"
          } focus:ring-2 focus:border-blue-500 transition duration-150 ease-in-out`}
          onChange={(selected) => {
            handleGuestChange(guest.id, "RegionId", selected.value);
            const selectedRegion = state.hotelInitialData.RegionList.find(
              (c) => c.Id === selected.value
            );
            if (selectedRegion) {
              handleGuestChange(guest.id, "RegionCode", selectedRegion.Code);
            }
          }}
          placeholder={
            <span className="dark:text-gray-400">
              {placeholderIsActive === "true" ? `${t(`Address.region`)} ${ isRequiredRegion ? `( * )\u00A0` : `` }` : `${t(`Address.selectRegion`)}`}
            </span>
          }
          optionLabelProp="label"
          popupClassName={`dark:dark`}
          showSearch
          dropdownStyle={{ minWidth: "260px" }}
          labelInValue
          value={
            regionValue
              ? { value: regionValue, label: getRegionLabel(regionValue) }
              : undefined
          }
          filterOption={(input, option) => {
            const region = regions.find((region) => region.Id === option.value);
            return region?.Description?.toLowerCase().includes(
              input.toLowerCase()
            );
          }}
          virtual={false}
        >
          {regions.map((region) => (
            <Select.Option
              key={region.Id}
              className={`dark:dark`}
              value={region.Id}
            >
              <div className="flex items-center">
                <span className="text-l">{region.Description}</span>
              </div>
            </Select.Option>
          ))}
        </Select>
        {error && (
          <div className="absolute left-0 bottom-0 transform translate-y-[calc(100%+4px)] flex items-center z-10 w-full">
            <AlertTriangle className="text-red-500 mr-1" size={16} />
            <span className="text-red-500 text-sm">{`${t(
              `HostForm.fieldRequired`
            )}`}</span>
          </div>
        )}
      </div>
    </>
  );
};
