export async function uploadImage(file){
    const formData = new FormData();
    formData.append("file", file);

    const requestOptions = {
        method:"POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        body: formData
    }

    const response = await fetch(`${process.env.REACT_APP_BACKEND_IP}/blobstorage/save`, requestOptions)

    if(!response.ok){
        throw {message: response.statusText, status: response.status}//eslint-disable-line
    }
    const data = await response.json()
    return data
}

export async function deleteImage(blobName) {
    const requestOptions = {
        method: "DELETE",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: {
            "Content-Type": "application/json"
        }
    };

    const response = await fetch(`${process.env.REACT_APP_BACKEND_IP}/api/blobstorage/delete?blobName=${encodeURIComponent(blobName)}`, requestOptions);

    if (!response.ok) {
        throw { message: response.statusText, status: response.status }; //eslint-disable-line
    }

    const data = await response.json();
    return data;
}