import { Routes, Route, Navigate } from "react-router-dom";
import { WebText, TableReservationMenu, OnlineCheckIn, TableReservation, Checkout, MaintenanceRequest, EMenu, Home, HotelHome, HotelReservationDetails, QrCode, EMenuMenu, } from "../pages";
import { useEffect, useState, useContext, useRef } from "react";
import { AppContext } from "../context/AppContext";
import { getGXPInitialData, getHotelById, getHotelInitialData, } from "../services";
import { useCookies } from "react-cookie";
import { RootLayout } from "../pages/RootLayout";
import dayjs from "dayjs";
import "dayjs/locale/pt";
import "dayjs/locale/en";
import { AdminPanel } from "../00_AdminPanel/AdminPanel";
import { HotelList } from "../00_AdminPanel/Pages/HotelList";
import { GroupHotelList } from "../00_AdminPanel/Pages/GroupHotelList";
import { Dashboard } from "../00_AdminPanel/Pages/DashBoard";
import { Parameters } from "../00_AdminPanel/Pages/Parameters";
import { StyleCategory } from "../00_AdminPanel/Pages/StyleCategory";
import { StyleSubCategory } from "../00_AdminPanel/Pages/StyleSubCategory";
import ThemeStyle from "../00_AdminPanel/Pages/ThemeStyle/ThemeStyle";
import { ThemeDefault } from "../00_AdminPanel/Pages/ThemeDefault/ThemeDefault";
import { applyTheme } from "./applyTheme";
import { Register } from "../00_AdminPanel/Pages/Auth/Register";
import { ProtectedRoute } from "./ProtectedRoute";
import { ConfirmRegister } from "../00_AdminPanel/Pages/ConfirmRegister";
import { HotelPanel } from "../01_HotelPanel/HotelPanel";
import { ParametersHotelPanel } from "../01_HotelPanel/Pages/Parameters/ParametersHotelPanel";
import { ThemeHotelPanel } from "../01_HotelPanel/Pages/Theme/ThemeHotelPanel";
import { HeaderFooterPreview } from "../pages/Others/HeaderFooterPreview";
import { SelectSection } from "../00_AdminPanel/Components/Layout/SelectSection";
import { Users } from "../00_AdminPanel/Pages/Users";
import { InactiveUser } from "../00_AdminPanel/Pages/InactiveUser";
import { LoginHostOne } from "../00_AdminPanel/Pages/Auth/LoginHostOne";

/**
 * @module AllRoutes
 * @description Core module for application routing and initialization.
 * Manages route configuration, theme handling, and initial data fetching.
 */

/**
 * @namespace ThemeController
 * @memberof module:AllRoutes
 * @description Component responsible for theme application and management.
 * Handles theme changes and preview mode functionality.
 *
 * @component
 * @param {Object} props - Component props
 * @param {Array} props.theme - Active theme configuration
 * @param {boolean} props.isPreviewMode - Flag indicating preview mode
 *
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @since 1.0.0
 * @version 1.0.0
 */
const ThemeController = ({ theme, isPreviewMode }) => {
  const previousThemeRef = useRef(theme);
  const [state, dispatch] = useContext(AppContext);

  /**
   * @function useEffectThemeApplication
   * @memberof module:AllRoutes.ThemeController
   * @description Effect that manages theme application based on preview mode and theme changes.
   * Implements intelligent theme updating to handle different scenarios:
   * - No theme available
   * - Non-preview mode theme application
   * - Initial preview mode mounting
   * - Preview mode property changes
   * - Special field handling (active states and images)
   *
   * @effect Updates applied theme based on current state and mode
   * @dependencies [state.activeTheme, isPreviewMode]
   *
   * @example
   * useEffect(() => {
   *   if (!state.activeTheme) return;
   *
   *   if (!isPreviewMode) {
   *     applyTheme(state.activeTheme);
   *     previousThemeRef.current = state.activeTheme;
   *     return;
   *   }
   *   // ... rest of theme application logic
   * }, [state.activeTheme, isPreviewMode]);
   *
   * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @since 1.0.0
   * @version 1.0.0
   */
  useEffect(() => {
    // Se o tema não estiver disponível, não faz nada
    if (!state.activeTheme) return;

    // Se não estiver em preview mode, aplica o tema sempre
    if (!isPreviewMode) {
      applyTheme(state.activeTheme);
      previousThemeRef.current = state.activeTheme;
      return;
    }

    // Se não tivermos um tema anterior (primeira montagem em preview mode)
    if (!previousThemeRef.current) {
      applyTheme(state.activeTheme);
      previousThemeRef.current = state.activeTheme;
      return;
    }

    // Se estiver em preview mode
    const changedProperty = state.activeTheme.find(
      (item, index) => item.value !== previousThemeRef.current[index]?.value
    );

    if (changedProperty) {
      const isSpecialField =
        changedProperty.themeStyleCode.includes("is_active") ||
        changedProperty.themeStyleCode.includes("image");

      if (!isSpecialField) {
        applyTheme(state.activeTheme);
      }
    }

    previousThemeRef.current = state.activeTheme;
  }, [state.activeTheme, isPreviewMode]);

  return null;
};

/**
 * @namespace AllRoutes
 * @memberof module:AllRoutes
 * @description Main routing component handling application navigation.
 * Manages route configuration, initial data loading, and theme settings.
 *
 * @component
 * @returns {JSX.Element} AllRoutes component
 *
 * @example
 * <AllRoutes />
 *
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @since 1.0.0
 * @version 1.0.0
 */
export const AllRoutes = () => {
  const [darkMode] = useState( JSON.parse(localStorage.getItem(`darkMode`)) || false );
  const [state, dispatch] = useContext(AppContext);
  const [cookie, setCookie] = useCookies([ "platformUid", "currentHotel", "detailId", "sUid", ]); // eslint-disable-line
  const [isAdmin, setIsAdmin] = useState();
  const [isHotelAdmin, setIsHotelAdmin] = useState();
  const [isPreviewMode, setIsPreviewMode] = useState();
  const pathname = window.location.pathname; // Obter o pathname completo
  const parts = pathname.split("/"); // Dividir o pathname por '/' para obter as partes
  const [mounted, setMounted] = useState(false);

  dayjs.locale(
    localStorage.getItem("userLanguage")
      ? localStorage.getItem("userLanguage").substring(0, 2)
      : "pt"
  );

  // Inicializa platformUid, detailId e sUid com valores padrão
  let platformUid, detailId, sUid;

  useEffect(() => {
    setMounted(true);
  }, []);

  /**
   * @function useEffectDarkMode
   * @memberof module:AllRoutes.AllRoutes
   * @description Effect that manages dark mode and route initialization.
   *
   * @effect Updates dark mode settings and initializes route parameters
   * @dependencies []
   *
   * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @since 1.0.0
   * @version 1.0.0
   */
  useEffect(() => {
    localStorage.setItem(`darkMode`, JSON.stringify(darkMode));
    if (darkMode) {
      document.documentElement.classList.add(`dark`);
    } else {
      document.documentElement.classList.remove(`dark`);
    }

    // Verifica se o pathname começa com '/overview'
    if (pathname.includes("/home")) {
      platformUid = parts[2].toLocaleLowerCase(); // Obtém platformUid
      sUid = parts[3]; // Obtém sUid
    }
    // Verifica se o pathname começa com '/hotel'
    else if (pathname.includes("/hotel/")) {
      platformUid = parts[2].toLocaleLowerCase(); // Obtém platformUid
      detailId = parts[3]; // Obtém detailId
    } else if (pathname.includes("/admin")) {
      setIsAdmin(true);
    } else if (pathname.includes("/previewmode")) {
      setIsPreviewMode(true);
    } else if (pathname.includes("/hotelpanel")) {
      setIsHotelAdmin(true);
    }
  }, []); // eslint-disable-line

  /**
   * @function useEffectFetchHotelData
   * @memberof module:AllRoutes.AllRoutes
   * @description Effect that handles hotel data fetching.
   *
   * @effect Fetches and sets hotel data
   * @dependencies [platformUid, isAdmin, isHotelAdmin]
   *
   * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @since 1.0.0
   * @version 1.0.0
   */
  useEffect(() => {
    const cookieOptions = { path: "/" };

    const fetchHotelData = async () => {
      if (window.location.pathname.includes("/admin")) {
        return; // Sai do useEffect se estiver na rota admin
      }

      if (window.location.pathname.includes("/previewmode")) {
        return; // Sai do useEffect se estiver na rota admin
      }

      if (window.location.pathname.includes("/hotelpanel")) {
        return; // Sai do useEffect se estiver na rota admin
      }

      // Queremos buscar se:
      // - Não temos um hotel no state (pmsId é null)
      // OU
      // - O platformUid atual é diferente do hotelId armazenado
      const shouldFetch =
        !state.currentHotel?.hotelId.toLowerCase() ||
        platformUid.toLowerCase() !==
          state.currentHotel?.hotelId?.substring(0, 8).toLowerCase();

      if (shouldFetch) {
        try {
          dispatch({ type: "SET_LOADING", payload: true });
          const hotelData = await getHotelById(platformUid.toLowerCase());
          dispatch({ type: "SET_CURRENT_HOTEL", payload: hotelData });
          setCookie("currentHotel", JSON.stringify(hotelData), cookieOptions);
          setCookie("platformUid", platformUid.toLowerCase(), cookieOptions);
          setCookie("cultureCode", hotelData?.defaultCultureCode, {
            path: "/",
          });
          if (detailId) {
            setCookie("detailId", detailId, cookieOptions);
          }
          if (sUid) {
            setCookie("sUid", sUid, cookieOptions);
          }
          if (!localStorage.getItem("userLanguage")) {
            localStorage.setItem("userLanguage", hotelData?.defaultCultureCode);
          }
        } catch (error) {
          console.error("App: Error fetching hotel info:", error);
        } finally {
          dispatch({ type: "SET_LOADING", payload: false });
        }
      }
    };

    if (isAdmin || isHotelAdmin || isPreviewMode) {
      return;
    }

    fetchHotelData();
  }, [platformUid, isAdmin, isHotelAdmin]); // eslint-disable-line

  /**
   * @function useEffectFetchHotelInitialData
   * @memberof module:AllRoutes.AllRoutes
   * @description Effect that fetches initial hotel data.
   *
   * @effect Updates hotel initial data in global state
   * @dependencies [state.currentHotel, userLanguage, isAdmin]
   *
   * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @since 1.0.0
   * @version 1.0.0
   */
  useEffect(() => {
    if (window.location.pathname.includes("/previewmode")) {
      return; // Sai do useEffect se estiver na rota admin
    }

    const fetchHotelInitialData = async () => {
      if (state.currentHotel && state.currentHotel.hotelId) {
        try {
          const hotelInitialData = await getHotelInitialData( state.currentHotel, localStorage.getItem("userLanguage") || "pt-PT" );
          dispatch({ type: "SET_HOTEL_INITIALDATA", payload: hotelInitialData.InitialData, });
        } catch (error) {
          console.error("Error loading initialData-AllRoutes:", error);
        }
      }
    };

    if (!isAdmin && !isPreviewMode) {
      fetchHotelInitialData();
    }
  }, [state.currentHotel, localStorage.getItem("userLanguage"), isAdmin]); // eslint-disable-line

  /**
   * @function useEffectFetchGxpInitialData
   * @memberof module:AllRoutes.AllRoutes
   * @description Effect that fetches GXP initial data.
   *
   * @effect Updates GXP data in global state
   * @dependencies [state.currentHotel, isAdmin]
   *
   * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @since 1.0.0
   * @version 1.0.0
   */
  useEffect(() => {
    const fetchGxpInitialData = async () => {
      if (state.currentHotel && state.currentHotel.hotelId) {
        try {
          const gxpInitialData = await getGXPInitialData(
            state.currentHotel.hotelId
          );
          dispatch({
            type: "SET_ACTIVE_THEME",
            payload: gxpInitialData.activeTheme,
          });
          dispatch({
            type: "SET_GXP_PARAMETERS",
            payload: gxpInitialData.parameters,
          });
        } catch (error) {
          console.error("Error loading initialData-AllRoutes:", error);
        }
      }
    };

    if (!isAdmin && !isPreviewMode) {
      fetchGxpInitialData();
    }
  }, [state.currentHotel, isAdmin]); // eslint-disable-line

  return (
    <div className="">
      {mounted && (
        <ThemeController
          theme={state.activeTheme}
          isPreviewMode={isPreviewMode}
        />
      )}
      <Routes className="">
        <Route path="/admin/login" element={<LoginHostOne />} />

        <Route path="/admin/register" element={<Register />} />

        <Route
          path="/admin"
          element={
            <ProtectedRoute>
              <AdminPanel />
            </ProtectedRoute>
          }
        >
          <Route path="/admin/confirmregister" element={<ConfirmRegister />} />
          <Route path="/admin/inactiveuser" element={<InactiveUser />} />

          <Route path="/admin/dashboard" element={<Dashboard />} />
          <Route path="/admin/users" element={<Users />} />

          <Route path="/admin/hotels" element={<HotelList />} />
          <Route path="/admin/grouphotels" element={<GroupHotelList />} />
          <Route path="/admin/parameters/parameters" element={<Parameters />} />
          <Route path="/admin/themes/stylecategories" element={<StyleCategory />} />
          <Route path="/admin/themes/stylesubcategories" element={<StyleSubCategory />} />
          <Route path="/admin/themes/themestyles" element={<ThemeStyle />} />
          <Route path="/admin/themes/themesdefault" element={<ThemeDefault />} />
        </Route>
        <Route path="/admin" element={<Navigate to="/admin/dashboard" replace />} />

        <Route path="/hotelpanel/:hotelId" element={<HotelPanel />}>
          <Route path="/hotelpanel/:hotelId/parameters" element={<ParametersHotelPanel />} />
          <Route path="/hotelpanel/:hotelId/themes" element={<ThemeHotelPanel />} />
        </Route>

        <Route element={<RootLayout />}>
          <Route path="/home/:platformUid/:sUid" element={<Home />} />

          <Route path="/hotel/:platformUid/:detailId" element={<HotelHome />} />

          <Route path="/hotel/:platformUid/:detailId/reservation" element={<HotelReservationDetails />} />
          <Route path="/hotel/:platformUid/:detailId/checkout" element={<Checkout />} />
          <Route path="/hotel/:platformUid/:detailId/qrcode" element={<QrCode />} />

          <Route path="/hotel/:platformUid/:detailId/maintenancerequest" element={<MaintenanceRequest />} />

          <Route path="/hotel/:platformUid/:detailId/webtext/:key" element={<WebText />} />

          <Route path="/hotel/:platformUid/:detailId/checkin/:sUid/:resNo" element={<OnlineCheckIn />} />
          <Route path="/hotel/:platformUid/:detailId/checkindirect/:sUid/:resNo" element={<OnlineCheckIn />} />
          <Route path="/hotel/:platformUid/:detailId/checkindirect2/:sUid/:resNo" element={<OnlineCheckIn />} />


          <Route path="/hotel/:platformUid/:detailId/checkin/:sUid/:resNo/scancheck" element={<OnlineCheckIn />} />
          <Route path="/hotel/:platformUid/:detailId/checkindirect/:sUid/:resNo/scancheck" element={<OnlineCheckIn />} />

          <Route path="/hotel/:platformUid/:detailId/emenu-menu/:sUid/:keys" element={<EMenuMenu />} />
          <Route path="/hotel/:platformUid/:detailId/emenu/:sUid/:key" element={<EMenu />} />
          <Route path="/hotel/:platformUid/:detailId/emenu/:sUid/:key/:itemId" element={<EMenu />} />

          <Route path="/hotel/:platformUid/:detailId/treservationmenu/:sUid/:keys" element={<TableReservationMenu />} />
          <Route path="/hotel/:platformUid/:detailId/treservation/:sUid/:key" element={<TableReservation />} />

          <Route path="/hotel/:platformUid/treservationmenudirect/:keys" element={<TableReservationMenu />} />
          <Route path="/hotel/:platformUid/treservationdirect/:key" element={<TableReservation />} />

          <Route path="/hotel/:platformUid/:detailId/emenu/:sUid/:keys" element={<EMenu />} />
        </Route>

        <Route path="/previewmode/onlinecheckindirect/:defaultThemeId" element={<OnlineCheckIn />} />
        <Route path="/previewmode/treservationdirect/:defaultThemeId" element={<TableReservation />} />
        <Route path="/previewmode/headerfooter/:defaultThemeId" element={<HeaderFooterPreview />}
        />
        <Route path="/previewmode/selectsection" element={<SelectSection />} />
        <Route path="/previewmode/previewhome/:defaultThemeId" element={<Home />} />
        <Route path="/previewmode/reservation/:defaultThemeId" element={<HotelReservationDetails />} />
        <Route path="/previewmode/checkout/:defaultThemeId" element={<Checkout />} />
        <Route path="/previewmode/maintenance/:defaultThemeId" element={<MaintenanceRequest />} />
        <Route path="/previewmode/qrcode/:defaultThemeId" element={<QrCode />} />
        <Route path="/previewmode/hotel/:defaultThemeId" element={<HotelHome />} />
        <Route path="/previewmode/emenu/:defaultThemeId" element={<EMenu />} />
        <Route path="/previewmode/emenu/:defaultThemeId/:itemId" element={<EMenu />} />
      </Routes>
    </div>
  );
  // }
};
